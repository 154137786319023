import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Label,

  Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader";

import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";

import {  Link, useHistory, useParams} from "react-router-dom";

import { getPermission, getRolRead } from "../../helpers/backend";
import { rolPermission } from "../../store/roles/actions"
import { useDispatch } from "react-redux";




const PermissionAssign = (props) => {
  const history = useHistory ();
  const dispatch =  useDispatch();
  const rolId = useParams().id

  const [selected, setSelected] = useState(null);
  const [added, setAdded] = useState(false);

  const [rol, setRol] = useState({});
  const getRolData = async () => {
    const response = await getRolRead(rolId);
    const data = response.data;
    setRol(data);
  };
  const [permissionList, setPermissionList] = useState({});
  const getPermissionData = async () => {
    const response = await getPermission();
    const data = response.data;
    setPermissionList(data);
  };

  useEffect(() => {
    getPermissionData();
    getRolData();
 // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [])

 const  permission = Array.isArray(selected) ? selected.map(item => item.value) : []
 
 const onSubmit = (e) => {
  e.preventDefault();
  dispatch(rolPermission({
    roles : rolId,
    permission : permission
  }))
  setAdded(true);
 }

   return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Roles| Asignar Permisos  </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={"Roles"} breadcrumbItem={"Asignar Permisos "} />
          {props.loading ? (
            <Loader />
          ) : (
            <div className="d-flex justify-content-center">
            <Card
                 style={{
                width: "60rem",
              }}>
                {added ? (
                <SweetAlert
                  title="¡Hecho!"
                  success
                  showConfirm={true}
                  onConfirm={() => {
                    history.push('/rol/add_rol');
                  }}
                >
                  Se han
                  agregado los permisos correctamente.
                </SweetAlert>

              ) : null}
              <CardBody>
                <h4 className="card-title">Asignar Permisos al Rol</h4>
                <Form className="container form-control" onSubmit={onSubmit}>                    
                    <div className="mt-3">
                    <h5><b> Rol: </b>{rol.nombre} </h5>
                    <Row className="mt-3 justify-content-center">
                        <Col lg= {7}>
                            <Label>
                               <b> Permisos</b> 
                            </Label>
                            <Select
                            id="permission"
                            name="permission"
                            onChange={(item)=> setSelected(item)}
                            options={Array.isArray(permissionList) ? permissionList.map((item) =>
                                ({ value: item.id, label: item.name })):[] }
                            placeholder ={'Seleccione'}
                            isMulti={true}                    
                            menuPosition="fixed"
                            closeMenuOnSelect={false}
                            >
                                Permisos
                            </Select>
                        </Col>
                    </Row>
                    </div> 

                  <Row className="col-lg-12 justify-content-center mt-5">
                    <Col md={3} >
                      <Link className="btn btn-secondary text-dark " to="/rol/add_rol">
                        <strong> Volver</strong>
                       
                      </Link>

                      <Button 
                      type = 'submit' 
                      className="btn btn-primary mx-1"
                      color="primary">
                        <strong> Guardar</strong>                       
                      </Button>
                    </Col>

                  </Row>
  
                </Form>               
                
              </CardBody>
            </Card>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};


export default PermissionAssign
