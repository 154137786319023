import { call, put, takeEvery } from "redux-saga/effects";

import {
  USER_LIST,
  USER_FILTER,
  USER_CREATE,
  USER_UPDATE,
  USER_DELETE,
  USER_PERMISSION,
  USER_CHANGE_PASSWORD
} from "./actionTypes";

import {
 userListSuccess,
 userCreateSuccess,
 userUpdateSuccess,
 userDeleteSuccess,
 userRequestFailed,
 userPermissionSuccess,
 userChangePasswordSuccess,
} from "./actions";

import {
  getUserList,
  getUserFilter,
  postUserCreate,
  patchUserUpdate,
  deleteUser,
  postPermissionToUser,
  putUpdatePassword,
} from "../../../helpers/backend";

function*userListSaga() {
  console.log("Trayendo lista de user...");
  try {
    const response = yield call(getUserList);
    if (response.status === 200) {
      yield put(userListSuccess(response.data));
    } else {
      yield put(userRequestFailed(response.data));
    }
  } catch (error) {
    yield put(userRequestFailed(error.response.data));
  }
}

function*userCreateSaga({ payload:user }) {
  console.log("Creando nuevo user...");
  try {
    const response = yield call(postUserCreate,user);
    if (response.status === 201) {
      yield put(userCreateSuccess(response.data));
    } else {
      yield put(userRequestFailed(response.data));
    }
  } catch (error) {
    yield put(userRequestFailed(error.response.data));
  }
}

function*userFilterSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getUserFilter, { changeUrl });
    if (response.status === 200) {
      yield put(userListSuccess(response.data));
    } else {
      yield put(userRequestFailed(response.data));
    }
  } catch (error) {
    yield put(userRequestFailed(error.response.data));
  }
}

function*userUpdateSaga({ payload: { id, data } }) {
  console.log("Actualizando datos del USER...");
  try {
    const response = yield call(patchUserUpdate, { id }, data);
    if (response.status === 200) {
      yield put(userUpdateSuccess(response.data));
    } else {
      yield put(userRequestFailed(response.data));
    }
  } catch (error) {
    yield put(userRequestFailed(error.response.data));
  }
}
function*userChangePasswordSaga({ payload: { id, data } }) {
  console.log("Actualizando datos del USER...");
  try {
    const response = yield call(putUpdatePassword, { id }, data);
    if (response.status === 200) {
      yield put(userChangePasswordSuccess(response.data));
    } else {
      yield put(userRequestFailed(response.data));
    }
  } catch (error) {
    yield put(userRequestFailed(error.response.data));
  }
}
function*userDeleteSaga({ payload: { id } }) {
  console.log("Actualizando datos del USER...");
  try {
    const response = yield call(deleteUser, id );
    if (response.status === 204) {
      yield put(userDeleteSuccess(response.data));
    } else {
      yield put(userRequestFailed(response.data));
    }
  } catch (error) {
    yield put(userRequestFailed(error.response.data));
  }
}

function*userPermissionSaga({ payload:permission }) {
  console.log("Creando nuevo user...");
  try {
    const response = yield call(postPermissionToUser,permission);
    if (response.status === 201) {
      yield put(userPermissionSuccess(response.data));
    } else {
      yield put(userRequestFailed(response.data));
    }
  } catch (error) {
    yield put(userRequestFailed(error.response.data));
  }
}
export default function*userSaga() {
  yield takeEvery(USER_LIST,userListSaga);
  yield takeEvery(USER_FILTER,userFilterSaga);
  yield takeEvery(USER_CREATE,userCreateSaga);
  yield takeEvery(USER_UPDATE,userUpdateSaga);
  yield takeEvery(USER_DELETE,userDeleteSaga);
  yield takeEvery(USER_PERMISSION,userPermissionSaga);
  yield takeEvery(USER_CHANGE_PASSWORD,userChangePasswordSaga);
}
