import React, { useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Col,
  Container,
  Row,
} from "reactstrap"
import "react-perfect-scrollbar/dist/css/styles.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ChatMessage from "./ChatMessage";
import ContactsList from "./ContactsList";

const Chat = () => {
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [selectedContactName, setSelectedContactName] = useState("");

  const handleContactClick = (contactId, contactName) => {
    setSelectedContactId(contactId);
    setSelectedContactName(contactName);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Chat </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="TrueContact" breadcrumbItem="Chat" />

          <Row>
            <Col lg={4}>
                    <ContactsList onContactClick={handleContactClick} />    
            </Col>
            <Col lg={8}>
                  <ChatMessage contactId={selectedContactId} contactName={selectedContactName}/>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Chat
