import React from 'react'
import Greforce from '../../../assets/images/marcas/Greforce - Sin fondo.png'
import Sunny from '../../../assets/images/marcas/Sunny - Sin fondo.png'
import Wanli from '../../../assets/images/marcas/Wanli - Sin fondo.png'
import Torque from '../../../assets/images/marcas/Troque - Sin fondo.png'
import Sunfull from '../../../assets/images/marcas/Sunfull - Sin fondo.png'
import Hevos from '../../../assets/images/marcas/Hevos - Sin fondo.png'
import Golden from '../../../assets/images/marcas/Golden unicorn - Sin fondo.png'

const DistribucionesLlantas = () => {

  const data = [
    {
      id: 1,
      statistics: '400+',
      descriptions: 'Clientes satisfechos'

    },
    {
      id: 2,
      statistics: '100%',
      descriptions: 'Entregas completadas'

    },
    {
      id: 3,
      statistics: '10K',
      descriptions: 'Productos a desposición'

    },
    {
      id: 4,
      statistics:'200+',
      descriptions: 'Reseñas de 5-Estrellas'

    },
  ]

  return (
    <section id='distribuciones' className='section_distribuciones'>
      <div data-aos="fade-left" className='distribuciones_container'>
        <h1 className='title_distri'>¡Distribuimos llantas a nivel nacional!</h1>
        <p className='p_distri'>Hacienda tus compras siempre seguras.</p>
      </div>

      <div data-aos="fade-right" className='statistics_container'>
        {
          data.map((dat) => (
            <div className='content_statistics' key={dat.id}>
              <h1 className='title_statistics'>{dat.statistics}</h1>
              <p className='p_descriptions'>{dat.descriptions}</p>
            </div>
          ))
        }
      </div>
      <div data-aos="fade-left" className='marcas'>
        <p className='title_marcas'>Nuestros Aliados</p>

        <div className='container_marcas'>
          <div className='marcas_content'>
            <img className='img_marcas' src={Greforce} alt="" />
          </div>
          
          <div className='marcas_content'>
            <img className='img_marcas' src={Sunny} alt="" />
          </div>

          <div className='marcas_content'>
            <img className='img_marcas' src={Wanli} alt="" />
          </div>

          <div className='marcas_content'>
            <img className='img_marcas' src={Torque} alt="" />
          </div>

          <div className='marcas_content'>
            <img className='img_marcas' src={Sunfull} alt="" />
          </div>

          <div className='marcas_content'>
            <img className='img_marcas' src={Hevos} alt="" />
          </div>

          <div className='marcas_content'>
            <img className='img_marcas' src={Golden} alt="" />
          </div>
        </div>

      </div>
    </section>
  )
}

export default DistribucionesLlantas