// CAMPAIGN requests
export const CAMPAIGN_LIST = "CAMPAIGN_LIST";
export const CAMPAIGN_CREATE = "CAMPAIGN_CREATE";
export const CAMPAIGN_FILTER = "CAMPAIGN_FILTER";
export const CAMPAIGN_UPDATE = "CAMPAIGN_UPDATE";
export const CAMPAIGN_DELETE = "CAMPAIGN_DELETE";
export const CAMPAIGN_READ = "CAMPAIGN_READ";

// CAMPAIGN success
export const CAMPAIGN_LIST_SUCCESS = "CAMPAIGN_LIST_SUCCESS";
export const CAMPAIGN_CREATE_SUCCESS = "CAMPAIGN_CREATE_SUCCESS";
export const CAMPAIGN_UPDATE_SUCCESS = "CAMPAIGN_UPDATE_SUCCESS";
export const CAMPAIGN_DELETE_SUCCESS = "CAMPAIGN_DELETE_SUCCESS";
export const CAMPAIGN_READ_SUCCESS = "CAMPAIGN_READ_SUCCESS";

// CAMPAIGN failed
export const CAMPAIGN_REQUEST_FAILED = "CAMPAIGN_REQUEST_FAILED";
