import React from 'react'
import ListCart from './components/ListCart'
import './style/CartStyle.css'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom'

const Cart = ({ cart }) => {
  const history = useHistory()


  const redirect = (path) => {
    history.push(`${path}`)
  };

  return (
    <div className={`container_cart ${cart ? 'cart_open' : 'cart_closed'}`}>
      <h3 className='title_cart'>Tu carrito de compra</h3>
      <ListCart />
      <div className='content_buttons_actions'>
        <Link className="button_ir_carrito" onClick={() => redirect("/mi-carrito")}>Ir al carrito</Link>
      </div>
    </div>
  )
}

export default Cart