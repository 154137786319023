import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend, ResponsiveContainer } from 'recharts';
import { getReportsForHourList } from "../../../helpers/backend";
import { Card } from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";

const ReportForHour = () => {
	const [selectedRange, setSelectedRange] = useState('today');
	const [data, setData] = useState({});


	const getReportData = async (range) => {
		const today = new Date();
		let fechaInicio;
		let fechaFin;

		if (range === 'lastWeek') {
			const lastWeek = new Date(today);
			lastWeek.setDate(today.getDate() - 7);
			fechaInicio = lastWeek.toISOString().split('T')[0];
			fechaFin = today.toISOString().split('T')[0];
		} else {
			fechaInicio = today.toISOString().split('T')[0];
			fechaFin = today.toISOString().split('T')[0];
		}

		const response = await getReportsForHourList(`?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&estado=&agents=&campaign=`);
		const data = response.data; // Supongamos que la respuesta es el objeto de datos esperado  
		setData(data);
	};

	useEffect(() => {
		getReportData(selectedRange);
  // eslint-disable-next-line
	}, [selectedRange]);

  const transformData = (data) => {  

  
    return Object.entries(data)  
      .filter(([key]) => key !== "total_gestiones") // Excluye total_gestiones  
      .map(([timeSlot, { contador }]) => ({  
        timeSlot,  
        Gestiones: contador, // Cambiamos contador por Gestiones  
      }));  
  };  

  const chartData = transformData(data);  

	return (
    <Card
      className="py-3 px-4"
      style={{ backgroundColor: "#f6f8fc", borderColor: "#f6f8fc", boxShadow: "0 4px 10px -1px rgb(0 0 0 / 0.1), 0 2px 7px -2px rgb(0 0 0 / 0.1)" }}
    >
      <div className="d-flex justify-content-between ">
        <p className="fw-semibold font-size-18">Por hora</p>
        <select
          style={{
            maxWidth: "170px",
            maxHeight: "34px",
            backgroundColor: "#f6f8fc",
          }}
          className="form-select font-size-13"
          value={selectedRange}
          onChange={(e) => setSelectedRange(e.target.value)}
        >
          <option value="lastWeek">Última Semana</option>
          <option value="today">Hoy</option>
        </select>
      </div>
      <ResponsiveContainer width="100%" height={280}>
        <BarChart
          className="p-1"
          data={chartData}
          margin={{ top: 10, right: 35, bottom: 0, left: -20 }}
        >
          <XAxis dataKey="timeSlot" />
          <YAxis
            domain={[0, "dataMax + 1"]}
            tickCount={Math.max(...chartData.map((item) => item.Gestiones)) + 1}
          />
          <Tooltip />
          <Legend />
          <CartesianGrid strokeDasharray="3 3" />
          <Bar dataKey="Gestiones" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
      <div className="p-1 d-flex justify-content-end">
        <Link to="report_for_hour" className="btn btn-success btn-sm">
          Consultar reportes
        </Link>
      </div>
    </Card>
  );
};

export default ReportForHour;