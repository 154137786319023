import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { 
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Form,
  Table,
  Input,
  Button,
  InputGroup,
 } from "reactstrap"
import { connect, useDispatch } from "react-redux";
import { withRouter, Link} from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader"
import Pagination from "../../components/Common/Pagination"

import { accountList, accountFilter } from "../../store/account/actions"

const Account = (props) => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(accountFilter(`?search=${search}`));
  };

  const resetSearch = () => {
    dispatch(accountFilter(`?search=`));
    setSearch("");
  };


  useEffect(() => {

    dispatch(accountList())

  }, [dispatch])

  const params = {
    count: props.account.count,
    next: props.account.next,
    previous: props.account.previous,
    results: props.account.results,
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Cuentas </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="TrueContact" breadcrumbItem=" Cuentas" />

          {props.loading ? (
            <Loader />
          ) : (
            <div>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={7}>
                      <Link
                        className="btn btn-primary waves-effect waves-light mb-1"
                        to="/account/create"
                      >
                        Agregar
                      </Link>
                    </Col>
                    <Col md={5}>
                      <Form className="search-box" onSubmit={onSubmit}>
                        <InputGroup>
                          <Input
                            type="text"
                            className="form-control"
                            name="search"
                            placeholder="Documento, nombre..."
                            value={search}
                            onChange={onChange}
                          />
                          <i className="bx bx-search-alt search-icon" />
                          <Button color="primary" onClick={resetSearch}>
                            <span className=" bx bx-x fa-lg align-middle"></span>
                          </Button>
                        </InputGroup>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                {params.results ? (
                  <CardBody className="ps-5 pe-5">
                    <h2 className="mb-3 font-size-20">Lista de Cuentas</h2>
                    <div className="table-responsive">
                      <Table
                        className="table  align-middle table-centered  mb-3"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <thead className="table-dark">
                          <tr>
                            <th>Documento</th>
                            <th>Nombre</th>
                            <th>Dirección</th>
                            <th>Teléfono</th>
                            <th>Ciudad</th>
                            <th>Acción</th>
                          </tr>
                        </thead>
                        <tbody>
                          {params.results.map((account, idx) => (
                            <tr key={idx}>
                              <th scope="row">{account.documentNumber}</th>
                              <td>{account.name}</td>
                              <td>{account.address}</td>
                              <td>{account.phone}</td>
                              <td>{account.city}</td>
                              <td>
                                <Link to={`/account/update/${account.id}`}>
                                  <span className="bx bx-edit fa-lg align-middle"></span>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    <Pagination
                      count={params.count}
                      next={params.next}
                      previous={params.previous}
                      limit={30}
                      baseUrl="produccion/account/"
                      filterFunction={accountFilter}
                    />
                  </CardBody>
                ) : (
                  <CardBody>
                    <h4 className="card-title">No se encontraron resultados</h4>
                  </CardBody>
                )}
              </Card>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}


const mapStateToProps = (state) => {
  const { error, loading, account } = state.Account;
  return { error, loading, account };
};

export default withRouter(connect(mapStateToProps)(Account));
