import React, { useState, useEffect} from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert"

import { connect, useDispatch} from "react-redux";
import { withRouter, Link,} from "react-router-dom";
import { contactsUpdate } from "../../../store/contacts/actions";
import { getDataUser } from "../../../helpers/backend";
import { MetaTags } from "react-meta-tags";
import Breadcrumb from "../../../components/Common/Breadcrumb";



const UserProfile = (props) => {
const dispatch= useDispatch();


const [showAlert, setShowAlert] = useState(false)
    const getData = async () => {
      const response = await getDataUser();
      const data = response.data[0];
      setFormData({
        first_name: data.first_name,
        last_name: data.last_name,
        contactType: data.contactType,
        documentType: data.documentType,
        documentNumber: data.documentNumber,
        gender:data.gender,
        birthdate: data.birthdate,
        city:data.city,
        id: data.id,

      }); console.log(response);
    };
     
    const [formData, setFormData] = useState({
      first_name:"",
      last_name:"",
      contactType:"",
      documentType:"",
      documentNumber:"",
      gender:"",
      birthdate:"",
      city: "",
 });
 const {  
  first_name, 
  last_name, 
  contactType,
  documentType,  
  documentNumber, 
  gender,
  birthdate, 
  city, 
} =
   formData;

  useEffect(() => {
    getData();

  },[]);
  

 const onChange = (e) => {
   setFormData({ ...formData, 
    [e.target.name]: e.target.value });
 };
   const onSubmit = (e) => {
   e.preventDefault();
   dispatch(contactsUpdate( formData.id, formData ));
   setShowAlert(true)
  };

  return (
    <React.Fragment>
    <div className="page-content">
        <MetaTags>
          <title>TrueContact  Perfil de Usuario </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={"TrueContact"} breadcrumbItem={"Perfil de Usuario"} />

          <Row className="justify-content-center">
           <Card className="col-lg-8"> 
           {props.contactsUpdated.id && showAlert ? (
                <SweetAlert
                  title="Hecho!"
                  success                  
                  showConfirm={false}
                  timeout={2000}
                  onConfirm={() =>{
                    setShowAlert(false)
                  }}
                  
                >
                  Se ha editado el usuario con éxito 
                </SweetAlert>
              ) : null}
              <CardBody>
                <h5><b>Perfil de Usuario </b></h5>
                <Form className="form-control needs-validation" onSubmit={onSubmit}>                    
                  <Row>
                    <Col lg={6} className="mb-3">                    
                      <Label
                        for="nombre"
                        className="form-label"
                      >
                        Nombres
                      </Label>
                      <Input 
                        id="first_name"
                        name="first_name"
                        type="text"
                        onChange={onChange}
                        value={first_name}
                        valid={props.error.first_name
                          ? true
                          : props.contactsCreated && false
                        }
                        invalid={
                          props.error
                            ? props.error.first_name
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.first_name}</FormFeedback>
                
                    </Col>                  
                    <Col lg={6}  className="mb-3">                    
                      <Label
                        for="apellido"
                        className="form-label"
                      >
                        Apellidos
                      </Label>
                      <Input
                        id="last_name"
                        name="last_name"
                        type="text"
                        onChange={onChange}
                        value={last_name}                     
                        valid={props.error.last_name
                          ? true
                          : props.contactsCreated && false
                        }
                        invalid={
                          props.error
                            ? props.error.last_name
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.last_name}</FormFeedback>
                     
                    </Col>
                  </Row>
                 
                  <Row>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="contactType"
                        className="form-label"
                      >
                      Tipo de Contacto
                      </Label>

                    <select
                            className="form-select"
                            id="contactType"
                            name="contactType"
                            value={contactType}                            
                            onChange={onChange}
                            >
                            <option value ="PERSONA NATURAL">
                              Persona Natural
                            </option>
                            <option value ="EMPRESA">
                              Empresa
                            </option>
                            <option value ="AMBOS">
                              Ambos
                            </option>
                            <option value ="OTRO">
                              Otro
                            </option>
                    </select>
                            
                         
                    </Col>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="contactType"
                        className="form-label"
                      >
                      Tipo de identificación
                      </Label>
                      <select
                            className="form-select"
                            id="documentType"
                            name="documentType"
                            value={documentType}                            
                            onChange={onChange}
                            >
                            <option value ="CEDULA">
                            Cédula Ciudadanía
                            </option>
                            <option value ="PASAPORTE">
                            Pasaporte
                            </option>
                            <option value ="CEDULA_EXTRANJERIA">
                            Cedula de Extranjería
                            </option>
                            <option value ="Nit">
                            NIT
                            </option>
                            <option value ="OTRO">
                            Otro
                            </option>
                    </select>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="documentNumber"
                        className="form-label"
                      >
                        Documento
                      </Label>
                      <Input
                        id="documentNumber"
                        name="documentNumber"
                        type="text"
                        onChange={onChange}
                        value={documentNumber}
                        valid={props.error.documentNumber
                          ? true
                          : props.contactsCreated && false
                        }
                        invalid={
                          props.error
                            ? props.error.documentNumber
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.documentNumber}</FormFeedback>
                    </Col>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="gender"
                        className="form-label"
                      >
                      Género
                      </Label>
                      <select
                            className="form-select"
                            id="gender"
                            name="gender"
                            value={gender}                            
                            onChange={onChange}
                            >
                            <option value ="Masculino">
                            Masculino
                            </option>
                            <option value ="Femenino">
                            Femenino
                            </option>
                            <option value ="Otro">
                            Otro
                            </option>
                    </select>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="birthdate"
                        className="form-label"
                      >
                        Fecha de Nacimiento
                      </Label>
                      <Input
                        id="birthdate"
                        name="birthdate"
                        type="date"
                        onChange={onChange}
                        value={birthdate}
                        valid={props.error.birthdate
                          ? true
                          : props.contactsCreated && false
                        }
                        invalid={
                          props.error
                            ? props.error.birthdate
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.birthdate}</FormFeedback>
                    </Col>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="city"
                        className="form-label"
                      >
                        Cuidad
                      </Label>
                      <Input
                        id="city"
                        name="city"
                        type="text"
                        onChange={onChange}
                        value={city}
                        valid={props.error.city
                          ? true
                          : props.contactsCreated && false
                        }
                        invalid={
                          props.error
                            ? props.error.city
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.city}</FormFeedback>
                    </Col>
                  </Row>
    
                  <Row className="col-lg-12 justify-content-center mt-3 mb-3">
                    <Col md={4}>
                      <Link className="btn btn-secondary text-dark ms-1" to="/dashboard">
                        Volver
                      </Link>
                      <Button color="primary" type="submit" className="ms-1">
                        Actualizar
                      </Button>
                    </Col>
                  </Row>     
        
                </Form>
              </CardBody>

            </Card>
          </Row>
          </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, contacts, contactsUpdated } = state.Contacts;
  return { error, loading, contacts, contactsUpdated };
};
contactsUpdate.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  contactsUpdated: PropTypes.object,
};
export default withRouter(connect(mapStateToProps,{ contactsUpdate })(UserProfile));