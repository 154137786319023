import {
  CALIFICACION_CONTACTO_LIST,
  CALIFICACION_CONTACTO_LIST_CHANGE,
  CALIFICACION_CONTACTO_LIST_SUCCESS,
  CALIFICACION_CONTACTO_LIST_FAILED,
  CALIFICACION_CONTACTO_LISTA,
  CALIFICACION_CONTACTO_LISTA_SUCCESS,
  CALIFICACION_CONTACTO_LISTA_FAILED,
  CALIFICACION_CONTACTO_ADD,
  CALIFICACION_CONTACTO_ADD_SUCCESS,
  CALIFICACION_CONTACTO_ADD_FAILED,
  CALIFICACION_CONTACTO_DELETE,
  CALIFICACION_CONTACTO_DELETE_SUCCESS,
  CALIFICACION_CONTACTO_DELETE_FAILED,
} from "./actionTypes";

const initialState = {
  calificacionContacto: [],
  calificacionContactoAdded: {},
  loading: false,
  error: "",
};

const calificacionContacto = (state = initialState, action) => {
  switch (action.type) {

    case CALIFICACION_CONTACTO_LIST:
      state = {
        ...state,
        loading: true,
      };
      return state;

      case CALIFICACION_CONTACTO_LIST_CHANGE:
        state = {
          ...state,
          loading: true,
        };
        return state;
    case CALIFICACION_CONTACTO_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        calificacionContacto: action.payload,
        calificacionContactoAdded: {},
      };
      return state;
    case CALIFICACION_CONTACTO_LIST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      return state;

    case CALIFICACION_CONTACTO_LISTA:
      state = {
        ...state,
        loading: true,
      };
      return state;

    case CALIFICACION_CONTACTO_LISTA_SUCCESS:
      state = {
        ...state,
        loading: false,
        calificacionContacto: action.payload,
        calificacionContactoAdded: {},
      };
      return state;
    case CALIFICACION_CONTACTO_LISTA_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      return state;

    case CALIFICACION_CONTACTO_ADD:
      state = {
        ...state,
        loading: true,
      };
      return state;
    case CALIFICACION_CONTACTO_ADD_SUCCESS:
      state = {
        ...state,
        loading: false,
        calificacionContactoAdded: action.payload,
        error: "",
      };
      return state;
    case CALIFICACION_CONTACTO_ADD_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      return state;


    case CALIFICACION_CONTACTO_DELETE:
      state = {
        ...state,
        loading: true,
      };
      return state;
    case CALIFICACION_CONTACTO_DELETE_SUCCESS:
      state = {
        ...state,
        loading: false,
        calificacionContactoDeleted: action.payload,
        error: "",
      };
      return state;
    case CALIFICACION_CONTACTO_DELETE_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      return state;

    
    default:
      state = { ...state };
      break;
  }
    
  return state;
};

export default calificacionContacto;
