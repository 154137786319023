// LEADS requests
export const LEADS_LIST = "LEADS_LIST";
export const LEADS_CREATE = "LEADS_CREATE";
export const LEADS_FILTER = "LEADS_FILTER";
export const LEADS_UPDATE = "LEADS_UPDATE";
export const LEADS_DELETE = "LEADS_DELETE";
export const LEADS_READ = "LEADS_READ";

// LEADS success
export const LEADS_LIST_SUCCESS = "LEADS_LIST_SUCCESS";
export const LEADS_CREATE_SUCCESS = "LEADS_CREATE_SUCCESS";
export const LEADS_UPDATE_SUCCESS = "LEADS_UPDATE_SUCCESS";
export const LEADS_DELETE_SUCCESS = "LEADS_DELETE_SUCCESS";
export const LEADS_READ_SUCCESS = "LEADS_READ_SUCCESS";

// LEADS failed
export const LEADS_REQUEST_FAILED = "LEADS_REQUEST_FAILED";
