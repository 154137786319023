import React, { useEffect, useState } from "react";
import { getReportSupervisor } from "../../../helpers/backend";
import { Card, Row } from "reactstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


export const AgentsState = () => {
  const history = useHistory();
  const [data, setData] = useState([]);  


  const getReportData = async () => { 
    const response = await getReportSupervisor();  
    setData(response.data.agentes);  
  };
  
  useEffect(() => {  
    getReportData();  
  }, [])

  const redirect = (path) => {
    history.push(`${path}`)
  };

  return (
    <React.Fragment>
      <Row>
        <Card
          className="p-0"
          style={{
            cursor: "pointer",
            backgroundColor: "#f6f8fc",
            borderColor: "#f6f8fc",
            marginTop: "4px",
            borderRadius: "5px",
            boxShadow:
              "0 4px 10px -1px rgb(0 0 0 / 0.1), 0 2px 7px -2px rgb(0 0 0 / 0.1)",
          }}
          onClick={() => redirect('/report_supervision')}
        >
          <table className="tabla text-center mb-2">
            <thead className="headerTable font-size-16">
              <tr>
                <th className="py-2">Agentes</th>
                <th className="py-2">Estado</th>
              </tr>
            </thead>
            <tbody>
              {data.map((agente) => (
                <tr className="animated" key={agente.id}>
                  <td className=" font-size-14 py-2">{agente.full_name}</td>
                  <td className="  py-2">
                    {agente.status === "CONECTADO" ? (
                      <span className="badge text-success">Conectado</span>
                    ) : agente.status === "DESCONECTADO" ? (
                      <span className="badge text-danger">Desconectado</span>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
      </Row>
    </React.Fragment>
  );
}
