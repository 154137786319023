import React from 'react';
import { Modal, Card, CardBody, Col, Row } from 'reactstrap';


const TimeLine = ({ isOpen, onClose, GestionLead }) => {
  console.log(GestionLead);

  const data = GestionLead[0]

  const estados = {
    SIN_ASIGNAR: { className: "bg-primary", text: "Asignado" },
    PENDIENTE: { className: "bg-warning", text: "Pendiente" },
    FINALIZADO: { className: "bg-success", text: "Finalizado" },
  };

  // Crear una lista de eventos en la línea de tiempo
  const timelineEvents = [
    {
      estado: "SIN_ASIGNAR",
      fecha: data.created_date,
      duracion: null,
      user: data.user,
    },
    {
      estado: "PENDIENTE",
      fecha: data.fecha_asignacion,
      duracion: data.duration_asignacion,
      user: data.user,
    },
    {
      estado: "FINALIZADO",
      fecha: data.fecha_finalizacion,
      duracion: data.duration_gestion,
      total: data.total_duration,
      user: data.user,
    }
  ];

  function Status({ estado }) {
    const estadoActual = estados[estado];
    return (
      <div
        className={`badge ${estadoActual.className} font-size-13`}
        style={{
          borderRadius: "12px",
          padding: "5px 10px",
          color: "#fff",
          fontWeight: "bold",
          textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
          border: "2px solid #fff",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)"
        }}
      >
        {estadoActual.text}
      </div>
    );
  }


  return (
    <Modal size='md' isOpen={isOpen} toggle={onClose}>
      <div className='p-3'>
        <h4>{data.lead}</h4>
        <Row className="justify-content-center">
          <Col xl={12}>
            <div className={`timeline`}>
              {timelineEvents.map((item, idx) => (
                <div className={`timeline-item ${idx % 2 === 0 ? 'timeline-left' : ''}`} key={idx}>
                  <div className="timeline-block">
                    <Card className="timeline-box">
                      <CardBody className='' style={{ lineHeight: "0.5rem" }}>
                        <div style={{ zIndex: '9999' }}>
                          <b className={`position-absolute top-0 ${idx % 2 === 0 ? 'start-0' : 'end-0'} m-2`}>
                            <Status estado={item.estado} />
                          </b>
                        </div>
                        <span className="timeline-icon"></span>
                        <p className="mt-4 font-size-12"><b>Fecha: </b>{item.fecha}</p>
                        {item.duracion && (
                          <p className="font-size-12"><b>Duración: </b>{item.duracion}</p>
                        )}
                        {item.total && (
                          <p className="font-size-12"><b>Duración: </b>{item.total}</p>
                        )}
                        <p className="font-size-12"><b>Usuario: </b>{item.user}</p>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default TimeLine;
