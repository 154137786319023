/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { TbCamera } from "react-icons/tb";
import { TbChartHistogram } from "react-icons/tb";
import { Link } from "react-router-dom";
import LoadingCarga from "../../Loading/LoadingCarga";
import { getProductosFilter, getProductosList } from "../../../helpers/backend";
import Pagination from "../../../components/Common/Pagination_ansync";
import Llanta from "../../../assets/images/llantas/neumatico-albacete-vector.png";

const ListaProductos = ({
  searchProductos,
  loading,
  setLoading,
  refresh,
  categoria,
  medida,
  rin,
  marca,
  refreshFiltro,
  setRefreshFiltro,
}) => {
  const [params, setParams] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });

  const BASE_URL = process.env.REACT_APP_API_URL;

  // FUNCIONES
  const getProductos = async () => {
    const response = await getProductosList();
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });

    setLoading(false);
  };
  console.log(params.results);

  const getFilterDataFiltros = async () => {
    setLoading(true);
    const response = await getProductosFilter(
      `?categoría=${categoria}&marca=${marca}&referencia=${medida}&rin=${rin}`
    );
    console.log(response);

    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setRefreshFiltro();
    setLoading(false);
  };

  const getFilterData = async (url) => {
    setLoading(true);
    const response = await getProductosFilter(url);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getProductos();
  }, []);

  useEffect(() => {
    if (refresh) {
      getProductos();
    }
  }, [refresh]);

  useEffect(() => {
    if (refreshFiltro) {
      getFilterDataFiltros();
    }
  }, [refreshFiltro]);

  const filteredLlantas = Array.isArray(params.results)
    ? params.results.filter((producto) =>
        producto.name.toLowerCase().includes(searchProductos.toLowerCase())
      )
    : [];

  return (
    <section className="section_table">
      <table className="tabla_productos" responsive>
        <thead className="thead_lista_productos">
          <tr className="th_lista">
            <th style={{ textAlign: "center" }}>
              <TbCamera size={18} strokeWidth={2.5} />
            </th>
            <th>Nombre</th>
            <th>Estado</th>
            <th>Precio</th>
            <th>Categoría</th>
            <th>Fecha</th>
            <th>Estadística</th>
            <th
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  paddingTop: "15px",
                }}
              >
                <label
                  className="label_imagen_producto_vehiculo"
                  htmlFor="imagenes_producto"
                >
                  <TbCamera size={18} strokeWidth={2.5} />
                </label>
                <input
                  className="input_imagen_producto"
                  id="imagenes_producto"
                  type="file"
                  // onChange={handleImagenChange}
                  // disabled={selectedProduct.length === 0}
                />
              </th>
          </tr>
        </thead>
        <tbody className="tbody_lista_productos">
          {loading ? (
            <tr>
              <td colSpan="7" style={{ textAlign: "center" }}>
                <LoadingCarga />
              </td>
            </tr>
          ) : filteredLlantas.length > 0 ? (
            filteredLlantas.map((producto) => {
              const imageUrl =
                producto.imagenes && producto.imagenes[0]
                  ? `${BASE_URL.replace("/api", "")}${
                      producto.imagenes[0].imagen
                    }`
                  : null;

              return (
                <tr key={producto.id} className="td_list">
                  
                  <td className="content_img_producto">
                    {imageUrl ? (
                      <img
                        className="img_producto"
                        style={{ paddingTop: ".5rem" }}
                        src={imageUrl}
                        alt={producto.name}
                      />
                    ) : (
                      <img
                        className="img_producto"
                        src={Llanta}
                        alt={producto.name}
                      />
                    )}
                  </td>
                  <td style={{ color: "#e0324b" }} className="content_td_name">
                    <Link to={`productos/${producto.id}`}>
                      <p className="name_td">{producto.name}</p>
                    </Link>
                    <div className="buttons_action_productos">
                      <div>
                        <p className="id_producto">ID: {producto.id}</p>
                      </div>
                      <div className="content_buttons_action">
                        <Link className="action_button" to="#">
                          Editar
                        </Link>{" "}
                        |
                        <Link className="action_button" to="#">
                          Eliminar
                        </Link>{" "}
                        |
                        <Link className="action_button" to="#">
                          Ver
                        </Link>
                      </div>
                    </div>
                  </td>
                  <td>
                    {producto.cantidad > 0 ? (
                      <p className="disponible">Disponible</p>
                    ) : (
                      <p className="no_disponible">No Disponible</p>
                    )}
                  </td>
                  <td>${producto.precio}</td>
                  {producto.categoria > 0 ? (
                    <td>{producto.categoria}</td>
                  ) : (
                    <td>Sin categoría</td>
                  )}
                  <td>
                    Publicado <p>20-10-2023</p>
                  </td>
                  <td>
                    <Link
                      to={{
                        pathname: `/page/${producto.id}/stats`,
                        state: { producto },
                      }}
                      className="icon_estadistica"
                    >
                      <TbChartHistogram size={24} strokeWidth={3} />
                    </Link>
                  </td>
                  <td  style={{textAlign:"center"}}>
                    <input type="checkbox" className="form-check-input" />
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan="7"
                style={{
                  textAlign: "center",
                  height: "100px",
                  paddingTop: "2.5rem",
                }}
              >
                No hay productos disponibles
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div style={{ paddingTop: "2rem" }}>
        <Pagination
          count={params.count}
          next={params.next}
          previous={params.previous}
          limit={12}
          baseUrl="/producto/producto/"
          filterFunction={getFilterData}
        />
      </div>
    </section>
  );
};

export default ListaProductos;
