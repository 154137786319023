import React, { useEffect, useState } from "react";
import {
  Modal,
  Input,
  Label,
  Button,
  Row,
  Col,
} from "reactstrap";
import { getTasksFullList, postTasksCreate } from "../../helpers/backend";

const TaskModal = (props) => {
  const { isOpen, onClose } = props;
  const [tasks, setTasks] = useState([]);
  const [taskCreated, setTaskCreated] = useState(null)


const [tasksList, setTaskList] = useState([])
const getTask = async() => {
  const response = await getTasksFullList()
  const data = response.data;
  setTaskList(data)
}
useEffect(() => {
  getTask()
}, [])

console.log(tasksList);
console.log(taskCreated);

  const [formData, setFormData] = useState({
    title: "",
    start: "",
    due_date: "",
    tasks: [],
  });
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async() => {
    const response = await postTasksCreate(formData)
    const data = response.data
    setTaskCreated(data)

  };

  const addTask = () => {
    const newTask = ""; // Nueva tarea vacía
    setTasks([...tasks, newTask]); // Agregar una nueva tarea vacía al arreglo.
    // Actualizar formData con las tareas
    setFormData({
      ...formData,
      tasks: [...formData.tasks, newTask],
    });
  };

  const handleTaskChange = (e, index) => {
    const updatedTasks = [...tasks];
    updatedTasks[index] = e.target.value;
    setTasks(updatedTasks);
    // Actualizar formData con las tareas
    setFormData({
      ...formData,
      tasks: updatedTasks,
    });
  };

  const removeTask = (index) => {
    const updatedTasks = [...tasks];
    updatedTasks.splice(index, 1); // Elimina la tarea en el índice dado.
    setTasks(updatedTasks);
    // Actualizar formData con las tareas
    setFormData({
      ...formData,
      tasks: updatedTasks,
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered
      toggle={onClose}
    >
      <div>
        <h1 className="p-2">Tareas</h1>
        <Row className="d-flex justify-content-center m-2">
        <Row>
            <Col lg={6}>
              <Label for="title">Título</Label>
              <Input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
              />
            </Col>
            <Col lg={6}>
              <Label for="start">Fecha</Label>
              <Input
                type="datetime-local"
                id="start"
                name="start"
                value={formData.start}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
            <Label for="due_date">Fecha de Vencimiento</Label>
            <Input
                type="datetime-local"
                id="due_date"
                name="due_date"
                value={formData.due_date}
                onChange={handleInputChange}
            />
            </Col>
          </Row>

        </Row>
        <hr />
        {tasks.map((task, index) => (
          <div key={index}>
            <Row className="d-flex justify-content-center m-3">
              <Col lg={2} className="d-flex justify-content-end mt-1"> <Label for={`task-${index}`}>Paso {index + 1}</Label> </Col>
              <Col lg={6}>
               
                <Input
                  type="text"
                  id={`task-${index}`}
                  name={`task-${index}`}
                  value={task}
                  onChange={(e) => handleTaskChange(e, index)}
                />
              </Col>
              <Col lg={4}>
                <Button                
                className="btn-sm"
                onClick={() => removeTask(index)}
              >
              <span className="fa fa-trash text-danger"></span>
            </Button>
              </Col>

            </Row>

          </div>
        ))}

        <Col className="d-flex justify-content-end">
        <Button style={{borderRadius: '20px'}} className="m-2" color="success" onClick={addTask}>
          <span className="fa fa-plus"></span>
        </Button>
        <Button style={{borderRadius: '20px'}} className="m-2" color="warning" onClick={handleSubmit}>
          <span className="fa fa-check"></span>
        </Button>
        </Col>

      </div>
    </Modal>
  );
};

export default TaskModal;
