import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Table,
  Input,
  Button,
  Label,
} from "reactstrap"
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader"
import Pagination from "../../components/Common/Pagination_ansync"
import Select from "react-select";
import { Link, useParams, } from "react-router-dom/cjs/react-router-dom.min";
import { getAgent, getCampaignFullList, getContactsRead, getGestionPorContacto, getGestionesContactoFilterAsync } from "../../helpers/backend";
import ModalManagementDetails from "../Whatsapp/components/ModalManagementDetails";

const GestionContacto = (props) => {
  const id = useParams().id
  const user = JSON.parse(localStorage.getItem("user"))
  const groups = String(user.groups);
  const [detallesModal, setDetallesModal] = useState(false);
  const [GestionDetallada, setGestionDetallada] = useState(false);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [estado, setEstado] = useState('');
  const [agente, setAgente] = useState('');
  const [campaña, setCampaña] = useState('');
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [selectedEstado, setSelectedEstado] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [isLead, setIsLead] = useState(false);
  const [type, setType] = useState('');
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });


  const handleSelectChange = (selectedOptions) => {
    const selectedContactData = selectedOptions ? selectedOptions.map(option => option.value).join(',') : '';
    setSelectedAgents(selectedOptions || []);
    setAgente(selectedContactData);
  };
  const handleSelectCampaignChange = (selectedOptions) => {
    setSelectedCampaign(selectedOptions || []);
    setCampaña(selectedOptions.value);
  };
  const handleSelectEstadoChange = (selectedOptions) => {
    setSelectedEstado(selectedOptions || []);
    setEstado(selectedOptions.value); // Asumo que setAgente está definido en otro lugar y actualiza el estado correspondiente
  };
  const handleSelectTypeChange = (selectedOptions) => {
    setSelectedType(selectedOptions || []);
    setType(selectedOptions.value); // Asumo que setAgente está definido en otro lugar y actualiza el estado correspondiente
  };
  const [contact, setContact] = useState({})
  const getContactData = async () => {
    const response = await getContactsRead(id);
    const data = response.data;
    setContact(data);
  };
  const [agent, setAgent] = useState({});
  const getAgentData = async () => {
    const response = await getAgent();
    const data = response.data;
    setAgent(data)
  }
  const [campaignData, setCampaignData] = useState({});
  const getCampaignData = async () => {
    const response = await getCampaignFullList();
    const data = response.data;
    setCampaignData(data);
  };

  const getGestionesData = async () => {
    const response = await getGestionPorContacto(id);
    setLoading(true);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };

  const filterDate = async () => {
    setLoading(true);
    const response = await getGestionesContactoFilterAsync(`?contact=${id}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&estado=${estado}&agents=${agente}&campaign=${campaña}&type=${type}&is_leads=${isLead} `);
    console.log(response);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };

  const resetSearch = () => {
    setFechaFin("");
    setFechaInicio("");
    setEstado('');
    setAgente('');
    setSelectedAgents("");
    setSelectedCampaign("");
    setSelectedEstado("");
    setSelectedType("")
    setIsLead(false)
    setType("")
    getGestionesData()
  };

  useEffect(() => {
    getContactData()
    getGestionesData()
    getAgentData()
    getCampaignData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleDetallesModal = () => {
    setDetallesModal(false);
  };
  const getFilterData = async (url) => {
    setLoading(true);
    const response = await getGestionesContactoFilterAsync(url);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Gestión del Contacto </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="TrueContact" breadcrumbItem=" Gestión del Contacto " route={contact.get_full_name} />

          {detallesModal ? (
            <ModalManagementDetails
              isOpen={detallesModal}
              onCloseClick={handleDetallesModal}
              GestionDetallada={GestionDetallada}
            ></ModalManagementDetails>
          ) : null}

          {loading ? (
            <Loader />
          ) : (
            <div>
              <Card>
                <CardBody>
                  <Row className="d-flex justify-content-center">
                    <Col lg={12}>
                      <Row>
                        <Col>
                          <Input
                            type="date"
                            className="form-control me-1"
                            name="fechaInicio"
                            placeholder="Fecha Inicio"
                            value={fechaInicio}
                            onChange={(e) => setFechaInicio(e.target.value)}
                          />
                        </Col>
                        <Col>
                          <Input
                            type="date"
                            className="form-control me-1"
                            name="fechaFin"
                            placeholder="Fecha Fin"
                            value={fechaFin}
                            onChange={(e) => setFechaFin(e.target.value)}
                          />
                        </Col>
                        {groups.includes("SuperAdmin") || groups.includes("Supervisor") ?
                          <>
                            <Col>
                              <Select
                                onChange={handleSelectEstadoChange}
                                options={[
                                  { value: 'EN_GESTION', label: 'En Gestión' },
                                  { value: 'VENCIDO', label: 'Vencido' },
                                  { value: 'FINALIZADO', label: 'Finalizado' },
                                  { value: 'PENDIENTE', label: 'Pendiente' }
                                ]}
                                placeholder="Estado"
                                value={selectedEstado}
                              />
                            </Col>
                            <Col>
                              <Select
                                className="me-1"
                                options={Array.isArray(agent)
                                  ? agent.map((item) => ({
                                    label: item.get_full_name,
                                    value: item.id,
                                  }))
                                  : []}
                                value={selectedAgents}
                                onChange={handleSelectChange}
                                isMulti={true}
                                placeholder="Agentes"
                                menuPosition="fixed"
                              />
                            </Col>
                            <Col>
                              <Select
                                className=" me-1"
                                options={Array.isArray(campaignData)
                                  ? campaignData.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                  }))
                                  : []}
                                onChange={handleSelectCampaignChange}
                                value={selectedCampaign}
                                menuPosition="fixed"
                                placeholder="Campañas"
                              />
                            </Col>
                            <Col>
                              <Select
                                className="me-1"
                                options={[
                                  { value: 'WhatsApp', label: 'WhatsApp' },
                                  { value: 'Telefonia - Manual', label: 'Telefonía - Manual' }
                                ]}
                                onChange={handleSelectTypeChange}
                                value={selectedType}
                                menuPosition="fixed"
                                placeholder="Tipo"
                              />
                            </Col>
                            <Col>
                              <div className="form-check">
                                <Input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="isLeadCheck"
                                  checked={isLead}
                                  onChange={(e) => setIsLead(e.target.checked)}
                                />
                                <Label className="form-check-label" for="isLeadCheck">
                                  ¿Lead?
                                </Label>
                              </div>
                            </Col>
                            <Col lg={2}>
                              <Button color="primary" className="btn-sm me-1 mt-1" onClick={filterDate}>
                                <b>Consultar</b>
                              </Button>
                              <Button color="warning" className="btn-sm me-1 mt-1" onClick={resetSearch}>
                                <b>Borrar</b>
                              </Button>
                            </Col>
                          </>
                          : null}
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                {params.results ? (
                  <CardBody className="ps-5 pe-5">
                    <h2 className="mb-3 font-size-20">Historial</h2>
                    <div className="table-responsive">
                      <Table
                        className="table  align-middle table-centered  mb-3"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <thead className="table-dark">
                          <tr>
                            <th>#</th>
                            <th>Fecha</th>
                            <th>Campaña</th>
                            <th>Canal de Comunicación</th>
                            <th>Calificación</th>
                            <th>Descripción</th>
                            <th>Estado</th>
                            <th>Acción</th>

                          </tr>
                        </thead>
                        <tbody>
                          {params.results.map((gestionContacto, idx) => (

                            <tr key={idx}>
                              <th scope="row">{idx + 1}</th>
                              <td>{gestionContacto.created_date}</td>
                              <td>{gestionContacto.campaign[0] && gestionContacto.campaign.map(item => item[0])}</td>
                              <td>{gestionContacto.type}</td>
                              <td>{gestionContacto.calificacion}</td>
                              <td>{gestionContacto.descripcion}</td>
                              <td>{gestionContacto.estado === "FINALIZADO" ? (
                                <span className="badge bg-success">
                                  Finalizado
                                </span>
                              ) : gestionContacto.estado === "VENCIDO" ? (
                                <span className="badge bg-danger">
                                  Vencido
                                </span>
                              ) : gestionContacto.estado === "PENDIENTE" ? (
                                <span className="badge bg-warning">
                                  Pendiente
                                </span>
                              ) : gestionContacto.estado === "EN_GESTION" ? (
                                <span
                                  className="badge "
                                  style={{
                                    background: "rgb(111, 66, 193)",
                                  }}
                                >
                                  En Gestión
                                </span>
                              ) : null}{" "}</td>
                              <td>
                                {gestionContacto.estado === "FINALIZADO" || gestionContacto.estado === "VENCIDO" ? (
                                  <Link to="#">
                                    <span
                                      className="fas fa-eye fa-lg"
                                      onClick={() => {
                                        setDetallesModal(true);
                                        setGestionDetallada(gestionContacto);
                                      }} >
                                    </span>
                                  </Link>
                                ) : null}
                                {gestionContacto.estado === "EN_GESTION" ? (
                                  <Link to="#">
                                    <span
                                      className="fas fa-pen fa-lg"
                                      onClick={() => {
                                        setDetallesModal(true);
                                        setGestionDetallada(gestionContacto);
                                      }} >
                                    </span>
                                  </Link>
                                ) : null}
                              </td>

                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    <Pagination
                      count={params.count}
                      next={params.next}
                      previous={params.previous}
                      limit={30}
                      filter={true}
                      contact={id}
                      baseUrl={`contact/gestionContacto/get_gestiones_contacto/`}
                      filterFunction={getFilterData}
                    />

                    <Row className="col-lg-12 justify-content-center">
                      <Col md={2} >
                        <Link className="btn btn-secondary text-dark ms-5" to={`/contacts/`}>
                          Volver
                        </Link>
                      </Col>
                    </Row>

                  </CardBody>
                ) : (
                  <CardBody>
                    <h4 className="card-title">No se encontraron resultados</h4>
                  </CardBody>
                )}
              </Card>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}


const mapStateToProps = (state) => {
  const { error, loading, gestionesContacto } = state.GestionesContacto;
  return { error, loading, gestionesContacto };
};

export default withRouter(connect(mapStateToProps)(GestionContacto));
