import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import Select from 'react-select';
//import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";

// Redux
import { connect } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";

import { calificacionContactoAdd } from "../../store/calificacionContacto/actions";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader";
import { getCampaignFullList } from "../../helpers/backend";

const CalificacionContactoAdd = (props) => {
  const history = useHistory();


  const [campaignData, setCampaignData] = useState({});
  const getCampaignData = async () => {
    const response = await getCampaignFullList();
    const data = response.data;
    setCampaignData(data);
  };

  const [formData, setFormData] = useState({
    name: "",
    campaign: "",
  });

  const { name, campaign } =
    formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    props.calificacionContactoAdd({
      campaign,
      name,
    });
  };
  useEffect(() => {
    getCampaignData()
  // eslint-disable-next-line
  }, []);


  const redirect = () => {
    history.push("/calificacionContacto");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TrueContact | Resultados </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={"TrueContact"} breadcrumbItem={"Resultados"} />
          {props.loading ? (
            <Loader />
          ) : (
            <Card className="col-lg-8 mx-auto  mt-3">
              {props.calificacionContactoAdded.id ? (
                <SweetAlert
                  title="Hecho!"
                  success
                  timeout={2000}
                  showConfirm={false}
                  onConfirm={() => {
                    redirect();
                  }}
                >
                  Se ha agregado con éxito
                </SweetAlert>
              ) : null}
              <CardBody>
                <h4 className="card-title">Agregar Resultados</h4>
                <Form className="needs-validation" onSubmit={onSubmit}>
                  <Row className="col-lg-12 justify-content-center mt-3">
                    <Col md={6} className="mb-3">
                      <Label
                        for="name"
                        className="col-form-label col-sm-4 d-flex"
                      >
                        <strong>Campaña</strong>
                      </Label>
                      <Select
                        id="agent"
                        name="agent"
                        options={Array.isArray(campaignData)
                          ? campaignData.map((item) => ({
                            label: item.name,
                            value: item.id,
                          }))
                          : []}
                        onChange={(e) => setFormData({...formData,  campaign: e.value })}
                        placeholder={"Seleccione"}
                        isMulti={false}
                        menuPosition="fixed"
                      />
                      <FormFeedback>{props.error.campaign}</FormFeedback>
                    </Col>
                  </Row>
                  <Row className="col-lg-12 justify-content-center mt-3">
                    <Col md={6} className="mb-3">
                      <Label
                        for="name"
                        className="col-form-label col-sm-4 d-flex"
                      >
                        <strong>Nombre</strong>
                      </Label>

                      <Input
                        id="name"
                        name="name"
                        type="text"
                        onChange={onChange}
                        value={name}
                        valid={
                          props.error.name
                            ? false
                            : props.calificacionContactoAdded.id && true
                        }
                        invalid={
                          props.error
                            ? props.error.name
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.name}</FormFeedback>
                    </Col>
                  </Row>


                  <Row className=" mx-2 col-lg-12 justify-content-center">
                    <Col md={4} className='m-1'>
                      <Link className="btn btn-secondary text-dark" to="/calificacionContacto">
                        Volver
                      </Link>

                      <Button color="primary" type="submit" className="ms-1">
                        Agregar
                      </Button>
                    </Col>

                  </Row>
                </Form>
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, calificacionContactoAdded } = state.CalificacionContacto;
  return { error, loading, calificacionContactoAdded };
};

CalificacionContactoAdd.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  calificacionContactoAdded: PropTypes.object,
};
export default withRouter(connect(mapStateToProps, { calificacionContactoAdd })(CalificacionContactoAdd));
