import React from 'react'
import './styles/Carrito.css'
import ContainerTienda from '../../components/TiendaLayout/ContainerTienda'
import ListCarrito from './components/ListCarrito';
import { MetaTags } from 'react-meta-tags';


const Carrito = () => {
  return (
    <ContainerTienda>
      <div className="page_carrito">
      <MetaTags>
        <title>Mi carrito - Colombia Ruedas</title>
      </MetaTags>
        <div className="container_page_carrito">
          <ListCarrito />
        </div>
      </div>
    </ContainerTienda>
  );
}

export default Carrito