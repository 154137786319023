import React from "react";
import { Modal, ModalBody, ModalFooter, Row, Col, Card, CardBody, Button } from "reactstrap";
import ReactApexChart from "react-apexcharts";

const ChartsModal = ({ isOpen, toggle, managementData }) => {
  const radialBarOptions = {
    chart: {
      height: 300,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '70%',
        },
        dataLabels: {
          name: {
            show: true,
          },
          value: {
            show: true,
            formatter: function (val) {
              // Muestra el porcentaje y la cantidad total
              const total = managementData.total_contactos || 0;
              const percentage = (total > 0) ? Math.round((val / total) * 100) : 0;
              return `${val} (${percentage}%)`;
            },
          },
        },
      },
    },
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" centered>
      <ModalBody>
        <Row>
          {/* Exitosos */}
          <Col md={6}>
            <Card>
              <CardBody>
                <h5 style={{ color: '#28a745' }}>Exitosos</h5>
                <ReactApexChart
                  options={{
                    ...radialBarOptions,
                    labels: ['Exitosos'],
                    colors: ['#28a745'], // Color verde
                  }}
                  series={[managementData.exitosos || 0]}
                  type="radialBar"
                  height="200"
                />
              </CardBody>
            </Card>
          </Col>
          {/* Fallidos */}
          <Col md={6}>
            <Card>
              <CardBody>
                <h5 style={{ color: '#dc3545' }}>Fallidos</h5>
                <ReactApexChart
                  options={{
                    ...radialBarOptions,
                    labels: ['Fallidos'],
                    colors: ['#dc3545'], // Color rojo
                  }}
                  series={[managementData.fallidos || 0]}
                  type="radialBar"
                  height="200"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          {/* Sin Teléfono */}
          <Col md={6}>
            <Card>
              <CardBody>
                <h5 style={{ color: '#ffc107' }}>Sin Teléfono</h5>
                <ReactApexChart
                  options={{
                    ...radialBarOptions,
                    labels: ['Sin Teléfono'],
                    colors: ['#ffc107'], // Color amarillo
                  }}
                  series={[managementData.sin_telefono || 0]}
                  type="radialBar"
                  height="200"
                />
              </CardBody>
            </Card>
          </Col>
          {/* Total Contactos */}
          <Col md={6}>
            <Card>
              <CardBody>
                <h5 style={{ color: '#007bff' }}>Total Contactos</h5>
                <ReactApexChart
                  options={{
                    ...radialBarOptions,
                    labels: ['Total de Contactos'],
                    colors: ['#007bff'], // Color azul
                  }}
                  series={[managementData.total_contactos || 0]}
                  type="radialBar"
                  height="200"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" className="text-dark" onClick={toggle}>
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ChartsModal;
