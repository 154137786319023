import React from 'react'
import CardCarrito from './CardCarrito'

const ListCarrito = () => {
  return (
    <div>
      <CardCarrito />
    </div>
  )
}

export default ListCarrito