import {
  ROL_LIST,
  ROL_FILTER,
  ROL_CREATE,  
  ROL_CREATE_SUCCESS,
  ROL_LIST_SUCCESS,
  ROL_REQUEST_FAILED,
  ROL_UPDATE,
  ROL_UPDATE_SUCCESS,
  ROL_DELETE,
  ROL_DELETE_SUCCESS,
  ROL_PERMISSION,
  ROL_PERMISSION_SUCCESS
} from "../roles/actionTypes";

const initialState = {
  rol: [],
  rolCreated: {},
  rolUpdated: {},
  permissionAssigned: {},
  rolDeleted:false,
  loading: false,
  error: "",
};

const rol = (state = initialState, action) => {
  switch (action.type) {
    case ROL_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ROL_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ROL_FILTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ROL_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ROL_DELETE:
        state = {
          ...state,
          loading: true,
          rolDeleted: false,
        };
        break;
    case ROL_PERMISSION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ROL_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        rolCreated: action.payload,
        error: "",
      };
      break;
    case ROL_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        rol: action.payload,
        rolCreated: {},
        rolUpdated: {},
        rolDeleted: false,
        error: "",
      };
      break;
    case ROL_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        rolUpdated: action.payload,
        error: "",
      };
      break;
    case ROL_DELETE_SUCCESS:
      state = {
        ...state,
        loading: false,
        rolDeleted: true,
        error: "",
      };
      break;
    case ROL_PERMISSION_SUCCESS:
        state = {
          ...state,
          loading: false,
          permissionAssigned: action.payload,
          error: "",
        };
        break;
    case ROL_REQUEST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default rol;
