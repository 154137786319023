import React, { useEffect, useState } from 'react'
import { Card, Nav, NavItem, NavLink, Row, Col, Label, Input } from 'reactstrap'
import whatsapp from "../../assets/images/whatsapp.png"
import telegram from "../../assets/images/telegram.png"
import facebook from "../../assets/images/facebook.png"
import telefonia from "../../assets/images/telefonia.png"
import classnames from 'classnames';
import './Chat.css';
import { getConversationsGestion, getConversationsGestionFilter, getGestionFinalizadas, getNewConversations, getNewConversationsFilter, getWhatsappPriority, patchWhatsappPriority, postAssignConversation } from '../../helpers/backend';
import ModalManagementDetails from './components/ModalManagementDetails'
import { useHistory, useLocation } from 'react-router-dom'
import ManagementManual from './components/ManagementManual'
import Pagination from "../../components/Common/Pagination_ansync"
import PhoneVerificationModal from './components/PhoneVerificationModal'

export const SideComponent = ({ onPhoneNumberSelect, onContactId, onCampaignId, onChatType, managementUpdate, contactsRefresh, setContactsRefresh, campaignRefresh, idConversation }) => {
  const URL = process.env.REACT_APP_API_URL.split('/');
  const user = JSON.parse(localStorage.getItem("user"))
  const groups = String(user.groups);
  const location = useLocation();
  const history = useHistory();

  const [managementFinished, setManagementFinished] = useState({})
  const [modalFinishedDetails, setModalFinishedDetails] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null);
  const [manualOpen, setManualOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('today');

  const [activeTab, setActiveTab] = useState('1');
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }
  const [activeTab2, setActiveTab2] = useState('1');
  const toggle2 = tab => {
    if (activeTab2 !== tab) setActiveTab2(tab);
  }
  const [finished, setFinished] = useState({});
  const getFinishedData = async () => {
    const response = await getGestionFinalizadas(selectedOption);
    const data = response.data;
    setFinished(data)
  }
  const [management, setManagement] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });
  const getManagementData = async () => {
    const response = await getConversationsGestion();
    const data = response.data;
    setManagement(data)
  }
  const [inLine, setInLine] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });
  const getInLineData = async () => {
    const response = await getNewConversations();
    const data = response.data;
    setInLine(data)
  }
  const [priority, setPriority] = useState({});
  const getPriorityData = async () => {
    const response = await getWhatsappPriority();
    const data = response.data;
    setPriority(data)
  }
  const handleInLine = async (id) => {
    await postAssignConversation(id);
    getManagementData()
    getInLineData()
    setActiveTab2('1')
  }

  useEffect(() => {
    getManagementData()
    getInLineData()
    getPriorityData()
    setContactsRefresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managementUpdate, contactsRefresh]);

  useEffect(() => {
    getFinishedData()
    console.log(selectedOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  let socketUrlInLine = `wss://${URL[2]}/ws/new_conversation/`;
  let socketUrlManagement = `wss://${URL[2]}/ws/en_gestion_conversation/`;

  useEffect(() => {
    const chatSocketInLine = new WebSocket(socketUrlInLine);
    chatSocketInLine.onopen = () => {
      console.log("En Linea conectado");
    };

    chatSocketInLine.onclose = () => {
      console.log("En Linea  desconectado");
    };

    chatSocketInLine.onmessage = async (event) => {
      const updateData = JSON.parse(event.data);
      // Actualiza inLine con la nueva data recibida
      setInLine(prevInLine => ({
        ...prevInLine,
        ...updateData.conversation_data
      }));
      // Vuelve a obtener los datos de inLine cada vez que se recibe un nuevo mensaje
      await getInLineData();
    };

  }, [socketUrlInLine]);

  useEffect(() => {
    const chatSocketManagement = new WebSocket(socketUrlManagement);
    chatSocketManagement.onopen = () => {
      console.log("Gestiones conectado");
    };

    chatSocketManagement.onclose = () => {
      console.log("Gestiones desconectado");
    };

    chatSocketManagement.onmessage = async (event) => {
      const updateData = JSON.parse(event.data);
      // Actualiza inLine con la nueva data recibida
      setManagement(prevInLine => ({
        ...prevInLine,
        ...updateData.conversation_data
      }));
      // Vuelve a obtener los datos de inLine cada vez que se recibe un nuevo mensaje
      await getManagementData();
    };

  }, [socketUrlManagement]);
  
  const isSelected = (id) => {
    return selectedItem === id;
  };
  const replaceLocation = () => {
    history.replace({
      pathname: location.pathname,
      state: {},
    });
  }
  const handleTimeChange = async (newValue, id, index) => {
    const updatedResults = [...priority.results];
    updatedResults[index].time = newValue;
    setPriority({ ...priority, results: updatedResults });
    const response = await patchWhatsappPriority(id, {
      time: newValue
    })
    console.log(response);
  };
  const handleManagementPageChange = async (url) => {
    const response = await getConversationsGestionFilter(url);
    setManagement({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
  };

  const handleInLinePageChange = async (url) => {
    const response = await getNewConversationsFilter(url);
    setInLine({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
  };
  
  return (
    <>
      <Row>
        <Card style={{ borderTop: '5px solid #0e685f' }}>
          <div className='management-container'>
          <button className="floating-button-sc" onClick={()=> setModalOpen(true)}>+</button>
          {modalOpen ?
          <PhoneVerificationModal isOpen={modalOpen} onClose={()=> setModalOpen(false)} />
          :null }
            <Nav pills justified role="tablist" className='mt-1'>
              <NavItem>
                <NavLink
                  to="#"
                  className={`p-2 ${classnames({ active: activeTab === '1' })}`}
                  onClick={() => { toggle('1'); }}
                  style={{ cursor: 'pointer' }}>
                  <i className="fas fa-comment-alt fa-md"></i>
                  <span className=" d-sm-block">Abierto</span>
                </NavLink>
              </NavItem>
              <NavItem >
                <NavLink
                  to="#"
                  className={`p-2 ${classnames({ active: activeTab === '2' })}`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => { toggle('2'); }}>
                  <i className="fas fa-check fa-md"></i>
                  <span className=" d-sm-block">Finalizado</span>
                </NavLink>
              </NavItem>
              <NavItem >
                <NavLink
                  to="#"
                  className={`p-2 ${classnames({ active: activeTab === '3' })}`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => setManualOpen(true)}>
                  <i className="fas fa-phone-alt fa-md"></i>
                  <span className=" d-sm-block">Gestión Telefónica</span>
                </NavLink>
              </NavItem>
              {groups.includes("SuperAdmin") || groups.includes("Supervisor") ? (
                <NavItem >
                  <NavLink
                    to="#"
                    className={`p-2 ${classnames({ active: activeTab === '4' })}`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => { toggle('4'); }}>
                    <i className="fas fa-tools fa-md"></i>
                    <span className=" d-sm-block">Configuraciòn</span>
                  </NavLink>
                </NavItem>
              ) : null}
            </Nav>
            {manualOpen &&
              <ManagementManual isOpen={manualOpen} onClose={() => setManualOpen(false)} />}
            {activeTab === '1' ? (
              <>
                <Nav tabs justified className="nav-tabs-custom mt-2" role="tablist">
                  <NavItem>
                    <NavLink
                      to="#"
                      className={`p-2 ${classnames({ active: activeTab2 === '1' })}`}
                      onClick={() => { toggle2('1'); }}
                      style={{ cursor: 'pointer' }}>
                      <span>Mis Gestiones
                        <span className="badge rounded-pill bg-info ms-1">{management.count}</span>
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem >
                    <NavLink
                      to="#"
                      className={`p-2 ${classnames({ active: activeTab2 === '2' })}`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => { toggle2('2'); }}>
                      <span>En Espera
                        <span className="badge rounded-pill bg-danger ms-1">{inLine.count}</span>
                      </span>

                    </NavLink>
                  </NavItem>
                </Nav>
                <div className='container-side-component ' >
                  {activeTab2 === '1' ? (
                    Array.isArray(management.results) ? management.results.map((item, index) => (
                      <div key={index}
                        className={`card-conversacion mt-1 ${isSelected(item.id) ? 'selected-conversation-card' : ''}`}
                        style={item.prioridad === "MEDIO" ?
                          { borderLeft: '5px solid yellow' }
                          : item.prioridad === "ALTO" ?
                            { borderLeft: '5px solid red' }
                            : { borderLeft: '5px solid green' }}>
                        <div className="chat-item" onClick={() => {
                          onPhoneNumberSelect(item.id);
                          onContactId(item.contacto ? item.contacto.contact_id : null);
                          onCampaignId(item.campaign ? item.campaign : [])
                          onChatType(item.type)
                          setSelectedItem(item.id)
                          replaceLocation();
                        }}>
                          {item.type === "WhatsApp" ? (
                            <img className="chat-item__img" src={whatsapp} alt="Avatar" />
                          ) :
                            item.type === "Telegram" ? (

                              <img className="chat-item__img" src={telegram} alt="Avatar" />
                            ) :
                              item.type === "Facebook" ? (

                                <img className="chat-item__img" src={facebook} alt="Avatar" />
                              ) :
                                item.type === "Telefonia" ? (

                                  <img className="chat-item__img" src={telefonia} alt="Avatar" />
                                ) : null
                          }
                          <div className="chat-item__info">
                            <div className="chat-item__date me-2">{item.creado}</div>
                            <div className="chat-item__name mt-1">{item.contacto ? item.contacto.contact_name : item.id}
                              <div className="chat-item__badge">
                                <span className='badge rounded-pill bg-success ms-1'>
                                  {item.message_received ? item.message_received : null}
                                </span>
                              </div>
                            </div>

                            <div className="chat-item__description mt-1">
                              <b>Agente: </b> {item.agent[0] ? item.agent[0][0] : null}
                            </div>
                            <div className="chat-item__description">
                              {
                                item.ultimo_message[0] === 'text' ?
                                  (item.ultimo_message[2] === true ?
                                    `Tu: ${item.ultimo_message[1]}` :
                                    `${item.ultimo_message[1]}`
                                  ) :
                                  (item.ultimo_message[0] === 'image' ||
                                    item.ultimo_message[0] === 'audio' ||
                                    item.ultimo_message[0] === 'document' ?
                                    (item.ultimo_message[2] === true ?
                                      `Tu: Imagen` :
                                      `Imagen`
                                    ) :
                                    null
                                  )
                              }
                            </div>
                          </div>

                        </div>
                      </div>
                    )) : []
                  ) : null}
                  {activeTab2 === '2' ? (
                    Array.isArray(inLine.results) ? inLine.results.map((item, index) => (
                      <div key={index} className='card-conversacion mt-1'
                        style={item.prioridad === "MEDIO" ?
                          { borderLeft: '8px solid #f7e81b' }
                          : item.prioridad === "ALTO" ?
                            { borderLeft: '8px solid red' }
                            : { borderLeft: '8px solid green' }}>
                        <div className="chat-item" onClick={() => {
                          handleInLine(item.id);
                          onPhoneNumberSelect(item.id);
                          onCampaignId(item.campaign ? item.campaign : [])
                          onContactId(item.contacto.contact_id)
                          setSelectedItem(item.id)
                          onChatType(item.type)
                        }}>
                          {item.type === "WhatsApp" ? (
                            <img className="chat-item__img" src={whatsapp} alt="Avatar" />
                          ) :
                            item.type === "Telegram" ? (

                              <img className="chat-item__img" src={telegram} alt="Avatar" />
                            ) :
                              item.type === "Facebook" ? (

                                <img className="chat-item__img" src={facebook} alt="Avatar" />
                              ) :
                                item.type === "Telefonia" ? (

                                  <img className="chat-item__img" src={telefonia} alt="Avatar" />
                                ) : null
                          }
                          <div className="chat-item__info mt-2">
                            <div className="chat-item__date me-2">{item.creado}</div>
                            <div className="chat-item__name mt-1">
                              {item.contacto.contact_name}
                              <div className="chat-item__badge">
                                <span className='badge rounded-pill bg-success ms-1'>
                                  {item.message_received ? item.message_received : null}
                                </span>
                              </div>
                            </div>
                            <div className='m-3'></div>
                            <div className="chat-item__description">
                              {
                                item.ultimo_message[0] === 'text' ?
                                  (item.ultimo_message[2] === true ?
                                    `Tu: ${item.ultimo_message[1]}` :
                                    `${item.ultimo_message[1]}`
                                  ) :
                                  (item.ultimo_message[0] === 'image' ||
                                    item.ultimo_message[0] === 'audio' ||
                                    item.ultimo_message[0] === 'document' ?
                                    (item.ultimo_message[2] === true ?
                                      `Tu: Imagen` :
                                      `Imagen`
                                    ) :
                                    null
                                  )
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    )) : []
                  ) : null}
                  {activeTab2 === '1' && (
                    <div className="font-size-13 mt-1">
                      <Pagination
                        count={management.count}
                        next={management.next}
                        previous={management.previous}
                        limit={30}
                        baseUrl="/whatsapp/conversation/get_conversation_gestion"
                        filterFunction={handleManagementPageChange}
                      />
                    </div>
                  )}
                  {activeTab2 === '2' && (
                    <div className="font-size-13 mt-1">
                      <Pagination
                        count={inLine.count}
                        next={inLine.next}
                        previous={inLine.previous}
                        limit={30}
                        baseUrl="/whatsapp/conversation/get_conversation_new"
                        filterFunction={handleInLinePageChange} // Función para manejar el cambio de página
                      />
                    </div>
                  )}
                </div>

              </>
            ) : null}
            {activeTab === '2' ? (
              <div className='container-side-component'>
                <h6 className='mt-3 text-muted'>Mis Gestiones Finalizadas</h6>
                <div className='d-flex justify-content-end px-2'>
                  <select style={{ maxWidth: '100px' }} className="form-select font-size-13 "
                    onChange={(e) => setSelectedOption(e.target.value)}
                    defaultValue="today">
                    <option value="all">Todas</option>
                    <option value="today">Hoy</option>
                    <option value="yesterday">Ayer</option>
                    <option value="week">De la semana</option>
                    <option value="month">Del mes</option>
                  </select>
                </div>
                <>
                  {Array.isArray(finished) ? finished.map((item, index) => (
                    <div key={index} className='card-conversacion mt-1 ' style={{ borderLeft: '5px solid orange' }}>
                      <div className="chat-item" onClick={() => { setManagementFinished(item); setModalFinishedDetails(true) }}>
                        {item.type === "WhatsApp" ? (
                          <img className="chat-item__img" src={whatsapp} alt="Avatar" />
                        ) :
                          item.type === "Telegram" ? (

                            <img className="chat-item__img" src={telegram} alt="Avatar" />
                          ) :
                            item.type === "Facebook" ? (

                              <img className="chat-item__img" src={facebook} alt="Avatar" />
                            ) :
                              item.type === "Telefonía - Manual" ? (

                                <img className="chat-item__img" src={telefonia} alt="Avatar" />
                              ) : null
                        }
                        <div className="chat-item__info">
                          <div className="chat-item__date mt-1 me-2">Finalizado: {item.fecha_finalizacion}</div>
                          <div className="chat-item__name mt-3">{item.contacto}</div>
                          <div className="chat-item__description"><b>Agente: </b>{item.user}</div>
                        </div>

                      </div>
                    </div>
                  )) : []}
                  {modalFinishedDetails ? (
                    <ModalManagementDetails
                      isOpen={modalFinishedDetails}
                      onCloseClick={() => setModalFinishedDetails(false)}
                      GestionDetallada={managementFinished}
                    />
                  ) : null}
                </>
              </div>

            ) : null}
            {activeTab === '4' ? (
              <div className="accordion mt-4" id="accordionExample">
                <div className="accordion-item">
                  <h6 className="accordion-header" id="headingOne" >
                    <button className="accordion-button p-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                      Prioridades
                    </button>
                  </h6>
                  <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      {priority.results && priority.results.map((item, idx) => (
                        <div>
                          {item.name !== "GESTION" ? (
                            <Row key={idx} className='d-flex justify-content-center mt-1 '>
                              <Col lg={3} className='d-flex justify-content-end font-size-13 mt-1' >
                                <Label>{item.name}</Label>
                              </Col>
                              <Col lg={6} className='d-flex justify-content-start'>
                                <Input
                                  type="select"
                                  name="priority"
                                  id="priority"
                                  style={{ fontSize: '12px' }}
                                  value={item.time}
                                  onChange={(e) => handleTimeChange(e.target.value, item.id, idx)}
                                >
                                  <option defaultValue='default'>------------</option>
                                  <option value="2">2 Minutos</option>
                                  <option value="5">5 Minutos</option>
                                  <option value="8">8 Minutos</option>
                                  <option value="10">10 Minutos</option>
                                </Input>
                              </Col>
                            </Row>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h6 className="accordion-header" id="headingTwo" >
                    <button className="accordion-button p-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Cierre de Gestión
                    </button>
                  </h6>
                  <div id="collapseTwo" className="accordion-collapse collapse " aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      {priority.results && priority.results.map((item, idx) => (
                        <div>
                          {item.name === "GESTION" ? (
                            <Row key={idx} className='d-flex justify-content-center mt-1 '>
                              <Col lg={6} className='d-flex justify-content-end font-size-13 mt-1' >
                                <Label>Tiempo para cerrar la Gestión</Label>
                              </Col>
                              <Col lg={6} className='d-flex justify-content-start'>
                                <Input
                                  type="select"
                                  name="priority"
                                  id="priority"
                                  style={{ fontSize: '12px' }}
                                  value={item.time}
                                  onChange={(e) => handleTimeChange(e.target.value, item.id, idx)}
                                >
                                  <option defaultValue='default'>------------</option>
                                  <option value="5"> 5 Horas</option>
                                  <option value="10">10 Horas</option>
                                  <option value="12">12 Horas</option>
                                  <option value="20">20 Horas</option>
                                  <option value="24">24 Horas</option>
                                </Input>
                              </Col>
                            </Row>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

          </div>
        </Card>

      </Row>

    </>


  )
}
