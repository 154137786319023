import React, { useEffect, useState } from 'react';
import { Input, Modal,  Row,  } from 'reactstrap';

import { getSurveyFull, getSurveyRead } from "../../helpers/backend";

const SurveyFull = (props) => {
  const { isOpen, onClose, surveyId } = props;
  const [survey, setSurvey] = useState({});
  const [viewFull, setViewFull] = useState({});

  const getView = async () => {
    const response = await getSurveyFull(surveyId);
    const data = response.data;
    setViewFull(data);
  };

  const getData = async (id) => {
    const response = await getSurveyRead(id);
    setSurvey(response.data);
  };

  useEffect(() => {
    getData(surveyId);
    getView();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyId]);

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered
      className="custom-modal"
      toggle={onClose}
    >
      <div className="survey-full-modal">
        <Row>
            <h5><b>Título de la encuesta:</b> {survey.name}</h5> 
            <h6><b>Canal de comunicación: </b> {survey.survey_type}</h6>
            <h6><b>Campaña: </b> {survey.campaign}</h6>
            <h6><b>Descripción: </b> {survey.description}</h6>
        </Row>
          <h5 className="mt-2"><b>Preguntas:</b> </h5>
          <div >
            {Array.isArray(viewFull) &&
              viewFull.map((question, index) => (
                <div key={index} className="question-item">
                  <p>{`${index + 1}. ${question.text}`}</p>
                  {question.question_type_id === 1 && (
                    <div>
                      {Array.isArray(question.options) &&
                        question.options.map((option, optionIndex) => (
                          <div key={optionIndex}>
                            <input
                              type="radio"
                              id={`option${optionIndex}`}
                              name={`question${index}`}
                              value={option.text}
                            />
                            <label
                              htmlFor={`option${optionIndex}`}
                              style={{ marginLeft: "3px" }}
                            >
                              {option.text}
                            </label>
                          </div>
                        ))}
                    </div>
                  )}
                  {question.question_type_id === 2 && (
                    <div>
                      {Array.isArray(question.options) &&
                        question.options.map((option, optionIndex) => (
                          <div key={optionIndex}>
                            <input
                              type="checkbox"
                              id={`option${optionIndex}`}
                              name={`question${index}`}
                              value={option.text}
                            />
                            <label
                              htmlFor={`option${optionIndex}`}
                              style={{ marginLeft: "3px" }}
                            >
                              {option.text}
                            </label>
                          </div>
                        ))}
                    </div>
                  )}
                  {question.question_type_id === 3 && (
                        <Input type="textarea" className="me-1" />
                  )}
                </div>
              ))}
          </div>
      </div>
    </Modal>
  );
};

export default SurveyFull;
