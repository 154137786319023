import React, { useEffect, useState } from 'react'
import "../../pages/Home/components/Home.css"
// import { Link } from "react-router-dom/cjs/react-router-dom";  
import Logo from "../../assets/images/Logo Colombia Rueda Negro 2020.07.10.png";
import { TbUser } from "react-icons/tb";
import { TbShoppingCart } from "react-icons/tb";
import AOS from 'aos';
import "aos/dist/aos.css";
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom';
import Swal from "sweetalert2";
import Cart from '../../pages/Cart/Cart';



const listNavbar = [
  {
    id: 1,
    name: "Inicio",
    path: "home",
    offSet: "-100"
  },
  {
    id: 2,
    name: "Llantas",
    path: "home#llantas",
  },
  {
    id: 3,
    name: "Rines",
    path: "",
    offSet: "0"
  },
  {
    id: 4,
    name: "Distribuciones",
    path: "home#distribuciones",
  },
  {
    id: 5,
    name: "Promociones",
    path: "",
    offSet: "0"
  },
  {
    id: 6,
    name: "Contáctanos",
    path: "",
    offSet: "0"
  },
]

const NavbarTienda = () => {
  const [cart, setCart] = useState(false)

  const history = useHistory()


  const redirect = (path) => {
    history.push(`${path}`)
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleAlert = () => {
    Swal.fire ({
      icon: 'info',
      title: '¡Estamos trabajando en ello!',
      timer: 2000,
    })   
  }

  const handleCart = () => {
    setCart((prev) => !prev)

  }
  return (
    <>
      <div className="mensaje_home">
        <p className="p_mensaje">tienda online de rines y llantas.</p>
      </div>
      <nav className="navbar_home">
        <div className="logo_navbar">
          <a className="logo_button" href="/home">
            <img className="logo" src={Logo} alt="Logo" />
          </a>
        </div>

        <div className="list_navbar">
          <ul className="ul_navbar">
            {listNavbar.map((list) => (
              <li key={list.id} className="">
                <a
                  href={list.path}
                  className="li_navbar"
                >
                  {list.name}
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div className="list_buttons_navbar">
          <div>
            <Link onClick={handleAlert} className="buttons_navbar" to="#">
              <TbUser size={25} />
            </Link>
          </div>
          <div>
            <Link
              onClick={handleCart}
              className={`buttons_navbar ${cart ? "button_navbar_active" : ""}`}
              to="#"
            >
              <TbShoppingCart size={25} />
            </Link>
          </div>
          <div>
            <Link
              className="buttons_navbar button_login"
              onClick={() => redirect("/login")}
            >
              Log in
            </Link>
          </div>
        </div>
        {cart ? <Cart cart={cart}/> : null}
      </nav>
    </>
  );
}

export default NavbarTienda