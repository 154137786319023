import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import Select from 'react-select';
import { getCampaignFullList, postTemplate } from '../../helpers/backend';
import SweetAlert from "react-bootstrap-sweetalert";
import EmojiPicker from 'emoji-picker-react';

const ModalTemplateAdd = ({ isOpen, onClose, refresh }) => {
  const [formData, setFormData] = useState({
    name: '',
    body: '',
    campaign: [],
  });

  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  const [campaignData, setCampaignData] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const getCampaignData = async () => {
      try {
        const response = await getCampaignFullList();
        if (isMounted) {
          setCampaignData(response.data);
        }
      } catch (err) {
        if (isMounted) {
          setError(true);
        }
      }
    };

    getCampaignData();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEmojiClick = (emojiObject) => {
    console.log('Emoji Object:', emojiObject); // Depuración
    setFormData({
      ...formData,
      body: formData.body + emojiObject.emoji,
    });
    setShowEmojiPicker(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await postTemplate(formData);
      if (response.status === 201) {
        setAlert(true);
      } else {
        setError(true);
      }
    } catch (err) {
      setError(true);
    }
  };

  return (
    <Modal size='lg' isOpen={isOpen} toggle={onClose}>
      {alert ? (
        <SweetAlert
          title="Creado"
          success
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            refresh();
            onClose();
            setAlert(false);
          }}
        />
      ) : null}
      {error ? (
        <SweetAlert
          title="Error"
          danger
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            setError(false);
          }}
        />
      ) : null}
      <div className='p-3'>
        <h4>Agregar Template</h4>
        <Form onSubmit={handleSubmit}>
          <Row className='d-flex justify-content-center'>
            <Col md={10} className='mt-3'>
              <FormGroup>
                <Label for="name">Nombre</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Nombre del template"
                />
              </FormGroup>
            </Col>
            <Col md={10} className='mt-3'>
              <FormGroup>
                <Label for="campaign">Campaña</Label>
                <Select
                  options={campaignData.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  onChange={(selectedOptions) => {
                    const selectedCampaigns = selectedOptions.map(
                      (option) => option.value
                    );
                    setFormData({
                      ...formData,
                      campaign: selectedCampaigns,
                    });
                  }}
                  placeholder={"Seleccione una o más campañas"}
                  isMulti={true}
                  menuPosition="fixed"
                />
              </FormGroup>
            </Col>
            <Col md={10} className='mt-3'>
              <FormGroup>
                <Label for="body">Texto</Label>
                <div className="position-relative">
                  <Input
                    type="textarea"
                    name="body"
                    id="body"
                    value={formData.body}
                    onChange={handleInputChange}
                    placeholder="Escribe el contenido del template"
                    rows={6}
                  />
                  <span
                    className="position-absolute"
                    style={{ bottom: 10, right: 10, cursor:"pointer"}}
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  >
                    😊
                  </span>
                  {showEmojiPicker && (
                    <div className="position-absolute" style={{ bottom: 50, right: 10 }}>
                      <EmojiPicker onEmojiClick={handleEmojiClick} />
                    </div>
                  )}
                </div>
              </FormGroup>
            </Col>
          </Row>
          <div className="d-flex justify-content-end mt-3">
            <Button color="secondary" onClick={onClose} className='text-dark me-1'>
              Cancelar
            </Button>
            <Button color="primary" type="submit" className="ml-2">
              Guardar
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalTemplateAdd;
