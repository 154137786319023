import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import Select from 'react-select'; // Asegúrate de tener instalada esta librería
import { getCampaignFullList, postBroadcastCampaign  } from '../../helpers/backend';
import SweetAlert from "react-bootstrap-sweetalert";

const BroadcastCampaignAdd = ({ isOpen, onClose, refresh }) => {
  const [formData, setFormData] = useState({
    name: '',
    body: '',
    campaign: [],
    description: "",
    managements: '',
  });
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  const [campaignData, setCampaignData] = useState({});

  const getCampaignData = async () => {
    const response = await getCampaignFullList();
    setCampaignData(response.data);
  };

  useEffect(() => {
    getCampaignData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await postBroadcastCampaign(formData);
    if (response.status === 201) {
      setAlert(true);
    }
    else{
        setError(true)
    }
  };

  return (
    <Modal size='lg' isOpen={isOpen} toggle={onClose}>
      {alert ? (
        <SweetAlert
          title="Creado"
          success
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            refresh();
            onClose();
            setAlert(false);
          }}
        />
      ) : null}
      {error ? (
        <SweetAlert
          title="Error"
          danger
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            setError(false);
          }}
        />
      ) : null}
      <div className='p-3'>
        <h4>Agregar Campaña de Difusión</h4>
        <Form onSubmit={handleSubmit}>
          <Row className='d-flex justify-content-center'>
            <Col md={10} className='mt-3'>
              <FormGroup>
                <Label for="name">Nombre</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={formData.name}
                  onChange={(e)=> setFormData({...formData, name: e.target.value})}
                  placeholder="Nombre de la Campaña de Difusión"
                />   
              </FormGroup>
            </Col>

            <Col md={10} className='mt-3'>
              <FormGroup>
                <Label for="campaign">Campaña</Label>
                <Select
                  options={Array.isArray(campaignData)
                    ? campaignData.map((item) => ({
                        label: item.name,
                        value: item.id,
                      }))
                    : []}
                  onChange={(selectedOptions)=> setFormData({...formData, campaign:selectedOptions.value })}
                  placeholder={"Seleccione una o más campañas"}
                  isMulti={false}
                  menuPosition="fixed"
                />
              </FormGroup>
            </Col>

            <Col md={10} className='mt-3'>
              <FormGroup>
                <Label for="metadata">Descripción</Label>
                <Input
                  type="textarea"
                  name="description" 
                  id="description"
                  value={formData.description}
                  onChange={(e)=> setFormData({...formData, description: e.target.value})}
                  placeholder="Ingresa Descripción"
                />
              </FormGroup>
            </Col>

          </Row>
          <div className="d-flex justify-content-end mt-3">
            <Button color="secondary" onClick={onClose} className='text-dark me-1'>
              Cancelar
            </Button>
            <Button color="primary" type="submit" className="ml-2">
              Guardar
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default BroadcastCampaignAdd;
