import React, { useState } from 'react'
import { TbPlus } from "react-icons/tb";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { postExcelProductos } from '../../../helpers/backend';
import Swal from "sweetalert2";
import LoadingCargaFullScreen from '../../Loading/LoadingCargaFullScreen';
import EnviarExcel from './EnviarExcel';


const NavbarProductos = ({ actualizarProductos }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false)

  const postExcel = async () => {
    setLoading(true)
    const formData = new FormData();
    formData.append('file', selectedFile);
    try {
      const response = await postExcelProductos(formData);
      console.log(response);
      if (response.status === 200) {
          handleAlert(
            "success",
            "¡Bien hecho!",
            "El archivo se subió correctamente."
          );
          setLoading(false)
          actualizarProductos();
      } else {
        setLoading(true)
          handleAlert(
            "error",
            "Error al subir el archivo",
            response.data?.message || "Ocurrió un error inesperado."
          );
      }
    } catch (error) {
      console.error("Error al cargar el archivo", error);
      handleAlert(
        "error",
        "Error al cargar el archivo",
        "Hubo un problema al intentar subir el archivo."
      );
    }
  };

  const handleAlert = (icon, title, text) => {
    Swal.fire({
        icon: icon,
        title: title,
        text: text,
        timer: 2000,
    });
  };
  
  return (
    <section>
      <article className="content_title_productos">
        <div>
          <h1 className="title_productos">Productos</h1>
        </div>
        <div className="content_buttons_agg">
          <Link to="productos/add" className="button_product_agg">
            <TbPlus size={20} strokeWidth={2.5} /> Añadir
          </Link>
          <input
            type="file"
            id="excelFile"
            accept=".xls, .xlsx"
            style={{ display: "none" }}
            onChange={(event) => {
              setSelectedFile(event.target.files[0]);
            }}
          />
          <button
            onClick={() => {
              document.getElementById("excelFile").click();
            }}
            className="button_product_agg"
          >
            Importar
          </button>
          {selectedFile ? (
            <EnviarExcel
              postExcel={postExcel}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
            />
          ) : null}
          <button
            onClick={() =>
              handleAlert(
                "info",
                "¡Muy pronto!",
                "Las descargas pronto estarán disponibles."
              )
            }
            className="button_product_agg"
          >
            Exportar
          </button>
        </div>
      </article>
      {loading && <LoadingCargaFullScreen />}
    </section>
  );
}

export default NavbarProductos