import React from 'react'
import LoadingImg from '../../assets/images/llantas/loading3.png'
import './styles/LoadingCarga.css'

const LoadingCarga = () => {
  return (
    <div className="loading-spinner">
      <div className="spinner">
        <img className="img_loading_list" src={LoadingImg} alt="" />
      </div>
      <p>Cargando...</p>
    </div>
  );
}

export default LoadingCarga