import React, { useEffect, useState } from 'react';
import {  Button, Container,} from 'reactstrap';
import { MetaTags } from 'react-meta-tags';
import Breadcrumb from '../../components/Common/Breadcrumb';
import CalendarComponent from './CalendarComponent';
import { useParams } from 'react-router-dom';
import  AvailabilityForm  from '../Modales/AvailabilityForm';
import { EventForm } from '../Modales/EventForm';
import { getContactsRead } from '../../helpers/backend';
import AssistantAssign from '../Modales/AssistantAssign';
import TicketModal from '../Modales/TicketModal';
import TaskModal from '../Modales/TasksModal';

function CalendarContact() {
  const contact = useParams().id
  const user = JSON.parse(localStorage.getItem("user"))
  const groups =String(user.groups);
  const [availabilityOpen, setAvailabilityOpen] = useState(false)
  const [eventOpen, setEventOpen] = useState(false)
  const [assistantOpen, setAssistantOpen] = useState(false)
  const [ticketOpen, setTicketOpen] = useState(false)
  const [tasksOpen, setTasksOpen] = useState(false)
  const [contactProfile, setContactProfile]= useState({})
  const getContactData = async () => {
    const response = await getContactsRead(contact);
    const data = response.data;
    setContactProfile(data);   
  };
  useEffect(() => {
      getContactData();  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Agenda </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title="TrueContact" breadcrumbItem=" Agenda "  route = {contactProfile.get_full_name} />

            <Button               
              className='mb-2 text-dark' 
              onClick={()=> setEventOpen(true)}
              style={{borderRadius: '50px', background: '#FFFFFF'}}
              title='Agregar Evento'> 
              <span><i className='fa fa-plus'></i></span>
            </Button>

            {!groups.includes('SuperAdmin') && (
            <Button 
              className='mb-2 text-dark ' 
              onClick={()=> setAvailabilityOpen(true)}
              style={{borderRadius: '50px' ,background: '#FFFFFF'}}
              title='Agregar Disponibilidad'> 
              <span><i className='fa fa-clock'></i></span>
            </Button>
          )}
            <Button 
              className='mb-2 text-dark ' 
              onClick={()=> setAssistantOpen(true)}
              style={{borderRadius: '50px', background: '#FFFFFF'}}
              title='Agregar Asistente'> 
              <span><i className='fa fa-user-alt'></i></span>
            </Button>

            <Button 
              className='mb-2 text-dark ' 
              onClick={()=> setTasksOpen(true)}
              style={{borderRadius: '50px', background: '#FFFFFF'}}
              title='Agregar Tarea'> 
              <span><i className='fa fa-tasks'></i></span>
            </Button>

            <Button 
              className='mb-2 text-dark ' 
              onClick={()=> setTicketOpen(true)}
              style={{borderRadius: '50px', background: '#FFFFFF'}}
              title='Agregar Caso'> 
              <span><i className='fas fa-ticket-alt'></i></span>
            </Button>

            {availabilityOpen && 
              <AvailabilityForm 
                contact ={contact}
                isOpen = {availabilityOpen}
                onClose = {()=> setAvailabilityOpen(false)} />
            }
             {eventOpen && 
              <EventForm 
                contact ={contact}
                isOpen = {eventOpen}
                onClose = {()=> setEventOpen(false)} 
                refresh = {getContactData()}
              />

            }
            {assistantOpen && 
              <AssistantAssign 
                contact ={contact}
                isOpen = {assistantOpen}
                onClose = {()=> setAssistantOpen(false)} />
            }
            {tasksOpen && 
              <TaskModal 
                contact ={contact}
                isOpen = {tasksOpen}
                onClose = {()=> setTasksOpen(false)} />
            }
            {ticketOpen && 
              <TicketModal 
                contact ={contact}
                isOpen = {ticketOpen}
                onClose = {()=> setTicketOpen(false)} />
            }
            
            <CalendarComponent contact ={contact} eventOpen = {eventOpen}/>

        </Container>
      </div>
    </React.Fragment>
  );
}

export default CalendarContact;