import React from 'react';
import styles from "./components/ticketModal.module.scss"
import { Modal } from 'reactstrap';

const TicketModal = (props) => {
  const { isOpen, onClose } = props;
  return (
  <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered
      data-toggle="modal"
      className={styles.cardWrap}
      toggle={onClose} // Utiliza la función de cerrar del padre para cerrar el modal
    >

  <div >
      <div className={`${styles.card} ${styles['cardLeft']}`}>
          <h2 >Caso 1</h2>
      </div>
      <div className={`${styles.card} ${styles['cardRight']}`}>
          <div className={styles.eye}></div>
          <div className={styles.barcode}></div>
      </div>
    </div>
    </Modal>


  );
};

export default TicketModal;
