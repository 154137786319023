import ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { store, sagaMiddleware } from "./store"
import rootSaga from "./store/sagas";

sagaMiddleware.run(rootSaga)

const app = (
  <Provider store={store}>    
      <BrowserRouter>
        <App />
      </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
