import React, { useState } from 'react'
import ContainerTienda from '../../components/TiendaLayout/ContainerTienda'
import FiltroLlantas from '../ProductosLlantas/components/FiltroLlantas';
import Llantas from '../ProductosLlantas/components/Llantas';
import '../ProductosLlantas/styles/PageLlantas.css'
import NavbarProductosMarket from './components/NavbarProductosMarket';
import './styles/ProductosMarket.css'
import { MetaTags } from 'react-meta-tags';

const ProductosMarket = () => {
  const [listType, setListType] = useState(() => {
    const savedListType = localStorage.getItem('listType');
    return savedListType ? JSON.parse(savedListType) : false;
  });
  const [searchProducto, setSearchProducto] = useState('')

  const handleListType = () => {
    const newListType = !listType;
    setListType(newListType);
    localStorage.setItem('listType', JSON.stringify(newListType));
  };

  const handleSearchChange = (e) => {
    setSearchProducto(e.target.value);
  }

  return (
    <ContainerTienda>
      <MetaTags>
        <title>Lista de Productos - Colombia Ruedas</title>
      </MetaTags>
      <main>
        <div className="page_llantas">
          <div className="container_page_llantas">
            <div
              className="container_filtro"
              data-aos="fade-right"
              data-aos-duration="500"
            >
              <FiltroLlantas />
            </div>

            <div className="container_llantas layout_" data-aos-duration="500">
              <div className="contain_navbar_market">
                <NavbarProductosMarket
                  listType={listType}
                  onListTypeChange={handleListType}
                  onSearchChange={handleSearchChange}
                />
              </div>
              <Llantas searchProducto={searchProducto} listType={listType} />
            </div>
          </div>
        </div>
      </main>
    </ContainerTienda>
  );
}

export default ProductosMarket