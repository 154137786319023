import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";


import SweetAlert from "react-bootstrap-sweetalert";

// Redux
import { connect, useDispatch } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { contactsCreate } from "../../../store/contacts/actions";
import { Link } from "react-router-dom/cjs/react-router-dom";
import fondo from "../../../assets/images/backgruond-blue.jpg"

const UserAddPublic = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();



  const [formData, setFormData] = useState({
    username: "",
    password: "",
    confirm_password: "",
    first_name: "",
    last_name: "",
    contactType: 'PERSONA NATURAL',
    documentType: "",
    documentNumber: "",
    

  });

  const { username, password, confirm_password, first_name, last_name, documentNumber} =
    formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const onSubmit = (e) => {
    e.preventDefault();
    dispatch (contactsCreate(formData));
  };

  const redirect = () => {
    history.push("/login");
  };

  return (

    <div className="d-flex align-items-center min-vh-100 position-relative">
    <div className="position-fixed top-0 start-0 w-100 h-100 z-index--1">
      <img src={fondo} alt="" className="w-100 h-100 " />
    </div>
  <React.Fragment>
        <MetaTags>
          <title>Trámites| Usuarios </title>
        </MetaTags>
              {props.contactsCreated.id ? (
                <SweetAlert
                  title="Hecho!"
                  success
                  timeout={2000}
                  showConfirm={false}
                  onConfirm={() => {
                    redirect();
                  }}
                >
                  Se ha creado el usuario exitosamente
                </SweetAlert>
              ) : null}
      <Container fluid>
        <Row>
          <Col className="d-flex justify-content-center">
                <Form 
                  style={{width:"600px", borderRadius: "35px", padding: '35px'}} 
                  className="form-control needs-validation" 
                  onSubmit={onSubmit}>
                  <CardTitle> <h4 className="mb-4"><b> Registrar nuevo Usuario </b></h4></CardTitle>
                  <Row>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="username"
                        className="form-label"
                      >
                        Correo Electrónico
                      </Label>
                      <Input
                        id="username"
                        name="username"
                        type="mail"
                        onChange={onChange}
                        value={username}
                        valid={
                          props.error.username
                            ? false
                            : props.contactsCreate.id && true
                        }
                        invalid={
                          props.error
                            ? props.error.username
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.username}</FormFeedback>
                    </Col>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="password"
                        className="form-label"
                      >
                        Contraseña
                      </Label>
                      <Input
                        id="password"
                        name="password"
                        type="password"
                        onChange={onChange}
                        value={password}
                        valid={props.contactsCreate.id ? true : false}
                        invalid={
                          props.error
                            ? props.error.password
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.password}</FormFeedback>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="confirm_password"
                        className="form-label"
                      >
                        Confirmación de Contraseña
                      </Label>
                      <Input
                        id="confirm_password"
                        name="confirm_password"
                        type="password"
                        onChange={onChange}
                        value={confirm_password}
                        valid={props.contactsCreate.id ? true : false}
                        invalid={
                          props.error
                            ? props.error.confirm_password
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>
                        {props.error.confirm_password}
                      </FormFeedback>
                    </Col>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="first_name"
                        className="form-label"
                      >
                        Nombres
                      </Label>
                      <Input
                        id="first_name"
                        name="first_name"
                        type="text"
                        onChange={onChange}
                        value={first_name}
                        valid={props.contactsCreate.id ? true : false}
                        invalid={
                          props.error
                            ? props.error.first_name
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.first_name}</FormFeedback>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="last_name"
                        className="form-label"
                      >
                        Apellidos
                      </Label>
                      <Input
                        id="last_name"
                        name="last_name"
                        type="text"
                        onChange={onChange}
                        value={last_name}
                        valid={props.contactsCreate.id ? true : false}
                        invalid={
                          props.error
                            ? props.error.last_name
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.last_name}</FormFeedback>
                    </Col>
                    <Col>
                    <Label
                      for="documentType"
                      className="form-label"
                    >
                      Tipo de Documento
                    </Label>
                    <select
                      className="form-select"
                      id="documentType"
                      name="documentType"
                      type="text"
                      onChange={onChange}                      
                      required 
                    >
                      <option defaultValue>Seleccione una opcion</option>
                      <option value ="CEDULA">CEDULA</option>
                      <option value ="PASAPORTE">PASAPORTE</option>
                      <option value ="CEDULA_EXTRANJERIA">CEDULA EXTRAJERIA</option>
                      <option value ="NIT">NIT</option>
                      <option value ="OTRO">OTRO</option>
                    </select>
                    {props.error && props.error.documentType ? (
                    <Alert color = 'danger' className="mt-2">{props.error.documentType}</Alert>
                    ):null}
                  </Col>
                  </Row>
                  <Col>  
                    <Label
                      for="documentNumber"
                      className='form-label'
                    >
                      Documento
                    </Label>
                    <Input

                      id="documentNumber"
                      name="documentNumber"
                      type="number"
                      onChange={onChange}
                      value={documentNumber}
                      required 
                    />
                    {props.error && props.error.documentNumber ? (
                    <Alert color = 'danger' className="mt-2">{props.error.documentNumber}</Alert>
                    ):null}
                  </Col>
                  <Row >
                    <Col className="d-flex justify-content-center mt-3">
                      <Link className="btn btn-secondary text-dark ms-5" to="/login">
                        Volver
                      </Link>
                      <Button color="primary" type="submit" className="ms-1">
                       Guardar
                      </Button>
                    </Col>
                  </Row>
                </Form>
          </Col>
        </Row>


      </Container>

  </React.Fragment>
    </div>                  
  );
};

const mapStateToProps = (state) => {
  const { error, loading, contactsCreated } = state.Contacts;
  return { error, loading, contactsCreated };
};

UserAddPublic.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  contactsCreateded: PropTypes.object,
};
export default withRouter(connect(mapStateToProps, { contactsCreate })(UserAddPublic));




