import React, { useEffect, useState } from 'react'
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend, ResponsiveContainer } from 'recharts';

const Resaltados = ({llanta}) => {
  const [selectedRange, setSelectedRange] = useState('today');
  const [data, setData] = useState([]);

  const getReportData = async (range) => {
    
    const mockData = {
      today: [
        { timeSlot: '08:00', vistas: 10 },
        { timeSlot: '09:00', vistas: 12 },
        { timeSlot: '10:00', vistas: 15 },
        { timeSlot: '11:00', vistas: 18 },
        { timeSlot: '12:00', vistas: 25 },
      ],
      lastWeek: [
        { timeSlot: 'Lunes', vistas: 50 },
        { timeSlot: 'Martes', vistas: 60 },
        { timeSlot: 'Miércoles', vistas: 40 },
        { timeSlot: 'Jueves', vistas: 75 },
        { timeSlot: 'Viernes', vistas: 30 },
      ],
      thisMonth: [
        { timeSlot: 'Semana 1', vistas: 200 },
        { timeSlot: 'Semana 2', vistas: 250 },
        { timeSlot: 'Semana 3', vistas: 300 },
        { timeSlot: 'Semana 4', vistas: 150 },
      ],
      thisYear: [
        { timeSlot: 'Julio', vistas: 500 },
        { timeSlot: 'Agosto', vistas: 600 },
        { timeSlot: 'Septiembre', vistas: 750 },
        { timeSlot: 'Octubre', vistas: 450 },
        { timeSlot: 'Noviembre', vistas: 300 },
      ],
    };

    setData(mockData[range]);
  };

  useEffect(() => {
    getReportData(selectedRange);
  }, [selectedRange]);

  const currentDate = new Date();
  const months = [
    'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto',
    'septiembre', 'octubre', 'noviembre', 'diciembre'
  ];
  const day = currentDate.getDate();
  const month = months[currentDate.getMonth()];
  const year = currentDate.getFullYear();
  const formattedDate = `${day} de ${month} de ${year}`;

  return (
    <section>
      <h3 style={{ paddingBottom: "1rem" }}>Resaltados</h3>
      <div style={{ display: "grid", gap: "4rem" }}>
        <article className="content_estadisticas_">
          <div className="content_estadisticas_totales">
            <div>
              <h6 className="title_estadisticas_resaltados">
                Estadísticas totales
              </h6>
            </div>
            <div>
              <h2 className="title_name_producto_estadistica">{llanta.name}</h2>
            </div>
            <div>
              <div>
                <p>Publicado el 20-10-2023</p>
              </div>
              <div className="content_vistas_estadisticas_totales">
                <div>
                  <p className="title_stats">Vistas</p>
                  <span className="valor_vista_stats">10</span>
                </div>
                <div>
                  <p className="title_stats">Me gusta</p>
                  <span className="valor_vista_stats">1</span>
                </div>
                <div>
                  <p className="title_stats">Comentario</p>
                  <span className="valor_vista_stats">0</span>
                </div>
              </div>
            </div>
          </div>

          <div className="content_estadisticas_totales">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h6 className="title_estadisticas_resaltados">
                Me gusta de la entreda
              </h6>
              <span className="number_megusta">1</span>
            </div>
            <div>
              <p>Esta entrada todavía no tiene ningún Me gusta.</p>
            </div>
          </div>
        </article>

        <article>
          <div className='content_buttons_range'>
            <h3 style={{color:"#1E2022"}}>{formattedDate}</h3>
            <div>
              {/* Botones de Dias, semanas, meses, años */}
              <button 
                onClick={() => setSelectedRange('today')}
                className={`left_rage ${selectedRange === 'today' ? 'button_range_active' : 'button_range_date'}`}
              >
                Hoy
              </button>
              <button 
                onClick={() => setSelectedRange('lastWeek')}
                className={`${selectedRange === 'lastWeek' ? 'button_range_active' : 'button_range_date'}`}
              >
                Esta Semana
              </button>
              <button 
                onClick={() => setSelectedRange('thisMonth')}
                className={`${selectedRange === 'thisMonth' ? 'button_range_active' : 'button_range_date'}`}
              >
                Este Mes
              </button>
              <button 
                onClick={() => setSelectedRange('thisYear')}
                className={`right_rage ${selectedRange === 'thisYear' ? 'button_range_active' : 'button_range_date'}`}
              >
                Este Año
              </button>
            </div>
          </div>
          <div style={{paddingTop:"2rem"}}>
            {/* Grafica */}
            <ResponsiveContainer width="100%" height={350}>
              <BarChart data={data} margin={{ top: 10, right: 35, bottom: 0, left: -20 }}>
                <XAxis dataKey="timeSlot" />
                <YAxis domain={[0, 'dataMax + 1']} tickCount={Math.max(...data.map((item) => item.vistas)) + 1} />
                <Tooltip />
                <Legend />
                <CartesianGrid strokeDasharray="3 3" />
                <Bar dataKey="vistas" fill="#e0324b" barSize={130} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </article>

        <article></article>
      </div>
    </section>
  );
}

export default Resaltados