import React, { useEffect, useState } from "react";  
import ReactApexChart from "react-apexcharts";  
import { getReportsBot } from "../../../helpers/backend";  
import { Card } from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";  
import { ResponsiveContainer } from "recharts";

const ReportOnBot = () => {  
  const [selectedRange, setSelectedRange] = useState('today');  
  const [chartData, setChartData] = useState({ series: [], options: {} });  

  const getReportData = async (range) => {  
    const today = new Date();  
    let fechaInicio;  
    let fechaFin;  

    if (range === 'lastWeek') {  
      const lastWeek = new Date(today);  
      lastWeek.setDate(today.getDate() - 7);  
      fechaInicio = lastWeek.toISOString().split('T')[0];  
      fechaFin = today.toISOString().split('T')[0];  
    } else {  
      fechaInicio = today.toISOString().split('T')[0];  
      fechaFin = today.toISOString().split('T')[0];  
    }  

    const response = await getReportsBot(`?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&estado=&agents=&campaign=`);  
    const data = response.data; // Supongamos que la respuesta es el objeto de datos esperado  
    transformDataForChart(data);  
  };  

  useEffect(() => {  
    getReportData(selectedRange); 
    // eslint-disable-next-line 
  }, [selectedRange]);  

  const transformDataForChart = (data) => {  
    const { gestiones_en_bot, gestiones_fuera_bot } = data;  
    const series = [gestiones_en_bot, gestiones_fuera_bot];  
    const options = {  
      labels: ['Conversaciones en Bot', 'Conversaciones fuera del Bot'],  
      colors: ['#0088FE', '#FF8042'],  
      legend: {  
        show: true,  
        position: 'bottom',  
        horizontalAlign: 'center',  
        verticalAlign: 'middle',  
        fontSize: '18',  
      },  
    };  

    setChartData({ series, options });  
  };  

  return (
    <Card
      className="px-4 py-3"
      style={{ backgroundColor: "#f6f8fc", borderColor: "#f6f8fc", boxShadow: "0 4px 10px -1px rgb(0 0 0 / 0.1), 0 2px 7px -2px rgb(0 0 0 / 0.1)" }}
    >
      <div className="d-flex justify-content-between ">
        <p className="fw-semibold font-size-18 ">Ingresados al Bot</p>
        <select
          style={{ maxWidth: "170px", maxHeight: "34px", backgroundColor: "#f6f8fc" }}
          className="form-select font-size-13"
          value={selectedRange}
          onChange={(e) => setSelectedRange(e.target.value)}
        >
          <option value="lastWeek">Última Semana</option>
          <option value="today">Hoy</option>
        </select>
      </div>
      <ResponsiveContainer width="100%" height={280}>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="pie"
          className="apex-charts "
          dir="ltr"
        />
      </ResponsiveContainer>
      <div className="p-1 d-flex justify-content-end">
        <Link to="report_for_bot" className="btn btn-success btn-sm">
          Consultar reportes
        </Link>
      </div>
    </Card>
  );  
};  

export default ReportOnBot;