import React, { useEffect, useRef, useState } from 'react';
import { TbDotsVertical } from 'react-icons/tb';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Visualizador from './Visualizador';

const GraficosRendimiento = () => {

  const datosPorDia = [
    {
      name: 'Ventas netas',
      data: [
        { timeSlot: 'Lunes', ventasNetas: 200 },
        { timeSlot: 'Martes', ventasNetas: 400 },
        { timeSlot: 'Miércoles', ventasNetas: 300 },
        { timeSlot: 'Jueves', ventasNetas: 600 },
        { timeSlot: 'Viernes', ventasNetas: 800 },
        { timeSlot: 'Sábado', ventasNetas: 700 },
        { timeSlot: 'Domingo', ventasNetas: 900 },
      ],
      id: 'ventasNetas'
    },
    {
      name: 'Pedidos',
      data: [
        { timeSlot: 'Lunes', pedidos: 70 },
        { timeSlot: 'Martes', pedidos: 60 },
        { timeSlot: 'Miércoles', pedidos: 40 },
        { timeSlot: 'Jueves', pedidos: 80 },
        { timeSlot: 'Viernes', pedidos: 60 },
        { timeSlot: 'Sábado', pedidos: 90 },
        { timeSlot: 'Domingo', pedidos: 80 },
      ],
      id: 'pedidos'
    },
    {
      name: 'Ventas totales',
      data: [
        { timeSlot: 'Lunes', ventasTotales: 200 },
        { timeSlot: 'Martes', ventasTotales: 400 },
        { timeSlot: 'Miércoles', ventasTotales: 300 },
        { timeSlot: 'Jueves', ventasTotales: 600 },
        { timeSlot: 'Viernes', ventasTotales: 800 },
        { timeSlot: 'Sábado', ventasTotales: 700 },
        { timeSlot: 'Domingo', ventasTotales: 900 },
      ],
      id: 'ventasTotales'
    },
    {
      name: 'Artículos vendidos',
      data: [
        { timeSlot: 'Lunes', articulosVendidos: 200 },
        { timeSlot: 'Martes', articulosVendidos: 400 },
        { timeSlot: 'Miércoles', articulosVendidos: 300 },
        { timeSlot: 'Jueves', articulosVendidos: 600 },
        { timeSlot: 'Viernes', articulosVendidos: 800 },
        { timeSlot: 'Sábado', articulosVendidos: 700 },
        { timeSlot: 'Domingo', articulosVendidos: 900 },
      ],
      id: 'articulosVendidos'
    },
    {
      name: 'Devoluciones',
      data: [
        { timeSlot: 'Lunes', devoluciones: 200 },
        { timeSlot: 'Martes', devoluciones: 400 },
        { timeSlot: 'Miércoles', devoluciones: 300 },
        { timeSlot: 'Jueves', devoluciones: 600 },
        { timeSlot: 'Viernes', devoluciones: 800 },
        { timeSlot: 'Sábado', devoluciones: 700 },
        { timeSlot: 'Domingo', devoluciones: 900 },
      ],
      id: 'devoluciones'
    },
  ];

  const datosPorSemana = [
    {
      name: 'Ventas netas',
      data: [
        { timeSlot: 'Semana 1', ventasNetas: 1400 },
        { timeSlot: 'Semana 2', ventasNetas: 1800 },
        { timeSlot: 'Semana 3', ventasNetas: 1500 },
        { timeSlot: 'Semana 4', ventasNetas: 2500 },
      ],
      id: 'ventasNetas'
    },
    {
      name: 'Pedidos',
      data: [
        { timeSlot: 'Semana 1', pedidos: 300 },
        { timeSlot: 'Semana 2', pedidos: 250 },
        { timeSlot: 'Semana 3', pedidos: 200 },
        { timeSlot: 'Semana 4', pedidos: 350 },
      ],
      id: 'pedidos'
    },
    {
      name: 'Ventas totales',
      data: [
        { timeSlot: 'Semana 1', ventasTotales: 300 },
        { timeSlot: 'Semana 2', ventasTotales: 250 },
        { timeSlot: 'Semana 3', ventasTotales: 200 },
        { timeSlot: 'Semana 4', ventasTotales: 350 },
      ],
      id: 'ventasTotales'
    },
    {
      name: 'Artículos vendidos',
      data: [
        { timeSlot: 'Semana 1', articulosVendidos: 300 },
        { timeSlot: 'Semana 2', articulosVendidos: 250 },
        { timeSlot: 'Semana 3', articulosVendidos: 200 },
        { timeSlot: 'Semana 4', articulosVendidos: 350 },
      ],
      id: 'articulosVendidos'
    },
    {
      name: 'Devoluciones',
      data: [
        { timeSlot: 'Semana 1', devoluciones: 300 },
        { timeSlot: 'Semana 2', devoluciones: 250 },
        { timeSlot: 'Semana 3', devoluciones: 200 },
        { timeSlot: 'Semana 4', devoluciones: 350 },
      ],
      id: 'devoluciones'
    },
  ];
  
  const [rangoSeleccionado, setRangoSeleccionado] = useState('dia');
  const datosAUsar = rangoSeleccionado === 'dia' ? datosPorDia : datosPorSemana;
  const [showVisualizador, setShowVisualizador] = useState(false);
  const [datosVisibles, setDatosVisibles] = useState(
    rangoSeleccionado === 'dia' ? ['ventasNetas', 'pedidos'] : ['ventasNetas', 'pedidos']
  );

  const visualizadorRef = useRef(null);

  const handleShowVisualizador = () => {
    setShowVisualizador(prev => !prev);
  };

  const handleClickOutside = (event) => {
    if (visualizadorRef.current && !visualizadorRef.current.contains(event.target)) {
      setShowVisualizador(false);
    }
  };

  useEffect(() => {
    if (showVisualizador) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showVisualizador]);

  const manejarRangoSeleccionado = (rango) => {
    setRangoSeleccionado(rango);
  };


  const toggleVisibility = (id) => {
    setDatosVisibles((prevState) => {
      if (prevState.includes(id)) {
        return prevState.filter(statId => statId !== id);
      } else {
        return [...prevState, id];
      }
    });
    // setTimeout(() => {
    //   setLoadingStats((prevState) => prevState.filter((statId) => statId !== id));
    // }, 1500);
  }

  return (
    <section style={{ paddingBottom: "3.5rem" }}>
      <div className="content_graficas_">
        <div>
          <h5>Gráficos</h5>
        </div>
        <div
          className="content_buttons_range"
          style={{ paddingRight: "1.5rem" }}
        >
          <div style={{ paddingRight: "1rem" }}>
            <button
              className={`left ${
                rangoSeleccionado === "dia"
                  ? "button_range_active"
                  : "button_range_date"
              }`}
              onClick={() => manejarRangoSeleccionado("dia")}
            >
              Por Día
            </button>
            <button
              className={`right ${
                rangoSeleccionado === "semana"
                  ? "button_range_active"
                  : "button_range_date"
              }`}
              onClick={() => manejarRangoSeleccionado("semana")}
            >
              Por semana
            </button>
          </div>
          <div
            className="content_dots_vertical"
            onClick={handleShowVisualizador}
          >
            <TbDotsVertical size={26} strokeWidth={2.5} />
          </div>
          {showVisualizador && (
            <Visualizador
              datos={rangoSeleccionado === "dia" ? datosPorDia : datosPorSemana}
              toggleVisibility={toggleVisibility}
              showVisualizador={showVisualizador}
              visibleStats={datosVisibles}
              referencia={visualizadorRef}
            />
          )}
        </div>
      </div>

      <article className="content_graficos_analisis">
        {datosAUsar.map((grafica) => {
          // Solo renderizar gráficos cuyo id esté en datosVisibles
          if (datosVisibles.includes(grafica.id)) {
            return (
              <div key={grafica.id} className="content_grafica_analisis">
                <h6 className='title_graficos_'>{grafica.name}</h6>
                <div style={{ paddingTop: "2rem" }}>
                  <ResponsiveContainer width="100%" height={350}>
                    <AreaChart
                      data={grafica.data}
                      margin={{ top: 10, right: 30, bottom: 0, left: 0 }}
                    >
                      <defs>
                        <linearGradient
                          id="colorUv"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor="#e0324b"
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor="#e0324b"
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                      <XAxis dataKey="timeSlot" />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Area
                        type="monotone"
                        dataKey={grafica.id}
                        stroke="#e0324b"
                        fillOpacity={1}
                        fill="url(#colorUv)"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </div>
            );
          }
          return null; // No renderizar nada si no está visible
        })}
      </article>
    </section>
  );
}

export default GraficosRendimiento;
