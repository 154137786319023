//import { REHYDRATE } from "redux-persist/lib/constants"
import {
  LOGIN_USER,
  ME,
  LOGOUT_USER,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  AUTH_REQUEST_FAILED,
} from "../auth/actionTypes";

const initialState = {
  user: null,
  error: null,
  loading: false,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ME:
      state = {
        ...state,
        loading: false,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        user: action.payload,
        error: "",
      };
      break;
    case LOGOUT_USER:
      state = {
        ...state,
        loading: true,
        user: null,
      };
      break;
    case LOGOUT_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case AUTH_REQUEST_FAILED:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
