import React, { useEffect, useRef, useState } from 'react'
import { TbDotsVertical } from "react-icons/tb";
import Visualizador from './Visualizador';


const rendimiento = [
  {
    id: 0,
    name: "Ventas totales",
    prom: "0",
    porcen: "0%"
  },
  {
    id: 1,
    name: "Ventas netas",
    prom: "0",
    porcen: "0%"
  },
  {
    id: 2,
    name: "Pedidos",
    prom: "0",
    porcen: "0%"
  },
  {
    id: 3,
    name: "Productos vendidos",
    prom: "0",
    porcen: "0%"
  },
  {
    id: 4,
    name: "Visitantes",
    prom: "0",
    porcen: "0%"
  },
  {
    id: 5,
    name: "Variaciones vendidas",
    prom: "0",
    porcen: "0%"
  },
  {
    id: 6,
    name: "Visitas",
    prom: "0",
    porcen: "0%"
  },
  {
    id: 7,
    name: "Valor medio de los pedidos",
    prom: "0",
    porcen: "0%"
  },
  {
    id: 8,
    name: "Importe nedo del descuento",
    prom: "0",
    porcen: "0%"
  },
  {
    id: 9,
    name: "Impuesto total",
    prom: "0",
    porcen: "0%"
  },
  {
    id: 10,
    name: "Ventas brutas",
    prom: "0",
    porcen: "0%"
  },
  {
    id: 11,
    name: "Pedidos con descuento",
    prom: "0",
    porcen: "0%"
  },
  {
    id: 12,
    name: "Devoluciones",
    prom: "0",
    porcen: "0%"
  },
  {
    id: 13,
    name: "Vistas",
    prom: "0",
    porcen: "0%"
  },
  {
    id: 14,
    name: "Impuesto de envío",
    prom: "0",
    porcen: "0%"
  }
]

const Rendimiento = () => {
  const [visibleStats, setVisibleStats] = useState(
    rendimiento.slice(0, 6).map(stat => stat.id)
  );
  const [showVisualizador, setShowVisualizador] = useState(false);
  const [loadingStats, setLoadingStats] = useState([]);

  const visualizadorRef = useRef(null);

  const handleShowVisualizador = () => {
    setShowVisualizador(prev => !prev);
  };

  const toggleVisibility = (id) => {
    setLoadingStats((prevState) => [...prevState, id]);
    setVisibleStats((prevState) =>
      prevState.includes(id)
        ? prevState.filter((statId) => statId !== id)
        : [...prevState, id]
    );
    setTimeout(() => {
      setLoadingStats((prevState) => prevState.filter((statId) => statId !== id));
    }, 1500);
  }

  const handleClickOutside = (event) => {
    if (visualizadorRef.current && !visualizadorRef.current.contains(event.target)) {
      setShowVisualizador(false);
    }
  };

  useEffect(() => {
    if (showVisualizador) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showVisualizador]);
  

  return (
    <section style={{ paddingBottom: "3.5rem" }}>
      <div className="content_rendimiento">
        <div>
          <h5>Rendimiento</h5>
        </div>
        <div onClick={handleShowVisualizador} className="content_dots_vertical">
          <TbDotsVertical size={26} strokeWidth={2.5} />
        </div>
        {showVisualizador && (
          
            <Visualizador
              datos={rendimiento}
              toggleVisibility={toggleVisibility}
              showVisualizador={showVisualizador}
              visibleStats={visibleStats}
              referencia={visualizadorRef}
            />
          
        )}
      </div>
      <article className="content_rendimiento_analisis">
        {rendimiento
          .filter((rendi) => visibleStats.includes(rendi.id))
          .map((rendi) => (
            <div key={rendi.id} className="card_redimiento">
              {loadingStats.includes(rendi.id) ? (
                <div>
                  <div className="skeleton skeleton_title"></div>
                  <div className="content_stats_rendimiento">
                    <div className="skeleton skeleton_stat"></div>
                    <div className="skeleton skeleton_stat"></div>
                  </div>
                </div>
              ) : (
                <div>
                  <h6 style={{ fontWeight: "400" }}>{rendi.name}</h6>
                  <div className="content_stats_rendimiento">
                    <p className="rendi_prom">{rendi.prom}</p>
                    <p className="rendi_porcen">{rendi.porcen}</p>
                  </div>
                </div>
              )}
            </div>
          ))}
      </article>
    </section>
  );
}

export default Rendimiento