import React, { useEffect } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom';
import AOS from 'aos';
import "aos/dist/aos.css";
import Swal from "sweetalert2";


const CardProduct = ({product}) => {

  const handleAlert = () => {
    Swal.fire ({
      icon: 'info',
      title: '¡Estamos trabajando en ello!',
      timer: 2000,
    })   
  }

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <article className="card_product" data-aos="fade-up">
      <div className="img_container">
        <img className="img_product" src={product.img} alt="llantas" />
      </div>
      <div className="container_product">
        <div className="details_container">
          <div>
            <h1 className="title_product">
              <span className="brand_product">{product.name}</span>{" "}
              {product.subname}
            </h1>
          </div>
          <div>
            <p className="category_product">Categoría: {product.category}</p>
          </div>
        </div>

        <div className='price-butt'>
          <div>
            <p className="price_product">${product.price}</p>
          </div>

          <div className="buttoms_action">
            <Link onClick={handleAlert} className="comprar_producto" to="#">
              Comprar ahora
            </Link>
            <Link onClick={handleAlert} className="detalles_producto" to="#">
              Más detalles
            </Link>
          </div>
        </div>
      </div>
    </article>
  );
}

export default CardProduct