
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { connect, useDispatch } from "react-redux";
import { withRouter, Link, useHistory, useParams} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Form,
  Button,
  CardTitle,
  Input,
  FormFeedback,
  Alert,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader"

import { getAccountRead } from "../../helpers/backend";

import {  accountUpdate, } from "../../store/actions";

const AccountUpdate = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const id = useParams().id




  const getAccountData = async () => {
    const response = await getAccountRead(id);
    const data = response.data;
    setFormData({
      name: data.name ,
      documentType:data.documentType,
      documentNumber:data.documentNumber,
      address: data.address,
      phone: data.phone,
      city: data.city

    })
  };
useEffect(() => {
  getAccountData();
// eslint-disable-next-line react-hooks/exhaustive-deps
},[])



  const [formData, setFormData] = useState({
    name: "",
    documentType:"",
    documentNumber:"",
    address: "",
    phone: "",
    city: "",
  });
  
  const {
    name,
    documentType,
    documentNumber,    
    address,
    phone,
    city,


  } = formData;
  
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  
  
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(accountUpdate( id, formData));
  };
  
  const redirect = () => {
    history.push("/account");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Cuenta  </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="TrueContact" breadcrumbItem=" Cuenta " />
          {props.loading ? (
            <Loader />
          ) : (
          <div className="d-flex justify-content-center">
              {props.accountUpdated.id ? (
                <SweetAlert
                  title="¡Hecho!"
                  success
                  showConfirm={true}
                  onConfirm={() => {
                    redirect();
                  }}
                >
                  Se ha
                  actualizado <b>{props.accountUpdated.nombre}</b> correctamente.
                </SweetAlert>
              ) : null}

              <Card
                style={{
                  width: "60rem",
                }}
              >
                <CardTitle className="ps-3 pt-3">
                  <h2 className="mb-3 font-size-20">Cuenta</h2>
                </CardTitle>
                <CardBody>
                <Form onSubmit={onSubmit}>
                <Row className="justify-content-center" >
              <Col lg={10}>
                <Row className="mt-3">
                  <Col>
                    <Label
                      for="name"                      
                    >
                      Nombre
                    </Label>
                    <Input                      
                      id="name"
                      name="name"
                      type="name"
                      onChange={onChange}
                      value= {name}
                      autoFocus
                      required
      
                    />
                    {props.error && props.error.name ? (
                      <Alert color = 'danger' className="mt-2">{props.error.name}</Alert>
                    ):null}
                  </Col>  
                  <Col>
                    <Label
                      for="documentType"
                      
                    >
                      Tipo de Documento
                    </Label>
                    <select
                      className="form-select"
                      id="documentType"
                      name="documentType"
                      type="text"
                      onChange={onChange}                      
                      required 
                    >
                      <option defaultValue> {documentType} </option>
                      <option value ="CEDULA">CEDULA</option>
                      <option value ="PASAPORTE">PASAPORTE</option>
                      <option value ="CEDULA_EXTRANJERIA">CEDULA EXTRAJERIA</option>
                      <option value ="NIT">NIT</option>
                      <option value ="OTRO">OTRO</option>
                    </select>
                    {props.error && props.error.documentType ? (
                    <Alert color = 'danger' className="mt-2">{props.error.documentType}</Alert>
                    ):null}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>  
                    <Label
                      for="documentNumber"
                      
                    >
                      Documento
                    </Label>
                    <Input
                     
                      id="documentNumber"
                      name="documentNumber"
                      type="number"
                      onChange={onChange}
                      value={documentNumber}
                      required 
                    />
                   
                    <FormFeedback>{props.error.documentNumber}</FormFeedback>
                    

                  </Col>
                  <Col>
                    <Label
                      for="address"
                      
                    >
                      Direccion
                    </Label>
                    <Input
                      
                      id="address"
                      name="address"
                      type="text"
                      onChange={onChange}
                      value={address}
                      required 
                    />
                    {props.error && props.error.address ? (
                    <Alert color = 'danger' className="mt-2">{props.error.address}</Alert>
                    ):null}

                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>  
                    <Label
                      for="phone"                      
                    >
                      Teléfono
                    </Label>
                    <Input
                     
                      id="phone"
                      name="phone"
                      type="number"
                      onChange={onChange}
                      value={phone}
                      required 
                    />

                  </Col>
                  <Col>
                    <Label
                      for="address"
                      
                    >
                      Ciudad
                    </Label>
                    <Input
                      
                      id="city"
                      name="city"
                      type="text"
                      onChange={onChange}
                      value={city}
                      required 
                    />
                    {props.error && props.error.city ? (
                    <Alert color = 'danger' className="mt-2">{props.error.city}</Alert>
                    ):null}

                  </Col>
                </Row>
              </Col>
              <Row className="justify-content-md-center mt-5 ms-5">
                      <Col lg={4}>
                        <Link className="btn btn-secondary text-dark me-1" to="/account">
                          Cancelar
                        </Link>

                        <Button color="primary" type="submit">
                          Actualizar
                        </Button>
                      </Col>
                    </Row>
            </Row>
       


                  </Form>
                </CardBody>
              </Card>

            
          </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const { error, loading, accountUpdated } = state.Account;
  return { error, loading, accountUpdated };
};

export default withRouter(connect(mapStateToProps)(AccountUpdate));

