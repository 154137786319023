// ROL requests
export const ROL_LIST = "ROL_LIST";
export const ROL_CREATE = "ROL_CREATE";
export const ROL_FILTER = "ROL_FILTER";
export const ROL_UPDATE = "ROL_UPDATE";
export const ROL_DELETE = "ROL_DELETE";
export const ROL_READ = "ROL_READ";
export const ROL_PERMISSION = "ROL_PERMISSION";

// ROL success
export const ROL_LIST_SUCCESS = "ROL_LIST_SUCCESS";
export const ROL_CREATE_SUCCESS = "ROL_CREATE_SUCCESS";
export const ROL_UPDATE_SUCCESS = "ROL_UPDATE_SUCCESS";
export const ROL_DELETE_SUCCESS = "ROL_DELETE_SUCCESS";
export const ROL_READ_SUCCESS = "ROL_READ_SUCCESS";
export const ROL_PERMISSION_SUCCESS = "ROL_PERMISSION_SUCCESS";

// ROL failed
export const ROL_REQUEST_FAILED = "ROL_REQUEST_FAILED";
