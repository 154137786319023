// Auth requests
export const LOGIN_USER = 'LOGIN_USER';
export const ME = "ME";
export const LOGOUT_USER = "LOGOUT_USER";

// Auth success
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_USER_SUCCESS";

// Auth failed
export const AUTH_REQUEST_FAILED = "AUTH_REQUEST_FAILED";
