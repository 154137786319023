import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Button,  Col, Form, FormFeedback,  Label, Modal, Row } from 'reactstrap';
import SweetAlert from "react-bootstrap-sweetalert";
import Select from 'react-select';
import { connect, useDispatch } from "react-redux";
import {  withRouter } from 'react-router-dom';
import { getContactsFullList, getContactsRead } from '../../helpers/backend';

import { contactsUpdate } from "../../store/contacts/actions";

export const AssistantAssign = (props) => {
  const dispatch= useDispatch();
  const { isOpen, onClose, contact } = props;
  const [alert, setAlert] = useState(false)
  const [contactList, setContactList] = useState([]);
  const getContactListData = async () => {
    const response = await getContactsFullList();
    const data = response.data;
    setContactList(data);
  };

  const getData = async () => {
    const response = await getContactsRead(contact);
    const data = response.data;console.log(data);
    setFormData({
      assistant: data.assistant,
 
    });
  };
  const [formData, setFormData] = useState({
    assistant: [],

});

useEffect(() => {
  getContactListData();
  getData();
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);


  const onSubmit = (e) => {
    e.preventDefault();
    const selectedAssistantValues = Array.isArray(formData.assistant) ? formData.assistant.map((option) => option[1]):[];
    // Crea un nuevo objeto formDataToSend solo con los valores necesarios
    const formDataToSend = {
      ...formData,
      assistant: selectedAssistantValues,
    };console.log(formDataToSend);
    dispatch(contactsUpdate( contact, formDataToSend ));
    setAlert(true)
   };
console.log(formData);
return (
<>
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered
      data-toggle="modal"
      size="md"
      className="custom-modal"
      toggle={onClose} // Utiliza la función de cerrar del padre para cerrar el modal
    >

      <div className="p-4">        
              {props.contactsUpdated.id && alert ? (
                <SweetAlert
                  title="Hecho!"
                  success
                  timeout={2000}
                  showConfirm={false}
                  onConfirm={() => {
                    onClose()
                    setAlert(false)
                  }}
                >
                  Se ha asignado el Asistente con éxito 
                </SweetAlert>
              ) : null}
      <h2 className="text-dark">Asignar Asistentes</h2>
      <hr />
      <Form className="container" onSubmit={onSubmit}>

    <Row className="col-lg-12 justify-content-center">
        <Col lg={10} className="mb-3">
            <Label
              for="Agente"
              className="form-label"
            >
          <strong>Asistente</strong> 
            </Label>
            <Select
                  id="agente"
                  name="agente"                                      
                  options={Array.isArray(contactList)? contactList.map(item=> ({label: item.get_full_name, value: item.id})):[]}
                  value={formData.assistant ? formData.assistant.map(([label, value]) => ({ label, value })): null}
                  onChange={(selectedOptions) => {
                    // Obtener los datos seleccionados de los contactos
                    const selectedContactData = selectedOptions.map((option) => [ option.label, option.value]);
                    // Actualizar formData.contacto con los datos seleccionados
                    setFormData({ ...formData, assistant: selectedContactData });
                  }}  
                  placeholder ={'Seleccione'}
                  isMulti={true}
                  menuPosition="fixed"
                />
            <FormFeedback>{props.error.assistant}</FormFeedback>
          </Col>
          </Row>

        <Row >
          <Col md={12} className="d-flex justify-content-center mt-3">
            <Button className="btn btn-secondary text-dark " onClick={onClose}>
              Volver
            </Button>
            <Button color="primary" type="submit" className=" ms-1">
              Guardar
            </Button>
          </Col>

        </Row>
      </Form>               
                



      </div>


    </Modal>
</>         
  );
};
const mapStateToProps = (state) => {
  const { error, loading, contactsUpdated } = state.Contacts;
  return { error, loading, contactsUpdated };
};
contactsUpdate.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  contactsUpdated: PropTypes.object,
};
export default withRouter(connect(mapStateToProps, { contactsUpdate })(AssistantAssign));