import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { 
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Form,
  Table,
  Input,
  Button,
  InputGroup,
 } from "reactstrap"
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader"
import Pagination from "../../components/Common/Pagination"

import { auditsList, auditsFilter } from "../../store/audits/actions"

const Audits = (props) => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [date, setDate] = useState("");

  const onChange = (e) => {
    setSearch(e.target.value);
    setDate(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (search){
      dispatch(auditsFilter(`?search=${search}`));
    }
    else {
      dispatch(auditsFilter(`?search=${date}`));
    }
    

  };

  const resetSearch = () => {
    dispatch(auditsFilter(`?search=`));
    setSearch("");
    setDate("");
  };


  useEffect(() => {

    dispatch(auditsList())

  }, [dispatch])

  const params = {
    count: props.audits.count,
    next: props.audits.next,
    previous: props.audits.previous,
    results: props.audits.results,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Auditoría </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="TrueContact" breadcrumbItem=" Auditoría " />

          {props.loading ? (
            <Loader />
          ) : (
            <div>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                    <Form className="search-box" onSubmit={onSubmit}>
                        <InputGroup>
                          <Input
                            type="date"
                            className="form-control"
                            name="date"                          
                            value={date}
                            onChange={onChange}
                          />
                          <i className="bx bx-search-alt search-icon" />
                          <Button color="primary" onClick={resetSearch}>
                            <span className=" bx bx-x fa-lg align-middle"></span>
                          </Button>
                        </InputGroup>
                      </Form>
                    </Col>
                    <Col md={6}>
                      <Form className="search-box" onSubmit={onSubmit}>
                        <InputGroup>
                          <Input
                            type="text"
                            className="form-control"
                            name="search"
                            placeholder="Usuario, Estado ..."
                            value={search}
                            onChange={onChange}
                          />
                          <i className="bx bx-search-alt search-icon" />
                          <Button color="primary" onClick={resetSearch}>
                            <span className=" bx bx-x fa-lg align-middle"></span>
                          </Button>
                        </InputGroup>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                {params.results ? (
                  <CardBody className="ps-5 pe-5">
                    <h2 className="mb-3 font-size-20">Historial</h2>
                    <div className="table-responsive">
                      <Table
                        className="table  align-middle table-centered  mb-3"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <thead className="table-dark table-nowrap">
                          <tr>
                            <th>#</th>
                            <th>Fecha y Hora</th>
                            <th>Tipo</th>
                            <th>Gestión</th>
                            <th>Estado</th>
                            <th>Descripción</th>
                            <th>Usuario</th>

                          </tr>
                        </thead>
                        <tbody>
                          {params.results.map((audits, idx ) => (
                            
                            <tr key={idx}>
                              <th scope="row">{ idx+1 }</th>
                              <td>{audits.created_date}</td>                              
                              <td>{audits.content_type.toUpperCase()}</td>
                              <td>{audits.tipo_gestion}</td>
                              <td>{audits.estado}</td>
                              <td>{audits.descripcion}</td>
                              <td>{audits.user}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    <Pagination
                      count={params.count}
                      next={params.next}
                      previous={params.previous}
                      limit={30}
                      baseUrl="audits/audits/"
                      filterFunction={auditsFilter}
                    />
                  </CardBody>
                ) : (
                  <CardBody>
                    <h4 className="card-title">No se encontraron resultados</h4>
                  </CardBody>
                )}
              </Card>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}


const mapStateToProps = (state) => {
  const { error, loading, audits } = state.Audits;
  return { error, loading, audits };
};

export default withRouter(connect(mapStateToProps)(Audits));
