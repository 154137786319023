import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Container, Form, Modal, Row, Table } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { contactsUpdate } from '../../store/contacts/actions';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getContactsRead } from '../../helpers/backend';

const AvailabilityForm = (props) => {
  const dispatch = useDispatch();
  const { isOpen, onClose, contact } = props;
  const [alert, setAlert] = useState(false);
  const [selectedDay, setSelectedDay] = useState('');
  const [availability, setAvailability] = useState([]);
  const [currentStartTime, setCurrentStartTime] = useState('06:00 AM'); // Hora de inicio predeterminada
  const [currentEndTime, setCurrentEndTime] = useState('07:00 AM'); // Hora de fin predeterminada
  const [isRecurrent, setIsRecurrent] = useState(true); // Por defecto, la disponibilidad es recurrente
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    try {
      const response = await getContactsRead(contact);
      const data = response.data.availability ? response.data.availability : [];
      setAvailability(data);
    } catch (error) {
      console.error('Error al obtener datos de disponibilidad:', error);
      // Aquí puedes agregar un mensaje de error o manejar el error de otra manera
    }
  };

  const handleAddAvailability = () => {
    if (isRecurrent && selectedDay && currentStartTime && currentEndTime) {
      // Convertir las horas de 12 horas a 24 horas antes de guardarlas
      const startTime24 = convertTo24HourFormat(currentStartTime);
      const endTime24 = convertTo24HourFormat(currentEndTime);

      const availabilityItem = {
        dayOfWeek: selectedDay,
        startTime: startTime24,
        endTime: endTime24,
      };

      setAvailability((prevAvailability) => [...prevAvailability, availabilityItem]);
      setCurrentStartTime('06:00 AM'); // Restaurar hora de inicio predeterminada
      setCurrentEndTime('07:00 AM'); // Restaurar hora de fin predeterminada
    } else if (!isRecurrent && selectedDate && currentStartTime && currentEndTime) {
      // Disponibilidad excepcional
      const startTime24 = convertTo24HourFormat(currentStartTime);
      const endTime24 = convertTo24HourFormat(currentEndTime);

      const availabilityItem = {
        date: selectedDate,
        startTime: startTime24,
        endTime: endTime24,
      };

      setAvailability((prevAvailability) => [...prevAvailability, availabilityItem]);
      setCurrentStartTime('06:00 AM'); // Restaurar hora de inicio predeterminada
      setCurrentEndTime('07:00 AM'); // Restaurar hora de fin predeterminada
    }
  };

  const handleDeleteAvailability = (index) => {
    const updatedAvailability = [...availability];
    updatedAvailability.splice(index, 1);
    setAvailability(updatedAvailability);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      contactsUpdate(contact, {
        availability: availability,
      })
    );
    setAlert(true);
  };

  const daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

  // Generar opciones de horas de 6:00 AM a 10:00 PM en formato de 12 horas
  const generateHourOptions = () => {
    const hours = [];
    for (let i = 6; i <= 22; i++) {
      const hour = i < 12 ? `${i}:00 AM` : i === 12 ? '12:00 PM' : `${i - 12}:00 PM`;
      hours.push(hour);
    }
    return hours;
  };

  // Función para convertir horas de 12 horas a 24 horas
  const convertTo24HourFormat = (time12hr) => {
    const [time, period] = time12hr.split(' ');
    const [hours, minutes] = time.split(':');
    let hours24 = parseInt(hours, 10);
    if (period === 'PM' && hours24 !== 12) {
      hours24 += 12;
    } else if (period === 'AM' && hours24 === 12) {
      hours24 = 0;
    }
    return `${hours24.toString().padStart(2, '0')}:${minutes}`;
  };

  // Función para mostrar una hora en formato de 12 horas
  const formatTo12HourFormat = (time24hr) => {
    const [hours, minutes] = time24hr.split(':');
    const parsedHours = parseInt(hours, 10);
    const period = parsedHours >= 12 ? 'PM' : 'AM';
    const formattedHours = parsedHours % 12 || 12;
    return `${formattedHours}:${minutes} ${period}`;
  };
  

const moment = require('moment-timezone');

// Función para formatear la fecha en formato "Día, Mes Día, Año" 
const formatDate = (dateStr, timeZone) => {
  return moment.tz(dateStr, timeZone).format('dddd, MMMM D, YYYY'); // Formato sin la hora
};

  return (
    <>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        size="lg"
        toggle={onClose}
      >
        <div className="p-4">
          <h2 className="text-dark">Agregar Disponibilidad</h2>
          <hr />
          <Form onSubmit={handleSubmit} style={{ paddingRight: '20px', paddingLeft: '20px' }}>
            <Container fluid>
              <Row>
                <Col lg={12}>
                  <label>Tipo de Disponibilidad:</label>
                  <div className="mb-2 d-flex justify-content-center">
                    <Button
                      color={isRecurrent ? 'success' : 'dark'}
                      onClick={() => setIsRecurrent(true)}
                      className="mr-2 btn-sm ms-1"
                    >
                      Recurrente
                    </Button>
                    <Button
                      color={!isRecurrent ? 'success' : 'dark'}
                      onClick={() => setIsRecurrent(false)}
                      className="mr-2 btn-sm ms-1"
                    >
                      Excepcional
                    </Button>
                  </div>
                </Col>
              </Row>
              {isRecurrent && (
                <Row>
                  <Col lg={12}>
                    <label>Selecciona un día:</label>
                    <div className="mb-2 d-flex justify-content-center">
                      {daysOfWeek.map((day) => (
                        <Button
                          key={day}
                          color={selectedDay === day ? 'success' : 'dark'}
                          onClick={() => setSelectedDay(day)}
                          className="mr-2 btn-sm ms-1"
                        >
                          {day}
                        </Button>
                      ))}
                    </div>
                  </Col>
                </Row>
              )}
              {!isRecurrent && (
                <Row>
                  <Col lg={4}>
                    <label>Selecciona Fecha:</label>
                    <input
                      type="date"
                      className="form-control"
                      value={selectedDate || ''}
                      onChange={(e) => setSelectedDate(e.target.value)}
                    />
                  </Col>
                  <Col lg={3}>
                      <label>Hora de inicio:</label>
                      <select
                        className="form-select"
                        value={currentStartTime}
                        onChange={(e) => setCurrentStartTime(e.target.value)}
                      >
                        {generateHourOptions().map((hour) => (
                          <option key={hour} value={hour}>
                            {hour}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col lg={3}>
                      <label>Hora de fin:</label>
                      <select
                        className="form-select"
                        value={currentEndTime}
                        onChange={(e) => setCurrentEndTime(e.target.value)}
                      >
                        {generateHourOptions().map((hour) => (
                          <option key={hour} value={hour}>
                            {hour}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col>
                      <Link to="#" onClick={handleAddAvailability}>
                        <span>
                          <i className="bx bx-lg bx-plus-circle mt-4"></i>
                        </span>
                      </Link>
                    </Col>
                </Row>
              )}
              {isRecurrent && selectedDay && (
                <>
                  <Row>
                    <Col lg={5}>
                      <label>Hora de inicio:</label>
                      <select
                        className="form-select"
                        value={currentStartTime}
                        onChange={(e) => setCurrentStartTime(e.target.value)}
                      >
                        {generateHourOptions().map((hour) => (
                          <option key={hour} value={hour}>
                            {hour}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col lg={5}>
                      <label>Hora de fin:</label>
                      <select
                        className="form-select"
                        value={currentEndTime}
                        onChange={(e) => setCurrentEndTime(e.target.value)}
                      >
                        {generateHourOptions().map((hour) => (
                          <option key={hour} value={hour}>
                            {hour}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col>
                      <Link to="#" onClick={handleAddAvailability}>
                        <span>
                          <i className="bx bx-lg bx-plus-circle mt-4"></i>
                        </span>
                      </Link>
                    </Col>
                  </Row>
                </>
              )}
              {/* Mostrar la lista de disponibilidades en una tabla */}
              <Row className="mt-4">
                <Col>
                  <Table
                    style={{fontSize: '0.85rem' }}
                    className="table table-sm align-middle table-centered  mb-3 text-center "
                    responsive
                    hover
                  >
                    <thead className="table-dark ">
                      <tr>
                        <th style={{width:'200px'}}>Día/Fecha</th>
                        <th>Hora de inicio</th>
                        <th>Hora de fin</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {availability.map((entry, index) => (
                        <tr key={index}>
                          <td>{entry.dayOfWeek ? entry.dayOfWeek : formatDate(entry.date, 'COT')}</td>
                          <td>{formatTo12HourFormat(entry.startTime)}</td>
                          <td>{formatTo12HourFormat(entry.endTime)}</td>
                          <td>
                            <Button
                              style={{ fontSize: '0.75rem' }}
                              color=""
                              className="text-danger"
                              onClick={() => handleDeleteAvailability(index)}
                            >
                              X
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-end mt-3">
                  <Button className="btn btn-sm text-dark" onClick={onClose}>
                    Atras
                  </Button>
                  <Button className="btn btn-sm ms-1 " type="submit" color="primary">
                    Guardar
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </div>
      </Modal>
      {alert ? (
        <SweetAlert
          title="Hecho!"
          success
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            onClose();
            setAlert(false);
          }}
        >
          Se ha agregado la disponibilidad con éxito
        </SweetAlert>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, contacts, contactsUpdated } = state.Contacts;
  return { error, loading, contacts, contactsUpdated };
};

AvailabilityForm.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  contactsUpdated: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, { contactsUpdate })(AvailabilityForm));
