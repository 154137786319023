import React, { useState } from "react";
import { Button, Container, Col, Row, Form, Card, CardBody } from "reactstrap";
import { MetaTags } from "react-meta-tags";
import logo from "../../assets/images/logo-tc.png";
import fondo from "../../assets/images/backgruond-blue.jpg";
import styles from "./components/Landing.module.css"
import { postLandingPage } from "../../helpers/backend";
import SweetAlert from "react-bootstrap-sweetalert";

function LandingPage() {
  const [alert, setAlert] = useState(false)
  const [formData, setFormData] = useState({
    pageName: "TrueContact",
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const { pageName, name, phone, email, message } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const response = await postLandingPage(formData)
    console.log(response);
    setAlert(true)
    console.log("Formulario enviado:", formData);
  };

  return (
    <div className="d-flex align-items-center min-vh-100 position-relative">
      {alert ? (
        <SweetAlert
          title="Hecho!"
          success
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            setAlert(false)
          }}
        >
          Formulario enviado
        </SweetAlert>
      ) : null}
      <div className="position-fixed top-0 start-0 w-100 h-100 z-index--1">
        <img src={fondo} alt="" className="w-100 h-100 position-absolute" style={{ opacity: 0.4 }} />
        <div className="overlay position-absolute w-100 h-100" style={{ backgroundColor: "rgba(0.3, 0, 0, 0)" }}></div>
      </div>
      <MetaTags>
        <title>TrueContact | Contáctanos</title>
      </MetaTags>
      <Container>
        <Row>
          <Col className="d-flex align-items-center justify-content-center">
            <img src={logo} alt="Logo" width="400" height="auto" />
          </Col>
          <Col className="d-flex justify-content-end">
            <Card style={{ borderRadius: "15px", maxWidth: "400px" }} className="w-100">
              <CardBody>
                <Form onSubmit={onSubmit}>
                  <h4 className="ms-3 mb-4 mt-3"><b>¡Contáctanos!</b></h4>
                  <input
                    className={`${styles["form-control"]} mb-4 mx-5 `}
                    type="hidden"
                    id="pageName"
                    name="pageName"
                    placeholder="Nombre de la Página"
                    value={pageName}
                    onChange={onChange}
                    required
                  />

                  <input
                    className={`${styles["form-control"]} mb-4 mx-5`}
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Tu nombre"
                    value={name}
                    onChange={onChange}
                    required
                  />

                  <input
                    className={`${styles["form-control"]} mb-4 mx-5`}
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="Tu teléfono"
                    value={phone}
                    onChange={onChange}
                    required
                  />

                  <input
                    className={`${styles["form-control"]} mb-4 mx-5`}
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Tu email"
                    value={email}
                    onChange={onChange}
                    required
                  />

                  <textarea
                    className={`${styles["form-control"]} mb-4 mx-5`}
                    id="message"
                    name="message"
                    placeholder="Tu mensaje"
                    value={message}
                    onChange={onChange}
                    required
                  ></textarea>

                  <Button
                    className={`${styles["form-control"]} mb-4 mx-5`}
                    color="primary"
                    size="lg"
                    type="submit"
                  >
                    <strong>Enviar</strong>
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}


export default LandingPage;
