import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Button, Card, Col, Container, Row } from "reactstrap"
import avatarHombre from "../../assets/images/avatarHombre.jpg"
import avatarMujer from "../../assets/images/avatarMujer.jpg"
import companyAvatar from "../../assets/images/companyAvatar.jpg"
import styles from "./HojaContacto.module.scss"
import { useParams, Link } from "react-router-dom";
import { getContactsRead, getHojaContacto } from "../../helpers/backend";
import { HvExperiencia } from "../Modales/HvExperiencia";
import { HvEducacion } from "../Modales/HvEducacion";
import { HvConocimiento } from "../Modales/HvConocimiento";
import { HvHoja } from "../Modales/HvHoja";

const HojaContacto = () => {
    const contactoId = useParams().id
    const [isModalExperienciaOpen, setIsModalExperienciaOpen] = useState(false);
    const [isModalEducacionOpen, setIsModalEducacionOpen] = useState(false);
    const [isModalConocimientoOpen, setIsModalConocimientoOpen] = useState(false);
    const [isModalHojaOpen, setIsModalHojaOpen] = useState(false);
    const [hojaDeVida, setHojaDeVida] = useState({});

const getHojaDeVidaData = async () => {
  const response = await getHojaContacto(contactoId);
  const data = response.data;
  setHojaDeVida(data)
};
const  [contacts, setContacts] = useState ({});
const getContactsData = async () => {
  const response = await getContactsRead(contactoId);
  const data = response.data;
  setContacts(data)
};
const hojaVidaData = Array.isArray(hojaDeVida) ? hojaDeVida[0] : []

useEffect(() => {
    getHojaDeVidaData();
    getContactsData();
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

  return (
    <React.Fragment>
    <Container className="justify-content-center ps-5 ms-5">
    {isModalHojaOpen && (
        <HvHoja
          isOpen={isModalHojaOpen}
          onClose={() => setIsModalHojaOpen(false)} 
          contacto= {contactoId}
          success = {() => getHojaDeVidaData()}
        />
      )}
      {isModalConocimientoOpen && (
        <HvConocimiento
          isOpen={isModalConocimientoOpen}
          onClose={() => setIsModalConocimientoOpen(false)} 
          hojaDeVida= {hojaVidaData ? hojaVidaData.id : null}
          success = {() => getHojaDeVidaData()}
        />
      )}
     {isModalExperienciaOpen && (
        <HvExperiencia
          isOpen={isModalExperienciaOpen}
          onClose={() => setIsModalExperienciaOpen(false)} 
          hojaDeVida= {hojaVidaData ? hojaVidaData.id : null}
          success = {() => getHojaDeVidaData()}
        />
      )}
     {isModalEducacionOpen && (
        <HvEducacion
          isOpen={isModalEducacionOpen}
          onClose={() => setIsModalEducacionOpen(false)} 
          hojaDeVida= {hojaVidaData ? hojaVidaData.id : null}
          success = {() => getHojaDeVidaData()}
        />
      )}
        <Card className={styles.card}>
            <Row >
                <Col lg={4} className={styles["avatar-column"]} >
                    <div className="text-start p-2">
                    {contacts.image ? (
                    <img src={contacts.image} alt="" className={styles.avatar} />
                    ) : contacts.contactType && contacts.gender === "Masculino" ? (
                    <img src={avatarHombre} alt="" className={styles.avatar} />
                    ) : contacts.contactType && contacts.gender === "Femenino" ? (
                    <img src={avatarMujer} alt="" className={styles.avatar} />
                    ) : contacts.contactType === "EMPRESA" ? (
                    <img src={companyAvatar} alt="" className={styles.avatar} />
                    ) : null}
                    </div>        
                </Col>
                <Col lg={8}>
                  <div className = {styles['text-container']}>
                    <h2 className="text-primary "><b>{contacts.get_full_name} </b> </h2>
                    {hojaVidaData ? 
                    <h5 className="text-primary "><b>{hojaVidaData.ocupacion}  </b> </h5>
                    : null}
                    <h6 className="text-dark ">Fecha de Nacimiento: {contacts.birthdate} </h6>
                    <h6 className="text-dark ">
                    Direcciones: {contacts.address
                        ? contacts.address.map((item) => item.address).join('; ')
                        : null}
                    </h6>
                    <h6 className="text-dark ">
                    Correo Electrónico: {contacts.email
                        ? contacts.email.map((item) => item.email).join('; ')
                        : null}
                    </h6>
                    <h6 className="text-dark ">
                    Teléfonos: {contacts.phone
                        ? contacts.phone.map((item) => item.telefono).join('/ ')
                        : null}
                    </h6>
                  </div>
                </Col>
            </Row>
        </Card>
    {hojaVidaData ? (
    <>
        <Card className={styles.card}>
        <h4 className="text-primary p-3">
          <b>Experiencia</b>
        </h4>
        <Link
          className={styles['button-plus']}
          onClick={() => setIsModalExperienciaOpen(true)} // Abre el modal al hacer clic en el botón
        >
          <span className="fas fa-plus fa-lg"></span>
        </Link>
        <Row>
        <Col>
        {hojaVidaData
            ? Array.isArray(hojaVidaData.experiencia)? hojaVidaData.experiencia.map((item, index) => (
                <div key={index} className="ps-3 pb-3">
                <h5 className="text-dark"> <b>{item.tipo_empleo}</b> </h5>
                <h5 className="text-dark"> <b>{item.nombre_empresa}</b> </h5>
                <h6 className="text-dark"> <b>Cargo: </b>{item.cargo} </h6>
                <h6 className="text-dark"> <b>Ubicación: </b>{item.ubicacion} </h6>
                <h6 className="text-dark"> <b>Descripción: </b>{item.descripcion} </h6>
                </div>
            ))
            :[]
        : null}
        </Col>
        </Row>
        </Card>
        <Card className={styles.card}>
            <Link 
            className={styles['button-plus']}
            onClick={() => setIsModalEducacionOpen(true)}><span className="fas fa-plus fa-lg "></span></Link>
            <Row>
            <Col>
            <h4 className="text-primary p-3">
                <b>Educación</b>
            </h4>
            
            {hojaVidaData
                ? Array.isArray(hojaVidaData.experiencia) ? hojaVidaData.educacion.map((item, index) => (
                    <div key={index} className="ps-3 pb-3">
                    <h5 className="text-dark"> <b>{item.institucion}</b> </h5>
                    <h5 className="text-dark"> <b>{item.titulo}</b> </h5>
                    <h6 className="text-dark"> <b>Fecha de finalización: </b>{item.fecha_fin} </h6>
                    <h6 className="text-dark"> <b>Ubicacion: </b>{item.ubicacion} </h6>
                    <h6 className="text-dark"> <b>Descripcion: </b>{item.descripcion} </h6>
                    </div>
                ))
                :[]
            : null}
            </Col>
            </Row>
        </Card>
        <Card className={styles.card}>
            <Link 
            className={styles['button-plus']}
            onClick={() => setIsModalConocimientoOpen(true)}><span className="fas fa-plus fa-lg "></span></Link>
            <Row>
            <Col>
            <h4 className="text-primary p-3">
                <b>Conocimientos</b>
            </h4>
            {hojaVidaData
                ? Array.isArray(hojaVidaData.experiencia) ? hojaVidaData.conocimientos.map((item, index) => (
                    <div key={index} className="ps-3 pb-3">
                    <h6 className="text-dark"> <b>{item.aptitud}</b> </h6>
                    <h6 className="text-dark"> {item.descripcion} </h6>
                    </div>
                ))
                :[]
            : null}
            </Col>
            </Row>
        </Card>
    </>  
    ):
    <Button 
    color="success"
    onClick={() => setIsModalHojaOpen(true)}>Crear Hoja de Vida</Button>
    }
    </Container>
    </React.Fragment>
  )
}

export default HojaContacto