import React from 'react'
import errorBusqueda from '../../assets/images/error/browser-error-svgrepo-com.svg'
import './styles/ErrorBusqueda.css'

const ErrorBusqueda = () => {
  return (
    <section data-aos="fade-up" data-aos-duration="300" className="contain_error_busqueda">
      <div className="content_imagen_error">
        <img className="img_error_busqueda" src={errorBusqueda} alt="" />
      </div>
      <article className="content_info_error">
        <div>
          <h3 className="title_error">
            <span style={{ color: "#e0324b" }}>¡</span>Uups
            <span style={{ color: "#e0324b" }}>!</span>
          </h3>
        </div>
        <div>
          <p className="p_error">
            No se encontraron coincidencias con tu búsqueda.
          </p>
        </div>
      </article>
    </section>
  );
}

export default ErrorBusqueda