import React, { useState } from "react"
import MetaTags from 'react-meta-tags';
import { connect, useDispatch } from "react-redux";
import { withRouter, Link, useHistory} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Form,
  Button,
  CardTitle,
  Input,
  FormFeedback,
  Alert,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader"

import { leadsCreate } from "../../store/actions";

const LeadsCreate = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();



  const [formData, setFormData] = useState({
    
    first_name: "",
    last_name: "",
    documentType:"",
    documentNumber: "",
    email: "",
    gender: "",
    birthday:"",
    city: "",
    observations: "",
    agent: "",
  });
  
  const {
    first_name,
    last_name, 
    documentNumber,
    email,
    birthday,
    city,
    observations,  
  } = formData;
  
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  console.log(formData);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(leadsCreate(formData));
  };
  
  const redirect = () => {
    history.push("/leads");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Prospectos </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="TrueContact" breadcrumbItem=" Prospectos" />
          {props.loading ? (
            <Loader />
          ) : (
          <div className="d-flex justify-content-center">
              {props.leadsCreated.id ? (
                <SweetAlert
                  title="¡Hecho!"
                  success
                  showConfirm={true}
                  onConfirm={() => {
                    redirect();
                  }}
                >
                  Se ha
                  agregado <b>{props.leadsCreated.nombre}</b> correctamente.
                </SweetAlert>
              ) : null}

              <Card
                style={{
                  width: "60rem",
                }}
              >
                <CardTitle className="ps-3 pt-3">
                  <h2 className="font-size-20">Leads</h2>
                </CardTitle>
                <CardBody>
                <Form onSubmit={onSubmit} className="form-control">
                  <Row className="justify-content-md-center" >
                    <Col lg={10}>
                <Row className="mt-3">
                  <Col>
                    <Label
                      for="first_name"                      
                    >
                      Nombres
                    </Label>
                    <Input                      
                      id="first_name"
                      name="first_name"
                      type="text"
                      onChange={onChange}
                      value= {first_name}
                      autoFocus
                      required
      
                    />
                    {props.error && props.error.first_name ? (
                      <Alert color = 'danger' className="mt-2">{props.error.first_name}</Alert>
                    ):null}
                  </Col> 
                  <Col>
                    <Label
                      for="first_name"                      
                    >
                      Apellidos
                    </Label>
                    <Input                      
                      id="last_name"
                      name="last_name"
                      type="text"
                      onChange={onChange}
                      value= {last_name}
                      autoFocus
                      required
      
                    />
                    {props.error && props.error.last_name ? (
                      <Alert color = 'danger' className="mt-2">{props.error.last_name}</Alert>
                    ):null}
                  </Col>  

                </Row>
                <Row className="mt-3">
                <Col>
                    <Label
                      for="documentType"
                      
                    >
                      Tipo de Documento
                    </Label>
                    <select
                      className="form-select"
                      id="documentType"
                      name="documentType"
                      type="text"
                      onChange={onChange}                      
                      required 
                    >
                      <option defaultValue>Seleccione una opcion</option>
                      <option value ="CEDULA">CEDULA</option>
                      <option value ="PASAPORTE">PASAPORTE</option>
                      <option value ="CEDULA_EXTRANJERIA">CEDULA EXTRAJERIA</option>
                      <option value ="NIT">NIT</option>
                      <option value ="OTRO">OTRO</option>
                    </select>
                    {props.error && props.error.documentType ? (
                    <Alert color = 'danger' className="mt-2">{props.error.documentType}</Alert>
                    ):null}
                  </Col>
                  <Col>  
                    <Label
                      for="documentNumber"
                      
                    >
                      Documento
                    </Label>
                    <Input
                     
                      id="documentNumber"
                      name="documentNumber"
                      type="number"
                      onChange={onChange}
                      value={documentNumber}
                      valid={
                        props.error.documentNumber
                          ? false
                          : props.leadsCreated.id && true
                      }
                      invalid={
                        props.error
                          ? props.error.documentNumber
                            ? true
                            : false
                          : false
                      }
                      required 
                    />
                   
                    <FormFeedback>{props.error.documentNumber}</FormFeedback>
                    

                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Label
                      for="first_name"                      
                    >
                      E-mail
                    </Label>
                    <Input                      
                      id="email"
                      name="email"
                      type="email"
                      onChange={onChange}
                      value= {email}
                      autoFocus
                      required
      
                    />
                    {props.error && props.error.email ? (
                      <Alert color = 'danger' className="mt-2">{props.error.email}</Alert>
                    ):null}
                  </Col> 
                  <Col>
                    <Label
                      for="documentType"
                      
                    >
                      Género
                    </Label>
                    <select
                      className="form-select"
                      id="gender"
                      name="gender"
                      type="text"
                      onChange={onChange}                      
                      required 
                    >
                      <option defaultValue>Seleccione una opcion</option>
                      <option value ="Masculino">Masculino</option>
                      <option value ="Femenino">Femenino</option>
                      <option value ="Otro">Otro</option>
                    </select>
                    {props.error && props.error.gender ? (
                    <Alert color = 'danger' className="mt-2">{props.error.gender}</Alert>
                    ):null}
                  </Col>

                </Row>
                <Row className="mt-3">
                  <Col>
                    <Label
                      for="first_name"                      
                    >
                      Fecha de Nacimiento
                    </Label>
                    <Input                      
                      id="birthday"
                      name="birthday"
                      type="date"
                      onChange={onChange}
                      value= {birthday}
                      autoFocus
                      required
      
                    />
                    {props.error && props.error.birthday ? (
                      <Alert color = 'danger' className="mt-2">{props.error.birthday}</Alert>
                    ):null}
                  </Col> 
                  <Col>
                    <Label
                      for="city"                      
                    >
                      Ciudad
                    </Label>
                    <Input                      
                      id="city"
                      name="city"
                      type="text"
                      onChange={onChange}
                      value= {city}
                      autoFocus
                      required
      
                    />
                    {props.error && props.error.city ? (
                      <Alert color = 'danger' className="mt-2">{props.error.city}</Alert>
                    ):null}
                  </Col>  

                </Row>
                <Row className="mt-3">
                  <Col>
                    <Label
                      for="first_name"                      
                    >
                      Observaciones
                    </Label>
                    <Input                      
                      id="birthday"
                      name="observations"
                      type="textarea"
                      onChange={onChange}
                      value= {observations}
                      autoFocus
                      required
      
                    />
                    {props.error && props.error.observations ? (
                      <Alert color = 'danger' className="mt-2">{props.error.observations}</Alert>
                    ):null}
                  </Col> 
                </Row>
                    <Row className="justify-content-md-center mt-5 ms-4">
                      <Col lg={4}>
                        <Link className="btn btn-secondary text-dark me-4" to="/leads">
                          Cancelar
                        </Link>

                        <Button color="success" type="submit">
                          Crear
                        </Button>
                      </Col>
                    </Row>
                    </Col>
                  </Row>
                </Form>
                </CardBody>
              </Card>

            
          </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const { error, loading, leadsCreated } = state.Leads;
  return { error, loading, leadsCreated };
};

export default withRouter(connect(mapStateToProps)(LeadsCreate));