import {
  CONTACTS_DOCUMENT_LIST,
  CONTACTS_DOCUMENT_FILTER,
  CONTACTS_DOCUMENT_CREATE,
  CONTACTS_DOCUMENT_UPDATE,
  CONTACTS_DOCUMENT_DELETE,
  CONTACTS_DOCUMENT_UPDATE_SUCCESS,
  CONTACTS_DOCUMENT_DELETE_SUCCESS,
  CONTACTS_DOCUMENT_CREATE_SUCCESS,
  CONTACTS_DOCUMENT_LIST_SUCCESS,
  CONTACTS_DOCUMENT_REQUEST_FAILED,
} from "./actionTypes";

export const contactsDocumentList = () => {
  return {
    type: CONTACTS_DOCUMENT_LIST,
    payload: {},
  };
};

export const contactsDocumentCreate = (contactsDocument) => {
  return {
    type: CONTACTS_DOCUMENT_CREATE,
    payload: contactsDocument,
  };
};

export const contactsDocumentFilter = (changeUrl) => {
  return {
    type: CONTACTS_DOCUMENT_FILTER,
    payload: { changeUrl },
  };
};

export const contactsDocumentUpdate = (id, data) => {
  return {
    type: CONTACTS_DOCUMENT_UPDATE,
    payload: { id, data },
  };
};
export const contactsDocumentDelete = (id) => {
  return {
    type: CONTACTS_DOCUMENT_DELETE,
    payload: { id },
  };
};

export const contactsDocumentListSuccess = (contactsDocument) => {
  return {
    type: CONTACTS_DOCUMENT_LIST_SUCCESS,
    payload: contactsDocument,
  };
};

export const contactsDocumentCreateSuccess = (contactsDocumentCreated) => {
  return {
    type: CONTACTS_DOCUMENT_CREATE_SUCCESS,
    payload: contactsDocumentCreated,
  };
};

export const contactsDocumentUpdateSuccess = (contactsDocument) => {
  return {
    type: CONTACTS_DOCUMENT_UPDATE_SUCCESS,
    payload: contactsDocument,
  };
};

export const contactsDocumentDeleteSuccess = (contactsDocumentDeleted) => {
  return {
    type: CONTACTS_DOCUMENT_DELETE_SUCCESS,
    payload: contactsDocumentDeleted,
  };
};

export const contactsDocumentRequestFailed = (error) => {
  return {
    type: CONTACTS_DOCUMENT_REQUEST_FAILED,
    payload: error,
  };
};
