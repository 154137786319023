import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, FormGroup, Label, Modal, ModalBody, ModalFooter, Form } from 'reactstrap';
import Select from 'react-select';
import { getGrupoContactoFilter, getTemplatesFilter, postBroadcastMessage } from '../../../helpers/backend';
import SweetAlert from "react-bootstrap-sweetalert";

const BroadcastMessagesModal = ({isOpen, onClose, broadcastCampaign }) => {
  const [formData, setFormData] = useState({
    campaign_broadcast: broadcastCampaign,
    grupo_contacto_id: [],
    template: null,
  });

  const [contactGroup, setContactGroup] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    getContactGroup();
    getTemplateList();
  }, []);

  const getContactGroup = async () => {
    try {
      const response = await getGrupoContactoFilter("FullList/");
      setContactGroup(response.data);
    } catch (error) {
      console.error('Error fetching contact group data', error);
    }
  };

  const getTemplateList = async () => {
    try {
      const response = await getTemplatesFilter("FullList/");
      setTemplateList(response.data);
    } catch (error) {
      console.error('Error fetching template list', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await postBroadcastMessage(formData);
    console.log(response);
    if (response.status === 201 || response.status === 200) {
      setAlert(true);
    } else {
      setError(true);
    }
  };
console.log(formData);
  return (
    <div>
      <Modal isOpen={isOpen} toggle={onClose} size="md">
        <h4 className='px-3 pt-3'>Enviar Mensaje de Difusión</h4>
        <ModalBody>
          <Container>
            {alert ? (
              <SweetAlert
                title="Creado"
                success
                timeout={2000}
                showConfirm={false}
                onConfirm={() => setAlert(false)}
              />
            ) : null}
            {error ? (
              <SweetAlert
                title="Error"
                danger
                timeout={2000}
                showConfirm={false}
                onConfirm={() => setError(false)}
              />
            ) : null}
            <Form onSubmit={handleSubmit}>
              <Row className='d-flex justify-content-center'>
                <Col md={8} className="mb-3">
                  <FormGroup>
                    <Label for="grupo_contacto_id">Grupo Contacto</Label>
                    <Select
                      id="grupo_contacto_id"
                      options={Array.isArray(contactGroup) ? contactGroup.map(item => ({
                        label: item.name,
                        value: item.id,
                      })) : []}
                      onChange={(selectedOptions) => {
                        const selectedContactData = selectedOptions.map(
                          (option) => option.value
                        );
                        setFormData({
                          ...formData,
                          grupo_contacto_id: selectedContactData,
                        });
                      }}
                      isMulti={true}
                      placeholder="Seleccione un grupo de contacto"
                      menuPosition="fixed"
                    />
                  </FormGroup>
                </Col>

                <Col md={8} className="mb-3">
                  <FormGroup>
                    <Label for="template">Template</Label>
                    <Select
                      id="template"
                      options={Array.isArray(templateList) ? templateList.map(item => ({
                        label: item.name,
                        value: item.id,
                      })) : []}
                      onChange={(item) => setFormData({...formData, template: item.value})}
                      placeholder="Seleccione una plantilla"
                      menuPosition="fixed"
                      isMulti={false}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Container>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button color="primary" onClick={handleSubmit}>Enviar</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default BroadcastMessagesModal;
