import React, { useState } from 'react';
import ImagenNoDisponible from '../../../assets/images/vehiculos/imagen-no-disponible.png'
import "./styles/ModalVehiculoTire.css"
import { useHistory } from 'react-router-dom/cjs/react-router-dom';


const VehiculoResult = ({vehiculo}) => {

  const history = useHistory()

  const redirect = (path) => {
    history.push(`${path}`)
  };

  const [rinSeleccionado, setRinSeleccionado] = useState(null);
  const [medidasOpcionales, setMedidasOpcionales] = useState([]);

  
  const handleRinClick = (rin) => {
    // Si ya está seleccionado, deseleccionamos el rin y limpiamos las medidas
    if (rinSeleccionado === rin) {
      setRinSeleccionado(null);
      setMedidasOpcionales([]);
    } else {
      // De lo contrario, seleccionamos el rin y mostramos las medidas
      const rinData = vehiculo.rin_medidas_opcionales.find((item) => item.rin === rin);
      if (rinData) {
        setRinSeleccionado(rin);
        setMedidasOpcionales(rinData.medidas_opcionales);
      }
    }
  };

  const isRinSelected = (rin) => rinSeleccionado === rin;

  console.log(vehiculo)
  return (
    <section className="container_vehicle">
      <div data-aos="fade-right" className="container_image">
        <div className="img_content">
          {vehiculo.imagen === null ? (
            <img
              className="image_vehicle"
              src={ImagenNoDisponible}
              alt="Imagen no disponible"
            />
          ) : (
            <img
              className="image_vehicle"
              src={vehiculo.imagen}
              alt={vehiculo.marca}
            />
          )}
        </div>

        <div className="pie_foto">
          <p className="pie_info_foto">
            Foto únicamente de referencia. Es posible que la foto no concuerde
            exactamente con el modelo y el año del vehículo buscado. Lo anterior
            debido a la gran cantidad de modelos de cada referencia y a que, en
            ciertos años, el fabricante lanza un nuevo modelo pero continúa
            ofreciendo la versión anterior. Por favor confirmar la medida
            original de su vehículo especificada en el costado de la puerta del
            conductor, en el manual del propietario o en el costado de la llanta
            original de fábrica.
          </p>
        </div>
      </div>

      <div data-aos="fade-left" className="container_info">
        <div className="medida_original_content">
          <div>
            <h2 className="title_medida_original">
              Medida original para tu vehículo:
            </h2>
          </div>
          <div>
            <p className="p_medida_original">
              (Encontrar llantas en esta medida)
            </p>
          </div>
          <div className="button_medida_original">
            <p
              className="medida_original"
              onClick={() => redirect("/llantas-")}
            >
              {vehiculo.medida}
            </p>
          </div>
        </div>

        <div className="medida_opcional_content">
          <h2 className="title_medida_opcional">Medidas Opcionales:</h2>
          <div className="medida_opcional_space">
            {vehiculo.medidas_opcionales.map((medidas_opcionales) => (
              <div
                className="button_medida_opcional"
                onClick={() => redirect("/llantas-")}
              >
                <p className="medida_opcional" key={medidas_opcionales.id}>
                  {medidas_opcionales.medidas}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className="medida_rin_opcional_content">
          <h2 className="title_medida_rin">
            Medidas opcionales en otro tamaño de rin:
          </h2>
          <div className="medida_rin_space">
            {vehiculo.rin_medidas_opcionales.map((rin) => (
              <div
                key={rin.id}
                style={{}}
                className={`${isRinSelected(rin.rin) ? "button_medida_rin_selected" : "button_medida_rin"}`}
                onClick={() => handleRinClick(rin.rin)}
              >
                <p className={`${isRinSelected(rin.rin) ? "medida_opcional_rin_selected" : "medida_opcional_rin"}`}>{rin.rin}</p>
              </div>
            ))}
          </div>
        </div>

        {rinSeleccionado && (
          <div className="medidas_opcionales_seleccionadas">
            <div className="medida_rin_space">
              {medidasOpcionales.map((medida, index) => (
                <div className='button_medida_rin' key={index}>
                  <p className="medida_opcional_rin">{medida}</p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default VehiculoResult