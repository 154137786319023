import {
  SURVEY_LIST,
  SURVEY_FILTER,
  SURVEY_CREATE,
  SURVEY_UPDATE,
  SURVEY_UPDATE_SUCCESS,
  SURVEY_CREATE_SUCCESS,
  SURVEY_LIST_SUCCESS,
  SURVEY_REQUEST_FAILED,
} from "./actionTypes";

export const surveyList = () => {
  return {
    type: SURVEY_LIST,
    payload: {},
  };
};

export const surveyCreate = (survey) => {
  return {
    type: SURVEY_CREATE,
    payload: survey,
  };
};

export const surveyFilter = (changeUrl) => {
  return {
    type: SURVEY_FILTER,
    payload: { changeUrl },
  };
};

export const surveyUpdate = (id, data) => {
  return {
    type: SURVEY_UPDATE,
    payload: { id, data },
  };
};

export const surveyListSuccess = (survey) => {
  return {
    type: SURVEY_LIST_SUCCESS,
    payload: survey,
  };
};

export const surveyCreateSuccess = (surveyCreated) => {
  return {
    type: SURVEY_CREATE_SUCCESS,
    payload: surveyCreated,
  };
};

export const surveyUpdateSuccess = (survey) => {
  return {
    type: SURVEY_UPDATE_SUCCESS,
    payload: survey,
  };
};

export const surveyRequestFailed = (error) => {
  return {
    type: SURVEY_REQUEST_FAILED,
    payload: error,
  };
};
