import {
  ACCOUNT_LIST,
  ACCOUNT_FILTER,
  ACCOUNT_CREATE,
  ACCOUNT_CREATE_SUCCESS,
  ACCOUNT_LIST_SUCCESS,
  ACCOUNT_REQUEST_FAILED,
  ACCOUNT_UPDATE,
  ACCOUNT_UPDATE_SUCCESS,
} from "../../store/account/actionTypes";

const initialState = {
  account: [],
  accountCreated: {},
  accountUpdated: {},
  loading: false,
  error: "",
};

const account = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ACCOUNT_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ACCOUNT_FILTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ACCOUNT_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ACCOUNT_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        accountCreated: action.payload,
        error: "",
      };
      break;
    case ACCOUNT_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        account: action.payload,
        accountCreated: {},
        accountUpdated: {},
        error: "",
      };
      break;
    case ACCOUNT_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        accountUpdated: action.payload,
        error: "",
      };
      break;
    case ACCOUNT_REQUEST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default account;
