import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Importar estilos de react-phone-input-2
import { postCreateConversation } from '../../../helpers/backend';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const PhoneVerificationModal = ({ isOpen, onClose }) => {
  const history = useHistory()
    const [phoneNumber, setPhoneNumber] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const [statusColor, setStatusColor] = useState('');
    const [contact, setContact] = useState(null);
    const [conversation, setConversation] = useState(null);
    const [isPhoneValid, setIsPhoneValid] = useState(false); // Nueva bandera para validar el número

    const verifyPhoneNumber = async () => {
        try {
            const response = await postCreateConversation({
              phone: phoneNumber,
            });
            console.log(response);
            if (response.status === 200) {
                setStatusMessage('¡El número de teléfono existe!');
                setStatusColor('success'); // Verde si es exitoso
                setIsPhoneValid(true); // El número es válido
                setContact(response.data.contact)
                setConversation(response.data.conversation)
            } else {
                setStatusMessage('El número de teléfono no existe.');
                setStatusColor('danger'); // Rojo si falla
                setIsPhoneValid(false); // No es válido
            }
        } catch (error) {
            setStatusMessage('Error al verificar el número de teléfono.');
            setStatusColor('danger'); // Rojo si hay error
            setIsPhoneValid(false); // No es válido
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={onClose}>
            <h5 className='p-3'>Verificar Número de Teléfono</h5>
            <ModalBody>
                <PhoneInput
                    country={'co'}
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                />
                <div className={`status-message text-${statusColor} mt-2`}>
                    {statusMessage}
                </div>
            </ModalBody>
            <ModalFooter>
                {isPhoneValid ? (
                  <Button 
                    color="success" 
                    onClick={() => {
                      window.location.reload(); 
                      history.replace({
                        pathname: '/chat',
                        state: {
                          selectedChatId: conversation,
                          contactId: contact, 
                          type: "WhatsApp",
                        }
                      });
                      onClose(); 
                    }}
                  >
                    Iniciar Chat
                  </Button>

                ) : (
                    <Button color="primary" onClick={verifyPhoneNumber}>
                        Verificar si el número existe
                    </Button>
                )}
                <Button className='text-dark' color="secondary" onClick={onClose}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default PhoneVerificationModal;
