import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";

import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";

// Redux
import { connect } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";

import { descripcionCalificacionAdd } from "../../store/descripcionCalificacion/actions";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader";
import { getCalificacionContactoFullList } from "../../helpers/backend";

const DescripcionCalificacionAdd = (props) => {
  const history = useHistory();

  const [selectedGroup, setselectedGroup] = useState(null);

  const handleSelectGroup = ({ value }) => {
    setselectedGroup(value)

  }
  const [calificacionList, setCalificacionList] = useState({})
  const getCalificacionListData = async () => {
    const response = await getCalificacionContactoFullList();
    const data = response.data;
    setCalificacionList(data);
  };

  useEffect(() => {
    getCalificacionListData()
  }, [])


  const [formData, setFormData] = useState({
    name: "",
  });

  const { name } =
    formData;


  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const calificacion = selectedGroup;

  const onSubmit = (e) => {
    e.preventDefault();
    props.descripcionCalificacionAdd({
      name,
      calificacion,
    });

  };

  const redirect = () => {
    history.push("/descripcionCalificacion");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TrueContact | Calificación</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={"TrueContact"} breadcrumbItem={"Calificación "} />
          {props.loading ? (
            <Loader />
          ) : (
            <Card className="col-lg-8 mx-auto  mt-3">
              {props.descripcionCalificacionAdded.id ? (
                <SweetAlert
                  title="Hecho!"
                  success
                  timeout={2000}
                  showConfirm={false}
                  onConfirm={() => {
                    redirect();
                  }}
                >
                  Se ha agregado la la Descripción
                </SweetAlert>
              ) : null}
              <CardBody>
                <h4 className="card-title">Agregar Calificación</h4>
                <Form className="needs-validation" onSubmit={onSubmit}>

                  <Row className="col-lg-12 justify-content-center">
                    <Col md={6} className="mb-3">
                      <Label
                        for="calificacion"
                        className="col-form-label col-sm-6 d-flex"
                      >
                        <strong> Resultado </strong>
                      </Label>
                      <Select
                        id="calificacion"
                        name="calificacion"
                        options={Array.isArray(calificacionList) ? calificacionList.map(item => ({ label: (item.name +  " - " +  item.campaign), value: item.id })) : []}
                        onChange={handleSelectGroup}
                        placeholder="Seleccione"
                        required

                      />

                    </Col>
                  </Row>

                  <Row className="col-lg-12 justify-content-center mt-3">
                    <Col md={6} className="mb-3">
                      <Label
                        for="name"
                        className="col-form-label col-sm-4 d-flex"
                      >
                        <strong>Nombre</strong>
                      </Label>

                      <Input
                        id="name"
                        name="name"
                        type="text"
                        onChange={onChange}
                        value={name}
                        valid={
                          props.error.name
                            ? false
                            : props.descripcionCalificacionAdded.id && true
                        }
                        invalid={
                          props.error
                            ? props.error.name
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.name}</FormFeedback>
                    </Col>
                  </Row>


                  <Row className="col-lg-12 justify-content-center">
                    <Col md={3} className="mx-1">

                      <Link className="btn btn-secondary text-dark" to="/descripcionCalificacion">
                        Volver
                      </Link>
                      <Button color="primary" type="submit" className="ms-1">
                        Agregar
                      </Button>


                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, descripcionCalificacionAdded } = state.DescripcionCalificacion;
  return { error, loading, descripcionCalificacionAdded };
};

DescripcionCalificacionAdd.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  descripcionCalificacionAdded: PropTypes.object,
};
export default withRouter(connect(mapStateToProps, { descripcionCalificacionAdd })(DescripcionCalificacionAdd));
