import React, { useEffect, useRef, useState } from 'react'

const ButtonsNavigationForm = ({ onButtonClick }) => {
  const [buttonActive, setButtonActive] = useState("general");

  const generalRef = useRef(null);
  const detallesRef = useRef(null);
  const organizacionRef = useRef(null);
  const precioRef = useRef(null);
  // const enviosRef = useRef(null);
  const borderRef = useRef(null)

  useEffect(() => {
    if (buttonActive) {
      const activeRef = {
        general: generalRef,
        detalles: detallesRef,
        organizacion: organizacionRef,
        precio: precioRef,
        // envios: enviosRef,
      }[buttonActive];

      if (activeRef.current && borderRef.current) {
        borderRef.current.style.left = `${activeRef.current.offsetLeft}px`;
        borderRef.current.style.width = `${activeRef.current.offsetWidth}px`;
      }
    }
  }, [buttonActive]);

  const handleActiveButton = (option) => {
    
    if (buttonActive !== option) {
      setButtonActive(option);
      onButtonClick(option);
    }
  };

  return (
    <section className="container_add_productos">
      <div className='buttons_navigation'>
        <button
          ref={generalRef}
          onClick={() => handleActiveButton("general")}
          className={`button_navigation_form ${
            buttonActive === "general" ? "button_navigation_form_active" : ""
          }`}
        >
          General
        </button>
        <button
          ref={organizacionRef}
          onClick={() => handleActiveButton("organizacion")}
          className={`button_navigation_form ${
            buttonActive === "organizacion"
              ? "button_navigation_form_active"
              : ""
          }`}
        >
          Organización y Variaciones
        </button>
        <button
          ref={detallesRef}
          onClick={() => handleActiveButton("detalles")}
          className={`button_navigation_form ${
            buttonActive === "detalles" ? "button_navigation_form_active" : ""
          }`}
        >
          Detalles
        </button>
        <button
          ref={precioRef}
          onClick={() => handleActiveButton("precio")}
          className={`button_navigation_form ${
            buttonActive === "precio" ? "button_navigation_form_active" : ""
          }`}
        >
          Precio
        </button>
        {/* <button
          ref={enviosRef}
          onClick={() => handleActiveButton("envios")}
          className={`button_navigation_form ${
            buttonActive === "envios" ? "button_navigation_form_active" : ""
          }`}
        >
          Envíos
        </button> */}
        <div ref={borderRef} className='border_button_navigation_form'></div>
      </div>
    </section>
  );
}

export default ButtonsNavigationForm