import React, { useState } from 'react'
import ResultButtons from './ResultButtons'
import InputsFiltros from './InputsFiltros';

const VehiculoTire = ({ modalVehiculo, setModalVehiculo}) => {

  const [marca, setMarca] = useState(null)
  const [year, setYear] = useState(null)
  const [line, setLine] = useState(null)
  const [version, setVersion] = useState(null)

  const handleInputMarca = (marca) => {
    setMarca(marca)
  }

  const handleInputYear = (year) => {
    setYear(year)
  }

  const handleInputLine = (line) => {
    setLine(line)
  }

  const handleInputVersion = (version) => {
    setVersion(version)
  }

  return (
    <div>
      <div
        data-aos="fade-up"
        data-aos-duration="400"
        className="container_modal_vehiculo"
      >
        <div className="modal_vehiculo_tire">
          <div
            className="closed_modal_llantas"
            onClick={() => setModalVehiculo(!modalVehiculo)}
          >
            X
          </div>
          <div className="modal_content">
            <h1 className="title_modal">Vehículo</h1>
            <InputsFiltros 
              marca={marca} marcaUpdate={handleInputMarca} 
              year={year} yearUpdate={handleInputYear}
              line={line} lineUpdate={handleInputLine}
              version={version} versionUpdate={handleInputVersion}
            />
              
            <ResultButtons 
              marcaUpdate={handleInputMarca} marca={marca} 
              yearUpdate={handleInputYear} year={year} 
              lineUpdate={handleInputLine} line={line}
              versionUpdate={handleInputVersion} version={version}
            />

          </div>
          
        </div>
      </div>
    </div>
  );
}

export default VehiculoTire