import React from 'react'
import opcion3 from "../../assets/images/llantas/llanta_rovelo_rhp780p_5_7.jpg";
import marca3 from "../../assets/images/marcas/rovelo.png";
import miniatura from "../../assets/images/llantas/llanta_rovelo_rhp780p_5_7-miniatura.jpg"
import { TbInfoCircleFilled } from 'react-icons/tb';
import { MetaTags } from 'react-meta-tags';



const llantas = [
  {
    id: 1,
    name: "Blackhawk HH1 Street-h",
    medida: "185/60R15",
    caracteristicas: [
      { clave: "utqg", valor: "420-A-A" },
      { clave: "Lonas", valor: "SL" },
      { clave: "Profundidad de banda", valor: "8 mm" },
      { clave: "Pais", valor: "China" },
      { clave: "Ancho de banda", valor: "" },
      { clave: "Fabricado en", valor: "China" },
      { clave: "Indice de carga", valor: "82(474 Kg)" },
      { clave: "Indice de velocidad", valor: "H(210 km)" },
      { clave: "Presion maxima", valor: "49 PSI" },
    ],
    precio: 239.552,
    cantidad: 28,
    image: opcion3,
    imagen_marca: marca3,
    image_mini: miniatura
  }
];

const ProductosDetails = () => {
  

  return (
    <main className="container_productos_true_contact">
      <MetaTags>
        <title>TrueContact | Detalles del Producto</title>
      </MetaTags>
      <div>
        <h1 style={{paddingTop:"4rem", paddingLeft:"5px"}} className="title_productos">Detalles del Producto</h1>
      </div>
      <section className="contain_producto_detail">
        {llantas.map((llanta) => (
          <div className="container_llanta" key={llanta.id}>
            <div className="content_llanta">
              <div className="content_imagen_miniatura">
                <img className="imagen_miniatura" src={opcion3} alt="" />
              </div>

              <article className="info_llanta">
                <div className="container_img_modelo">
                  <div className="content_img_medida">
                    <img className="img_medida" src={opcion3} alt="" />
                  </div>
                  <div className="content_img_marca">
                    <img className="img_marca" src={marca3} alt="" />
                  </div>
                </div>
                <div className="">
                  <div className="content_name">
                    <div>
                      <h2 className="name_llanta">{llanta.name}</h2>
                    </div>
                    <h4 className="name_medida">Medida {llanta.medida}</h4>
                  </div>
                  {llanta.caracteristicas.map((obj) => (
                    <div key={obj.valor}>
                      <p className="info_llantas_caracteristicas">
                        <span className="span_llanta">{obj.clave}</span>:{" "}
                        {obj.valor} <TbInfoCircleFilled />
                      </p> 
                    </div>
                  ))}
                </div>
              </article>

              <article className="content_precios">
                <div className="precios">
                  <div className="line"></div>
                  <p className="precio_viejo_market">
                    $344.900 <span className="cada_uno">c/u</span>
                  </p>
                  <p className="precio_nuevo_market">
                    ${llanta.precio} <span className="cada_uno">c/u</span>
                  </p>
                </div>
                <div className="content_envios">
                  <p className="envios_market">* ENVÍOS GRATIS</p>
                  <p className="iva_market">* IVA INCLUIDO</p>
                </div>

                <div className="content_buttons_market">
                  <div className="container_buttons_cantidad">
                    <div>
                      <span className="cantidad">
                        +{llanta.cantidad - 5} DISPONIBLES
                      </span>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        ))}
      </section>
    </main>
  )
}

export default ProductosDetails