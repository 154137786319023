import {
  DESCRIPCION_CALIFICACION_LIST,
  DESCRIPCION_CALIFICACION_LIST_CHANGE,
  DESCRIPCION_CALIFICACION_LIST_SUCCESS,
  DESCRIPCION_CALIFICACION_LIST_FAILED,
  DESCRIPCION_CALIFICACION_LISTA,
  DESCRIPCION_CALIFICACION_LISTA_SUCCESS,
  DESCRIPCION_CALIFICACION_LISTA_FAILED,
  DESCRIPCION_CALIFICACION_ADD,
  DESCRIPCION_CALIFICACION_ADD_SUCCESS,
  DESCRIPCION_CALIFICACION_ADD_FAILED,
  DESCRIPCION_CALIFICACION_DELETE,
  DESCRIPCION_CALIFICACION_DELETE_SUCCESS,
  DESCRIPCION_CALIFICACION_DELETE_FAILED,

} from "./actionTypes";


export const descripcionCalificacionList = () => {
  return {
    type: DESCRIPCION_CALIFICACION_LIST,
    payload: {},
  };
};
export const descripcionCalificacionListChange = (changeUrl) => {
  return {
    type: DESCRIPCION_CALIFICACION_LIST_CHANGE,
    payload: { changeUrl },
  };
};
export const descripcionCalificacionListSuccess = (descripcionCalificacion) => {
  return {
    type: DESCRIPCION_CALIFICACION_LIST_SUCCESS,
    payload: descripcionCalificacion,
  };
};

export const descripcionCalificacionListFailed = (error) => {
  return {
    type: DESCRIPCION_CALIFICACION_LIST_FAILED,
    payload: error,
  };
};

export const descripcionCalificacionLista = () => {
  return {
    type: DESCRIPCION_CALIFICACION_LISTA,
    payload: {},
  };
};

export const descripcionCalificacionListaSuccess = (descripcionCalificacion) => {
  return {
    type: DESCRIPCION_CALIFICACION_LISTA_SUCCESS,
    payload: descripcionCalificacion,
  };
};

export const descripcionCalificacionListaFailed = (error) => {
  return {
    type: DESCRIPCION_CALIFICACION_LISTA_FAILED,
    payload: error,
  };
};

export const descripcionCalificacionAdd = (descripcionCalificacion) => {
  return {
    type: DESCRIPCION_CALIFICACION_ADD,
    payload: { descripcionCalificacion },
  };
};

export const descripcionCalificacionAddSuccess = (descripcionCalificacionAdded) => {
  return {
    type: DESCRIPCION_CALIFICACION_ADD_SUCCESS,
    payload: descripcionCalificacionAdded,
  };
};

export const descripcionCalificacionAddFailed = (error) => {
  return {
    type: DESCRIPCION_CALIFICACION_ADD_FAILED,
    payload: error,
  };
};

export const descripcionCalificacionDelete = (id) => {
  return {
    type: DESCRIPCION_CALIFICACION_DELETE,
    payload: {id},
  };
};

export const descripcionCalificacionDeleteSuccess = (data) => {
  return {
    type: DESCRIPCION_CALIFICACION_DELETE_SUCCESS,
    payload: data,
  };
};

export const descripcionCalificacionDeleteFailed = (error) => {
  return {
    type: DESCRIPCION_CALIFICACION_DELETE_FAILED,
    payload: error,
  };
};