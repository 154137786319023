import React, { useEffect, useState } from 'react';
import { Card, Input, Col, Row, Label,Button } from 'reactstrap';
import "../Chat.css"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { getContactsRead, postUpdateChatContact } from '../../../helpers/backend';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";

const ChatContact= ({ updateContactOpen, selectedContact, contactsRefresh }) => {
  const [activeNewPhone, setActiveNewPhone] = useState(false)
  const[alert, setAlert] = useState(false)
  const[status, setStatus] = useState(false)

  const handleContactButtonClick = () => {
      updateContactOpen(false);
      };
  
  const getData = async () => {
    const response = await getContactsRead(selectedContact);
    const data = response.data;
    console.log(data);
    setFormData({
      first_name: data.first_name,
      last_name: data.last_name,
      documentType: data.documentType,
      documentNumber: data.documentNumber,
      phone1:data.phone && data.phone[0] ? data.phone[0].telefono : null ,
      email: data.email && data.email[0]? data.email[0].email : null ,
      address: data.address && data.address[0] ? data.address[0].address : null ,
    });
  };
  const [formData, setFormData] = useState({
    first_name: "",
    last_name:"",
    documentNumber: "",
    phone: "",
    email: "",
    address: "",
  });
  const {  
    first_name, 
    last_name, 
    documentNumber, 
    phone1,
    email, 
    address,
  
  } = formData;
    useEffect(() => {
      if(selectedContact){
        getData();
      }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContact])

  const updateContact = async () => {
    const response = await postUpdateChatContact(selectedContact,formData)
    setStatus(response.status)
    setAlert(true)
    contactsRefresh(true)
  }
  console.log(formData);
return (
<>
{status === 200 && alert  ? (
        <SweetAlert
          title="Hecho!"
          success
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            setAlert(false)
          }}
        >
        </SweetAlert>
      ) : null}
    {status !== 200 && alert  ? (
        <SweetAlert
          title="Error"
          error
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
           setAlert(false)
          }}
        >
            No se ha creado la Gestión
        </SweetAlert>
      ) : null}
  <Card style={{ borderTop: '5px solid #0e685f'}}>
  <div className='management-container'>
      <span className='window-close' onClick={handleContactButtonClick}>
          <i className='fas fa-times'></i>
      </span>  

  <div>
      <h4 className='p-3'><b>Contacto</b> </h4>    
  </div> 
    <Row className="justify-content-center px-3">
      <Col lg ={6} className="mb-3">
          <Label
          for="observaciones"
          className="form-label"
          >
          Nombre
          </Label>
          <Input
          id="first_name"
          name="first_name"
          type="text"
          value={first_name}
          onChange={(e)=> setFormData({...formData, first_name: e.target.value})}                 
          />
      </Col>
      <Col lg ={6} className="mb-3">
          <Label
          for="observaciones"
          className="form-label"
          >
          Apellido
          </Label>
          <Input
          id="last_name"
          name="last_name"
          type="text"
          value={last_name}
          onChange={(e)=> setFormData({...formData, last_name: e.target.value})}                 
          />
      </Col>
    </Row>
    <Row className="justify-content-center px-3">
      <Col lg ={6} className="mb-3">
          <Label
          for="observaciones"
          className="form-label"
          >
          Documento
          </Label>
          <Input
          id="documentNumber"
          name="documentNumber"
          type="number"
          value={documentNumber}
          onChange={(e)=> setFormData({...formData, documentNumber: e.target.value})}                 
          />
      </Col>        
      <Col lg ={6} className="mb-3">
          <Label
          for="observaciones"
          className="form-label"
          >
            Correo Electrónico
          </Label>
          <Input
          id="email"
          name="email"
          type="mail"
          value={email}
          onChange={(e)=> setFormData({...formData, email: e.target.value})}                 
          />
      </Col>
    </Row>
    <Row  className="justify-content-center px-3">
      <Col lg ={8} className="mb-3">
          <Label
          for="observaciones"
          className="form-label"
          >
          Teléfono
          </Label>
          <PhoneInput
            id="phone1"
            name="phone1"
            type="text"
            value={phone1}
            country={'co'}   
            disabled       
          />
      </Col>
      {formData.phone1 && (
      <Col lg = {4} className='mt-3'>
        <Link
          to="#"
          onClick={()=> {setActiveNewPhone(true)}}>
          <span className=""><i className=" bx bx-sm bx-plus-circle mt-4 mx-3 "></i></span>
        </Link>
      </Col>
        )}
    </Row>
    {activeNewPhone ? 
      <>
      <Row className="justify-content-center px-3">
        <Col lg={8}>
          <PhoneInput
            id="phone2"
            name="phone2"
            type="text"
            country={'co'}
            onChange={(item)=> setFormData({...formData, phone: item})}                 
          />
        </Col>
        <Col lg={4} >
        <Link
          to="#"
          onClick={()=> {setActiveNewPhone(false)}}>
          <span className=""><i className=" bx bx-sm bx-minus-circle mt-1 mx-3 "></i></span>
        </Link>
        </Col>
      </Row>
        </>
      :null }
    <Row className="justify-content-center px-3">
      <Col lg ={12} className="mb-3">
          <Label
          for="observaciones"
          className="form-label"
          >
          Dirección
          </Label>
          <Input
          id="address"
          name="address"
          type="text"
          value={address}
          onChange={(e)=> setFormData({...formData, address: e.target.value})}                 
          />
      </Col>
    </Row>
    <Row className="col-lg-12 justify-content-center">
        <Col md={1} className= "mx-3">
        <Button color="primary" onClick={updateContact} >
            Guardar
        </Button>
        </Col>
    </Row>
  </div>              
  </Card>
  </>);
};
export default ChatContact;
