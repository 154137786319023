import {
  TIPO_GESTION_CONTACTO_LIST,
  TIPO_GESTION_CONTACTO_LIST_CHANGE,
  TIPO_GESTION_CONTACTO_LIST_SUCCESS,
  TIPO_GESTION_CONTACTO_LIST_FAILED,
  TIPO_GESTION_CONTACTO_LISTA,
  TIPO_GESTION_CONTACTO_LISTA_SUCCESS,
  TIPO_GESTION_CONTACTO_LISTA_FAILED,
  TIPO_GESTION_CONTACTO_ADD,
  TIPO_GESTION_CONTACTO_ADD_SUCCESS,
  TIPO_GESTION_CONTACTO_ADD_FAILED,
  TIPO_GESTION_CONTACTO_DELETE,
  TIPO_GESTION_CONTACTO_DELETE_SUCCESS,
  TIPO_GESTION_CONTACTO_DELETE_FAILED,

} from "./actionTypes";


export const tipoGestionContactoList = () => {
  return {
    type: TIPO_GESTION_CONTACTO_LIST,
    payload: {},
  };
};
export const tipoGestionContactoListChange = (changeUrl) => {
  return {
    type: TIPO_GESTION_CONTACTO_LIST_CHANGE,
    payload: { changeUrl },
  };
};
export const tipoGestionContactoListSuccess = (tipoGestionContacto) => {
  return {
    type: TIPO_GESTION_CONTACTO_LIST_SUCCESS,
    payload: tipoGestionContacto,
  };
};

export const tipoGestionContactoListFailed = (error) => {
  return {
    type: TIPO_GESTION_CONTACTO_LIST_FAILED,
    payload: error,
  };
};

export const tipoGestionContactoLista = () => {
  return {
    type: TIPO_GESTION_CONTACTO_LISTA,
    payload: {},
  };
};

export const tipoGestionContactoListaSuccess = (tipoGestionContacto) => {
  return {
    type: TIPO_GESTION_CONTACTO_LISTA_SUCCESS,
    payload: tipoGestionContacto,
  };
};

export const tipoGestionContactoListaFailed = (error) => {
  return {
    type: TIPO_GESTION_CONTACTO_LISTA_FAILED,
    payload: error,
  };
};

export const tipoGestionContactoAdd = (tipoGestionContacto) => {
  return {
    type: TIPO_GESTION_CONTACTO_ADD,
    payload: { tipoGestionContacto },
  };
};

export const tipoGestionContactoAddSuccess = (tipoGestionContactoAdded) => {
  return {
    type: TIPO_GESTION_CONTACTO_ADD_SUCCESS,
    payload: tipoGestionContactoAdded,
  };
};

export const tipoGestionContactoAddFailed = (error) => {
  return {
    type: TIPO_GESTION_CONTACTO_ADD_FAILED,
    payload: error,
  };
};

export const tipoGestionContactoDelete = (id) => {
  return {
    type: TIPO_GESTION_CONTACTO_DELETE,
    payload: {id},
  };
};

export const tipoGestionContactoDeleteSuccess = (data) => {
  return {
    type: TIPO_GESTION_CONTACTO_DELETE_SUCCESS,
    payload: data,
  };
};

export const tipoGestionContactoDeleteFailed = (error) => {
  return {
    type: TIPO_GESTION_CONTACTO_DELETE_FAILED,
    payload: error,
  };
};