import {
  CAMPAIGN_LIST,
  CAMPAIGN_FILTER,
  CAMPAIGN_CREATE,
  CAMPAIGN_CREATE_SUCCESS,
  CAMPAIGN_LIST_SUCCESS,
  CAMPAIGN_REQUEST_FAILED,
  CAMPAIGN_UPDATE,
  CAMPAIGN_UPDATE_SUCCESS,
  CAMPAIGN_DELETE, 
  CAMPAIGN_DELETE_SUCCESS
} from "../../store/campaign/actionTypes";

const initialState = {
  campaign: [],
  campaignCreated: {},
  campaignUpdated: {},
  campaignDeleted: false,
  loading: false,
  error: "",
};

const campaign = (state = initialState, action) => {
  switch (action.type) {
    case CAMPAIGN_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CAMPAIGN_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CAMPAIGN_FILTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CAMPAIGN_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CAMPAIGN_DELETE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CAMPAIGN_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        campaignCreated: action.payload,
        error: "",
      };
      break;
    case CAMPAIGN_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        campaign: action.payload,
        campaignCreated: {},
        campaignUpdated: {},
        campaignDeleted:false,
        error: "",
      };
      break;
    case CAMPAIGN_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        campaignUpdated: action.payload,
        error: "",
      };
      break;

    case CAMPAIGN_DELETE_SUCCESS:
      state = {
        ...state,
        loading: false,
        campaignDeleted: true,
        error: "",
      };
      break;
    case CAMPAIGN_REQUEST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default campaign;
