// TIPO_GESTION_CONTACTO list
export const TIPO_GESTION_CONTACTO_LIST = "TIPO_GESTION_CONTACTO_LIST";
export const TIPO_GESTION_CONTACTO_LIST_SUCCESS = "TIPO_GESTION_CONTACTO_LIST_SUCCESS";
export const TIPO_GESTION_CONTACTO_LIST_FAILED = "TIPO_GESTION_CONTACTO_LIST_FAILED";
export const TIPO_GESTION_CONTACTO_LIST_CHANGE = "TIPO_GESTION_CONTACTO_LIST_CHANGE";

// TIPO_GESTION_CONTACTO lista sin Paginacion
export const TIPO_GESTION_CONTACTO_LISTA = "TIPO_GESTION_CONTACTO_LISTA";
export const TIPO_GESTION_CONTACTO_LISTA_SUCCESS = "TIPO_GESTION_CONTACTO_LISTA_SUCCESS";
export const TIPO_GESTION_CONTACTO_LISTA_FAILED = "TIPO_GESTION_CONTACTO_LISTA_FAILED";

// Add TIPO_GESTION_CONTACTO
export const TIPO_GESTION_CONTACTO_ADD = "TIPO_GESTION_CONTACTO_ADD";
export const TIPO_GESTION_CONTACTO_ADD_SUCCESS = "TIPO_GESTION_CONTACTO_ADD_SUCCESS";
export const TIPO_GESTION_CONTACTO_ADD_FAILED = "TIPO_GESTION_CONTACTO_ADD_FAILED";

//TIPO_GESTION_CONTACTO edit
export const TIPO_GESTION_CONTACTO_EDIT = "TIPO_GESTION_CONTACTO_EDIT";
export const TIPO_GESTION_CONTACTO_EDIT_SUCCESS = "TIPO_GESTION_CONTACTO_EDIT_SUCCESS";
export const TIPO_GESTION_CONTACTO_EDIT_FAILED = "TIPO_GESTION_CONTACTO_EDIT_FAILED";


//TIPO_GESTION_CONTACTO READ
export const TIPO_GESTION_CONTACTO_READ = "TIPO_GESTION_CONTACTO_READ";
export const TIPO_GESTION_CONTACTO_READ_SUCCESS = "TIPO_GESTION_CONTACTO_READ_SUCCESS";
export const TIPO_GESTION_CONTACTO_READ_FAILED = "TIPO_GESTION_CONTACTO_READ_FAILED";

//TIPO_GESTION_CONTACTO DELETE
export const TIPO_GESTION_CONTACTO_DELETE = "TIPO_GESTION_CONTACTO_DELETE";
export const TIPO_GESTION_CONTACTO_DELETE_SUCCESS = "TIPO_GESTION_CONTACTO_DELETE_SUCCESS";
export const TIPO_GESTION_CONTACTO_DELETE_FAILED = "TIPO_GESTION_CONTACTO_DELETE_FAILED";

