import React from 'react'
import CardProduct from './CardProduct'

const ProductsLists = ({products}) => {
  return (
    <div className='products_list'>
      {products.map((product) => (
        <CardProduct key={product.id} product={product}/>
      ))}
    </div>
  )
}

export default ProductsLists