import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import { withRouter, useHistory } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

// Actions
import { loginSuccess } from "../../store/actions"

const Sidebar = props => {

  const history = useHistory()
  const dispatch = useDispatch()
  //const user = props.user

  useEffect(() => {
    if(!props.user){      
      dispatch(loginSuccess(history))
    }
  })

  return (
    <React.Fragment>
      <div style={{ backgroundColor:"#19243f", borderRight:"1px solid #19243f", transition:"all .3s ease"}} className="vertical-menu">
        <div className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  const { user } = state.Login
  return {
    layout: state.Layout,
    user,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
