import {
  AUDITS_LIST,
  AUDITS_FILTER,
  AUDITS_CREATE,
  AUDITS_UPDATE,
  AUDITS_UPDATE_SUCCESS,
  AUDITS_CREATE_SUCCESS,
  AUDITS_LIST_SUCCESS,
  AUDITS_REQUEST_FAILED,
} from "./actionTypes";

export const auditsList = () => {
  return {
    type: AUDITS_LIST,
    payload: {},
  };
};

export const auditsCreate = (audits) => {
  return {
    type: AUDITS_CREATE,
    payload: audits,
  };
};

export const auditsFilter = (changeUrl) => {
  return {
    type: AUDITS_FILTER,
    payload: { changeUrl },
  };
};

export const auditsUpdate = (id, data) => {
  return {
    type: AUDITS_UPDATE,
    payload: { id, data },
  };
};

export const auditsListSuccess = (audits) => {
  return {
    type: AUDITS_LIST_SUCCESS,
    payload: audits,
  };
};

export const auditsCreateSuccess = (auditsCreated) => {
  return {
    type: AUDITS_CREATE_SUCCESS,
    payload: auditsCreated,
  };
};

export const auditsUpdateSuccess = (audits) => {
  return {
    type: AUDITS_UPDATE_SUCCESS,
    payload: audits,
  };
};

export const auditsRequestFailed = (error) => {
  return {
    type: AUDITS_REQUEST_FAILED,
    payload: error,
  };
};
