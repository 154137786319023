import {
  ROL_LIST,
  ROL_FILTER,
  ROL_CREATE,
  ROL_UPDATE,
  ROL_DELETE,
  ROL_PERMISSION,
  ROL_UPDATE_SUCCESS,
  ROL_DELETE_SUCCESS,
  ROL_CREATE_SUCCESS,
  ROL_LIST_SUCCESS,
  ROL_PERMISSION_SUCCESS,
  ROL_REQUEST_FAILED,
} from "../roles/actionTypes";

export const rolList = () => {
  return {
    type: ROL_LIST,
    payload: {},
  };
};

export const rolCreate = (rol) => {
  return {
    type: ROL_CREATE,
    payload: rol,
  };
};

export const rolFilter = (changeUrl) => {
  return {
    type: ROL_FILTER,
    payload: { changeUrl },
  };
};

export const rolUpdate = (id, data) => {
  return {
    type: ROL_UPDATE,
    payload: { id, data },
  };
};
export const rolDelete = (id) => {
  return {
    type: ROL_DELETE,
    payload: { id },
  };
};
export const rolPermission = (permission) => {
  return {
    type: ROL_PERMISSION,
    payload: permission,
  };
};

export const rolListSuccess = (rol) => {
  return {
    type: ROL_LIST_SUCCESS,
    payload: rol,
  };
};

export const rolCreateSuccess = (rolCreated) => {
  return {
    type: ROL_CREATE_SUCCESS,
    payload: rolCreated,
  };
};

export const rolUpdateSuccess = (rol) => {
  return {
    type: ROL_UPDATE_SUCCESS,
    payload: rol,
  };
};

export const rolDeleteSuccess = (rolDeleted) => {
  return {
    type: ROL_DELETE_SUCCESS,
    payload: rolDeleted,
  };
};

export const rolPermissionSuccess = (permissionAssigned) => {
  return {
    type: ROL_PERMISSION_SUCCESS,
    payload: permissionAssigned,
  };
};

export const rolRequestFailed = (error) => {
  return {
    type: ROL_REQUEST_FAILED,
    payload: error,
  };
};
