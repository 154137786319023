import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Label, Modal, Row } from 'reactstrap';
import SweetAlert from "react-bootstrap-sweetalert";
import Select, { components } from 'react-select';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getCalendarEventRead, getContactsFullList, patchCalendarEvent, postCalendarEvent } from '../../helpers/backend';

const colorOptions = [
  { value: 'orange', label: 'Naranja' },
  { value: 'yellow', label: 'Amarillo' },
  { value: 'blue', label: 'Azul' },
  { value: 'pink', label: 'Rosado' },
  { value: 'green', label: 'Verde Militar' },
  { value: 'purple', label: 'Púrpura' },
  { value: 'violet', label: 'Violeta' },
  // Agrega más colores aquí
];

export const EventForm = (props) => {
  const { isOpen, onClose, id } = props;
  const [alert, setAlert] = useState(false)
  const [alertUpdate, setAlertUpdate] = useState(false)
 
  const [contactList, setContactList] = useState([]);
  const getContactListData = async () => {
    const response = await getContactsFullList();
    const data = response.data;
    setContactList(data);
  };

  const getData = async () => {
    const response = await getCalendarEventRead(id);
    const data = response.data;console.log(data);
    setFormData({
      contacto: data.contacto,
      start: data.start,
      end: data.end,
      title: data.title,
      caso: data.caso,
      color:data.color
    });
  };
  const [formData, setFormData] = useState({
    contacto: [],
    start: "",
    end: "",
    title: "",
    caso: "",
    color:""
});
const { start,end, title } =
 formData;


  useEffect(() => {
    getContactListData();
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


const onChange = (e) => {
 setFormData({ ...formData, [e.target.name]: e.target.value });
};

const handleSubmit = async (e) => {
  e.preventDefault();
  // Extrae solo los valores (value) de formData.contacto
  const selectedContactValues = Array.isArray(formData.contacto) ? formData.contacto.map((option) => option[1]):[];
  // Crea un nuevo objeto formDataToSend solo con los valores necesarios
  const formDataToSend = {
    ...formData,
    contacto: selectedContactValues,
  };
  const response = await postCalendarEvent(formDataToSend);
  const data = response.data;
  if (data.id) {
    setAlert(true);
  }
};

const handleUpdate = async (e) => {
  e.preventDefault();
  const selectedContactValues = Array.isArray(formData.contacto) ? formData.contacto.map((option) => option[1]):[];
  const formDataToSend = {
    ...formData,
    contacto: selectedContactValues,
  };
  const response = await patchCalendarEvent(id,formDataToSend);
  const data = response.data;
  if (data.id) {
      setAlertUpdate(true);
    }
};

  const { Option } = components;
  
  const ColorOption = (props) => (
    <Option {...props}>
      <div style={{ backgroundColor: props.data.value,  marginRight: '8px' }}></div>
      {props.label}
    </Option>
  );
return (
<>
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered
      data-toggle="modal"
      size="lg"
      className="custom-modal"
      toggle={onClose} // Utiliza la función de cerrar del padre para cerrar el modal
    >

<div className='p-4'>
  {!id ? 
      <h2 className='text-dark'>Crea un Evento</h2>
  :
      <h2 className='text-dark'>Actualizar Evento</h2>
  }
      <hr />
      <Form onSubmit={handleSubmit}>
        <Row>
            <Col lg={6} className="mb-3">                    
                <Label
                for="title"
                className="form-label"
                >
                Título del Evento
                </Label>
                <Input
                id="title"
                name="title"
                type="text"
                onChange={onChange}
                value={title}
                required
                />
        
            </Col>                                   
            <Col lg={6}  className="mb-3">                    
                <Label
                for="contacto"
                className="form-label"
                >
                Contactos
                </Label>
                <Select
                    closeMenuOnSelect={false}
                    id="contacto"
                    name="contacto"
                    options={Array.isArray(contactList)? contactList.map(item=> ({label: item.get_full_name, value: item.id})):[]}
                    value={formData.contacto ? formData.contacto.map(([label, value]) => ({ label, value })): null}
                    onChange={(selectedOptions) => {
                      // Obtener los datos seleccionados de los contactos
                      const selectedContactData = selectedOptions.map((option) => [option.label, option.value]);
                      // Actualizar formData.contacto con los datos seleccionados
                      setFormData({ ...formData, contacto: selectedContactData });
                    }}                        
                    placeholder="Seleccione"
                    isMulti={true}                    
                    menuPosition="fixed"                   
                    />    
            </Col>
        </Row>     
        <Row>
            <Col lg={5}  className="mb-3">                    
                <Label
                for="date-time"
                className="form-label"
                >
                Fecha y Hora de Inicio
                </Label>
                <Input
                id="start"
                name="start"
                type="datetime-local"
                onChange={onChange}
                value={start}                        
                />

                
            </Col>    
            <Col lg={5}  className="mb-3">                    
                <Label
                for="date-time"
                className="form-label"
                >
                Fecha y Hora de Finalización
                </Label>
                <Input
                id="end"
                name="end"
                type="datetime-local"
                onChange={onChange}
                value={end}                        
                />                
            </Col>
            <Col lg= {2}>
             <Label
              for="color"
              className="form-label"
              >
              Color
              </Label>
              <Select
                closeMenuOnSelect={true}
                id="color"
                name="color"
                options={colorOptions.map((color) => ({
                  label: (
                    <div
                      style={{
                        backgroundColor: color.value,
                        width: '24px',
                        height: '24px',
                        marginRight: '8px',
                        marginBottom: '5px',
                        marginTop: '5px'
                      }}
                    ></div>
                  ),
                  value: color.value,
                }))}
                placeholder=""
                isClearable={true}
                components={{ Option: ColorOption }}
                onChange={(item) => setFormData({ ...formData, color: item?.value || '' })}              
              />
          </Col>
        </Row> 

        
        <Row className="mt-3">
          <Col className='d-flex justify-content-end'>
            <Link className="btn btn-secondary text-dark" to="#" onClick={onClose}>
              Cerrar
            </Link>
            {!id ? 
            <Button color="primary" className="ms-1" type='submit'>
              Agregar
            </Button>
            :
            <Button color="primary" className="ms-1" onClick={handleUpdate}>
            Actualizar
          </Button>
            }

          </Col>
        </Row>
      </Form>
    </div>

    </Modal>
    {alert ? (
        <SweetAlert
            title="Hecho!"
            success
            timeout={2000}
            showConfirm={false}
            onConfirm={() => {
                onClose();
                setAlert(false); 

            }}
        >
            Se ha agregado los datos con éxito
        </SweetAlert>
    ) : null}
        {alertUpdate ? (
        <SweetAlert
            title="Hecho!"
            success
            timeout={2000}
            showConfirm={false}
            onConfirm={() => {
                onClose();
                setAlertUpdate(false); 

            }}
        >
            Se han actualizado los datos con éxito
        </SweetAlert>
    ) : null}
</>         
  );
};
