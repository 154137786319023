// CALIFICACION_CONTACTO list
export const CALIFICACION_CONTACTO_LIST = "CALIFICACION_CONTACTO_LIST";
export const CALIFICACION_CONTACTO_LIST_SUCCESS = "CALIFICACION_CONTACTO_LIST_SUCCESS";
export const CALIFICACION_CONTACTO_LIST_FAILED = "CALIFICACION_CONTACTO_LIST_FAILED";
export const CALIFICACION_CONTACTO_LIST_CHANGE = "CALIFICACION_CONTACTO_LIST_CHANGE";

// CALIFICACION_CONTACTO lista sin Paginacion
export const CALIFICACION_CONTACTO_LISTA = "CALIFICACION_CONTACTO_LISTA";
export const CALIFICACION_CONTACTO_LISTA_SUCCESS = "CALIFICACION_CONTACTO_LISTA_SUCCESS";
export const CALIFICACION_CONTACTO_LISTA_FAILED = "CALIFICACION_CONTACTO_LISTA_FAILED";

// Add CALIFICACION_CONTACTO
export const CALIFICACION_CONTACTO_ADD = "CALIFICACION_CONTACTO_ADD";
export const CALIFICACION_CONTACTO_ADD_SUCCESS = "CALIFICACION_CONTACTO_ADD_SUCCESS";
export const CALIFICACION_CONTACTO_ADD_FAILED = "CALIFICACION_CONTACTO_ADD_FAILED";

//CALIFICACION_CONTACTO edit
export const CALIFICACION_CONTACTO_EDIT = "CALIFICACION_CONTACTO_EDIT";
export const CALIFICACION_CONTACTO_EDIT_SUCCESS = "CALIFICACION_CONTACTO_EDIT_SUCCESS";
export const CALIFICACION_CONTACTO_EDIT_FAILED = "CALIFICACION_CONTACTO_EDIT_FAILED";


//CALIFICACION_CONTACTO READ
export const CALIFICACION_CONTACTO_READ = "CALIFICACION_CONTACTO_READ";
export const CALIFICACION_CONTACTO_READ_SUCCESS = "CALIFICACION_CONTACTO_READ_SUCCESS";
export const CALIFICACION_CONTACTO_READ_FAILED = "CALIFICACION_CONTACTO_READ_FAILED";

//CALIFICACION_CONTACTO DELETE
export const CALIFICACION_CONTACTO_DELETE = "CALIFICACION_CONTACTO_DELETE";
export const CALIFICACION_CONTACTO_DELETE_SUCCESS = "CALIFICACION_CONTACTO_DELETE_SUCCESS";
export const CALIFICACION_CONTACTO_DELETE_FAILED = "CALIFICACION_CONTACTO_DELETE_FAILED";

