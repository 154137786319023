import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useHistory } from "react-router-dom";
import { getDashboard } from '../../../helpers/backend';




const Widget = props => {
  const history = useHistory()
/*  const user = JSON.parse(localStorage.getItem("user"));
  const groups = String(user.groups);
  */

  const [dashboardList, setDashboardList] = useState({})

  const getData = async () => {
    const response = await getDashboard();
    const data = response.data;
    setDashboardList(data)
    console.log(data)
  };

  useEffect(() => {
    getData();
  }, [])
  const widget = [

    {
      id: 1,
      title: "Contactos",
      count: dashboardList.contactos_activos,
      icon: 'fas fa-user-tie text-white',
      path: '/contacts',
      class: 'contacts',
      colorClass: 'color_contact',
      iconClass: 'icon_contact'
    },
    {
      id: 2,
      title: "Gestiones",
      icon: 'fas fa-user-alt text-white',
      path: '/managementList',
      class: 'gestiones',
      colorClass: 'color_gestiones',
      iconClass: 'icon_gestiones'

    },
    {
      id: 3,
      title: "Campañas",
      icon: 'fas fa-flag text-white',
      path: '/campaign',
      class: 'compaing',
      colorClass: 'color_compaing',
      iconClass: 'icon_compaing'

    },
  ]
  const redirect = (path) => {
    history.push(`${path}`)
  };
  return (
    <React.Fragment>
      <Row>
        {widget.map((widget, key) => (
          <Col lg={4} key={key}>
            <Card
              style={{ cursor: "pointer", height: "140px", marginTop: "4px", border: "none" }}
              onClick={() => redirect(widget.path)}
            >
              <div className={widget.class}>
                <CardBody>
                  <div className={`float-end content_icon ${widget.iconClass}`}>
                    <div className=" font-size-24">
                      <span>
                        <i className={`${widget.icon}`}></i>
                      </span>
                    </div>
                  </div>
                  <div>
                    <p className="p-2 text-uppercase fw-semibold font-size-18 ">
                      {widget.title}
                    </p>
                    <h3 className="mb-1 mt-1">
                      <span
                        className={`counter-value mx-2 ${widget.colorClass}`}
                        data-target="58425"
                      >
                        {widget.count}
                      </span>
                    </h3>
                  </div>
                </CardBody>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
}

export default Widget;