// CONTACTS_DATA requests
export const CONTACTS_DATA_LIST = "CONTACTS_DATA_LIST";
export const CONTACTS_DATA_CREATE = "CONTACTS_DATA_CREATE";
export const CONTACTS_DATA_FILTER = "CONTACTS_DATA_FILTER";
export const CONTACTS_DATA_UPDATE = "CONTACTS_DATA_UPDATE";
export const CONTACTS_DATA_DELETE = "CONTACTS_DATA_DELETE";
export const CONTACTS_DATA_READ = "CONTACTS_DATA_READ";

// CONTACTS_DATA success
export const CONTACTS_DATA_LIST_SUCCESS = "CONTACTS_DATA_LIST_SUCCESS";
export const CONTACTS_DATA_CREATE_SUCCESS = "CONTACTS_DATA_CREATE_SUCCESS";
export const CONTACTS_DATA_UPDATE_SUCCESS = "CONTACTS_DATA_UPDATE_SUCCESS";
export const CONTACTS_DATA_DELETE_SUCCESS = "CONTACTS_DATA_DELETE_SUCCESS";
export const CONTACTS_DATA_READ_SUCCESS = "CONTACTS_DATA_READ_SUCCESS";

// CONTACTS_DATA failed
export const CONTACTS_DATA_REQUEST_FAILED = "CONTACTS_DATA_REQUEST_FAILED";
