import React, { useState , useEffect } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";

// Redux
import { connect, useDispatch } from "react-redux";
import { withRouter, Link, useHistory,  useParams } from "react-router-dom";

import { userUpdate, userList } from "../../../store/actions";

//Import Breadcrumb
import Breadcrumb from "../../../components/Common/Breadcrumb";
import Loader from "../../../components/Common/Loader";

const UserEdit = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const param = useParams();
  const str = param.id; 
  const usersId = Number(str);
  //const user= useSelector(state=> state.User.user)

  useEffect(() => {
      dispatch(userList())
    
    },[dispatch]);


    const results = props.user.results;

    const  userId = results && results.filter(item => item.id === usersId )

    const username1 = userId && userId.map(item => item.username);
    const firstName = userId && userId.map(item => item.first_name);
    const lastName =  userId && userId.map(item => item.last_name);
    const password = userId && userId.map(item => item.password)
    const email1 = userId && userId.map(item => item.email)

  const [formData, setFormData] = useState({
    username: String(username1),
    first_name: String (firstName),
    last_name: String(lastName),
    email: String(email1),
  });

  const { username, first_name, last_name, email } =
    formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
 
  const onSubmit = (e) => {
    e.preventDefault();
    props.userUpdate(usersId,{
      username,
      first_name,
      last_name,
      password : String(password),
      email,
    });
  };

/*const updateUser = () => {
  dispatch (userUpdate( usersId, {
    username,
    first_name,
    last_name,
  }))
}*/


  const redirect = () => {
    history.push("/users");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TrueContact | Usuarios </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={"TrueContact"} breadcrumbItem={"Usuarios"} />
          {props.loading ? (
            <Loader />
          ) : (
            <Card className= "col-lg-8 mx-auto  mt-3">
              {props.userUpdated.id  ? (
                <SweetAlert
                  title="Hecho!"
                  success
                  timeout={2000}
                  showConfirm={false}
                  onConfirm={() => {
                    redirect();
                  }}
                >
                  Se ha actualizado el usuario con éxito
                </SweetAlert>
              ) : null}
              
              {props.error.detail ? (
                <SweetAlert
                  title="Lo Sentimos,"
                  error
                  // timeout={2000}
                  showConfirm={true}
                  onConfirm={() => {
                  redirect();               
                  }}
                >
              {props.error.detail}
                </SweetAlert>
              ) : null}

              <CardBody>
                <h4 className="card-title">Editar usuario</h4>
                <Form className="needs-validation" onSubmit={onSubmit}>

                  <Row className="col-lg-12 justify-content-center mt-3">
                    <Col md={1} className="mb-3">
                      <Label
                        for="username"
                        className="col-form-label col-sm-4 d-flex"
                      >
                       <b>Usuario</b> 
                      </Label>
                    </Col>
                    <Col md={4} className="mb-3">
                      <Input
                        id="username"
                        name="username"
                        type="email"
                        onChange={onChange}
                        value={username}
                        invalid={
                          props.error
                            ? props.error.username
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.username}</FormFeedback>
                    </Col>
                  </Row>
  
                  <Row className="col-lg-12 justify-content-center">
                    <Col md={1} className="mb-3">
                      <Label
                        for="first_name"
                        className="col-form-label col-sm-4 d-flex"
                      >
                       <b>Nombres</b> 
                      </Label>
                    </Col>
                    <Col md={4} className="mb-3">
                      <Input
                        id="first_name"
                        name="first_name"
                        type="text"
                        onChange={onChange}
                        value={first_name}                        
                        invalid={
                          props.error
                            ? props.error.first_name
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.first_name}</FormFeedback>
                    </Col>
                  </Row>
                  <Row className="col-lg-12 justify-content-center">
                    <Col md={1} className="mb-3">
                      <Label
                        for="last_name"
                        className="col-form-label col-sm-4 d-flex"
                      >
                       <b>Apellidos</b> 
                      </Label>
                    </Col>
                    <Col md={4} className="mb-3">
                      <Input
                        id="last_name"
                        name="last_name"
                        type="text"
                        onChange={onChange}
                        value={last_name}
                        invalid={
                          props.error
                            ? props.error.last_name
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.last_name}</FormFeedback>
                    </Col>
                  </Row>

                  <Row className="col-lg-12 justify-content-center mt-3">
                    <Col md={4}>
                      <Link className="btn btn-secondary text-dark" to="/users">
                        Volver
                      </Link>
                      <Button color="primary" type="submit" className="ms-1" >
                        Actualizar
                      </Button>
                    </Col>

                  </Row>
                </Form>
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, user, userUpdated } = state.User;
  return { error, loading, user, userUpdated };
};

UserEdit.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  userUpdated: PropTypes.object,
};
export default withRouter(connect(mapStateToProps, { userList, userUpdate })(UserEdit));
