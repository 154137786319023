import React, { useState } from 'react'
import Rendimiento from './components/Rendimiento';
import './styles/Analisis.css'
import { TbChevronDown, TbChevronUp  } from "react-icons/tb";
import GraficosRendimiento from './components/GraficosRendimiento';
import FavoritoAnalisis from './components/FavoritoAnalisis';
import Calendario from '../Calendar/Calendario';


const Analisis = () => {
  const [startDate, setStartDate] = useState(new Date('2024-11-01'));
  const [endDate, setEndDate] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const handleDateSelect = (start, end) => {
    if (start && end) {
      if (start <= end) {
        setStartDate(start);
        setEndDate(end);
      } else {
        setStartDate(end);
        setEndDate(start);
      }
    }

  };

  const formatDateRange = () => {
    const start = startDate.toLocaleDateString("es-ES", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    const end = endDate.toLocaleDateString("es-ES", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return `Desde ${start} | Hasta ${end}`;
  };

  return (
    <main
      className="container_productos_true_contact_add main_container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "3rem",
        }}
      >
        <div>
          <h3>Detalles</h3>
        </div>
        <div
          className="content_button_calendary"
          style={{ paddingRight: "2rem" }}
        >
          <button
            className={`${
              showCalendar
                ? "button_filter_analisis_active"
                : "button_filter_analisis"
            }`}
            onClick={toggleCalendar}
          >
            <div className="content_p_fecha_rango">{formatDateRange()}</div>
            <div>
              {showCalendar ? (
                <TbChevronUp className="icon_fecha_rango" size={22} />
              ) : (
                <TbChevronDown className="icon_fecha_rango" size={22} />
              )}
            </div>
          </button>
          {showCalendar && (
            <Calendario
              startDate={startDate}
              endDate={endDate}
              onDateSelect={handleDateSelect}
              onClose={() => setShowCalendar(false)}
              showCalendar={showCalendar}
            />
          )}
        </div>
      </div>
      <div>
        <Rendimiento />
      </div>
      <div>
        <GraficosRendimiento />
      </div>
      <div>
        <FavoritoAnalisis />
      </div>
    </main>
  );
}

export default Analisis