import { call, put, takeEvery } from "redux-saga/effects";

import {
  ROL_LIST,
  ROL_FILTER,
  ROL_CREATE,
  ROL_UPDATE,
  ROL_DELETE,
  ROL_PERMISSION,
} from "./actionTypes";

import {
 rolListSuccess,
 rolCreateSuccess,
 rolUpdateSuccess,
 rolPermissionSuccess,
 rolDeleteSuccess,
 rolRequestFailed,
} from "./actions";

import {
  getRolList,
  getRolFilter,
  postRolCreate,
  patchRolUpdate,
  deleteRol,
  postPermissionToGroup,
} from "../../helpers/backend";

function*rolListSaga() {
  console.log("Trayendo lista de rol...");
  try {
    const response = yield call(getRolList);
    if (response.status === 200) {
      yield put(rolListSuccess(response.data));
    } else {
      yield put(rolRequestFailed(response.data));
    }
  } catch (error) {
    yield put(rolRequestFailed(error.response.data));
  }
}

function*rolCreateSaga({ payload:rol }) {
  console.log("Creando nuevo rol...");
  try {
    const response = yield call(postRolCreate,rol);
    if (response.status === 201) {
      yield put(rolCreateSuccess(response.data));
    } else {
      yield put(rolRequestFailed(response.data));
    }
  } catch (error) {
    yield put(rolRequestFailed(error.response.data));
  }
}

function*rolFilterSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getRolFilter, { changeUrl });
    if (response.status === 200) {
      yield put(rolListSuccess(response.data));
    } else {
      yield put(rolRequestFailed(response.data));
    }
  } catch (error) {
    yield put(rolRequestFailed(error.response.data));
  }
}

function*rolUpdateSaga({ payload: { id, data } }) {
  console.log("Actualizando datos del ROL...");
  try {
    const response = yield call(patchRolUpdate, { id }, data);
    if (response.status === 200) {
      yield put(rolUpdateSuccess(response.data));
    } else {
      yield put(rolRequestFailed(response.data));
    }
  } catch (error) {
    yield put(rolRequestFailed(error.response.data));
  }
}

function*rolDeleteSaga({ payload: { id } }) {
  console.log("Actualizando datos del Contacto...");
  try {
    const response = yield call(deleteRol, id );
    if (response.status === 200) {
      yield put(rolDeleteSuccess(response.data));
    } else {
      yield put(rolRequestFailed(response.data));
    }
  } catch (error) {
    yield put(rolRequestFailed(error.response.data));
  }
}

function*rolPermissionSaga({ payload:permission }) {
  console.log("Creando nuevo lista de permisios...");
  try {
    const response = yield call(postPermissionToGroup, permission);
    if (response.status === 201) {
      yield put(rolPermissionSuccess(response.data));
    } else {
      yield put(rolRequestFailed(response.data));
    }
  } catch (error) {
    yield put(rolRequestFailed(error.response.data));
  }
}
export default function*rolSaga() {
  yield takeEvery(ROL_LIST,rolListSaga);
  yield takeEvery(ROL_FILTER,rolFilterSaga);
  yield takeEvery(ROL_CREATE,rolCreateSaga);
  yield takeEvery(ROL_UPDATE,rolUpdateSaga);
  yield takeEvery(ROL_DELETE,rolDeleteSaga);
  yield takeEvery(ROL_PERMISSION,rolPermissionSaga);
}
