/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import CardLlanta from './CardLlanta';
import ErrorBusqueda from '../../Error/ErrorBusqueda';
import { getProductosList } from '../../../helpers/backend';
import LoadingCarga from '../../Loading/LoadingCarga';

const ListaLlantas = ({ searchProducto = '', listType }) => {
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });

  const loaderRef = useRef(null);

  const getProductos = async (url = '') => {
    setLoading(true);
    try {
      const response = await getProductosList(url);
      setParams((prevParams) => ({
        count: response.data.count,
        next: response.data.next,
        previous: response.data.previous,
        results: [...prevParams.results, ...response.data.results],
      }));
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  const filteredLlantas = params.results.filter((llanta) =>
    llanta.name.toLowerCase().includes(searchProducto.toLowerCase())
  );

  useEffect(() => {
    setParams({
      count: 0,
      next: null,
      previous: null,
      results: [],
    });
    getProductos();
  }, [searchProducto]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !loading && params.next) {
          getProductos(params.next);
        }
      },
      {
        rootMargin: '100px',
      }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [loading, params.next]);

  return (
    <div className={`${listType ? 'llantas_list_layout' : 'llantas_list'}`}>
      {loading && (
        <div style={{ display: 'grid', placeItems: 'center', width: '100%' }}>
          <LoadingCarga />
        </div>
      )}

      {filteredLlantas.length === 0 && !loading ? (
        <div style={{ width: '1000px' }}>
          <ErrorBusqueda />
        </div>
      ) : (
        filteredLlantas.map((llanta) => (
          <CardLlanta key={llanta.id} llanta={llanta} listType={listType} />
        ))
      )}

      <div ref={loaderRef} style={{ display: 'grid', placeItems: 'center', width: '100%' }}>
        <LoadingCarga />
      </div>
    </div>
  );
};

export default ListaLlantas;
