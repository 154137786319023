import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Card, CardBody, Col, Input, Modal, Row, Table } from "reactstrap";
import { deleteContactsData, getContactsDataAddress, postContactsDataCreate } from "../../../../helpers/backend";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";

const AddressList = () => {
  const contactoId = useParams().id
  const user = JSON.parse(localStorage.getItem("user"))
  const groups = String(user.groups);

  const optionGroupLabel = [
    {
    options: [
        { label: "Principal", value: "Principal" },
        { label: "Trabajo ", value: "Trabajo" },
        { label: "Casa ", value: "Casa" },
        { label: "Otro ", value: "Otro" },
        ],
    },
];

  const [isOpen, setIsOpen] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [tipoContacto, setTipoContacto] = useState(null)
  const [dataText, setDataText] = useState(null)
  const [datoId, setDatoId] = useState(null)

  const [addressData, setAddressData] = useState({});
  const getAddressData = async () => {
    const response = await getContactsDataAddress(contactoId);
    const data = response.data;
    setAddressData(data)
  }
const HandleDelete = async () => {
  await deleteContactsData(datoId)
  setConfirm(false)
  getAddressData()
}
  useEffect(() => {
    getAddressData()
    // eslint-disable-next-line
  }, [])
  
  const onSubmit = async () => {
    await postContactsDataCreate({
        contact : contactoId, 
        label : tipoContacto, 
        tipo: "Direccion", 
        value_1: dataText, 

    });
    setIsOpen(false)
    getAddressData()
   };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        centered={true}
        toggle={() => setIsOpen(false)}

      >
        <div className="modal-header">
          <h4 className="modal-title mt-1">
            Nuevo Dato de Contacto
          </h4>
        </div>
        <Row className="d-flex justify-content-center mt-2 mb-2">
          <Col style={{ maxWidth: "325px"}}>
          <Select

            id='tipo_Contacto'
            name='tipo_Conatcto'
            options={optionGroupLabel}
            placeholder="Seleccione una opción"
            onChange={(e) => setTipoContacto(e.value)}
            autoFocus
            required

          />         
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col style={{ maxWidth: "325px"}}>
          <Input
            type="textarea"
            placeholder="Escriba una dirección"
            value={dataText}
            onChange={(item) => setDataText(item.target.value) }
          />
          </Col>
          </Row>
          <Row className="mt-3 p-3">
            <Col className="d-flex justify-content-end">
              <Button className="text-dark btn btn-secondary"
                onClick={() => {
                  setIsOpen(false)
                }}
              >
                Cerrar
              </Button>
              <Button className="ms-1 btn btn-success"
                onClick={() => {
                  onSubmit()
                }}
              >
                Guardar
              </Button>
            </Col>
          </Row>

      </Modal>
      {confirm ? (
        <SweetAlert
          title="¿Estás seguro de que quieres eliminar este dato de contacto ?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={HandleDelete}
          onCancel={() => setConfirm(false)}
      >
      </SweetAlert>
          ) : null}
      {groups.includes("SuperAdmin") || groups.includes("Supervisor")
        || groups.includes("Agente") ? (
        <Button className="btn btn-primary waves-effect waves-light btn-sm mb-1"
          color="success"
          onClick={()=> setIsOpen(true)}
        >
          Agregar Nueva Dirección
        </Button>
      ) : null}
      <Card>
        <CardBody className="pb-0">
          <h4 className="card-title">Direcciones</h4>

          {addressData.count !== 0 ? (
            <div>
              <Table
                className="table text-center "
                hover
                responsive
              >
                <thead className="table-dark">
                  <tr>
                    <th>#</th>
                    <th>Tipo</th>
                    <th>Dirección</th>
                    {groups.includes("SuperAdmin") || groups.includes("Supervisor") ? (
                      <th>Acción</th>) : null}
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(addressData.results) ?
                    addressData.results.map((dato, idx) => (
                      <tr key={idx}>
                        <th scope="row">{idx + 1}</th>
                        <td>{dato.label}</td>
                        <td>{dato.value_1}</td>
                        <td>
                          {groups.includes("SuperAdmin") || groups.includes("Supervisor") ? (
                            <Link
                              to="#"
                            >
                              <span className="bx bx-trash bx-sm align-middle"
                                onClick={() => {setConfirm(true); setDatoId(dato.id) }  }></span>
                            </Link>
                          ) : null}
                        </td>
                      </tr>
                    )) : []}
                </tbody>

              </Table>
            </div>

          ) : (
            <CardBody>
              <h4 className="card-title">No se encontraron resultados</h4>
            </CardBody>
          )}
        </CardBody>

      </Card>

    </React.Fragment>

  )
}
export default AddressList;