import { call, put, takeEvery } from "redux-saga/effects";

import {
  CAMPAIGN_LIST,
  CAMPAIGN_FILTER,
  CAMPAIGN_CREATE,
  CAMPAIGN_UPDATE,
  CAMPAIGN_DELETE
} from "./actionTypes";

import {
 campaignListSuccess,
 campaignCreateSuccess,
 campaignUpdateSuccess,
 campaignDeleteSuccess,
 campaignRequestFailed,
} from "./actions";

import {
  getCampaignList,
  getCampaignFilter,
  postCampaignCreate,
  patchCampaignUpdate,
  deleteCampaign,
} from "../../helpers/backend";

function*campaignListSaga() {
  console.log("Trayendo lista de campaign...");
  try {
    const response = yield call(getCampaignList);
    if (response.status === 200) {
      yield put(campaignListSuccess(response.data));
    } else {
      yield put(campaignRequestFailed(response.data));
    }
  } catch (error) {
    yield put(campaignRequestFailed(error.response.data));
  }
}

function*campaignCreateSaga({ payload:campaign }) {
  console.log("Creando nuevo campaign...");
  try {
    const response = yield call(postCampaignCreate,campaign);
    if (response.status === 201) {
      yield put(campaignCreateSuccess(response.data));
    } else {
      yield put(campaignRequestFailed(response.data));
    }
  } catch (error) {
    yield put(campaignRequestFailed(error.response.data));
  }
}

function*campaignFilterSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getCampaignFilter, { changeUrl });
    if (response.status === 200) {
      yield put(campaignListSuccess(response.data));
    } else {
      yield put(campaignRequestFailed(response.data));
    }
  } catch (error) {
    yield put(campaignRequestFailed(error.response.data));
  }
}
function*campaignDeleteSaga({ payload: { id } }) {
  console.log("Borrando");
  try {
    const response = yield call(deleteCampaign, id );
    if (response.status === 204) {
      yield put(campaignDeleteSuccess(response.data));
    } else {
      yield put(campaignRequestFailed(response.data));
    }
  } catch (error) {
    yield put(campaignRequestFailed(error.response.data));
  }
}
function*campaignUpdateSaga({ payload: { id, data } }) {
  console.log("Actualizando datos de la Campana...");
  try {
    const response = yield call(patchCampaignUpdate, { id }, data);
    if (response.status === 200) {
      yield put(campaignUpdateSuccess(response.data));
    } else {
      yield put(campaignRequestFailed(response.data));
    }
  } catch (error) {
    yield put(campaignRequestFailed(error.response.data));
  }
}

export default function*campaignSaga() {
  yield takeEvery(CAMPAIGN_LIST,campaignListSaga);
  yield takeEvery(CAMPAIGN_FILTER,campaignFilterSaga);
  yield takeEvery(CAMPAIGN_CREATE,campaignCreateSaga);
  yield takeEvery(CAMPAIGN_UPDATE,campaignUpdateSaga);
  yield takeEvery(CAMPAIGN_DELETE,campaignDeleteSaga);
}
