import {
  SURVEY_LIST,
  SURVEY_FILTER,
  SURVEY_CREATE,
  SURVEY_CREATE_SUCCESS,
  SURVEY_LIST_SUCCESS,
  SURVEY_REQUEST_FAILED,
  SURVEY_UPDATE,
  SURVEY_UPDATE_SUCCESS,
} from "../../store/survey/actionTypes";

const initialState = {
  survey: [],
  surveyCreated: {},
  surveyUpdated: {},
  loading: false,
  error: "",
};

const survey = (state = initialState, action) => {
  switch (action.type) {
    case SURVEY_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SURVEY_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SURVEY_FILTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SURVEY_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SURVEY_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        surveyCreated: action.payload,
        error: "",
      };
      break;
    case SURVEY_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        survey: action.payload,
        surveyCreated: {},
        surveyUpdated: {},
        error: "",
      };
      break;
    case SURVEY_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        surveyUpdated: action.payload,
        error: "",
      };
      break;
    case SURVEY_REQUEST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default survey;
