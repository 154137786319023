import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Input, Label, Row } from 'reactstrap';
import "../Chat.css"
import { getCalificacionContactoforCampaign, getCampaignFullList, getDescripcionCalificacion, postLeadsStatusChange, putGuardarGestion } from '../../../helpers/backend';
import { useParams } from 'react-router-dom';
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory, useLocation } from 'react-router-dom';

const ManagementCreate = ({ updateManagementOpen, selectedContact, onSubmitStatus, selectedCampaign, isLead, leadId }) => {
  const contactId = useParams().id
  const location = useLocation();
  const history = useHistory();
  const [alert, setAlert] = useState(false)
  const [status, setStatus] = useState(false)
  const [message, setMessage] = useState("")
  const [campaignId, setCampaignId] = useState(null)
  const [gestionId, setGestionId] = useState(null)

  const [campaignData, setCampaignData] = useState({});
  const getCampaignData = async () => {
    const response = await getCampaignFullList();
    setCampaignData(response.data);
  };

  const [calificacionList, setCalificacionList] = useState([])
  const getCalificacionData = async (id) => {
    const response = await getCalificacionContactoforCampaign(id);
    const data = response.data;
    setCalificacionList(data);
  };
  const [descripcionList, setDescripcionList] = useState({})
  const getDescripcionData = async (id) => {
    const response = await getDescripcionCalificacion(id);
    const data = response.data;
    setDescripcionList(data);
  };

  useEffect(() => {
    if (selectedCampaign && selectedCampaign.length === 1) {
      setCampaignId(selectedCampaign[0][1])
    }
    // eslint-disable-next-line
  }, [selectedCampaign, campaignId])

  useEffect(() => {
    if (campaignId) {
      getCalificacionData(campaignId);
    }
    // eslint-disable-next-line
  }, [campaignId])
  useEffect(() => {
    getCampaignData()
    // eslint-disable-next-line
  }, [])
  const [formData, setFormData] = useState({
    calificacion: "",
    descripcion: "",
    observaciones: "",
    fecha_proximo_contacto: null,
    hora_proximo_contacto: null,
    contacto: contactId ? contactId : selectedContact,
    campaign: campaignId,
    is_leads: isLead

  });

  useEffect(() => {
    if (formData.calificacion) {
      getDescripcionData(formData.calificacion)
    }
  }, [formData.calificacion])

  const onSubmit = async () => {
    const response = await putGuardarGestion(contactId ? contactId : selectedContact, formData)
    setGestionId(response.data.gestion.id)
    setStatus(response.status)
    setAlert(true)
    setMessage(response.data.message)
    onSubmitStatus(true);
    console.log(response);
  }

  const handleManagementButtonClick = () => {
    updateManagementOpen(false);
  };
  const replaceLocation = () => {
    history.replace({
      pathname: location.pathname,
      state: {},
    });
    window.location.reload();
  }
  const FinalizarGestion = async () => {
    await postLeadsStatusChange(leadId, {
      accion: "FINALIZAR",
      gestion: gestionId
    })

  }

  return (
    <>
      {status === 200 && alert ? (
        <SweetAlert
          title="Hecho!"
          success
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            FinalizarGestion()
            replaceLocation()
          }}
        >
          {message}
        </SweetAlert>
      ) : null}
      {status !== 200 && alert ? (
        <SweetAlert
          title="Error"
          error
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            setAlert(false)
          }}
        >
          {message}
        </SweetAlert>
      ) : null}
      <Card style={{ borderTop: '5px solid #0e685f' }}>
        <div className='management-container'>
          <span className='window-close' onClick={handleManagementButtonClick}>
            <i className='fas fa-times'></i>
          </span>

          <div>
            <h4 className='p-3'><b>Tipificación</b> </h4>
          </div>
          {(Array.isArray(campaignData) && (campaignData.length > 1 || campaignData.length === 0)) ? (
            <Row className="justify-content-center">
              <Col lg={8} className="mb-3">
                <Label for="campaign" className="form-label">Campaña</Label>
                <Select
                  options={campaignData.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  onChange={(item) => {
                    setCampaignId(item.value);
                    setFormData({
                      ...formData,
                      campaign: item.value,
                    });
                  }}
                  placeholder={"Seleccione"}
                  isMulti={false}
                  menuPosition="fixed"
                />
              </Col>
            </Row>
          ) : null}

          <Row className="justify-content-center mx-1">
            <Col lg={8} className="mb-3">
              <Label
                for="calificacion"
                className="form-label"
              >
                Resultados
              </Label>
              <Select
                id="calificacion"
                name="calificacion"
                placeholder="Seleccione"
                onChange={(item) => setFormData({
                  ...formData,
                  calificacion: item.value,
                })
                }
                options={Array.isArray(calificacionList) ? calificacionList.map(item => ({ label: item.name, value: item.id })) : []}
                menuPosition="fixed"
              />
            </Col>
          </Row>
          <Row className="justify-content-center mx-1">
            <Col lg={8} className="mb-3">
              <Label
                for="rol"
                className="form-label"
              >
                Calificación
              </Label>
              <Select
                placeholder="Seleccione"
                options={Array.isArray(descripcionList) ? descripcionList.map(item => ({ label: item.name, value: item.id })) : []}
                onChange={(item) => setFormData({
                  ...formData,
                  descripcion: item.value,
                })
                }
                menuPosition="fixed"

              />
            </Col>
          </Row>
          <Row className="justify-content-center mx-1">
            <Col lg={8} className="mb-3">
              <Label
                for="observaciones"
                className="form-label"
              >
                Observaciones
              </Label>
              <Input
                id="observaciones"
                name="observaciones"
                type="textarea"
                onChange={(e) => setFormData({ ...formData, observaciones: e.target.value })}
              />
            </Col>
          </Row>
          <p className='p-1 bg-secondary'> <b className='ms-1'>Próximo Contacto (Opcional)</b></p>
          <Row className="justify-content-center">
            <Col lg={8} className="mb-3">
              <Label for="date" className="form-label">Fecha</Label>
              <Input
                id="date"
                name="date"
                type="date"
                onChange={(e) => {
                  setFormData({ ...formData, fecha_proximo_contacto: e.target.value });
                }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={8} className="mb-3">
              <Label for="time" className="form-label">Hora</Label>
              <Input
                id="time"
                name="time"
                type="time"
                onChange={(e) => {
                  // Para el campo de tiempo, ya está en el formato correcto según el mensaje de error
                  setFormData({ ...formData, hora_proximo_contacto: e.target.value });
                }}
              />
            </Col>
          </Row>
          <Row className="col-lg-12 justify-content-center">
            <Col md={1} className="mx-3">
              <Button color="primary" onClick={onSubmit} >
                Guardar
              </Button>
            </Col>
          </Row>
        </div>
      </Card>
    </>);
};
export default ManagementCreate;
