import React, { useEffect, useState } from "react";
import { Card, Table } from "reactstrap";
import { getReportStatus, postLeadsStatusChange } from "../../../helpers/backend";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ManagementManual from "../../Whatsapp/components/ManagementManual";
import whatsapp from "../../../assets/images/whatsapp.png";
import ModalManagementDetails from "../../Whatsapp/components/ModalManagementDetails";
import "./DashboardStyles.css"

const ContactsInManagement = () => {
  const history = useHistory();
  const [dataList, setDataList] = useState([]);
  const [manualOpen, setManualOpen] = useState(false);
  const [contactId, setContactId] = useState(null);
  const [detallesModal, setDetallesModal] = useState(false);
  const [GestionDetallada, setGestionDetallada] = useState(false);

  const getData = async () => {
    const response = await getReportStatus();
    console.log(response);
    setDataList(response.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleStatusChange = async (lead) => {
    const response = await postLeadsStatusChange(lead, {
      accion: "INICIAR",
    });
    console.log(response);
  };

  const handleDetallesModal = () => {
    setDetallesModal(false);
  };

  const StatusBadge = ({ estado }) => {
    const estadoConfig = {
      FINALIZADO: { text: "Finalizado", className: "bg-success" },
      PENDIENTE: { text: "Pendiente", className: "bg-danger" },
      SIN_ASIGNAR: { text: "Sin Asignar", className: "bg-warning" },
      "EN GESTION": { text: "En Gestión", className: "", style: { background: "rgb(111, 66, 193)" } },
    };
    const config = estadoConfig[estado] || {};
    return (
      <span className={`badge ${config.className}`} style={config.style}>
        {config.text}
      </span>
    );
  };

  return (
    <Card
      className="px-4 py-3"
      style={{
        backgroundColor: "#f6f8fc",
        borderColor: "#f6f8fc",
        boxShadow: "0 4px 10px -1px rgb(0 0 0 / 0.1), 0 2px 7px -2px rgb(0 0 0 / 0.1)",
      }}
    >
      {manualOpen && <ManagementManual isLead={true} isOpen={manualOpen} onClose={() => setManualOpen(false)} contactId={contactId} />}
      {detallesModal && (
        <ModalManagementDetails isOpen={detallesModal} onCloseClick={handleDetallesModal} GestionDetallada={GestionDetallada} />
      )}
      <div>
        <p className="fw-semibold font-size-18">Contactos por finalizar</p>
        <div className="table-wrapper custom-scroll" style={{ maxHeight: "400px", overflowY: "auto"}}>
          <Table className="table mb-4 text-center table-sm font-size-13" hover responsive>
            <thead className="table-light">
              <tr>
                <th>#</th>
                <th>Nombre</th>
                <th>Estado</th>
                <th>Teléfono</th>
                <th>Última Gestión</th>
                <th>Campaña</th>
                <th>Agente</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody className="align-middle">
              {Array.isArray(dataList)
                ? dataList.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <b>{index + 1}</b>
                      </td>
                      <td>{item.get_full_name}</td>
                      <td>
                        <StatusBadge estado={item.statusDescripcion} />
                      </td>
                      <td>{item.phone && item.phone[0]?.telefono}</td>
                      <td>
                        <Link
                          to="#"
                          onClick={() => {
                            setDetallesModal(true);
                            setGestionDetallada(item.ultima_gestion);
                          }}
                        >
                          {item.ultima_gestion.created_date}
                        </Link>
                      </td>
                      <td>{item.campaign ? item.campaign.map((subItem) => subItem[0]).join(", ") : null}</td>
                      <td>{item.agent && item.agent[0] ? item.agent[0][0] : null}</td>
                      <td>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            window.focus();
                            history.push({
                              pathname: "/chat",
                              state: {
                                selectedChatId: item.phone && item.phone[0]?.telefono,
                                contactId: item.id && item.id,
                                type: "WhatsApp",
                              },
                            });
                            handleStatusChange(item.id);
                          }}
                        >
                          <img className="chat-item__img" src={whatsapp} alt="Avatar" />
                        </span>{" "}
                        <Link
                          to="#"
                          onClick={() => {
                            setManualOpen(true);
                            setContactId(item.id);
                          }}
                        >
                          <span className="fa fa-phone fa-md align-middle" style={{ fontSize: "20px" }}></span>
                        </Link>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
        </div>
      </div>
    </Card>
  );
};

export default ContactsInManagement;
