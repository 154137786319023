import { useEffect, useState } from 'react'
import { TbMessage, TbEye, TbArrowGuide } from "react-icons/tb";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer
} from "recharts";

const CorreosAbiertos = () => {
  const [selectedRange, setSelectedRange] = useState('days');
  const [data, setData] = useState([]);

  const currentDate = new Date();
  const months = [
    'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto',
    'septiembre', 'octubre', 'noviembre', 'diciembre'
  ];
  const day = currentDate.getDate();
  const month = months[currentDate.getMonth()];
  const year = currentDate.getFullYear();
  const formattedDate = `${day} de ${month} de ${year}`;

  const getReportData = (range) => {
    if (range === 'days') {
      setData([]);
    } else if (range === 'hours') {
      setData([]);
    }
  };

  useEffect(() => {
    getReportData(selectedRange); 
  }, [selectedRange]);

  return (
    <section>
      <h3 style={{ paddingBottom: "1rem" }}>Correos abiertos</h3>
      <div style={{ display: "grid", gap: "4rem" }}>
        <article className="content_stats_email_open">
          <div className="content_estadisticas_totales position_stats">
            <TbMessage size={28} strokeWidth={2.5} />
            <h6>Receptor</h6>
            <p className="p_email_open">0</p>
          </div>
          <div className="content_estadisticas_totales position_stats">
            <TbEye size={28} strokeWidth={2.5} />
            <h6>Total de aperturas</h6>
            <p className="p_email_open">0</p>
          </div>
          <div className="content_estadisticas_totales position_stats">
            <TbArrowGuide size={28} strokeWidth={2.5} />
            <h6>Índice de aperturas</h6>
            <p className="p_email_open">-</p>
          </div>
        </article>

        <article>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <h3>Estadísticas: {formattedDate}</h3>
            </div>
            <div>
              <button
                onClick={() => setSelectedRange("days")}
                className={`left_rage ${
                  selectedRange === "days"
                    ? "button_range_active"
                    : "button_range_date"
                }`}
              >
                Días
              </button>
              <button
                onClick={() => setSelectedRange("hours")}
                className={`right_rage ${
                  selectedRange === "hours"
                    ? "button_range_active"
                    : "button_range_date"
                }`}
              >
                Horas
              </button>
            </div>
          </div>

          <div style={{ width: "100%", height: 300, paddingTop: "2rem", position:"relative" }}>
            <ResponsiveContainer width="100%" height="100%">
            <BarChart data={data} margin={{ top: 10, right: 35, bottom: 0, left: -20 }}>
                <XAxis dataKey="timeSlot" />
                <YAxis />
                <Tooltip />
                <Legend />
                <CartesianGrid strokeDasharray="3 3" />
                <Bar dataKey="vistas" fill="#e0324b" barSize={10}/>
              </BarChart>
            </ResponsiveContainer>
            {data.length === 0 && (
              <div 
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: '#888',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  padding: '10px',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  borderRadius: '5px',
                }}
              >
                <p>Sin actividad durante este periodo</p>
              </div>
            )}
          </div>
        </article>
      </div>
    </section>
  );
}

export default CorreosAbiertos