import { call, put, takeEvery } from "redux-saga/effects";

import {
  CONTACTS_DATA_LIST,
  CONTACTS_DATA_FILTER,
  CONTACTS_DATA_CREATE,
  CONTACTS_DATA_UPDATE,
  CONTACTS_DATA_DELETE
} from "./actionTypes";

import {
 contactsDataListSuccess,
 contactsDataCreateSuccess,
 contactsDataUpdateSuccess,
 contactsDataDeleteSuccess,
 contactsDataRequestFailed,
} from "./actions";

import {
  getContactsDataList,
  getContactsDataFilter,
  postContactsDataCreate,
  patchContactsDataUpdate,
  deleteContactsData,
} from "../../helpers/backend";

function*contactsDataListSaga() {
  console.log("Trayendo lista de contactsData...");
  try {
    const response = yield call(getContactsDataList);
    if (response.status === 200) {
      yield put(contactsDataListSuccess(response.data));
    } else {
      yield put(contactsDataRequestFailed(response.data));
    }
  } catch (error) {
    yield put(contactsDataRequestFailed(error.response.data));
  }
}

function*contactsDataCreateSaga({ payload:contactsData }) {
  console.log("Creando nuevo contactsData...");
  try {
    const response = yield call(postContactsDataCreate,contactsData);
    if (response.status === 201) {
      yield put(contactsDataCreateSuccess(response.data));
    } else {
      yield put(contactsDataRequestFailed(response.data));
    }
  } catch (error) {
    yield put(contactsDataRequestFailed(error.response.data));
  }
}

function*contactsDataFilterSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getContactsDataFilter, { changeUrl });
    if (response.status === 200) {
      yield put(contactsDataListSuccess(response.data));
    } else {
      yield put(contactsDataRequestFailed(response.data));
    }
  } catch (error) {
    yield put(contactsDataRequestFailed(error.response.data));
  }
}

function*contactsDataUpdateSaga({ payload: { id, data } }) {
  console.log("Actualizando datos del CONTACTS_DATA...");
  try {
    const response = yield call(patchContactsDataUpdate, { id }, data);
    if (response.status === 200) {
      yield put(contactsDataUpdateSuccess(response.data));
    } else {
      yield put(contactsDataRequestFailed(response.data));
    }
  } catch (error) {
    yield put(contactsDataRequestFailed(error.response.data));
  }
}

function*contactsDataDeleteSaga({ payload: { id } }) {
  console.log("Actualizando datos del Contacto...");
  try {
    const response = yield call(deleteContactsData, id );
    if (response.status === 204) {
      yield put(contactsDataDeleteSuccess(response.data));
    } else {
      yield put(contactsDataRequestFailed(response.data));
    }
  } catch (error) {
    yield put(contactsDataRequestFailed(error.response.data));
  }
}

export default function*contactsDataSaga() {
  yield takeEvery(CONTACTS_DATA_LIST,contactsDataListSaga);
  yield takeEvery(CONTACTS_DATA_FILTER,contactsDataFilterSaga);
  yield takeEvery(CONTACTS_DATA_CREATE,contactsDataCreateSaga);
  yield takeEvery(CONTACTS_DATA_UPDATE,contactsDataUpdateSaga);
  yield takeEvery(CONTACTS_DATA_DELETE,contactsDataDeleteSaga);
}
