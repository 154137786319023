import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Container,Col,  Row, Form, Card, CardBody } from "reactstrap";
import styles from "./Login.module.css"
import logo from "../../assets/images/logo-tc.png"
import fondo from "../../assets/images/backgruond-blue.jpg"
// Redux
import { connect } from "react-redux";
import { withRouter} from "react-router-dom";

// actions
import { loginUser } from "../../store/actions";  
import { MetaTags } from "react-meta-tags";

function Login(props) {
    const [formData, setFormData] = useState({
        username: "",
        password: "",
      });
    
      const { username, password } = formData;
    
      const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      const onSubmit = (e) => {
        e.preventDefault();
        props.loginUser({ username, password }, props.history);
      };


  return (
    <div className="d-flex align-items-center min-vh-100 position-relative">
    <div className="position-fixed top-0 start-0 w-100 h-100 z-index--1">
      <img src={fondo} alt="" className="w-100 h-100 " />
    </div>
    <MetaTags>
          <title> TrueContact | Login </title>
    </MetaTags>
    <Container>
      <Row>
        <Col className="justify-content-center">
          <Card  style= {{borderRadius: "35px", maxWidth :"400px"}} className="mx-auto">
            <CardBody>
            <div className=' d-flex flex-row  justify-content-center p-2 '>
                <img src={logo} alt='' width="200" height="65"></img>
              </div>
              <Form onSubmit={onSubmit}>
                <h6 className="ms-3 mb-4 mt-3 "><b> Ingresa tus Datos para Continuar</b></h6>
            <input
             className={`${styles["form-control"]} mb-4 mx-5`} 
             type="text"        
             name="username"
             placeholder="Ingresa tu usuario"
             value={username}
             onChange={onChange}
             autoFocus
             required 
  
            />

            <input 
            className={`${styles["form-control"]} mb-4 mx-5 `}
            type="password"
            name="password"
            placeholder="Ingresa tu contraseña"
            value={password}
            onChange={onChange}
            required

            />            

            {props.error &&
            typeof props.error.detail === "string" ? (
            <p className={`mb-4 mx-5 font-size-13 text-danger `} > {props.error.detail}</p>
            ) : null}


            <Button 
            className={`${styles["form-control"]} mb-2 mx-5 `}
            color='primary' 
            size='lg' 
            type="submit"            
            >
             <strong>Iniciar Sesión</strong> 
            </Button>
            <p className=" mb-3 pb-lg-3 ms-5"><a className="text-muted" href="#!"> ¿Olvidaste la contraseña?</a></p>
            <p className='text-center'>¿No tienes cuenta? <a className="text-primary" href="/users/add_user2"> Crea una nueva aqui</a></p>
            <p className="text-center"><a className=" text-primary" href="/LandingPage"><b>¡Contáctanos!</b> </a></p>
            </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>

          
  );
}

const mapStateToProps = (state) => {
    const { error, loading } = state.Login;
    return { error, loading };
  };
  
  Login.propTypes = {
    error: PropTypes.any,
    loading: PropTypes.bool,
    history: PropTypes.object,
    loginUser: PropTypes.func,
  };
  
  export default withRouter(connect(mapStateToProps, { loginUser })(Login));