import {
  USER_LIST,
  USER_FILTER,
  USER_CREATE,
  USER_UPDATE,
  USER_DELETE,
  USER_CHANGE_PASSWORD,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_PERMISSION,
  USER_PERMISSION_SUCCESS,
  USER_UPDATE_SUCCESS,
  USER_DELETE_SUCCESS,
  USER_CREATE_SUCCESS,
  USER_LIST_SUCCESS,
  USER_REQUEST_FAILED,
} from "../user/actionTypes";

export const userList = () => {
  return {
    type: USER_LIST,
    payload: {},
  };
};

export const userCreate = (user) => {
  return {
    type: USER_CREATE,
    payload: user,
  };
};

export const userFilter = (changeUrl) => {
  return {
    type: USER_FILTER,
    payload: { changeUrl },
  };
};

export const userUpdate = (id, data) => {
  return {
    type: USER_UPDATE,
    payload: { id, data },
  };
};

export const userChangePassword = (id, data) => {
  return {
    type: USER_CHANGE_PASSWORD,
    payload: { id, data },
  };
};
export const userDelete = (id) => {
  return {
    type: USER_DELETE,
    payload: { id },
  };
};


export const userPermission = (permission) => {
  return {
    type: USER_PERMISSION,
    payload: permission,
  };
};

export const userListSuccess = (user) => {
  return {
    type: USER_LIST_SUCCESS,
    payload: user,
  };
};

export const userCreateSuccess = (userCreated) => {
  return {
    type: USER_CREATE_SUCCESS,
    payload: userCreated,
  };
};

export const userUpdateSuccess = (user) => {
  return {
    type: USER_UPDATE_SUCCESS,
    payload: user,
  };
};
export const userChangePasswordSuccess = (user) => {
  return {
    type: USER_CHANGE_PASSWORD_SUCCESS,
    payload: user,
  };
};
export const userDeleteSuccess = (userDeleted) => {
  return {
    type: USER_DELETE_SUCCESS,
    payload: userDeleted,
  };
};

export const userPermissionSuccess = (permissionAssigned) => {
  return {
    type: USER_PERMISSION_SUCCESS,
    payload: permissionAssigned,
  };
};
export const userRequestFailed = (error) => {
  return {
    type: USER_REQUEST_FAILED,
    payload: error,
  };
};

