import React from 'react'
import LogoLoading from '../../assets/images/logo-corto.png'
import './styles/LoadingHome.css'


const LoadingHome = () => {
  return (
    <main className="content_loading_home">
      <div className="loading">
        <div className="loading_mask"></div>
        <img className="img_loading" src={LogoLoading} alt="" />
      </div>
    </main>
  );
}

export default LoadingHome