import React from 'react'
import ListaLlantas from './ListaLlantas'

const Llantas = ({ searchProducto, listType }) => {
  return (
    <div className='content_list_llantas'>
      <ListaLlantas listType={listType} searchProducto={searchProducto}/>
    </div>
  )
}

export default Llantas