import React, { useState, useEffect} from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  Row,
  Table,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert"
import Select from "react-select";
import avatarHombre from "../../../assets/images/avatarHombre.jpg"
import avatarMujer from "../../../assets/images/avatarMujer.jpg"
import companyAvatar from "../../../assets/images/companyAvatar.jpg"
import styles from "../Contacts.module.scss"
import { connect, useDispatch} from "react-redux";
import { useParams, withRouter, Link } from "react-router-dom";
import { contactsUpdate } from "../../../store/contacts/actions";
import { getContactsFullList, getContactsRead, postRelacion } from "../../../helpers/backend";

const CompanyDetails = (props) => {
const param = useParams();
const dispatch= useDispatch();
const str = param.id; 
const contactoId = Number(str);
const user = JSON.parse(localStorage.getItem("user"));
const groups =String(user.groups);

const parentescoOptions = [
  { value: "Socio", label: "Socio" },
  { value: "Padre", label: "Padre" },
  { value: "Hijo", label: "Hijo" },
  { value: "Hermano", label: "Hermano" },
  { value: "Tio", label: "Tio" },
  { value: "Cuñado", label: "Cuñado" },
  { value: "Primo", label: "Primo" },
  { value: "Amigo", label: "Amigo" },
];

const [selectedParentesco, setSelectedParentesco] = useState('')
const [selectedContacto, setSelectedContacto] = useState(null)
const [contactsName, setContactsName] = useState("")
const [datosIngresados, setDatosIngresados] = useState([]);
const [showAlert, setShowAlert] = useState(false)
const [contactRead, setContactRead] = useState ({});
const [clear, setClear] = useState(true)
const [open, setOpen] = useState(false)
 
const handleParentescoChange = (item) => {
  setSelectedParentesco(item.value);
};
const handleContactsChange = (item) => {
  setSelectedContacto(item.value);
  setContactsName(item.label)
};

const agregarDatos = () => {

  const nuevoDato = {
    tipo_relacion: selectedParentesco,
    destinatario : selectedContacto,
    contactsName: contactsName
  };
  setDatosIngresados([...datosIngresados, nuevoDato]);
};

const eliminarDato = (index) => {
  const nuevosDatos = [...datosIngresados];
  nuevosDatos.splice(index, 1);
  setDatosIngresados(nuevosDatos);
};
   
const  [contactList, setContactList] = useState ({});
const getContactsData = async () => {
  const response = await getContactsFullList();
  const data = response.data;
  setContactList(data)
};
    const getData = async (contactoId) => {
      const response = await getContactsRead(contactoId);
      const data = response.data;
      setFormData({
        razon_social: data.razon_social,
        actividad: data.empresa.actividad,
        documentType: data.documentType,
        documentNumber: data.documentNumber,
        web_site:data.empresa.web_site,
        persona_contacto: data.empresa.persona_contacto,
        relaciones: data.relaciones,
      });
    };
     
    const [formData, setFormData] = useState({
      razon_social:"",
      actividad:"",
      documentType:"",
      documentNumber:"",
      web_site:"",
      persona_contacto:[],
      relaciones: []
 });
 const {  
  razon_social, 
  actividad, 
  documentType,  
  documentNumber, 
  web_site,
  persona_contacto, 
  relaciones
} =
   formData;
  
  const [selectedPersonaContacto, setSelectedPersonaContacto] = useState([]);
  const handlePersonaContactoChange = (selectedItems) => {
    setSelectedPersonaContacto(selectedItems);
  };

 const onChange = (e) => {
   setFormData({ ...formData, 
    [e.target.name]: e.target.value });
 };

 const onSubmit = (e) => {
  e.preventDefault();
  dispatch(contactsUpdate(contactoId, formData));
  setShowAlert(true);
};

useEffect(() => {
  getData(contactoId);
  getContactsData();

  // El siguiente bloque de código solo se ejecutará cuando se monte el componente
  if (!selectedPersonaContacto.length) {
    // Asignar las personas de contacto seleccionadas al iniciar
    const selectedContactos = persona_contacto.map((contacto) => ({
      label: contacto.get_full_name,
      value: contacto.id,
    }));
    setSelectedPersonaContacto(selectedContactos);
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [contactoId, selectedPersonaContacto]);

const guardarParentesco = (e) =>{
  e.preventDefault();
  setClear(false)
  postRelacion(contactoId, datosIngresados)
  setShowAlert(true)
  setDatosIngresados("")
  }

    const verContacto = async (id) => {
      const response = await getContactsRead(id);
      const data = response.data;
      setContactRead(data)
      setOpen(true)
    };


  return (
    <React.Fragment>      
         <Row className="justify-content-center">
           <Card className="col-lg-10"> 
              {props.contactsUpdated.id && showAlert ? (
                <SweetAlert
                  title="Hecho!"
                  success                  
                  showConfirm={false}
                  timeout={2000}
                  onConfirm={() =>{
                    setShowAlert(false)
                  }}
                  
                >
                  Se ha editado el usuario con éxito 
                </SweetAlert>
              ) : null}
              { showAlert ? (
                <SweetAlert
                  title="Hecho!"
                  success                  
                  showConfirm={false}
                  timeout={2000}
                  onConfirm={() =>{
                    getData(contactoId)
                    setClear(true)                    
                    setShowAlert(false)
                  
                  }}
                  
                >
                  Se ha agregado el parentesco 
                </SweetAlert>
              ) : null}
          <Modal
            isOpen={open}
            centered={true}
            toggle={() => setOpen(false)}
            size="lg"
          >
            <h2 className="mt-4 ms-4 text-dark">
              <b>Contacto</b>{" "}
            </h2>
            <Row className="justify-content-center mt-2 mx-5 mb-5">
              <Col lg={4}>
                <div className="text-start">
                {contactRead.image ? (
                  <img src={contactRead.image} alt="" className={styles.avatar} />
                ) : contactRead.contactType && contactRead.gender === "Masculino" ? (
                  <img src={avatarHombre} alt="" className={styles.avatar} />
                ) : contactRead.contactType && contactRead.gender === "Femenino" ? (
                  <img src={avatarMujer} alt="" className={styles.avatar} />
                ) : contactRead.contactType === "EMPRESA" ? (
                  <img src={companyAvatar} alt="" className={styles.avatar} />
                ) : null}
                </div>
              </Col>
              <Col lg={8} className="text-start mt-3">
                  <h5> <b>Nombre: </b>{contactRead.first_name ? contactRead.get_full_name : contactRead.razon_social}</h5>
                  <h5> <b>Tipo de Documento: </b>{contactRead.documentType}</h5>
                  <h5> <b>Documento: </b>{contactRead.documentNumber}</h5>
                  {contactRead.contactType === "EMPRESA" ? (
                    <>
                    <h5> <b>Actividad: </b>{contactRead.empresa.actividad}</h5>
                    <h5> <b>Sitio Web: </b>{contactRead.empresa.web_site}</h5>
                    </>
                  ):
                  <h5> <b>Fecha de Nacimiento: </b>{contactRead.birthdate}</h5>
                  }
              </Col>
            </Row>
            <Row className="mb-3 mx-3">
              <Col className="text-end">
                <Button className="text-dark" onClick={() => setOpen(false)}>
                  Cerrar
                </Button>
              </Col>
            </Row>
          </Modal>
        {groups.includes("SuperAdmin") || groups.includes("Supervisor") ||  groups.includes( "Operador") || groups.includes("Crear y modificar Cliente") ? (
              <CardBody>
                <h4 className="card-title"> Detalles del Contacto </h4>

                <Form className="form-control needs-validation" onSubmit={onSubmit}>    
                  <Row>
                    <Col lg={6} className="mb-3">                    
                      <Label
                        for="razon_social"
                        className="form-label"
                      >
                        Razon Social
                      </Label>
                      <Input 
                        id="razon_social"
                        name="razon_social"
                        type="text"
                        onChange={onChange}
                        value={razon_social}
                        valid={props.error.razon_social
                          ? true
                          : props.contactsCreated && false
                        }
                        invalid={
                          props.error
                            ? props.error.razon_social
                              ? true
                              : false
                            : false
                        }
                        required
                      />
                      <FormFeedback>{props.error.razon_social}</FormFeedback>
                
                    </Col>                  
                    <Col lg={6}  className="mb-3">                    
                      <Label
                        for="actividad"
                        className="form-label"
                      >
                        Actividad
                      </Label>
                      <Input
                        id="actividad"
                        name="actividad"
                        type="text"
                        onChange={onChange}
                        value={actividad}                     
                        valid={props.error.actividad
                          ? true
                          : props.contactsCreated && false
                        }
                        invalid={
                          props.error
                            ? props.error.actividad
                              ? true
                              : false
                            : false
                        }
                        required
                      />
                      <FormFeedback>{props.error.actividad}</FormFeedback>
                     
                    </Col>
                  </Row>
                 
                  <Row>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="contactType"
                        className="form-label"
                      >
                      Tipo de identificación
                      </Label>
                      <select
                            className="form-select"
                            id="documentType"
                            name="documentType"
                            value={documentType}                            
                            onChange={onChange}
                            >
                            <option value ="CEDULA">
                            Cédula Ciudadanía
                            </option>
                            <option value ="PASAPORTE">
                            Pasaporte
                            </option>
                            <option value ="CEDULA_EXTRANJERIA">
                            Cedula de Extranjería
                            </option>
                            <option value ="Nit">
                            NIT
                            </option>
                            <option value ="OTRO">
                            Otro
                            </option>
                    </select>
                    </Col>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="documentNumber"
                        className="form-label"
                      >
                        Documento
                      </Label>
                      <Input
                        id="documentNumber"
                        name="documentNumber"
                        type="text"
                        onChange={onChange}
                        value={documentNumber}
                        valid={props.error.documentNumber
                          ? true
                          : props.contactsCreated && false
                        }
                        invalid={
                          props.error
                            ? props.error.documentNumber
                              ? true
                              : false
                            : false
                        }
                        required
                      />
                      <FormFeedback>{props.error.documentNumber}</FormFeedback>
                    </Col>
                  </Row>

                  <Row>
                  <Col lg={6} className="mb-3">
                      <Label
                        for="web_site"
                        className="form-label"
                      >
                        Sitio Web
                      </Label>
                      <Input
                        id="web_site"
                        name="web_site"
                        type="text"
                        onChange={onChange}
                        value={web_site}
                        valid={props.error.web_site
                          ? true
                          : props.contactsCreated && false
                        }
                        invalid={
                          props.error
                            ? props.error.web_site
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.web_site}</FormFeedback>
                    </Col>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="persona_contacto"
                        className="form-label"
                      >
                      Persona de Conatcto
                      </Label>
                      <Select
                        isMulti={true}
                        id="persona_contacto"
                        name="persona_contacto"
                        value={selectedPersonaContacto}
                        options={Array.isArray(contactList)
                          ? contactList.map((item) => ({
                              label: item.documentNumber + " - " + (item.first_name ? item.get_full_name : item.razon_social),
                              value: item.id,
                            }))
                          : []
                        }
                        onChange={(item) => handlePersonaContactoChange(item)}
                      />

                    </Col>
                  </Row>
                  <Row>
                    <Col>
                        <h5> <b>Relaciones: </b> </h5>
                       
                     {Array.isArray(relaciones)
                    ? relaciones.map((item, index) => (
                        <React.Fragment key={index}>
                          <Link to="#" onClick={() => verContacto(item.destinatario_id)}>
                            {item.destinatario} <b>({item.tipo_relacion})</b>
                          </Link>
                          <br /> {/* Salto de línea */}
                        </React.Fragment>
                      ))
                    : []}
                    </Col>
                  </Row>
                  <Row className="justify-content-center mt-3 mb-3">
                    <Col md={2}>
                   
                      <Button color="primary" type="submit">
                        Actualizar
                      </Button>
                    </Col>
                  </Row>     
        
                </Form>
               {/*Agregar Relaciones */}
               {clear && (
                <>
                  <div className="form-control">
                      <h5 className="bg-secondary p-2"><b>Agregar Relaciones</b> </h5>
                      <Row>
                        <Col lg={5} className="mb-3">
                          <Label for="contacts" className="form-label">
                            Contactos
                          </Label>
                          <Select                            
                            id="contacts"
                            name="contacts"
                            placeholder= "Seleccione"                            
                            options={Array.isArray(contactList)
                              ? contactList.map((item) => ({
                                  label:
                                    item.documentNumber +
                                    " - " +
                                    (item.first_name ? item.get_full_name : item.razon_social),
                                  value: item.id,
                                }))
                              : []}
                            onChange={(selectedOption) => handleContactsChange(selectedOption)}
                          />
                        </Col>
                        <Col lg={5} className="mb-3">
                          <Label for="parentesco" className="form-label">
                            Parentesco
                          </Label>
                          <Select
                            id="parentesco"
                            name="parentesco"
                            placeholder= "Seleccione"                            
                            options={parentescoOptions}
                            onChange={(selectedOption) => handleParentescoChange(selectedOption)}
                          />
                        </Col>
                        <Col lg= {2}>
                          <Link
                            to="#"
                            onClick={()=> {agregarDatos()}}>
                            <span><i className=" bx bx-lg bx-plus-circle mt-4"></i></span>
                          </Link>
                        </Col>
                      </Row>
                      <Table
                        className="mt-4 mb-3"
                        style={{
                          textAlign: "center",
                        }}
                        responsive
                        hover
                      >
                        <thead className="table-dark align-middle">
                          <tr>
                            <th>Contacto</th>
                            <th>Parentesco</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody className='align-middle'>
                          {/* Iterar sobre los datos ingresados y mostrarlos en la tabla */}
                          {datosIngresados && datosIngresados.map((dato, index) => (
                            <tr key={index}>
                              <td>{dato.contactsName}</td>
                              <td>{dato.tipo_relacion}</td>
                              <td><Button 
                                className="btn-danger btn-sm" 
                                onClick={() => eliminarDato(index)}> Eliminar</Button></td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                  </div>
                  <Row className=" justify-content-center mt-3 mb-3">
                    <Col md={2}>
                   
                      <Button color="success" onClick={guardarParentesco}>
                        Guardar
                      </Button>
                    </Col>
                  </Row> 
                </>
               )}

              </CardBody>
              ):               
              <CardBody>
                <h4 className="card-title"> Detalles del Contacto </h4>
              <Form className="form-control needs-validation">    
                <Row>
                  <Col lg={6} className="mb-3">                    
                    <Label
                      for="first_name"
                      className="form-label"
                    >
                      Razon Social
                    </Label>
                    <Input 
                      id="razon_social"
                      name="razon_social"
                      type="text"
                      onChange={onChange}
                      value={razon_social}
                      disabled
                    />

                  </Col>                  
                  <Col lg={6}  className="mb-3">                    
                    <Label
                      for="apellido"
                      className="form-label"
                    >
                      Actividad
                    </Label>
                    <Input
                      id="actividad"
                      name="actividad"
                      type="text"
                      onChange={onChange}
                      value={actividad}   
                      disabled     
                    />
                  </Col>
                </Row>
              
                <Row>
                  <Col lg={6} className="mb-3">
                    <Label
                      for="tipo_contacto"
                      className="form-label"
                    >
                    Tipo de identificación
                    </Label>
                    <select
                          className="form-select"
                          id="documentType"
                          name="documentType"
                          value={documentType}                            
                          onChange={onChange}
                          disabled
                          >
                          <option value ="CEDULA">
                          Cédula Ciudadanía
                          </option>
                          <option value ="PASAPORTE">
                          Pasaporte
                          </option>
                          <option value ="CEDULA_EXTRANJERIA">
                          Cedula de Extranjería
                          </option>
                          <option value ="Nit">
                          NIT
                          </option>
                          <option value ="OTRO">
                          Otro
                          </option>
                  </select>
                  </Col> 
                  <Col lg={6} className="mb-3">
                    <Label
                      for="documentNumber"
                      className="form-label"
                    >
                      Documento
                    </Label>
                    <Input
                      id="documentNumber"
                      name="documentNumber"
                      type="text"
                      onChange={onChange}
                      value={documentNumber}
                      disabled

                    />
                  </Col>
                </Row>
                <Row>
                <Col lg={6} className="mb-3">
                    <Label
                      for="birthdate"
                      className="form-label"
                    >
                      Sitio Web
                    </Label>
                    <Input
                      id="web_site"
                      name="web_site"
                      type="text"
                      onChange={onChange}
                      value={web_site}
                      disabled
                    />

                  </Col>
                  <Col lg={6} className="mb-3">
                    <Label
                      for="persona_contacto"
                      className="form-label"
                    >
                    Persona de Contacto
                    </Label>
                    <select
                          className="form-select"
                          id="persona_contacto"
                          name="persona_contacto"
                          value={persona_contacto}                            
                          onChange={onChange}
                          disabled
                          >
                          <option value ="Masculino">
                          Masculino
                          </option>
                          <option value ="Femenino">
                          Femenino
                          </option>
                          <option value ="Otro">
                          Otro
                          </option>
                  </select>
                  </Col>
                </Row>

              </Form>
              </CardBody>
 }  
            </Card>
          </Row>

    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, contacts, contactsUpdated } = state.Contacts;
  return { error, loading, contacts, contactsUpdated };
};
contactsUpdate.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  contactsUpdated: PropTypes.object,
};
export default withRouter(connect(mapStateToProps,{ contactsUpdate })(CompanyDetails));