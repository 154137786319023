import React, { useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Col,
  Container,
  Button,
  Table,
  Row,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import excelLogo from "../../assets/images/excel.png"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader"
import { postPdfMasive } from "../../helpers/backend";

const ExcelMasive = (props) => {

  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(null)
  const [alert, setAlert] = useState(false)
  const [data, setData] = useState("")

  const postExcel = async () => {
    const formData = new FormData();
    formData.append('file', selectedFile);
    const response = await postPdfMasive(formData)
    setLoading(false)
    setStatus(response.status)
    setData(response.data)
    setLoading(false)
    setAlert(true)   
  };
  return (
  <React.Fragment>
    <div className="page-content">
      <MetaTags>
        <title> TrueContact | Mensajería Masiva</title>
      </MetaTags>
      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="TrueContact" breadcrumbItem=" Mensajería Masiva" />
        {loading ?
          <Loader />
        :null}
      {status === 200 && alert  ? (
      <SweetAlert
        title="Hecho!"
        success
        timeout={2000}
        showConfirm={false}
        onConfirm={() => {
          setAlert(false)
          setSelectedFile("")
        }}
      >{ data.message}
      </SweetAlert>
    ) : null}
      {status !== 200 && alert  ? (
      <SweetAlert
        title="Error"
        danger
        timeout={2000}
        showConfirm={false}
        onConfirm={() => {
          setAlert(false)
        }}
      > {data.error}
      </SweetAlert>
    ) : null}
        {props.loading ? (
          <Loader />
        ) : (
        <div>
        <Col className="d-flex align-items-center">
          <div className="d-flex flex-column">
            <input
              type="file"
              id="excelFile"
              accept=".xls, .xlsx"
              style={{ display: 'none' }}
              onChange={(event) => setSelectedFile(event.target.files[0])}
            />
            <Button
              color="light"
              className="mb-1"
              onClick={() => {
                document.getElementById('excelFile').click();
              }}
            >
              <span>
                <img className="me-1" alt="" src={excelLogo} height="25px" />
              </span>
              Importar Excel
            </Button>
            {selectedFile && (
              <p className="font-size-10 ms-3 mb-1">
                Archivo seleccionado: {selectedFile.name}
              </p>
            )}
          </div>
          {selectedFile && (
            <>
              <Button
                color="success"
                className="mb-1 ms-2"
                onClick={()=> { setLoading(true) ; postExcel()}}
              >
                Generar
              </Button>
              <Button
                color="danger"
                className="mb-1 ms-2"
                onClick={() => {
                  window.location.reload();
                  setSelectedFile(null);
                }}
              >
                Borrar
              </Button>
            </>
          )}
        </Col>
        {data ?
        <Row className="mt-3 d-flex justify-content-center">
          <Col lg={4}>
            <Card className="mb-3">
              <CardBody>
                <CardTitle className="card-title mb-1">Generados</CardTitle>
                <div className="table-responsive" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                  <Table className="table table-sm text-center mb-3" hover>
                    <thead className="table-dark align-middle">
                      <tr>
                        <th>#</th>
                        <th>Nombres</th>
                        <th>Telefono</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.enviados &&
                        data.enviados.map((generated, idx) => (
                          <tr key={idx}>
                            <th scope="row">{idx + 1}</th>
                            <td>{generated.nombre}</td>
                            <td>{generated.telefono}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className="mb-3">
              <CardBody>
                <CardTitle className="card-title mb-1">No Generados</CardTitle>
                <div className="table-responsive" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                  <Table className="table-sm text-center mb-3" hover>
                    <thead className="table-dark align-middle">
                      <tr>
                        <th>#</th>
                        <th>Nombres</th>
                        <th>Telefono</th>
                        <th>Error</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.no_enviados &&
                        data.no_enviados.map((notGenerated, idx) => (
                          <tr key={idx}>
                            <th scope="row">{idx + 1}</th>
                            <td>{notGenerated.nombre}</td>
                            <td>{notGenerated.telefono}</td>
                            <td>{notGenerated.error}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        :null}
        </div>
        )}
      </Container>
    </div>
  </React.Fragment>
  )
}



export default ExcelMasive;
