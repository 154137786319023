import React from 'react'
import CardCart from './CardCart'

const ListCart = () => {
  return (
    <div className='list_cart'>
      <CardCart />
    </div>
  )
}

export default ListCart