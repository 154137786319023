import {
  CONTACTS_DATA_LIST,
  CONTACTS_DATA_FILTER,
  CONTACTS_DATA_CREATE,
  CONTACTS_DATA_UPDATE,
  CONTACTS_DATA_DELETE,
  CONTACTS_DATA_UPDATE_SUCCESS,
  CONTACTS_DATA_DELETE_SUCCESS,
  CONTACTS_DATA_CREATE_SUCCESS,
  CONTACTS_DATA_LIST_SUCCESS,
  CONTACTS_DATA_REQUEST_FAILED,
} from "./actionTypes";

export const contactsDataList = () => {
  return {
    type: CONTACTS_DATA_LIST,
    payload: {},
  };
};

export const contactsDataCreate = (contactsData) => {
  return {
    type: CONTACTS_DATA_CREATE,
    payload: contactsData,
  };
};

export const contactsDataFilter = (changeUrl) => {
  return {
    type: CONTACTS_DATA_FILTER,
    payload: { changeUrl },
  };
};

export const contactsDataUpdate = (id, data) => {
  return {
    type: CONTACTS_DATA_UPDATE,
    payload: { id, data },
  };
};
export const contactsDataDelete = (id) => {
  return {
    type: CONTACTS_DATA_DELETE,
    payload: { id },
  };
};

export const contactsDataListSuccess = (contactsData) => {
  return {
    type: CONTACTS_DATA_LIST_SUCCESS,
    payload: contactsData,
  };
};

export const contactsDataCreateSuccess = (contactsDataCreated) => {
  return {
    type: CONTACTS_DATA_CREATE_SUCCESS,
    payload: contactsDataCreated,
  };
};

export const contactsDataUpdateSuccess = (contactsData) => {
  return {
    type: CONTACTS_DATA_UPDATE_SUCCESS,
    payload: contactsData,
  };
};

export const contactsDataDeleteSuccess = (contactsDataDeleted) => {
  return {
    type: CONTACTS_DATA_DELETE_SUCCESS,
    payload: contactsDataDeleted,
  };
};

export const contactsDataRequestFailed = (error) => {
  return {
    type: CONTACTS_DATA_REQUEST_FAILED,
    payload: error,
  };
};
