import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Label,
  Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader";

import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";

// Redux
import { connect, useDispatch } from "react-redux";
import { withRouter, Link, useHistory, useParams } from "react-router-dom";

//Actions
import { userList, userUpdate } from "../../store/actions";
import { rolList } from "../../store/actions";
import { getUserRead } from "../../helpers/backend";


const RolUpdate = (props) => {
    const userId = useParams().id
    const history = useHistory();
    const dispatch = useDispatch();
   // const rol = useSelector(state => state.Rol)
  
useEffect(() => {
  dispatch(rolList());
  getUserData()   
// eslint-disable-next-line react-hooks/exhaustive-deps
},[dispatch]);
 
const [userData, setUserData] = useState([]);
const getUserData = async () => {
  const response = await getUserRead(userId);
  const data = response.data;
  setUserData(data);
};

  const rol = props.rol.results;

    const  [roles, setRol] = useState(); 
  
    function handleSelectGrupos(rol) {
      setRol(rol.value);
  
    } 
  
    const updateUser = () => {
    dispatch (userUpdate( Number(userId), {
      username: userData.username,
      password: userData.password,
      confirm_password : userData.confirm_password, 
      roles,
    }))
  }
   
    const redirect = () => {
      history.push("/users");
    };
  
   return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Roles| Actualizar Rol </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={"Roles"} breadcrumbItem={"Actualizar Rol"} route= {userData.get_full_name} />
          {props.loading ? (
            <Loader />
          ) : (
            <Card className= "col-lg-7 mx-auto  mt-3">
            {props.userUpdated.id  ? (
              <SweetAlert
                title="Hecho!"
                success
                timeout={2000}
                showConfirm={false}
                onConfirm={() => {
                  redirect();
                }}
              >
                Se ha asignado el Rol con éxito
              </SweetAlert>
            ) : null}
            
            {props.error.detail ? (
              <SweetAlert
                title="Lo Sentimos,"
                error
                timeout={2000}
                showConfirm={false}
                onConfirm={() => {
                redirect();               
                }}
              >
            {props.error.detail}
              </SweetAlert>
            ) : null}

            <CardBody>
              <h4 className="card-title">Asignar Rol</h4>
              <Form className="needs-validation form-control" onSubmit={updateUser}>
                  <Row className="col-lg-12 justify-content-center">
                    <Col lg ={6} className="mb-3">
                      <Label
                        for="rol"
                        className="form-label"
                      >
                        Roles
                      </Label>
                      <Select
                        // value={selectedMulti}
                        isMulti={false}                        
                        options={rol && rol.map(item=> ({label: item.nombre, value: item.id}))}
                        classNamePrefix="select2-selection"
                        placeholder={String(userData.roles)}
                        onChange={handleSelectGrupos}
                        menuPosition="fixed"
                        
                      />
                    </Col>
                    </Row>
               
                <Row >
                  <Col className="d-flex justify-content-center mt-3">
                    <Link className="btn btn-secondary text-dark me-1" to="/users">
                      Volver
                    </Link>                    
                    <Button color="primary" type="submit" >
                      Asignar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        )}
      </Container>
    </div>
  </React.Fragment>
);
};

const mapStateToProps = (state) => {
const { error, loading, user, userUpdated } = state.User;
const { rol } = state.Rol;
return { error, loading, user, userUpdated, rol };
};


RolUpdate.propTypes = {
error: PropTypes.any,
loading: PropTypes.bool,
userUpdated: PropTypes.object,
};
export default withRouter(connect(mapStateToProps, { userList, userUpdate })(RolUpdate));
