import React, { useState, useRef, useEffect } from 'react';
import { TbDotsVertical } from 'react-icons/tb';
import Visualizador from './Visualizador';

const favoritos = [
  {
    id: 1,
    name: "Principales categorías",
    descrip: "No hay datos almacenados del período de tiempo seleccionado.",
  },
  {
    id: 2,
    name: "Principales Productos",
    descrip: "No hay datos almacenados del período de tiempo seleccionado.",
  },
  {
    id: 3,
    name: "Principales Clientes",
    descrip: "No hay datos almacenados del período de tiempo seleccionado.",
  },
  {
    id: 4,
    name: "Ventas con Cupones",
    descrip: "No hay datos almacenados del período de tiempo seleccionado.",
  },
];

const FavoritoAnalisis = () => {
  const [showVisualizador, setShowVisualizador] = useState(false);
  const [favoritosVisibles, setFavoritosVisibles] = useState([1, 2]);
  const visualizadorRef = useRef(null);

  const handleShowVisualizador = () => {
    setShowVisualizador(prev => !prev);
  };

  const handleClickOutside = (event) => {
    if (visualizadorRef.current && !visualizadorRef.current.contains(event.target)) {
      setShowVisualizador(false);
    }
  };

  useEffect(() => {
    if (showVisualizador) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showVisualizador]);

  const toggleVisibility = (id) => {
    setFavoritosVisibles((prevState) =>
      prevState.includes(id)
        ? prevState.filter(item => item !== id)
        : [...prevState, id]
    );
  };

  return (
    <section>
      <div className="content_rendimiento">
        <div>
          <h5>Favoritos</h5>
        </div>
        <div className="content_dots_vertical" onClick={handleShowVisualizador}>
          <TbDotsVertical size={26} strokeWidth={2.5} />
        </div>

        {showVisualizador && (
          <Visualizador
            datos={favoritos}
            toggleVisibility={toggleVisibility}
            showVisualizador={showVisualizador}
            visibleStats={favoritosVisibles}
            referencia={visualizadorRef}
          />
        )}
      </div>

      <article className="content_graficos_analisis">
        {favoritos.map(
          (fav) =>
            favoritosVisibles.includes(fav.id) && (
              <div key={fav.id} className="content_favorito_analisis">
                <h5>{fav.name} - Artículos vendidos</h5>
                <div className="content_info_fav">
                  <p>{fav.descrip}</p>
                </div>
              </div>
            )
        )}
      </article>
    </section>
  );
};

export default FavoritoAnalisis;
