import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { TIPO_GESTION_CONTACTO_LIST,TIPO_GESTION_CONTACTO_LISTA,TIPO_GESTION_CONTACTO_ADD, TIPO_GESTION_CONTACTO_LIST_CHANGE } from "./actionTypes";
import {
  tipoGestionContactoListSuccess,
  tipoGestionContactoListFailed,
  tipoGestionContactoListaSuccess,
  tipoGestionContactoListaFailed,
  tipoGestionContactoAddSuccess,
  tipoGestionContactoAddFailed,


} from "./actions";

import {
  getTipoGestionContactoList,
  getTipoGestionContactoFullList,
  getChangeTipoGestionContactoList,
  postTipoGestionContactoAdd,

} from "../../helpers/backend";

function* tipoGestionContactoListChangeSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getChangeTipoGestionContactoList, { changeUrl });

    if (response.status === 200) {
      yield put(tipoGestionContactoListSuccess(response.data));
    } else {
      yield put(tipoGestionContactoListFailed(response.data));
    }
  } catch (error) {
    yield put(tipoGestionContactoListFailed(error.response.data));
  }
}
function* tipoGestionContactoListSaga() {
  try {
    const response = yield call(getTipoGestionContactoList);

    if (response.status === 200) {
      yield put(tipoGestionContactoListSuccess(response.data));
    } else {
      yield put(tipoGestionContactoListFailed(response.data));
    }
  } catch (error) {
    yield put(tipoGestionContactoListFailed(error.response.data));
  }
}

function* tipoGestionContactoListaSaga() {
  try {
    const response = yield call(getTipoGestionContactoFullList);

    if (response.status === 200) {
      yield put(tipoGestionContactoListaSuccess(response.data));
    } else {
      yield put(tipoGestionContactoListaFailed(response.data));
    }
  } catch (error) {
    yield put(tipoGestionContactoListaFailed(error.response.data));
  }
}

function* tipoGestionContactoAddSaga({ payload: { tipoGestionContacto } }) {
  try {
    const response = yield call(postTipoGestionContactoAdd, {
      name: tipoGestionContacto.name,

    });

    if (response.status === 201) {
      yield put(tipoGestionContactoAddSuccess(response.data));
    } else {
      yield put(tipoGestionContactoAddFailed(response.data));
    }
  } catch (error) {
    yield put(tipoGestionContactoAddFailed(error.response.data));
  }
}



function* tipoGestionContactoSaga() {
  yield takeEvery(TIPO_GESTION_CONTACTO_LIST, tipoGestionContactoListSaga);
  yield takeEvery(TIPO_GESTION_CONTACTO_LISTA, tipoGestionContactoListaSaga);
  yield takeEvery(TIPO_GESTION_CONTACTO_ADD, tipoGestionContactoAddSaga);
  yield takeEvery(TIPO_GESTION_CONTACTO_LIST_CHANGE, tipoGestionContactoListChangeSaga);

}
  
export default tipoGestionContactoSaga;
