import React from 'react'
import { TbFileUpload } from "react-icons/tb";

const EnviarExcel = ({ selectedFile, postExcel, setSelectedFile }) => {
  return (
    <section className="modal_llantas">
      <div className="open_modal_llantas">
        <div
          className="modal_content_llantas animation_modal"
          style={{ width: "350px", height: "350px", display:"grid", alignContent:"center", placeContent:"center", gap:"2rem" }}
        >
          {selectedFile && (
            <div className="content_info_excel">
              <h6 className="title_generar_excel">Archivo seleccionado:</h6>
              <p className="name_excel">{selectedFile.name}</p>
              <TbFileUpload size={45} />
            </div>
          )}
          {selectedFile && (
            <div className="content_buttons_excel">
              <button
                className="button_enviar_excel"
                onClick={() => {
                  postExcel();
                  setSelectedFile(null);
                }}
              >
                Generar
              </button>
              <button
                className="button_cancelar_excel"
                onClick={() => {
                  setSelectedFile(null);
                }}
              >
                Borrar
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default EnviarExcel