import {
  LOGIN_USER,
  ME,
  LOGOUT_USER,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  AUTH_REQUEST_FAILED,
} from "../auth/actionTypes";

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  };
};

export const me = (history) => {;
  return {
    type: ME,
    payload: { history },
  };
};

export const loginSuccess = (userData) => {
  return {
    type: LOGIN_SUCCESS,
    payload: userData,
  };
};

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutSuccess = () => {
  return {
    type: LOGOUT_SUCCESS,
    payload: {},
  };
};

export const authRequestFailed = (error) => {
  return {
    type: AUTH_REQUEST_FAILED,
    payload: error,
  };
};
