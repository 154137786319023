import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Label, Modal, Table, Row, Form } from 'reactstrap';
import { getAgent, getCampaignFullList, getContactsFullList, postContactsCreate, postLeadsCreate } from '../../../helpers/backend';
import Select from "react-select";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import SweetAlert from "react-bootstrap-sweetalert";

const ManualProgrammingForm = ({ isOpen, onClose, refresh }) => {
  const [leads, setLeads] = useState([]);
  const [newLead, setNewLead] = useState({
    contact: null,
    campaign: null,
    agent: [],
    observaciones: "",
    fecha_vencimiento: ""
  });
  const [alert, setAlert] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [agents, setAgents] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [showNewContactFields, setShowNewContactFields] = useState(false);
  const [status, setStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [errorRequired, setErrorRequired] = useState(false);

  const toggleNewContactFields = () => setShowNewContactFields(!showNewContactFields);


  const handleSaveNewContact = async (e) => {
    e.preventDefault();
    if (newLead.phone) {
      const response = await postContactsCreate({
        first_name: newLead.nombre,
        last_name: newLead.apellido,
        email: newLead.email,
        phone: newLead.phone,
      });
      setStatus(response.status);
      setMessage(response.data.message);
      if (response.status === 201) {
        const newContact = response.data;
        const newContactOption = {
          label: `${newContact.phone[0].telefono || ''} - ${newContact.get_full_name}`,
          value: newContact.id
        };

        setContacts([...contacts, newContact]);
        setNewLead({
          ...newLead,
          contact: newContactOption
        });

        setAlert(true);
      }
    } else {
      setErrorRequired(true);
    }
  };


  const getContactsData = async () => {
    const response = await getContactsFullList();
    setContacts(response.data);
  };

  const getAgentData = async () => {
    const response = await getAgent();
    setAgents(response.data);
  };

  const getCampaignData = async () => {
    const response = await getCampaignFullList();
    setCampaignData(response.data);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewLead({
      ...newLead,
      [name]: value
    });
  };

  const handleSelectChange = (name, selectedOption) => {
    if (name === 'agent') {
      setNewLead({
        ...newLead,
        [name]: selectedOption ? [selectedOption] : []
      });
    } else {
      setNewLead({
        ...newLead,
        [name]: selectedOption
      });
    }
  };

  useEffect(() => {
    getContactsData();
    getAgentData();
    getCampaignData();
  }, []);

  const addLead = () => {
    const leadToAdd = {
      contact: newLead.contact ? newLead.contact.value : null,
      campaign: newLead.campaign ? newLead.campaign.value : null,
      agent: newLead.agent.length > 0 ? [newLead.agent[0].value] : [],
      observaciones: newLead.observaciones,
      fecha_vencimiento: newLead.fecha_vencimiento
    };
    setLeads([...leads, leadToAdd]);
    setNewLead({
      contact: null,
      campaign: null,
      agent: [],
      observaciones: "",
      fecha_vencimiento: ""
    });
  };

  const saveLeads = async () => {
    const response = await postLeadsCreate({
      leads: leads
    });
    console.log(response);
    console.log('Leads:', leads);
    refresh();
    onClose()
  };

  return (
    <Modal size='xl' isOpen={isOpen} toggle={onClose}>

      <div className='p-3'>
        <h4>Programación Manual</h4>
        <div>
          <Row className='d-flex justify-content-center'>
            <Col lg={10} >
              <Label for="contact" className="form-label">Contacto</Label>
              <div className="d-flex">
                <Select
                  isDisabled={showNewContactFields}
                  placeholder="Seleccione"
                  value={newLead.contact}  // Asegúrate de que `newLead.contact` sea un objeto con `label` y `value`
                  onChange={(item) => handleSelectChange('contact', item)}
                  options={contacts.map(item => ({
                    label: `${item.phone && item.phone[0] ? item.phone[0].telefono : ''} - ${item.get_full_name}`,
                    value: item.id
                  }))}
                  menuPosition="fixed"
                  className="flex-grow-1 me-2"
                />

                <Button color="primary" onClick={toggleNewContactFields}>+</Button>
              </div>
            </Col>
          </Row>
          {showNewContactFields && (
            <Form onSubmit={handleSaveNewContact}>
              <Row className='d-flex justify-content-center'>
                <Col lg={5} className="mb-3">
                  <Label for="nombre" className="form-label">Nombre</Label>
                  <Input
                    id="nombre"
                    name="nombre"
                    required
                    onChange={(e) => setNewLead({ ...newLead, nombre: e.target.value })}
                  />
                </Col>
                <Col lg={5} className="mb-3">
                  <Label for="apellido" className="form-label">Apellido</Label>
                  <Input
                    id="apellido"
                    name="apellido"
                    required
                    onChange={(e) => setNewLead({ ...newLead, apellido: e.target.value })}
                  />
                </Col>
              </Row>
              <Row className='d-flex justify-content-center'>
                <Col lg={5} className="mb-3">
                  <Label for="email" className="form-label">Email</Label>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    required
                    onChange={(e) => setNewLead({ ...newLead, email: e.target.value })}
                  />
                </Col>
                <Col lg={5} className="mb-3">
                  <Label for="phone" className="form-label">Teléfono</Label>
                  <PhoneInput
                    inputProps={{
                      name: 'phone',
                      required: true,
                    }}
                    id="phone"
                    name="phone"
                    country={'co'}
                    value={newLead.phone}
                    onChange={(item) => { setNewLead({ ...newLead, phone: item }); setErrorRequired(false) }}
                  />
                  {errorRequired ?
                    <p className='font-size-13 text-danger'>Este campo es requerido</p>
                    : null}
                </Col>
              </Row>
              <Row className="justify-content-center mb-3">
                <Col lg={4} className="d-flex justify-content-center">
                  <Button color="secondary" className='text-dark me-1' onClick={toggleNewContactFields}>Cancelar</Button>
                  <Button color="primary" type="submit">Guardar</Button>
                </Col>
              </Row>
            </Form>
          )}
          {!showNewContactFields && (
            <>
              <Row className='d-flex justify-content-center'>
                <Col lg={10}>
                  <Label for="campaign">Campaña</Label>
                  <Select
                    className="me-1"
                    options={campaignData.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    onChange={(selectedOption) => handleSelectChange('campaign', selectedOption)}
                    value={newLead.campaign}
                    menuPosition="fixed"
                    placeholder="Campañas"
                  />
                </Col>
              </Row>

              <Row className='d-flex justify-content-center'>
                <Col lg={5}>
                  <Label for="agent">Agente</Label>
                  <Select
                    className="me-1"
                    options={agents.map((item) => ({
                      label: item.get_full_name,
                      value: item.id,
                    }))}
                    onChange={(selectedOption) => handleSelectChange('agent', selectedOption)}
                    value={newLead.agent[0] || null}
                    isMulti={false}
                    placeholder="Agentes"
                    menuPosition="fixed"
                  />
                </Col>
                <Col lg={5}>
                  <Label for="fecha_vencimiento">Fecha de Vencimiento</Label>
                  <Input type="date" name="fecha_vencimiento" id="fecha_vencimiento" value={newLead.fecha_vencimiento} onChange={handleInputChange} required />
                </Col>
              </Row>
              <Row className='d-flex justify-content-center'>
                <Col lg={10}>
                  <Label for="observaciones">Observaciones</Label>
                  <Input type="textarea" name="observaciones" id="observaciones" value={newLead.observaciones} onChange={handleInputChange} required />
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col className='d-flex justify-content-center'>
                  <Button color="primary" className="mb-2" onClick={addLead}>
                    Agregar
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </div>
        <div>
          <Row className=' d-flex justify-content-center '>
            <Col lg={11}>
              <Table striped>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Contacto</th>
                    <th>Campaña</th>
                    <th>Agente</th>
                    <th>Observaciones</th>
                    <th>Vencimiento</th>
                  </tr>
                </thead>
                <tbody>
                  {leads.map((lead, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{contacts.find(contact => contact.id === lead.contact)?.get_full_name || ''}</td>
                      <td>{campaignData.find(campaign => campaign.id === lead.campaign)?.name || ''}</td>
                      <td>{agents.find(agent => agent.id === (lead.agent[0] || null))?.get_full_name || ''}</td>
                      <td>{lead.observaciones}</td>
                      <td>{lead.fecha_vencimiento}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col className=' d-flex justify-content-end '>
              <Button color="success" className="mt-2" onClick={saveLeads}>
                Guardar
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      {(status === 201 || status === 200) && alert ? (
        <SweetAlert
          title="Hecho!"
          success
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            setAlert(false);
            setShowNewContactFields(false);
          }}
        >
          {message}
        </SweetAlert>
      ) : null}
      {(status !== 201 && status !== 200) && alert ? (
        <SweetAlert
          title="Error"
          error
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            setAlert(false);
          }}
        >
          {message}
        </SweetAlert>
      ) : null}

    </Modal>
  );
};

export default ManualProgrammingForm;
