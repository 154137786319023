// DESCRIPCION_CALIFICACION list
export const DESCRIPCION_CALIFICACION_LIST = "DESCRIPCION_CALIFICACION_LIST";
export const DESCRIPCION_CALIFICACION_LIST_SUCCESS = "DESCRIPCION_CALIFICACION_LIST_SUCCESS";
export const DESCRIPCION_CALIFICACION_LIST_FAILED = "DESCRIPCION_CALIFICACION_LIST_FAILED";
export const DESCRIPCION_CALIFICACION_LIST_CHANGE = "DESCRIPCION_CALIFICACION_LIST_CHANGE";

// DESCRIPCION_CALIFICACION lista sin Paginacion
export const DESCRIPCION_CALIFICACION_LISTA = "DESCRIPCION_CALIFICACION_LISTA";
export const DESCRIPCION_CALIFICACION_LISTA_SUCCESS = "DESCRIPCION_CALIFICACION_LISTA_SUCCESS";
export const DESCRIPCION_CALIFICACION_LISTA_FAILED = "DESCRIPCION_CALIFICACION_LISTA_FAILED";

// Add DESCRIPCION_CALIFICACION
export const DESCRIPCION_CALIFICACION_ADD = "DESCRIPCION_CALIFICACION_ADD";
export const DESCRIPCION_CALIFICACION_ADD_SUCCESS = "DESCRIPCION_CALIFICACION_ADD_SUCCESS";
export const DESCRIPCION_CALIFICACION_ADD_FAILED = "DESCRIPCION_CALIFICACION_ADD_FAILED";

//DESCRIPCION_CALIFICACION edit
export const DESCRIPCION_CALIFICACION_EDIT = "DESCRIPCION_CALIFICACION_EDIT";
export const DESCRIPCION_CALIFICACION_EDIT_SUCCESS = "DESCRIPCION_CALIFICACION_EDIT_SUCCESS";
export const DESCRIPCION_CALIFICACION_EDIT_FAILED = "DESCRIPCION_CALIFICACION_EDIT_FAILED";


//DESCRIPCION_CALIFICACION READ
export const DESCRIPCION_CALIFICACION_READ = "DESCRIPCION_CALIFICACION_READ";
export const DESCRIPCION_CALIFICACION_READ_SUCCESS = "DESCRIPCION_CALIFICACION_READ_SUCCESS";
export const DESCRIPCION_CALIFICACION_READ_FAILED = "DESCRIPCION_CALIFICACION_READ_FAILED";

//DESCRIPCION_CALIFICACION DELETE
export const DESCRIPCION_CALIFICACION_DELETE = "DESCRIPCION_CALIFICACION_DELETE";
export const DESCRIPCION_CALIFICACION_DELETE_SUCCESS = "DESCRIPCION_CALIFICACION_DELETE_SUCCESS";
export const DESCRIPCION_CALIFICACION_DELETE_FAILED = "DESCRIPCION_CALIFICACION_DELETE_FAILED";

