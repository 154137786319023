import React, { useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Col, Form, Input, Label, Modal, Row } from 'reactstrap';
import { postExperienciaHoja } from '../../helpers/backend';
import SweetAlert from "react-bootstrap-sweetalert";

export const HvExperiencia = (props) => {
  const { isOpen, onClose, success } = props;
  const [alert, setAlert] = useState(false)

  const [formData, setFormData] = useState({
    tipo_empleo: "",
    cargo:"",
    nombre_empresa:"",
    ubicacion:"",
    tipo_ubicacion:"",
    descripcion: "",
    hoja_de_vida: props.hojaDeVida,

});

const {  tipo_empleo ,cargo, nombre_empresa,  ubicacion, tipo_ubicacion, descripcion } =
 formData;

const onChange = (e) => {
 setFormData({ ...formData, [e.target.name]: e.target.value });
};

const onSubmit = async () => {
    const response = await postExperienciaHoja(formData);
    const data = response.data;
    if (data.id) {
        setAlert(true);
        success()
      }
  };

return (
<>
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered
      data-toggle="modal"
      size="lg"
      className="custom-modal"
      toggle={onClose} // Utiliza la función de cerrar del padre para cerrar el modal
    >
        <div>
            <h3 className='text-dark mt-4 ms-4 '><b>Agregar Experiencia</b> </h3>
            <div className='modal-body'>
              <Form className='form-control'>
                <Row>
                    <Col lg={6} className="mb-3">                    
                        <Label
                        for="tipo_empleo"
                        className="form-label"
                        >
                        Tipo de Empleo
                        </Label>
                        <Input
                        id="tipo_empleo"
                        name="tipo_empleo"
                        type="text"
                        onChange={onChange}
                        value={tipo_empleo}
                        required
                        />
                
                    </Col>                  
                    <Col lg={6}  className="mb-3">                    
                        <Label
                        for="nombre_empresa"
                        className="form-label"
                        >
                        Nombre de la Empresa
                        </Label>
                        <Input
                        id="nombre_empresa"
                        name="nombre_empresa"
                        type="text"
                        onChange={onChange}
                        value={nombre_empresa}                        
                        />

                        
                    </Col>
                </Row>                 
                <Row>
                    <Col lg={6} className="mb-3">
                        <Label
                          for="cargo"
                          className="form-label"
                        >
                        Cargo
                        </Label>
                        <Input
                          id="cargo"
                          name="cargo"
                          type="text"                          
                          onChange={onChange}
                          value={cargo}           
                        />                        
                      </Col>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="ubicacion"
                        className="form-label"
                      >
                      Ubicación
                      </Label>
                      <Input
                          id="ubicacion"
                          name="ubicacion"
                          type="text"                          
                          onChange={onChange}
                          value={ubicacion}           
                        /> 
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="tipo_ubicacion"
                        className="form-label"
                      >
                        Tipo de Ubicacion
                      </Label>
                      <Input
                        id="tipo_ubicacion"
                        name="tipo_ubicacion"
                        type="text"
                        onChange={onChange}
                        value={tipo_ubicacion}
                        
                      />
                    </Col>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="gender"
                        className="form-label"
                      >
                      Descripción
                      </Label>
                      <Input
                        id="descripcion"
                        name="descripcion"
                        type="text"
                        onChange={onChange}
                        value={descripcion}
                      />
                    </Col>
                </Row>
                <Row className=" justify-content-center mt-3">
                    <Col md={4}>
                      <Link className="btn btn-secondary text-dark" to="#" onClick={onClose}>
                        Volver
                      </Link>
                      <Button color="primary" className="ms-1" onClick={onSubmit}>
                        Guardar
                      </Button>
                    </Col>
                </Row>
              </Form>
            </div>
        </div>
    </Modal>
    {alert ? (
        <SweetAlert
            title="Hecho!"
            success
            timeout={2000}
            showConfirm={false}
            onConfirm={() => {
                onClose();
                setAlert(false); 

            }}
        >
            Se ha agregado los datos con éxito
        </SweetAlert>
    ) : null}
</>         
  );
};
