import React from 'react'
import NavbarTienda from '../HorizontalLayout/NavBarTienda'
import FooterHome from '../../pages/Home/components/FooterHome'

const ContainerTienda = ({children}) => {
  return (
    <main>
      <div>
        <NavbarTienda />
      </div>
      <div>
        {children}
      </div>
      <div>
        <FooterHome />
      </div>
    </main>
  )
}

export default ContainerTienda