import React from 'react'
import info from '../../../assets/images/error/info-svgrepo-com.svg'
import { TbLayoutDashboard } from "react-icons/tb";


const ClicksCorreos = () => {
  return (
    <div style={{ display: "grid", placeItems: "center" }}>
      <div>
        <img style={{ width: "200px" }} src={info} alt="" />
      </div>
      <div>
        <p style={{ fontSize: "2rem", fontWeight: "600" }}>
          Estamos trabajando en ello
        </p>
      </div>
      <div className="content_cart_">
        <div style={{paddingBottom:"1rem"}}>
          <div style={{display:"flex", justifyContent:"space-between"}}>
            <div>
              <p>Numero: </p>
            </div>
            <div>
              <span style={{fontWeight:"600"}}>1</span>
            </div>
          </div>
          <div style={{display:"flex", justifyContent:"space-between"}}>
            <div>
              <p> Fecha: </p>
            </div>
            <div>
              <span style={{fontWeight:"600"}}>2024-11-06</span>
            </div>
          </div>
          <div style={{display:"flex", justifyContent:"space-between"}}>
            <div>
              <p> Hora: </p>
            </div>
            <div>
              <span style={{fontWeight:"600"}}>00:00:00</span>
            </div>
          </div>
          <div style={{display:"flex", justifyContent:"space-between"}}>
            <div>
              <p> Carretero:</p>
            </div>
            <div>
              <span style={{fontWeight:"600"}}>Albán</span>
            </div>
          </div>
          
          <div style={{display:"flex", justifyContent:"space-between"}}>
            <div>
              <p> Departamento:</p>
            </div>
            <div>
              <span style={{fontWeight:"600"}}>Cundinamarca</span>
            </div>
          </div>
        
        </div>
        <div className="content_but_">
          <button>
            <TbLayoutDashboard size={20}/>
          </button>
          <button>
            <TbLayoutDashboard size={20}/>
          </button>
          <button>
            <TbLayoutDashboard size={20}/>
          </button>
          <button>
            <TbLayoutDashboard size={20}/>
          </button>
          <button>
            <TbLayoutDashboard size={20}/>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ClicksCorreos