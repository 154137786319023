// CASOS requests
export const CASOS_LIST = "CASOS_LIST";
export const CASOS_CREATE = "CASOS_CREATE";
export const CASOS_FILTER = "CASOS_FILTER";
export const CASOS_UPDATE = "CASOS_UPDATE";
export const CASOS_DELETE = "CASOS_DELETE";
export const CASOS_READ = "CASOS_READ";

// CASOS success
export const CASOS_LIST_SUCCESS = "CASOS_LIST_SUCCESS";
export const CASOS_CREATE_SUCCESS = "CASOS_CREATE_SUCCESS";
export const CASOS_UPDATE_SUCCESS = "CASOS_UPDATE_SUCCESS";
export const CASOS_DELETE_SUCCESS = "CASOS_DELETE_SUCCESS";
export const CASOS_READ_SUCCESS = "CASOS_READ_SUCCESS";

// CASOS failed
export const CASOS_REQUEST_FAILED = "CASOS_REQUEST_FAILED";
