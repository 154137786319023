// ACCOUNT requests
export const ACCOUNT_LIST = "ACCOUNT_LIST";
export const ACCOUNT_CREATE = "ACCOUNT_CREATE";
export const ACCOUNT_FILTER = "ACCOUNT_FILTER";
export const ACCOUNT_UPDATE = "ACCOUNT_UPDATE";
export const ACCOUNT_DELETE = "ACCOUNT_DELETE";
export const ACCOUNT_READ = "ACCOUNT_READ";

// ACCOUNT success
export const ACCOUNT_LIST_SUCCESS = "ACCOUNT_LIST_SUCCESS";
export const ACCOUNT_CREATE_SUCCESS = "ACCOUNT_CREATE_SUCCESS";
export const ACCOUNT_UPDATE_SUCCESS = "ACCOUNT_UPDATE_SUCCESS";
export const ACCOUNT_DELETE_SUCCESS = "ACCOUNT_DELETE_SUCCESS";
export const ACCOUNT_READ_SUCCESS = "ACCOUNT_READ_SUCCESS";

// ACCOUNT failed
export const ACCOUNT_REQUEST_FAILED = "ACCOUNT_REQUEST_FAILED";
