import React, { useState } from 'react'
import { MetaTags } from 'react-meta-tags'
import './styles/Productos.css'
import FormProducto from './componentsProductoAdd/FormProducto';
import NavBarAddProductos from './componentsProductoAdd/NavBarAddProductos';
import ButtonsNavigationForm from './componentsProductoAdd/ButtonsNavigationForm';
import { postProductoIndividual } from '../../helpers/backend';
import Swal from "sweetalert2";
import LoadingCargaFullScreen from '../Loading/LoadingCargaFullScreen';


const ProductosAdd = () => {
  const [activeSection, setActiveSection] = useState("general");
  const [formPersisteciaDatos, setFormPersistenciaDatos] = useState({
    general: {
      codigo_producto: "",
      name: "",
      marca: "",
      descripcion: "",
      imagenes: [],
    },
    organizacion: {},
    detalles: {},
    precio: {},
    // envios: {},
  });
  const [loading, setLoading] = useState(false);


   // Función para manejar el cambio de entradas (se pueden seleccionar imágenes)
  const handleInputChange = (section, data) => {
    if (data.imagenes) {
      const archivos = data.imagenes;
      setFormPersistenciaDatos((prevData) => ({
        ...prevData,
        [section]: {
          ...prevData[section],
          imagenes: archivos, // Almacenamos los archivos tal cual, sin base64
        },
      }));
    } else {
      setFormPersistenciaDatos((prevData) => ({
        ...prevData,
        [section]: {
          ...prevData[section],
          ...data,
        },
      }));
    }
  };

  const handleAlert = (icon, title, text) => {
    Swal.fire({
        icon: icon,
        title: title,
        text: text,
        timer: 2000,
    });
  };  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Convertir las imágenes a base64 antes de enviar al backend
    const base64ImagesPromises = formPersisteciaDatos.general.imagenes.map(
      (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);

          reader.onload = () => {
            const base64 = reader.result.split(",")[1];
            resolve(base64);
          };

          reader.onerror = (error) => {
            reject(error);
          };
        });
      }
    );

    // Esperamos a que todas las imágenes se conviertan a base64
    Promise.all(base64ImagesPromises)
    .then((base64Images) => {
      // Formatear los datos para el backend
      const datosFormateados = {
        name: formPersisteciaDatos.general.name,
        categoria: formPersisteciaDatos.organizacion.categoria,
        referencia: formPersisteciaDatos.organizacion.medida,
        rin: formPersisteciaDatos.organizacion.rin,
        marca: formPersisteciaDatos.general.marca,
        precio: formPersisteciaDatos.precio.precioEntrada,
        descripcion: formPersisteciaDatos.general.descripcion,
        detalles_producto: [
          {
            diseño: formPersisteciaDatos.detalles.diseño,
            indice_carga: formPersisteciaDatos.detalles.indice_carga,
            indice_velocidad: formPersisteciaDatos.detalles.indice_velocidad,
            lonas: formPersisteciaDatos.detalles.lonas,
            ancho_seccion: formPersisteciaDatos.detalles.ancho_seccion,
            diametro_total: formPersisteciaDatos.detalles.diametro_total,
            capacidad_carga_maxima: formPersisteciaDatos.detalles.capacidad_carga_maxima,
            utqg: formPersisteciaDatos.detalles.utqg,
            m_s: formPersisteciaDatos.detalles.m_s,
            presion_maxima: formPersisteciaDatos.detalles.presion_maxima,
            construccion: formPersisteciaDatos.detalles.construccion,
            sin_numero: formPersisteciaDatos.detalles.sin_numero,
            descripcion: formPersisteciaDatos.detalles.descripcion_detalle,
            observaciones: formPersisteciaDatos.detalles.observaciones
          }
        ],
        imagenes: base64Images.map((imagen) => ({
          imagen: imagen
        }))
      };

      setLoading(true)
      postProductoIndividual(datosFormateados)
        .then((response) => {
          console.log("producto enviado", response);
          setTimeout(() => {
            setLoading(false);
          }, 1500);
          setFormPersistenciaDatos({
            general: {
              codigo_producto: "",
              name: "",
              marca: "",
              descripcion: "",
              imagenes: [],
            },
            organizacion: {},
            detalles: {},
            precio: {},
          });
          handleAlert(
            "success",
            "¡Bien hecho!",
            "El producto se subió correctamente."
          );
        })
        .catch((error) => {
          console.log("error al enviar", error);
          setTimeout(() => {
            setLoading(false);
          }, 1500);
          handleAlert(
            "error",
            "Error al subir el archivo",
            error || "Ocurrió un error inesperado."
          );
        });
    })
    .catch((error) => {
    console.log("Error al convertir las imágenes a base64", error);
    handleAlert(
      "error",
      "Error al cargar las imagenes",
      "Hubo un problema al intentar convertir o subir las imagenes."
    );
    });
  };
  

  return (
    <main
      style={{ paddingTop: "5rem" }}
      className="container_productos_true_contact_add "
    >
      <MetaTags>
        <title>TrueContact | Productos</title>
      </MetaTags>

      <div>
        <NavBarAddProductos handleSubmit={handleSubmit} />
      </div>

      <div>
        <ButtonsNavigationForm onButtonClick={setActiveSection} />
      </div>

      <div>
        <FormProducto
          formPersisteciaDatos={formPersisteciaDatos}
          onInputChange={handleInputChange}
          activeSection={activeSection}
        />
      </div>

      {loading ? (
        <LoadingCargaFullScreen />
      ) : null}
    </main>
  );
}

export default ProductosAdd