import React, { useState } from 'react'
import Carro from '../../../assets/images/tipo-vehiculo/auto.svg'
import Camion from '../../../assets/images/tipo-vehiculo/camion.svg'
// import Tractor from '../../../assets/images/tipo-vehiculo/tractor.svg'
import Moto from '../../../assets/images/tipo-vehiculo/motos.svg'
// import Utv from '../../../assets/images/tipo-vehiculo/utv.svg'
// import Bicicleta from '../../../assets/images/tipo-vehiculo/bicicleta.svg'
import LlantasModal from './LlantasModal'


const tiposVehiculos = [
  {
    id: 1,
    name: "Carros, Camperos y Camionetas",
    image: Carro,
  },
  {
    id: 2,
    name: "Motocicletas",
    image: Moto,
  },
];

const TipoVehiculoModal = ({ modal, setModal }) => {
  const [modalLlantas, setModalLlantas] = useState(false);

  const handleModalLlantas = () => {
    setModalLlantas(true);
  };

  return (
    <section className="open_modal_llantas">
      <div
        data-aos="fade-up"
        data-aos-duration="400"
        className={`modal_content_tipo_vehiculo ${modalLlantas ? "modal_oculto" : ""}`}
        
      >
        <div className="closed_modal_llantas" onClick={() => setModal(!modal)}>
          X
        </div>
        <div>
          <h2 className='title_modal_llantas'>Selecciona el tipo de Vehículo para tus llantas:</h2>
        </div>

        <div className='options_modal_llantas'>
          {tiposVehiculos.map((vehiculo) => (
            <div className='vehiculo_seleccionar' onClick={handleModalLlantas}>
              <div className='container_imagen'>
                <img className='imagen_vehiculo' src={vehiculo.image} alt="" />
              </div>
              <div className='container_name_vehiculo'>
                <p className='name_vehiculo' key={vehiculo.id}>{vehiculo.name}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {
        modalLlantas ? 
        <LlantasModal modalLlantas={modalLlantas} setModalLlantas={setModalLlantas}/>
        : null
      }
    </section>
  );
}

export default TipoVehiculoModal