import React, { useState } from 'react';
import { Card, CardBody, Col, Label, Row, Table } from 'reactstrap';
import styles from "./CasosDetails.module.scss"
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import MapModal from '../Modales/MapModal';

function Timeline(props) {     
    const gestiones = props.gestiones
    const [modalOpen, setModalOpen] = useState(false);    
    const [coords, setCoords] = useState("");
    
    const [checked, setchecked] = useState(false);
    const changeLista = (e) => {
      setchecked(e.target.checked)
    }

    function Status({ estado }) {
        const estados = {    
          ACEPTADO: { className: "bg-warning", text: "Aceptado" },
          ASIGNADO: { className: "bg-primary", text: "Asignado" },
          INICIADO: { className: "bg-primary", text: "Iniciado" },
          FINALIZADO: { className: "bg-success", text: "Finalizado" },
          PAUSADO: { className: "bg-info", text: "Pausado" },
          REANUDADO: { className: "bg-success bg-soft text-muted", text: "Reanudado" },

        };
      
        const estadoActual = estados[estado];
      
        return (
          <div className={`badge  ${estadoActual.className} font-size-13`}>
            <strong>{estadoActual.text}</strong>
          </div>
        );
      }
console.log(gestiones);
  return (

    <Row className="justify-content-center">
        <div className="form-check form-switch">
            <input className="form-check-input  " type="checkbox" id="flexSwitchCheckChecked" onChange={changeLista} checked = {checked} />
            <Label className="form-check-label" for="flexSwitchCheckChecked">Ver Linea de Tiempo</Label>
        </div>

    <Col xl={12}>
    {!checked ? (
        <div>
            <h4 className="text-primary text-center"><b>Historial de Gestiones</b> </h4>
        <Table
            className="mt-4 mb-3"
            style={{
                textAlign: "center",
            }}
            responsive
            hover
        >
            <thead className="table-dark align-middle">
                <tr>
                <th>#</th>
                <th>Fecha de Acción</th>    
                <th>Acción</th>       
                <th>Tiempo entre acciones</th>                   
                <th>Observacion</th>
                <th>Ubicación</th>  
                <th>Usuario</th>                               
                </tr>
            </thead>
            <tbody className='align-middle'>
            {Array.isArray(gestiones) ? [...gestiones].reverse().map((item, idx) => (
                <tr key={idx}>
                    <th>{idx+1}</th>
                    <td> {item.created_date} </td>
                    <td> <Status estado={item.estado} /></td>
                    <td> {item.duracion} </td>
                    <td> {item.observaciones} </td>
                    <td>{item.coordenadas ? <Link to= '#' onClick={()=> { setModalOpen(true) ; setCoords(item.coordenadas)}}>
                            Ver Ubicación
                        </Link> :null}
                    </td>
                    {modalOpen ? 
                      <MapModal 
                        isOpen = {modalOpen}
                        onClose = {()=> setModalOpen(false)}
                        coords = {coords}
                      /> 
                    : null}
                    <td> {item.user} </td>
                </tr>

            )):[]}
            </tbody>

        </Table>
        </div> 
        ):
        <div className={`timeline ${styles.timeLine}`}>
        {Array.isArray(gestiones) ? [...gestiones].reverse().map((item, idx) => (
            <div className={`timeline-item ${idx % 2 === 0 ? 'timeline-left' : ''}`} key={idx}>
                <div className="timeline-block">
                    <Card className="timeline-box" style={{ maHeight: '100px'  }}>
                        <CardBody className='' style={{ lineHeight: "0.5rem" }}>
                            {item.estado ?
                                <div style={{ zIndex: '9999' }}>
                                    <b className={`position-absolute top-0 ${idx % 2 === 0 ? 'start-0' : 'end-0'} m-2`}>
                                       {item.estado ? <Status estado={item.estado} /> :null}
                                    </b>
                                </div>
                                : null}
                            <span className="timeline-icon"></span>
                            <p className="mt-4 font-size-12"><b>Fecha: </b>{item.created_date} </p>
                            <p className="font-size-12"><b>Tiempo entre acciones: </b>{item.duracion} </p>
                            <p className="font-size-12" style={{ lineHeight: "1rem" }}><b>Observaciones: </b>{item.observaciones}</p>
                            <p className="font-size-12"><b> Usuario: </b>{item.user}</p>
                        </CardBody>
                    </Card>
                </div>
            </div>
        )) : null}
        </div>
    }

    </Col>
    </Row>

  );
}

export default Timeline;
