import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,

} from "reactstrap";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";

// Redux
import { connect} from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";

import { contactsCreate } from "../../store/contacts/actions";
//import { getAgent } from "../../helpers/backend";


//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader";



const ContactsCreate = (props) => {
  const history = useHistory();


  const optionGroupTipoIdentificacion = [
    {
      options: [
        { label: "Cédula Ciudadanía", value: "CEDULA" },
        { label: "Pasaporte", value: "PASAPORTE" },
        { label: "Cedula de Extranjería", value: "CEDULA_EXTRANJERIA" },
        { label: "Otro", value: "OTRO" },
      ],
    },

  ];

  const optionGroupGenero = [
    {
      options: [
        { label: "Masculino", value: "Masculino" },
        { label: "Femenino", value: "Femenino" },
        { label: "Otro", value: "Otro" },
      ],
    },
  ];

    const [formData, setFormData] = useState({
      username: "",
      first_name:"",
      last_name:"",
      documentNumber:"",
      birthdate:"2000-01-01",
      city: "",
      gender: "Otro",
      contactType: "PERSONA NATURAL",
      documentType:"",
      phone: "",
      address:"",
      contacts: "",
      parentesco: "",
 });

 const {  username ,first_name, last_name,  documentNumber, birthdate, city, persona_contacto , gender, documentType, contactType, phone, address } =
   formData;

 const onChange = (e) => {
   setFormData({ ...formData, [e.target.name]: e.target.value });
 };

 const onSubmit = (e) => {
   e.preventDefault();
   props.contactsCreate({
    first_name ,
    last_name ,
    username,
    contactType,
    documentType,
    documentNumber,
    gender,
    birthdate,
    city,
    persona_contacto,
    phone,
    address,
   });
  };


  const redirect = () => {
    history.goBack();
  };

   return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TrueContact| Contactos</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={"TrueContact"} breadcrumbItem={"Agregar Contactos"} />
          {props.loading ? (
            <Loader />
          ) : (
            <Card className= "col-lg-8 mx-auto  mt-3">
              {props.contactsCreated.id ? (
                <SweetAlert
                  title="Hecho!"
                  success
                  timeout={2000}
                  showConfirm={false}
                  onConfirm={() => {
                    redirect();
                  }}
                >
                  Se ha agregado el Contacto con éxito
                </SweetAlert>
              ) : null}
              <CardBody>
                <h4 className="card-title">Crear Nuevo Contacto </h4>
                <Form className="form-control needs-validation"  onSubmit={onSubmit}>

                <> {/* Datos Persona Natural*/}
        
                  <Row>
                    <Col lg={6} className="mb-3">                    
                      <Label
                        for="first_name"
                        className="form-label"
                      >
                        Nombres
                      </Label>
                      <Input
                        id="first_name"
                        name="first_name"
                        type="text"
                        onChange={onChange}
                        value={first_name}
                        valid={props.error.first_name
                          ? true
                          : props.contactsCreated && false
                        }
                        invalid={
                          props.error
                            ? props.error.first_name
                              ? true
                              : false
                            : false
                        }
                        required
                      />
                      <FormFeedback>{props.error.first_name}</FormFeedback>
                
                    </Col>                  
                    <Col lg={6}  className="mb-3">                    
                      <Label
                        for="last_name"
                        className="form-label"
                      >
                        Apellidos
                      </Label>
                      <Input
                        id="last_name"
                        name="last_name"
                        type="text"
                        onChange={onChange}
                        value={last_name}
                        valid={props.error.last_name
                          ? true
                          : props.contactsCreated && false
                        }
                        invalid={
                          props.error
                            ? props.error.last_name
                              ? true
                              : false
                            : false
                        }
                        required
                      />
                      <FormFeedback>{props.error.last_name}</FormFeedback>
                     
                    </Col>
                  </Row>
                 
                  <Row>
                    <Col lg={6} className="mb-3">
                        <Label
                          for="username"
                          className="form-label"
                        >
                        Correo Electrónico
                        </Label>
                        <Input
                          id="username"
                          name="username"
                          type="text"
                          placeholder="example@xxxx.com"
                          onChange={onChange}
                          value={username}
                          valid={props.error.username
                            ? true
                            : props.contactsCreated && false
                          }
                          invalid={
                            props.error
                              ? props.error.username
                                ? true
                                : false
                              : false
                          }
                          required
                        />
                        <FormFeedback>{props.error.username}</FormFeedback>
                      </Col>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="contactType"
                        className="form-label"
                      >
                      Tipo de identificación
                      </Label>
                    <Select
                            id="documentType"
                            name="documentType"
                            onChange={(item)=> setFormData({ ...formData, documentType: item.value })}
                            options={optionGroupTipoIdentificacion}
                            placeholder ={'Seleccione'}
                          />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="documento"
                        className="form-label"
                      >
                        Documento
                      </Label>
                      <Input
                        id="documentNumber"
                        name="documentNumber"
                        type="number"
                        onChange={onChange}
                        value={documentNumber}
                        valid={props.error.documentNumber
                          ? true
                          : props.contactsCreated && false
                        }
                        required
                        invalid={
                          props.error
                            ? props.error.documentNumber
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.documentNumber}</FormFeedback>
                    </Col>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="gender"
                        className="form-label"
                      >
                      Género
                      </Label>
                    <Select
                            id="gender"
                            name="gender"
                            onChange={(item)=> setFormData({ ...formData, gender: item.value })}
                            options={optionGroupGenero}
                            placeholder ={'Seleccione'}
                          />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="birthdate"
                        className="form-label"
                      >
                        Fecha de Nacimiento
                      </Label>
                      <Input
                        id="birthdate"
                        name="birthdate"
                        type="date"
                        placeholder="dd/mm/aaaa"
                        onChange={onChange}
                        value={birthdate}
                        valid={props.error.birthdate
                          ? true
                          : props.contactsCreated && false
                        }
                        invalid={
                          props.error
                            ? props.error.birthdate
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.birthdate}</FormFeedback>
                    </Col>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="address"
                        className="form-label"
                      >
                        Dirección
                      </Label>
                      <Input
                        id="address"
                        name="address"
                        type="text"
                        onChange={onChange}
                        value={address}
                        valid={props.error.address
                          ? true
                          : props.contactsCreated && false
                        }
                        invalid={
                          props.error
                            ? props.error.address
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.address}</FormFeedback>
                    </Col>
                  </Row>
                  <Row>
                  <Col lg={6} className="mb-3">
                      <Label
                        for="phone"
                        className="form-label"
                      >
                        Teléfono
                      </Label>
                      <PhoneInput
                        id="phone"
                        name="phone"
                        country={'co'}
                        value={phone}
                        onChange={(item) => setFormData({...formData, phone: item}) }
                      />
                      <FormFeedback>{props.error.phone}</FormFeedback>
                    </Col>
                  </Row> 

                  <Row className="col-lg-12 justify-content-center mt-3">
                    <Col md={4} className= 'ms-5'>
                      <Link className="btn btn-secondary text-dark" to="/contacts">
                        Volver
                      </Link>
                      <Button color="primary" type="submit" className="ms-1">
                        Enviar
                      </Button>
                    </Col>
                  </Row>
                </>
        
                </Form>
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, contactsCreated } = state.Contacts;
  return { error, loading, contactsCreated};
};

contactsCreate.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  contactsCreated: PropTypes.object,
};
export default withRouter(connect(mapStateToProps, { contactsCreate })(ContactsCreate));
