import React from "react"
import { Container } from "reactstrap"
//import ChatBar from "../../pages/Chat/ChatBar";
//import ChatWindow from "../../pages/Chat/ChatWindow";

const Footer = () => {
 // const [chatOpen, setChatOpen] = useState(false);

 /* const toggleChat = () => {
    setChatOpen(!chatOpen);
  };
*/
  return (
    <React.Fragment>
      <footer className="footer">
{/*      <div>
          <ChatBar onClick={toggleChat} />

          {/* Componente del chat que se muestra o no, dependiendo del estado 
          {chatOpen && <ChatWindow />}
  </div>*/}

        <Container fluid={true}>
          <div className="text-sm-end d-none d-sm-block">
          <span className="mx-3 badge  bg-secondary ">

          </span>
            {new Date().getFullYear()} © TrueContact by GrupoDa2

          </div>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
