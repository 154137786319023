import { call, put, takeEvery } from "redux-saga/effects";

import {
  AUDITS_LIST,
  AUDITS_FILTER,
  AUDITS_CREATE,
  AUDITS_UPDATE,
} from "./actionTypes";

import {
 auditsListSuccess,
 auditsCreateSuccess,
 auditsUpdateSuccess,
 auditsRequestFailed,
} from "./actions";

import {
  getAuditsList,
  getAuditsFilter,
  postAuditsCreate,
  patchAuditsUpdate,
} from "../../helpers/backend";

function*auditsListSaga() {
  console.log("Trayendo lista de audits...");
  try {
    const response = yield call(getAuditsList);
    if (response.status === 200) {
      yield put(auditsListSuccess(response.data));
    } else {
      yield put(auditsRequestFailed(response.data));
    }
  } catch (error) {
    yield put(auditsRequestFailed(error.response.data));
  }
}

function*auditsCreateSaga({ payload:audits }) {
  console.log("Creando nuevo audits...");
  try {
    const response = yield call(postAuditsCreate,audits);
    if (response.status === 201) {
      yield put(auditsCreateSuccess(response.data));
    } else {
      yield put(auditsRequestFailed(response.data));
    }
  } catch (error) {
    yield put(auditsRequestFailed(error.response.data));
  }
}

function*auditsFilterSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getAuditsFilter, { changeUrl });
    if (response.status === 200) {
      yield put(auditsListSuccess(response.data));
    } else {
      yield put(auditsRequestFailed(response.data));
    }
  } catch (error) {
    yield put(auditsRequestFailed(error.response.data));
  }
}

function*auditsUpdateSaga({ payload: { id, data } }) {
  console.log("Actualizando datos del audits...");
  try {
    const response = yield call(patchAuditsUpdate, { id }, data);
    if (response.status === 200) {
      yield put(auditsUpdateSuccess(response.data));
    } else {
      yield put(auditsRequestFailed(response.data));
    }
  } catch (error) {
    yield put(auditsRequestFailed(error.response.data));
  }
}

export default function*auditsSaga() {
  yield takeEvery(AUDITS_LIST,auditsListSaga);
  yield takeEvery(AUDITS_FILTER,auditsFilterSaga);
  yield takeEvery(AUDITS_CREATE,auditsCreateSaga);
  yield takeEvery(AUDITS_UPDATE,auditsUpdateSaga);
}
