// AUDITS requests
export const AUDITS_LIST = "AUDITS_LIST";
export const AUDITS_CREATE = "AUDITS_CREATE";
export const AUDITS_FILTER = "AUDITS_FILTER";
export const AUDITS_UPDATE = "AUDITS_UPDATE";
export const AUDITS_DELETE = "AUDITS_DELETE";
export const AUDITS_READ = "AUDITS_READ";

// AUDITS success
export const AUDITS_LIST_SUCCESS = "AUDITS_LIST_SUCCESS";
export const AUDITS_CREATE_SUCCESS = "AUDITS_CREATE_SUCCESS";
export const AUDITS_UPDATE_SUCCESS = "AUDITS_UPDATE_SUCCESS";
export const AUDITS_DELETE_SUCCESS = "AUDITS_DELETE_SUCCESS";
export const AUDITS_READ_SUCCESS = "AUDITS_READ_SUCCESS";

// AUDITS failed
export const AUDITS_REQUEST_FAILED = "AUDITS_REQUEST_FAILED";
