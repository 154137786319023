import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { TbBrandLinkedin } from "react-icons/tb";
import { TbBrandInstagram } from "react-icons/tb";
import { TbBrandFacebook } from "react-icons/tb";
import { TbBrandTwitter } from "react-icons/tb";
import Logo from "../../../assets/images/LogoTranspareciaLabelBlanco.png";


const FooterHome = () => {
  return (
    <footer id="" className="footer_container">
      <div className="info_legal_container">
        <h1 className="title_info">Información Legal</h1>
        <div className="info_legal">
          <Link className="options" to="#">
            Política de protección de datos
          </Link>
          <Link className="options" to="#">
            Política de despachos
          </Link>
          <Link className="options" to="#">
            Manual al usuario
          </Link>
          <Link className="options" to="#">
            Posconsumo de llantas
          </Link>
          <Link className="options" to="#">
            Sagrilaft
          </Link>
        </div>
      </div>

      <div>
        <div>
          <h1 className="title_info">Soporte</h1>
          <div className="soporte_container">
            <Link className="options" to="#">
              Preguntas frecuentes
            </Link>
            <Link className="options" to="#">
              Reclamos
            </Link>
          </div>
        </div>
        <div className='social'>
          <h1 className="title_info ">Redes sociales</h1>
          <div className="icon_container">
            <Link className="options_icon" to="#">
              <TbBrandLinkedin size={30} />
            </Link>
            <Link className="options_icon" to="#">
              <TbBrandInstagram size={30} />
            </Link>
            <Link className="options_icon" to="#">
              <TbBrandFacebook size={30} />
            </Link>
            <Link className="options_icon" to="#">
              <TbBrandTwitter size={30} />
            </Link>
          </div>
        </div>
      </div>

      <div className="logo_footer_container">
        <a className='logo_button' href="/home">
          <img className="logo_footer" src={Logo} alt="Logo" />
        </a>
        <p className='p_logo'>Copyright 2022 All Rights Reserved.</p>
      </div>
    </footer>
  );
}

export default FooterHome