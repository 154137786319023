import {
  CASOS_LIST,
  CASOS_FILTER,
  CASOS_CREATE,
  CASOS_CREATE_SUCCESS,
  CASOS_LIST_SUCCESS,
  CASOS_REQUEST_FAILED,
  CASOS_UPDATE,
  CASOS_UPDATE_SUCCESS,
} from "../../store/casos/actionTypes";

const initialState = {
  casos: [],
  casosCreated: {},
  casosUpdated: {},
  loading: false,
  error: "",
};

const casos = (state = initialState, action) => {
  switch (action.type) {
    case CASOS_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CASOS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CASOS_FILTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CASOS_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CASOS_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        casosCreated: action.payload,
        error: "",
      };
      break;
    case CASOS_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        casos: action.payload,
        casosCreated: {},
        casosUpdated: {},
        error: "",
      };
      break;
    case CASOS_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        casosUpdated: action.payload,
        error: "",
      };
      break;
    case CASOS_REQUEST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default casos;
