import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'reactstrap';

import agregar from "../../assets/images/AgregarGestiones.avif"
import lista from "../../assets/images/ListaGestiones.avif"
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const GestionContactoModal = (props) => {
  const history = useHistory()
  const { isOpen, onClose, contact } = props;
  const [alert, setAlert] = useState(false)


return (
<>
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered
      data-toggle="modal"
      size="lg"
      className="custom-modal"
      toggle={onClose} // Utiliza la función de cerrar del padre para cerrar el modal
    >
            <h2 className="mt-4 ms-4 text-dark">
              <b>Gestiones del Contacto</b>{" "}
            </h2>
            <Row className="justify-content-center mt-2 mx-5 mb-5">
              <Col>
                <Button onClick={()=> history.push(`/gestionContacto/${contact}`)}>
                  <img style={{ height: "300px", width: 'auto' }} src={lista} alt="" />
                  <h5 className="text-dark mt-2">
                    <b>Lista de Gestiones</b>{" "}
                  </h5>
                </Button>
              </Col>
              <Col>
                <Button onClick={()=> history.push(`/gestionContactoCreate/${contact}`)}>
                  <img style={{ height: "300px",  width: '300px'}} src={agregar} alt="" />
                  <h5 className="text-dark mt-2">
                    <b>Agregar Gestión</b>{" "}
                  </h5>
                </Button>
              </Col>
            </Row>
            <Row className="mb-3 mx-3">
              <Col className="text-end">
                <Button className="text-dark" onClick={() => onClose()}>
                  Cerrar
                </Button>
              </Col>
            </Row>
    </Modal>
    {alert ? (
        <SweetAlert
            title="Hecho!"
            success
            timeout={2000}
            showConfirm={false}
            onConfirm={() => {
                onClose();
                setAlert(false); 

            }}
        >
            Se ha agregado los datos con éxito
        </SweetAlert>
    ) : null}
</>         
  );
};
