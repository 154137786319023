import React, { useState } from 'react';
import './styles/Calendario.css';
import { TbChevronLeft, TbChevronRight } from "react-icons/tb";

const Calendario = ({ startDate, endDate, onDateSelect, onClose, showCalendar }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDates, setSelectedDates] = useState([startDate, endDate]);

  const changeMonth = (direction) => {
    const newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() + direction);
    setCurrentDate(newDate);
  };

  const generateCalendar = () => {
    const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDay();
    
    const weeks = [];
    let week = [];
    
    const prevMonthDays = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();
    let prevMonthStartDay = prevMonthDays - firstDayOfMonth + 1;
    
    for (let i = prevMonthStartDay; i <= prevMonthDays; i++) {
      week.push({ day: i, isCurrentMonth: false, monthOffset: -1 });
    }
    
    for (let day = 1; day <= daysInMonth; day++) {
      week.push({ day, isCurrentMonth: true, monthOffset: 0 });
      
      if (week.length === 7) {
        weeks.push(week);
        week = [];
      }
    }

    if (week.length < 7) {
      const nextMonthStartDay = 1;
      const nextMonthDaysNeeded = 7 - week.length;

      for (let i = nextMonthStartDay; i < nextMonthStartDay + nextMonthDaysNeeded; i++) {
        week.push({ day: i, isCurrentMonth: false, monthOffset: 1 });
      }
      weeks.push(week);
    }

    return weeks;
  };

  const handleDateClick = (date, isCurrentMonth, monthOffset) => {
    if (!isCurrentMonth && monthOffset === 0) return;

    let [start, end] = selectedDates;

    if (!start) {
      setSelectedDates([date, null]);
    } else {
      if (!end) {
        if (date < start) {
          setSelectedDates([date, start]);
        } else {
          setSelectedDates([start, date]);
        }
      } else {
        setSelectedDates([date, null]);
      }
    }

    onDateSelect(date, start);
  };

  const weeks = generateCalendar();

  return (
    <div className="" onClick={onClose}>
      <div className={`calendar ${showCalendar ? "calendar_open" : ""}`} onClick={(e) => e.stopPropagation()}>
        <div className="calendar-header">
          <button className="calendar-nav" onClick={() => changeMonth(-1)}>
            <TbChevronLeft />
          </button>
          <span className="calendar-month">
            {currentDate.toLocaleString("es-ES", {
              month: "long",
              year: "numeric",
            })}
          </span>
          <button className="calendar-nav" onClick={() => changeMonth(1)}>
            <TbChevronRight />
          </button>
        </div>
        <div className="calendar-body">
          <div className="calendar-weekdays">
            {["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"].map((day) => (
              <div className="calendar-weekday" key={day}>
                {day}
              </div>
            ))}
          </div>
          {weeks.map((week, idx) => (
            <div className="calendar-week" key={idx}>
              {week.map(({ day, isCurrentMonth, monthOffset }, dayIdx) => {
                const date = new Date(
                  currentDate.getFullYear(),
                  currentDate.getMonth() + monthOffset,
                  day
                );
                const isStart =
                  selectedDates[0] &&
                  date.getDate() === selectedDates[0].getDate();
                const isEnd =
                  selectedDates[1] &&
                  date.getDate() === selectedDates[1].getDate();
                const isSelected =
                  isStart ||
                  isEnd ||
                  (selectedDates[0] &&
                    selectedDates[1] &&
                    date >= selectedDates[0] &&
                    date <= selectedDates[1]);

                return (
                  <div
                    key={dayIdx}
                    className={`calendar-day ${
                      isCurrentMonth ? "active" : "outside-month"
                    } 
                    ${isSelected ? "selected" : ""} ${isStart ? "start" : ""} ${
                      isEnd ? "end" : ""
                    }`}
                    onClick={() =>
                      handleDateClick(date, isCurrentMonth, monthOffset)
                    }
                  >
                    {day || ""}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div style={{display:"flex", justifyContent:"end", padding:"1rem 0 0 0"}}>
          <button onClick={() => onClose()} className='button_send_calendario'>Hecho</button>
        </div>
      </div>
    </div>
  );
};

export default Calendario;
