// GESTIONES_CONTACTO requests
export const GESTIONES_CONTACTO_LIST = "GESTIONES_CONTACTO_LIST";
export const GESTIONES_CONTACTO_CREATE = "GESTIONES_CONTACTO_CREATE";
export const GESTIONES_CONTACTO_FILTER = "GESTIONES_CONTACTO_FILTER";
export const GESTIONES_CONTACTO_UPDATE = "GESTIONES_CONTACTO_UPDATE";
export const GESTIONES_CONTACTO_DELETE = "GESTIONES_CONTACTO_DELETE";
export const GESTIONES_CONTACTO_READ = "GESTIONES_CONTACTO_READ";

// GESTIONES_CONTACTO success
export const GESTIONES_CONTACTO_LIST_SUCCESS = "GESTIONES_CONTACTO_LIST_SUCCESS";
export const GESTIONES_CONTACTO_CREATE_SUCCESS = "GESTIONES_CONTACTO_CREATE_SUCCESS";
export const GESTIONES_CONTACTO_UPDATE_SUCCESS = "GESTIONES_CONTACTO_UPDATE_SUCCESS";
export const GESTIONES_CONTACTO_DELETE_SUCCESS = "GESTIONES_CONTACTO_DELETE_SUCCESS";
export const GESTIONES_CONTACTO_READ_SUCCESS = "GESTIONES_CONTACTO_READ_SUCCESS";

// GESTIONES_CONTACTO failed
export const GESTIONES_CONTACTO_REQUEST_FAILED = "GESTIONES_CONTACTO_REQUEST_FAILED";
