import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader";

import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";

// Redux
import { connect, useDispatch } from "react-redux";
import { withRouter, Link, useHistory, useParams  } from "react-router-dom";
import { getCalificacionContactoFullList, getDescripcionCalificacion, getTipoGestionContactoFullList } from "../../helpers/backend";
import { gestionesContactoCreate } from "../../store/actions";




const GestionContactoCreate = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const contactId = useParams().id

    const [ tipoGestionList, setTipoGestionList ] = useState(null)
    const getTipoGestionData = async () => {
        const response = await getTipoGestionContactoFullList();
        const data = response.data;
        setTipoGestionList(data);
      };
    const [ calificacionList, setCalificacionList ] = useState(null)
    const getCalificacionData = async () => {
        const response = await getCalificacionContactoFullList();
        const data = response.data;
        setCalificacionList(data);
      };  
      const [ descripcionList, setDescripcionList ] = useState({})
      const getDescripcionData = async (id) => {
        const response = await getDescripcionCalificacion(id);
        const data = response.data;
        setDescripcionList(data);
        }; 

    useEffect(() => {
        getCalificacionData();
        getTipoGestionData();
    }, [])
    
    const [formData, setFormData] = useState({
    
        calificacion: "",
        descripcion:"",
        observaciones: "",
        contacto: contactId,
      });

      useEffect(() => {
        if(formData.calificacion){
            getDescripcionData(formData.calificacion)
        }
    }, [formData.calificacion])


const onSubmit = () => {
    dispatch (gestionesContactoCreate (formData))
}
console.log(formData);
   return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>  TrueContact | Gestión del Contacto </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={"TrueContact"} breadcrumbItem={"Gestión del Contacto"} />
          {props.loading ? (
            <Loader />
          ) : (
            <Card className= "col-lg-8 mx-auto  mt-3">
            {props.gestionesContactoCreated.id  ? (
              <SweetAlert
                title="Hecho!"
                success
                timeout={2000}
                showConfirm={false}
                onConfirm={() => {
                  history.push(`/gestionContacto/${contactId}`)
                }}
              >
                Se ha creado la Gestión con éxito
              </SweetAlert>
            ) : null}
            
            {props.error.detail ? (
              <SweetAlert
                title="Lo Sentimos,"
                error
                timeout={2000}
                showConfirm={false}
                onConfirm={() => {
                  history.push(`/contacts/${contactId}/index`)
                }}
              >
            {props.error.detail}
              </SweetAlert>
            ) : null}

            <CardBody>
              <h4 className="card-title"> Crear Gestión </h4>
              <Form className="needs-validation form-control" onSubmit={onSubmit}>
                <Row className="col-lg-12 justify-content-center">
                    <Col lg ={6} className="mb-3">
                      <Label
                        for="rol"
                        className="form-label"
                      >
                        Tipo de Gestión
                      </Label>
                      <Select                        
                        placeholder ="Seleccione"
                        options={Array.isArray(tipoGestionList)? tipoGestionList.map(item=> ({label: item.name, value: item.id})):[]}                       
                        onChange={ (item) => setFormData({
                            ...formData,
                            tipo_gestion: item.value,
                          })
                          }
                        menuPosition="fixed"
                        
                      />
                    </Col>
                </Row>
                <Row className="col-lg-12 justify-content-center">
                    <Col lg={6} className="mb-3">
                      <Label
                        for="usuarios"
                        className="form-label"
                      >
                      Calificación
                      </Label>
                    <Select
                            id="calificacion"
                            name="calificacion"
                            placeholder ="Seleccione"
                            onChange={ (item) => setFormData({
                                ...formData,
                                calificacion: item.value,
                              })
                              }
                            options={calificacionList && calificacionList.map(item=> ({label: item.name , value: item.id}))}
                            menuPosition="fixed"     
                          />                        
                    </Col>
                </Row>
                <Row className="col-lg-12 justify-content-center">
                    <Col lg ={6} className="mb-3">
                      <Label
                        for="rol"
                        className="form-label"
                      >
                        Descripción
                      </Label>
                      <Select                        
                        placeholder ="Seleccione"
                        options={Array.isArray(descripcionList)? descripcionList.map(item=> ({label: item.name, value: item.id})):[]}                       
                        onChange={ (item) => setFormData({
                            ...formData,
                            descripcion: item.value,
                          })
                          }
                        menuPosition="fixed"
                        
                      />
                    </Col>
                </Row>
                    <Row className="col-lg-12 justify-content-center">
                    <Col lg ={6} className="mb-3">
                      <Label
                        for="observaciones"
                        className="form-label"
                      >
                        Observaciones
                      </Label>
                      <Input
                        id="observaciones"
                        name="observaciones"
                        type="textarea"
                        onChange={(e)=> setFormData({...formData, observaciones: e.target.value})}                 
                      />
                    </Col>
                    </Row>

               
                <Row className="col-lg-12 justify-content-center">
                  <Col md={1} className= "mx-3">
                    <Button color="primary" type="submit" >
                      Guardar
                    </Button>
                  </Col>
                  <Col md={1} className= "mx-3">
                    <Link className="btn btn-secondary text-dark" to={`/contacts`}>
                      Volver
                    </Link>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        )}
      </Container>
    </div>
  </React.Fragment>
);
};

const mapStateToProps = (state) => {
const { error, loading, gestionesContacto, gestionesContactoCreated } = state.GestionesContacto;
return { error, loading, gestionesContacto, gestionesContactoCreated, };
};

GestionContactoCreate.propTypes = {
error: PropTypes.any,
loading: PropTypes.bool,
GestionesContactoCreated: PropTypes.object,
};
export default withRouter(connect(mapStateToProps)(GestionContactoCreate));
