import {
  CONTACTS_LIST,
  CONTACTS_FILTER,
  CONTACTS_CREATE,
  CONTACTS_UPDATE,
  CONTACTS_DELETE,
  CONTACTS_UPDATE_SUCCESS,
  CONTACTS_DELETE_SUCCESS,
  CONTACTS_CREATE_SUCCESS,
  CONTACTS_LIST_SUCCESS,
  CONTACTS_REQUEST_FAILED,
} from "../contacts/actionTypes";

export const contactsList = () => {
  return {
    type: CONTACTS_LIST,
    payload: {},
  };
};

export const contactsCreate = (contacts) => {
  return {
    type: CONTACTS_CREATE,
    payload: contacts,
  };
};

export const contactsFilter = (changeUrl) => {
  return {
    type: CONTACTS_FILTER,
    payload: { changeUrl },
  };
};

export const contactsUpdate = (id, data) => {
  return {
    type: CONTACTS_UPDATE,
    payload: { id, data },
  };
};
export const contactsDelete = (id) => {
  return {
    type: CONTACTS_DELETE,
    payload: { id },
  };
};

export const contactsListSuccess = (contacts) => {
  return {
    type: CONTACTS_LIST_SUCCESS,
    payload: contacts,
  };
};

export const contactsCreateSuccess = (contactsCreated) => {
  return {
    type: CONTACTS_CREATE_SUCCESS,
    payload: contactsCreated,
  };
};

export const contactsUpdateSuccess = (contactsUpdated) => {
  return {
    type: CONTACTS_UPDATE_SUCCESS,
    payload: contactsUpdated,
  };
};

export const contactsDeleteSuccess = (contactsDeleted) => {
  return {
    type: CONTACTS_DELETE_SUCCESS,
    payload: contactsDeleted,
  };
};

export const contactsRequestFailed = (error) => {
  return {
    type: CONTACTS_REQUEST_FAILED,
    payload: error,
  };
};
