import React from 'react'
import { TbEyeClosed, TbEye } from "react-icons/tb";

const Visualizador = ({ showVisualizador, datos, toggleVisibility, visibleStats, referencia }) => {
  return (
    <article
          className={`${
            showVisualizador
              ? "content_visualizador_open"
              : "content_visualizador"
          }`}
          ref={referencia}
        >
          <div className="visualizador_container">
            {datos.map((rendi) => (
              <div
                onClick={() => toggleVisibility(rendi.id)}
                key={rendi.id}
                style={{
                  padding: ".5rem 0",
                  color: visibleStats.includes(rendi.id) && "#e0324b",
                }}
              >
                <span className="content_name_visualizador">
                  {rendi.name}
                  {visibleStats.includes(rendi.id) ? (
                    <TbEye size={24} />
                  ) : (
                    <TbEyeClosed size={24} />
                  )}
                </span>
              </div>
            ))}
          </div>
        </article>
  )
}

export default Visualizador