import React, { useState } from "react"
import MetaTags from 'react-meta-tags';
import { connect, useDispatch } from "react-redux";
import { withRouter, Link, useHistory} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Form,
  Button,
  CardTitle,
  Input,
  Alert,
  FormFeedback,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader"



import { accountCreate } from "../../store/actions";

const AccountCreate = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [formData, setFormData] = useState({
    name: "",
    documentType:"",
    documentNumber:"",
    address: "",
    phone: "",
    city: "",
  });
  
  const {
    name,
    documentNumber,
    address,
    phone,
    city,

  } = formData;
  
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  console.log(formData);
  
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(accountCreate(formData));
  };
  
  const redirect = () => {
    history.push("/account");
  };
 console.log(props);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Cuenta </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="TrueContact" breadcrumbItem=" Cuenta" />
          {props.loading ? (
            <Loader />
          ) : (
          <div className="d-flex justify-content-center">
              {props.accountCreated.id ? (
                <SweetAlert
                  title="¡Hecho!"
                  success
                  showConfirm={true}
                  onConfirm={() => {
                    redirect();
                  }}
                >
                  Se ha
                  agregado <b>{props.accountCreated.nombre}</b> correctamente.
                </SweetAlert>
              ) : null}

              <Card
                style={{
                  width: "60rem",
                }}
              >
                <CardTitle className="ps-3 pt-3">
                  <h2 className="font-size-20">Cuenta</h2>
                </CardTitle>
                <CardBody>
                <Form onSubmit={onSubmit} className="form-control">
            <Row className="justify-content-center" >
              <Col lg={10}>
                <Row className="mt-3">
                  <Col>
                    <Label
                      for="name"                      
                    >
                      Nombre
                    </Label>
                    <Input                      
                      id="name"
                      name="name"
                      type="name"
                      onChange={onChange}
                      value= {name}
                      autoFocus
                      required
      
                    />
                    {props.error && props.error.name ? (
                      <Alert color = 'danger' className="mt-2">{props.error.name}</Alert>
                    ):null}
                  </Col>  
                  <Col>
                    <Label
                      for="documentType"
                      
                    >
                      Tipo de Documento
                    </Label>
                    <select
                      className="form-select"
                      id="documentType"
                      name="documentType"
                      type="text"
                      onChange={onChange}                      
                      required 
                    >
                      <option defaultValue>Seleccione una opcion</option>
                      <option value ="CEDULA">CEDULA</option>
                      <option value ="PASAPORTE">PASAPORTE</option>
                      <option value ="CEDULA_EXTRANJERIA">CEDULA EXTRAJERIA</option>
                      <option value ="NIT">NIT</option>
                      <option value ="OTRO">OTRO</option>
                    </select>
                    {props.error && props.error.documentType ? (
                    <Alert color = 'danger' className="mt-2">{props.error.documentType}</Alert>
                    ):null}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>  
                    <Label
                      for="documentNumber"
                      
                    >
                      Documento
                    </Label>
                    <Input
                     
                      id="documentNumber"
                      name="documentNumber"
                      type="number"
                      onChange={onChange}
                      value={documentNumber}
                      valid={
                        props.error.documentNumber
                          ? false
                          : props.accountCreated.id && true
                      }
                      invalid={
                        props.error
                          ? props.error.documentNumber
                            ? true
                            : false
                          : false
                      }
                      required 
                    />
                   
                    <FormFeedback>{props.error.documentNumber}</FormFeedback>
                    

                  </Col>
                  <Col>
                    <Label
                      for="address"
                      
                    >
                      Direccion
                    </Label>
                    <Input
                      
                      id="address"
                      name="address"
                      type="text"
                      onChange={onChange}
                      value={address}
                      required 
                    />
                    {props.error && props.error.address ? (
                    <Alert color = 'danger' className="mt-2">{props.error.address}</Alert>
                    ):null}

                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>  
                    <Label
                      for="phone"                      
                    >
                      Teléfono
                    </Label>
                    <Input
                     
                      id="phone"
                      name="phone"
                      type="number"
                      onChange={onChange}
                      value={phone}
                      required 
                    />

                  </Col>
                  <Col>
                    <Label
                      for="address"
                      
                    >
                      Ciudad
                    </Label>
                    <Input
                      
                      id="city"
                      name="city"
                      type="text"
                      onChange={onChange}
                      value={city}
                      required 
                    />
                    {props.error && props.error.city ? (
                    <Alert color = 'danger' className="mt-2">{props.error.city}</Alert>
                    ):null}

                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="col-lg-12 justify-content-center mt-4" >
                <Col md={1} className= 'mx-1'>
                  <Link className="btn btn-secondary text-dark" to="/account">
                    Volver
                  </Link>
                </Col>
                <Col md={1} className= 'mx-1'>
                  <Button color="primary" type="submit">
                    Enviar
                  </Button>
                </Col>
              </Row>

                  </Form>
                </CardBody>
              </Card>

            
          </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const { error, loading, accountCreated } = state.Account;
  return { error, loading, accountCreated };
};

export default withRouter(connect(mapStateToProps)(AccountCreate));