import React, { useState } from 'react'
import { TbChevronRight } from "react-icons/tb";
import "../styles/PageLlantas.css"

const FiltroLlantas = () => {

  const [isVisibleTipo, setIsVisibleTipo] = useState(false)
  const [isVisibleCategoria, setIsVisibleCategoria] = useState(false)
  const [isVisibleMarca, setIsVisibleMarca] = useState(false)
  const [isVisibleUTQG, setIsVisibleUTQG] = useState(false)
  const [isVisibleProcedencia, setIsVisibleProcedencia] = useState(false)
  const [isVisibleCarga, setIsVisibleCarga] = useState(false)
  const [isVisibleVelocidad, setIsVisibleVelocidad] = useState(false)
  const [isVisibleLonas, setIsVisibleLonas] = useState(false)
  const [isVisibleBanda, setIsVisibleBanda] = useState(false)
  const [isVisiblePresion, setIsVisiblePresion] = useState(false)
  const [isVisiblePrecio, setIsVisiblePrecio] = useState(false)

  const handleAcordeonTipo = () => {
    setIsVisibleTipo(!isVisibleTipo)
  }

  const handleAcordeonCategoria = () => {
    setIsVisibleCategoria(!isVisibleCategoria)
  }
  const handleAcordeonMarca = () => {
    setIsVisibleMarca(!isVisibleMarca)
  }
  const handleAcordeonUTQG = () => {
    setIsVisibleUTQG(!isVisibleUTQG)
  }
  const handleAcordeonProcedencia = () => {
    setIsVisibleProcedencia(!isVisibleProcedencia)
  }
  const handleAcordeonCarga = () => {
    setIsVisibleCarga(!isVisibleCarga)
  }
  const handleAcordeonVelocidad = () => {
    setIsVisibleVelocidad(!isVisibleVelocidad)
  }
  const handleAcordeonLonas = () => {
    setIsVisibleLonas(!isVisibleLonas)
  }
  const handleAcordeonBanda = () => {
    setIsVisibleBanda(!isVisibleBanda)
  }
  const handleAcordeonPresion = () => {
    setIsVisiblePresion(!isVisiblePresion)
  }
  const handleAcordeonPrecio = () => {
    setIsVisiblePrecio(!isVisiblePrecio)
  }
  

  return (
    <div className="content_filtro">

      {/* Filtro De Precio */}
      <div className="filtros">
      <div >
        <h2 className="title_filtrar_por">FILTRAR POR:</h2>
      </div>
        <div className="filtro_precio">
          <div>
            <div className="content_title" onClick={handleAcordeonPrecio}>
              <div>
                <h3 className="title_filtros">Precio</h3>
              </div>
              <div className={`icon_chevron ${isVisiblePrecio ? "" : "rotate"}`}>
                <TbChevronRight />
              </div>
            </div>
            <div className={`filtros_precios ${isVisiblePrecio ? "active_options_precio" : ""}`}>
              <div>
                <input className="input_range" type="range" />
              </div>
              <div className="content_input_precio">
                <input
                  disabled
                  value="160470"
                  className="input_precio"
                  type="text"
                />
                <span className="guion">-</span>
                <input
                  disabled
                  value="530600"
                  className="input_precio"
                  type="text"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="contenido_filtro">
          <div className="content_title" onClick={handleAcordeonTipo}>
            <div>
              <h3 className="title_filtros">Tipo de categoría</h3>
            </div>
            <div className={`icon_chevron ${isVisibleTipo ? "rotate" : ""}`}>
              <TbChevronRight />
            </div>
          </div>
          <div
            className={`filtros_opciones ${isVisibleTipo ? "active_options" : ""}`}
          >
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  Oro
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  Plata
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  Bronce
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
          </div>
        </div>

        <div className="contenido_filtro">
          <div className="content_title" onClick={handleAcordeonCategoria}>
            <div>
              <h3 className="title_filtros">Categoría</h3>
            </div>
            <div className={`icon_chevron ${isVisibleCategoria ? "rotate" : ""}`}>
              <TbChevronRight />
            </div>
          </div>
          <div className={`filtros_opciones ${isVisibleCategoria ? "active_options" : ""}`}>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  Alto desempeño
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  Ecológico
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  Pavimento alto
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  Rendimiento
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  Turismo
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  Ultra alto desempeño
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="content_title" onClick={handleAcordeonMarca}>
            <div>
              <h3 className="title_filtros">Marca</h3>
            </div>
            <div className={`icon_chevron ${isVisibleMarca ? "rotate" : ""}`}>
              <TbChevronRight />
            </div>
          </div>
          <div className={`filtros_opciones ${isVisibleMarca ? "active_options" : ""}`}>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  Acenda
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  Autogrip
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  Blackhawk
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  Boto
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  Bridgestone
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  Michelin
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  Pirelli
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  Pirelli
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  Pirelli
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  Pirelli
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="content_title" onClick={handleAcordeonUTQG}>
            <div>
              <h3 className="title_filtros">UTQG</h3>
            </div>
            <div className={`icon_chevron ${isVisibleUTQG ? "rotate" : ""}`}>
              <TbChevronRight />
            </div>
          </div>
          <div className={`filtros_opciones ${isVisibleUTQG ? "active_options" : ""}`}>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  300-A-A
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  320-A-A
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  340-A-A
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  360-A-A
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="content_title" onClick={handleAcordeonProcedencia}>
            <div>
              <h3 className="title_filtros">Procedencia</h3>
            </div>
            <div className={`icon_chevron ${isVisibleProcedencia ? "rotate" : ""}`}>
              <TbChevronRight />
            </div>
          </div>
          <div className={`filtros_opciones ${isVisibleProcedencia ? "active_options" : ""}`}>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  Brasil
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  China
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  China, Taiwán
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  Ecuador
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  Indonesia, Japón
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  Filipinas, Japón
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  Estados Unidos
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="content_title" onClick={handleAcordeonCarga}>
            <div>
              <h3 className="title_filtros">Índice de carga</h3>
            </div>
            <div className={`icon_chevron ${isVisibleCarga ? "rotate" : ""}`}>
              <TbChevronRight />
            </div>
          </div>
          <div className={`filtros_opciones ${isVisibleCarga ? "active_options" : ""}`}>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  82 <span>(475 Kg)</span>
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  84 <span>(500 Kg)</span>
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  88 <span>(560 Kg)</span>
                </label>
              </div>

              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="content_title" onClick={handleAcordeonVelocidad}>
            <div>
              <h3 className="title_filtros">Índice de velocidad</h3>
            </div>
            <div className={`icon_chevron ${isVisibleVelocidad ? "rotate" : ""}`}>
              <TbChevronRight />
            </div>
          </div>
          <div className={`filtros_opciones ${isVisibleVelocidad ? "active_options" : ""}`}>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  H <span>(219 Km/h)</span>
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  T <span>(190 Km/h)</span>
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  V <span>(240 Km/h)</span>
                </label>
              </div>

              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="content_title" onClick={handleAcordeonLonas}>
            <div>
              <h3 className="title_filtros">Lonas</h3>
            </div>
            <div className={`icon_chevron ${isVisibleLonas ? "rotate" : ""}`}>
              <TbChevronRight />
            </div>
          </div>
          <div className={`filtros_opciones ${isVisibleLonas ? "active_options" : ""}`}>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  SL
                </label>
              </div>

              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  XL
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="content_title" onClick={handleAcordeonBanda}>
            <div>
              <h3 className="title_filtros">Profundidad de banda</h3>
            </div>
            <div className={`icon_chevron ${isVisibleBanda ? "rotate" : ""}`}>
              <TbChevronRight />
            </div>
          </div>
          <div className={`filtros_opciones ${isVisibleBanda ? "active_options" : ""}`}>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  6.7 mm
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  7 mm
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  7.3 mm
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  7.0 mm
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  7.1 mm
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  7.5 mm
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="content_title" onClick={handleAcordeonPresion}>
            <div>
              <h3 className="title_filtros">Presión máxima</h3>
            </div>
            <div className={`icon_chevron ${isVisiblePresion ? "rotate" : ""}`}>
              <TbChevronRight />
            </div>
          </div>
          <div className={`filtros_opciones ${isVisiblePresion ? "active_options" : ""}`}>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  42
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  43
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  44
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  50
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
            <div className="opcion_filtro">
              <div>
                <label className="label_filtro" htmlFor="">
                  51
                </label>
              </div>
              <div>
                <input className="input_filtros" type="checkbox" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FiltroLlantas