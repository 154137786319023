import React, { useEffect, useState } from 'react';
import { Button, Card, CardTitle, Col, Input, Row } from 'reactstrap';
import {postGestionEvent } from '../../../helpers/backend';
import styles from "./EventDetails.module.scss"

const EventDetails = ({ selectedEvent, groups, eventGestion, getGestionData, handleEventUpdate, setEventId, setDeleteConfirm, user, formatDate, formatTime }) => {
  const [inputFocused, setInputFocused] = useState(false);
  const [error, setError] = useState(false);
  const [inputText, setInputText] = useState('');


  const handlePostGestionEvent = async () => {
    if (inputText) {
      try {
        await postGestionEvent({
          event: selectedEvent.id,
          observaciones: inputText
        });
        // Actualiza los datos de gestión después de realizar el post
        await getGestionData(selectedEvent.id);
        setInputText("");
        setInputFocused(false);
      } catch (error) {
        console.error('Error al realizar el post o actualizar los datos de gestión:', error);
      }
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    getGestionData(selectedEvent.id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <Col>
      {selectedEvent && (
        <>
          <Card className="p-2" style={{ borderRadius: '20px', margin: '0px 0' }}>
            <Row>
              <Col lg={12}>
                <Row>
                  <Col lg={9}>
                    <h3 className="mt-3">
                      <b>{selectedEvent.title}</b>
                    </h3>
                  </Col>
                  {(groups.includes('SuperAdmin') || groups.includes('Supervisor')) ? (
                    <Col lg={3} className='text-end mt-1'>
                      <Button className='btn-sm me-1' style={{ borderRadius: '20px' }} color='info' onClick={() => handleEventUpdate(selectedEvent.id)}>
                        <span className="fas fa-pencil-alt align-middle"></span>
                      </Button>
                      <Button className='btn-sm me-2' style={{ borderRadius: '20px' }} color='danger' onClick={() => { setEventId(selectedEvent.id); setDeleteConfirm(true) }}>
                        <span className="fa fa-trash align-middle "></span>
                      </Button>
                    </Col>
                  ) : null}
                </Row>
                <hr className="mt-0" />
                <p>
                  Comienza: {formatDate(selectedEvent.start)} a las{' '}
                  {formatTime(selectedEvent.start)}
                </p>
                <p>
                  Termina: {formatDate(selectedEvent.end)} a las{' '}
                  {formatTime(selectedEvent.end)}
                </p>
              </Col>
            </Row>
          </Card>
          <Card style={{ margin: '5px 0' }}>
            <CardTitle className='ms-1 mt-1 font-size-12' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {user.get_full_name}
            </CardTitle>
            <hr className='mt-0 mb-0' />
            <Row>
              <Col>
                <Input
                  type='textarea'
                  className='font-size-12'
                  style={{ border: 'none', boxShadow: 'none' }}
                  placeholder='Agrega un comentario'
                  onFocus={() => { setInputFocused(true); setError(false) }}
                  onChange={(e) => setInputText(e.target.value)}
                  value={inputText}
                />
              </Col>
            </Row>
            <Row>
              <Col className='d-flex justify-content-end m-1'>
                {error && (
                  <p className='text-danger'>Debe escribir un comentario</p>
                )}
                {inputFocused && (
                    <>
                  <span
                    onClick={handlePostGestionEvent}
                    className='fas fa-check fa-lg m-1'
                    style={{
                      fontSize: '14px',
                      cursor: 'pointer',
                      color: 'gray',
                      transition: 'color 0.3s'
                    }}
                    onMouseOver={(e) => e.currentTarget.style.color = 'blue'}
                    onMouseOut={(e) => e.currentTarget.style.color = 'black'}
                  />
                  </>
                )}
              </Col>
            </Row>
          </Card>
          <Row className="d-flex justify-content-center align-items-center">
            {eventGestion && (Array.isArray(eventGestion) ? eventGestion.map(item => (
              <Card className={styles.card} style={{ margin: '3px 0', width: '480px' }}>
                <CardTitle className='font-size-12 d-flex justify-content-between align-items-center'>
                  <div>
                    {item.user}
                  </div>
                  <div className='text-end'>
                    {item.created_date}
                  </div>
                </CardTitle>
                <Row>
                  <Col className='font-size-12'>
                    <p>{item.observaciones}</p>
                  </Col>
                </Row>
              </Card>
            )) : [])}
          </Row>
        </>
      )}
    </Col>
  );
};

export default EventDetails;
