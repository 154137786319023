import React, { useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Col, Form, Input, Label, Modal, Row } from 'reactstrap';
import { postConocimientoHoja } from '../../helpers/backend';
import SweetAlert from "react-bootstrap-sweetalert";

export const HvConocimiento = (props) => {
  const { isOpen, onClose, success } = props;
  const [alert, setAlert] = useState(false)

  const [formData, setFormData] = useState({
    aptitud: "",
    descripcion:"",
    hoja_de_vida: props.hojaDeVida,

});

const {  aptitud , descripcion } =
 formData;

const onChange = (e) => {
 setFormData({ ...formData, [e.target.name]: e.target.value });
};

const onSubmit = async () => {
    const response = await postConocimientoHoja(formData);
    const data = response.data;
    if (data.id) {
        setAlert(true);
        success()
      }
  };

return (
<>
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered
      data-toggle="modal"
      size="lg"
      className="custom-modal"
      toggle={onClose} // Utiliza la función de cerrar del padre para cerrar el modal
    >
        <div>
            <h3 className='text-dark mt-4 ms-4 '><b>Agregar Conocimiento</b> </h3>
            <div className='modal-body'>
              <Form className='form-control'>
                <Row className='justify-content-center'>
                    <Col lg={6} className="mb-3">                    
                        <Label
                        for="aptitud"
                        className="form-label"
                        >
                        Aptitudes
                        </Label>
                        <Input
                        id="aptitud"
                        name="aptitud"
                        type="text"
                        onChange={onChange}
                        value={aptitud}
                        required
                        />
                
                    </Col>                  
                </Row>
                <Row className='justify-content-center'>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="descripcion"
                        className="form-label"
                      >
                      Descripción
                      </Label>
                      <Input
                        id="descripcion"
                        name="descripcion"
                        type="text"
                        onChange={onChange}
                        value={descripcion}
                      />
                    </Col>
                </Row>
                <Row className=" justify-content-center mt-3">
                    <Col md={4}>
                      <Link className="btn btn-secondary text-dark" to="#" onClick={onClose}>
                        Volver
                      </Link>
                      <Button color="primary" className="ms-1" onClick={onSubmit}>
                        Guardar
                      </Button>
                    </Col>
                </Row>
              </Form>
            </div>
        </div>
    </Modal>
    {alert ? (
        <SweetAlert
            title="Hecho!"
            success
            timeout={2000}
            showConfirm={false}
            onConfirm={() => {
                onClose();
                setAlert(false); 

            }}
        >
            Se ha agregado los datos con éxito
        </SweetAlert>
    ) : null}
</>         
  );
};
