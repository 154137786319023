import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

// Redux
import { connect, useDispatch} from "react-redux";
import { withRouter, Link, useHistory  } from "react-router-dom";

import { userChangePassword } from "../../../store/actions";

//Import Breadcrumb
import Breadcrumb from "../../../components/Common/Breadcrumb";
import Loader from "../../../components/Common/Loader";

const UserChangePassword = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const [alert , setAlert] = useState(false)
  const [showCuPassword, setShowCuPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCoPassword, setShowCoPassword] = useState(false);

  const [formData, setFormData] = useState({
    current_password: "",
    password: "",
    confirm_password: ""
  });

  const { current_password, password, confirm_password } =
    formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
 
  const onSubmit = (e) => {  
  e.preventDefault()  
   dispatch(userChangePassword (user.id,{
      current_password,
      password,
      confirm_password,
    }));
    setAlert(true)
  };
  const redirect = () => {
    history.push("/dashboard");
  };

  const togglePasswordVisibility = (num) => {
    if(num === 1){
      setShowCuPassword(!showCuPassword);
    }
    else if (num===2){
      setShowPassword(!showPassword)
    }
    else if(num === 3 )
    setShowCoPassword(!showCoPassword)
  };
  

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TrueContact | Cambiar Contraseña </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={"TrueContact"} breadcrumbItem={"Cambiar Contraseña"} />
          {props.loading ? (
            <Loader />
          ) : (
            <Card className= "col-lg-5 mx-auto  mt-3">
              {props.userUpdated === 200  ? (
                <SweetAlert
                  title="Hecho!"
                  success
                  timeout={2000}
                  showConfirm={false}
                  onConfirm={() => {
                    redirect();
                  }}
                >
                  Se ha cambiado la contraseña con éxito
                </SweetAlert>
              ) : null}
              
              {props.error.detail && alert ? (
                <SweetAlert
                  title="Lo Sentimos,"
                  error
                  timeout={2000}
                  showConfirm={false}
                  onConfirm={() => {
                  setAlert(false)               
                  }}
                >
              {props.error.detail}
                </SweetAlert>
              ) : null}

              <CardBody>
                <h4 className="card-title">Cambiar Contraseña</h4>
                <Form className="form-control needs-validation" onSubmit={onSubmit}>

                  <Row className="col-lg-12  mt-3">
                    <Col md={5} className="mb-3">
                      <Label
                        for="last_name"
                        className="col-form-label d-flex justify-content-end"
                      >
                      Contraseña Actual
                      </Label>
                    </Col>
                    <Col md={5} className="mb-3 ">
                      <Input
                        id="current_password"
                        name="current_password"
                        type={showCuPassword ? 'text' : 'password'}
                        onChange={onChange}
                        value={current_password}
                        invalid={
                          props.error
                            ? props.error.current_password
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.current_password}</FormFeedback>                      
                    </Col>
                    <Col lg={2} className="mt-1">
                    <span className="password-toggle text-dark" onClick={()=> togglePasswordVisibility(1)}>
                      {showCuPassword ? 
                      <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                    </span>
                    </Col>
                  </Row>
                  <Row className="col-lg-12 justify-content-center">
                    <Col md={5} className="mb-3">
                      <Label
                        for="username"
                        className="col-form-label d-flex justify-content-end"
                      >
                       Nueva Contraseña 
                      </Label>
                    </Col>
                    <Col md={5} className="mb-3">
                      <Input
                        id="password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        onChange={onChange}
                        value={password}
                        invalid={
                          props.error
                            ? props.error.password
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.password}</FormFeedback>
                    </Col>
                    <Col lg={2} className="mt-1">
                    <span className="password-toggle text-dark" onClick={()=>togglePasswordVisibility(2)}>
                      {showPassword ? 
                      <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                    </span>
                    </Col>
                  </Row>
  
                  <Row className="col-lg-12 justify-content-center">
                    <Col md={5} className="mb-3">
                      <Label
                        for="confirm_password"
                        className="col-form-label d-flex justify-content-end"
                      >
                      Confirmar Contraseña 
                      </Label>
                    </Col>
                    <Col md={5} className="mb-3">
                      <Input
                        id="confirm_password"
                        name="confirm_password"
                        type={showCoPassword ? 'text' : 'password'}
                        onChange={onChange}
                        value={confirm_password}                        
                        invalid={
                          props.error
                            ? props.error.confirm_password
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.confirm_password}</FormFeedback>
                    </Col>
                    <Col lg={2} className="mt-1">
                    <span className="password-toggle text-dark" onClick={()=>togglePasswordVisibility(3)}>
                      {showCoPassword ? 
                      <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                    </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}  className="d-flex justify-content-center mt-3">
                      <Link className="btn btn-secondary text-dark" to="/dashboard">
                        Volver
                      </Link>
                      <Button color="primary" type="submit" className="ms-1" >
                        Actualizar
                      </Button>
                    </Col>

                  </Row>
                </Form>
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, userUpdated } = state.User;
  return { error, loading,  userUpdated };
};

UserChangePassword.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  userUpdated: PropTypes.object,
};
export default withRouter(connect(mapStateToProps, { userChangePassword })(UserChangePassword));
