
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { connect, useDispatch } from "react-redux";
import { withRouter, Link, useHistory, useParams} from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Form,
  Button,
  CardTitle,
  Input,
  FormFeedback,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader"

import { getLeadsRead } from "../../helpers/backend";

import {  leadsUpdate, } from "../../store/actions";

const LeadsUpdate = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const id = useParams().id




  const getHerramientaData = async () => {
    const response = await getLeadsRead(id);
    const data = response.data;
    setFormData({
      referencia: data.referencia,
      nombre: data.nombre,
      descripcion: data.descripcion,
      existencia: data.existencia,

    })
  };
useEffect(() => {
  getHerramientaData();
// eslint-disable-next-line react-hooks/exhaustive-deps
},[])



  const [formData, setFormData] = useState({
    referencia: "",
    nombre: "",
    descripcion: "",
    existencia:""

  });
  
  const {
    referencia,
    nombre,
    descripcion,
    existencia

  } = formData;
  
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  
  
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(leadsUpdate( id, formData));
  };
  
  const redirect = () => {
    history.push("/leads");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Prospectos  </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="TrueContact" breadcrumbItem=" Prospectos " />
          {props.loading ? (
            <Loader />
          ) : (
          <div className="d-flex justify-content-center">
              {props.leadsUpdated.id ? (
                <SweetAlert
                  title="¡Hecho!"
                  success
                  showConfirm={true}
                  onConfirm={() => {
                    redirect();
                  }}
                >
                  Se ha
                  agregado <b>{props.leadsUpdated.nombre}</b> correctamente.
                </SweetAlert>
              ) : null}

              <Card
                style={{
                  width: "60rem",
                }}
              >
                <CardTitle className="ps-3 pt-3">
                  <h2 className="mb-3 font-size-20">Leads</h2>
                </CardTitle>
                <CardBody>
                <Form onSubmit={onSubmit}>
                  <Row className="justify-content-md-center" >
                    <Col lg={12}>
                    <Row className="justify-content-md-center">
                      <Col lg={8}>
                        <div className="input-group mb-3">
                          <span className="input-group-text">
                            <i className=" fas fa-barcode fa-lg" />
                          </span>
                          <div className="form-floating">
                            <Input
                              type="number"
                              className="form-control"
                              placeholder="Número de referencia"
                              name="referencia"
                              id="referencia"
                              value={referencia}
                              onChange={onChange}
                              {...(props.error && {
                                ...(props.error.referencia
                                  ? { invalid: true }
                                  : { valid: true }),
                              })}
                              required
                            />
                            <FormFeedback tooltip>
                              {props.error.document_number}
                            </FormFeedback>
                            <Label for="document_number">
                            Código de Referencia
                            </Label>
                          </div>
                        </div>
                      </Col>
                      </Row>
                      <Row className="justify-content-md-center">
                      <Col lg={8}>
                        <div className="input-group mb-3">
                          <span className="input-group-text">
                            <i className="  fas fa-tags fa-lg" />
                          </span>
                          <div className="form-floating">
                            <Input
                              type="nombre"
                              className="form-control"
                              placeholder="Número de referencia"
                              name="nombre"
                              id="nombre"
                              value={nombre}
                              onChange={onChange}
                              {...(props.error && {
                                ...(props.error.nombre
                                  ? { invalid: true }
                                  : { valid: true }),
                              })}
                              required
                            />
                            <FormFeedback tooltip>
                              {props.error.nombre}
                            </FormFeedback>
                            <Label for="document_number">
                            Nombre
                            </Label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                      <Col lg={8}>
                        <div className="input-group mb-3">
                          <span className="input-group-text">
                            <i className="fas fa-align-justify fa-lg" />
                          </span>
                          <div className="form-floating">
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Descripción"
                              name="descripcion"
                              id="descripcion"
                              value={descripcion}
                              onChange={onChange}
                              {...(props.error && {
                                ...(props.error.descripcion
                                  ? { invalid: true }
                                  : { valid: true }),
                              })}
                              required
                            />
                            <FormFeedback tooltip>
                              {props.error.descripcion}
                            </FormFeedback>
                            <Label for="code">Descripción</Label>
                          </div>
                        </div>
                      </Col>
                      </Row>
                      <Row className="justify-content-md-center">
                      <Col lg={8}>
                        <div className="input-group mb-3">
                          <span className="input-group-text">
                            <i className=" fas fa-hashtag fa-lg " />
                          </span>
                          <div className="form-floating">
                            <Input
                              type="number"
                              className="form-control"
                              placeholder="Cantidad"
                              name="existencia"
                              id="existencia"
                              value={existencia}
                              onChange={onChange}
                              {...(props.error && {
                                ...(props.error.existencia
                                  ? { invalid: true }
                                  : { valid: true }),
                              })}
                              required
                            />
                            <FormFeedback tooltip>
                              {props.error.existencia}
                            </FormFeedback>
                            <Label for="full_name">Cantidad</Label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="justify-content-md-center mt-3 ms-5">
                      <Col lg={3}>
                        <Link className="btn btn-secondary text-dark me-4" to="/leads">
                          Cancelar
                        </Link>

                        <Button color="success" type="submit">
                          Actualizar
                        </Button>
                      </Col>
                    </Row>
                    </Col>
                  </Row>
                  </Form>
                </CardBody>
              </Card>

            
          </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const { error, loading, leadsUpdated } = state.Leads;
  return { error, loading, leadsUpdated };
};

export default withRouter(connect(mapStateToProps)(LeadsUpdate));

