import {
  CAMPAIGN_LIST,
  CAMPAIGN_FILTER,
  CAMPAIGN_CREATE,
  CAMPAIGN_UPDATE,
  CAMPAIGN_UPDATE_SUCCESS,
  CAMPAIGN_CREATE_SUCCESS,
  CAMPAIGN_LIST_SUCCESS,
  CAMPAIGN_REQUEST_FAILED,
  CAMPAIGN_DELETE,
  CAMPAIGN_DELETE_SUCCESS,
} from "../campaign/actionTypes";

export const campaignList = () => {
  return {
    type: CAMPAIGN_LIST,
    payload: {},
  };
};

export const campaignCreate = (campaign) => {
  return {
    type: CAMPAIGN_CREATE,
    payload: campaign,
  };
};

export const campaignFilter = (changeUrl) => {
  return {
    type: CAMPAIGN_FILTER,
    payload: { changeUrl },
  };
};

export const campaignUpdate = (id, data) => {
  return {
    type: CAMPAIGN_UPDATE,
    payload: { id, data },
  };
};
export const campaignDelete = (id) => {
  return {
    type: CAMPAIGN_DELETE,
    payload: { id },
  };
};
export const campaignListSuccess = (campaign) => {
  return {
    type: CAMPAIGN_LIST_SUCCESS,
    payload: campaign,
  };
};

export const campaignCreateSuccess = (campaignCreated) => {
  return {
    type: CAMPAIGN_CREATE_SUCCESS,
    payload: campaignCreated,
  };
};

export const campaignUpdateSuccess = (campaign) => {
  return {
    type: CAMPAIGN_UPDATE_SUCCESS,
    payload: campaign,
  };
};
export const campaignDeleteSuccess = (campaignDeleted) => {
  return {
    type: CAMPAIGN_DELETE_SUCCESS,
    payload: campaignDeleted,
  };
};
export const campaignRequestFailed = (error) => {
  return {
    type: CAMPAIGN_REQUEST_FAILED,
    payload: error,
  };
};
