import { call, put, takeEvery } from "redux-saga/effects";

import {
  ACCOUNT_LIST,
  ACCOUNT_FILTER,
  ACCOUNT_CREATE,
  ACCOUNT_UPDATE,
} from "../../store/account/actionTypes";

import {
 accountListSuccess,
 accountCreateSuccess,
 accountUpdateSuccess,
 accountRequestFailed,
} from "../../store/account/actions";

import {
  getAccountList,
  getAccountFilter,
  postAccountCreate,
  patchAccountUpdate,
} from "../../helpers/backend";

function*accountListSaga() {
  console.log("Trayendo lista de account...");
  try {
    const response = yield call(getAccountList);
    if (response.status === 200) {
      yield put(accountListSuccess(response.data));
    } else {
      yield put(accountRequestFailed(response.data));
    }
  } catch (error) {
    yield put(accountRequestFailed(error.response.data));
  }
}

function*accountCreateSaga({ payload:account }) {
  console.log("Creando nuevo account...");
  try {
    const response = yield call(postAccountCreate,account);
    if (response.status === 201) {
      yield put(accountCreateSuccess(response.data));
    } else {
      yield put(accountRequestFailed(response.data));
    }
  } catch (error) {
    yield put(accountRequestFailed(error.response.data));
  }
}

function*accountFilterSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getAccountFilter, { changeUrl });
    if (response.status === 200) {
      yield put(accountListSuccess(response.data));
    } else {
      yield put(accountRequestFailed(response.data));
    }
  } catch (error) {
    yield put(accountRequestFailed(error.response.data));
  }
}

function*accountUpdateSaga({ payload: { id, data } }) {
  console.log("Actualizando datos de la maquiba...");
  try {
    const response = yield call(patchAccountUpdate, { id }, data);
    if (response.status === 200) {
      yield put(accountUpdateSuccess(response.data));
    } else {
      yield put(accountRequestFailed(response.data));
    }
  } catch (error) {
    yield put(accountRequestFailed(error.response.data));
  }
}

export default function*accountSaga() {
  yield takeEvery(ACCOUNT_LIST,accountListSaga);
  yield takeEvery(ACCOUNT_FILTER,accountFilterSaga);
  yield takeEvery(ACCOUNT_CREATE,accountCreateSaga);
  yield takeEvery(ACCOUNT_UPDATE,accountUpdateSaga);
}
