// USER requests
export const USER_LIST = "USER_LIST";
export const USER_CREATE = "USER_CREATE";
export const USER_FILTER = "USER_FILTER";
export const USER_UPDATE = "USER_UPDATE";
export const USER_DELETE = "USER_DELETE";
export const USER_READ = "USER_READ";
export const USER_PERMISSION = "USER_PERMISSION";
export const USER_CHANGE_PASSWORD = "USER_CHANGE_PASSWORD";

// USER success
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_READ_SUCCESS = "USER_READ_SUCCESS";
export const USER_CHANGE_PASSWORD_SUCCESS = "USER_CHANGE_PASSWORD_SUCCESS";
export const USER_PERMISSION_SUCCESS = "USER_PERMISSION_SUCCESS";

// USER failed
export const USER_REQUEST_FAILED = "USER_REQUEST_FAILED";
