import {
  LEADS_LIST,
  LEADS_FILTER,
  LEADS_CREATE,
  LEADS_CREATE_SUCCESS,
  LEADS_LIST_SUCCESS,
  LEADS_REQUEST_FAILED,
  LEADS_UPDATE,
  LEADS_UPDATE_SUCCESS,
} from "../../store/leads/actionTypes";

const initialState = {
  leads: [],
  leadsCreated: {},
  leadsUpdated: {},
  loading: false,
  error: "",
};

const leads = (state = initialState, action) => {
  switch (action.type) {
    case LEADS_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LEADS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LEADS_FILTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LEADS_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LEADS_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        leadsCreated: action.payload,
        error: "",
      };
      break;
    case LEADS_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        leads: action.payload,
        leadsCreated: {},
        leadsUpdated: {},
        error: "",
      };
      break;
    case LEADS_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        leadsUpdated: action.payload,
        error: "",
      };
      break;
    case LEADS_REQUEST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default leads;
