import React, { useEffect, useState } from 'react'
import NavbarHome from './components/NavbarHome'
import "./components/Home.css"
import { HomeInicial } from './components/HomeInicial'
import ProductosDestacados from './components/ProductosDestacados'
import DistribucionesLlantas from './components/DistribucionesLlantas'
import FooterHome from './components/FooterHome'
import { MetaTags } from 'react-meta-tags'
import LoadingHome from '../Loading/LoadingHome'

const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1800);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <LoadingHome />
    );
  }

  return (
    <main className="bg-red-700 h-screen home">
      <MetaTags>
        <title>Colombia Ruedas - Llantas y Rines para toda Colombia</title>
      </MetaTags>
      <NavbarHome />
      <HomeInicial />
      <ProductosDestacados />
      <DistribucionesLlantas />
      <FooterHome />
    </main>
  );
}

export default Home