import {
  DESCRIPCION_CALIFICACION_LIST,
  DESCRIPCION_CALIFICACION_LIST_CHANGE,
  DESCRIPCION_CALIFICACION_LIST_SUCCESS,
  DESCRIPCION_CALIFICACION_LIST_FAILED,
  DESCRIPCION_CALIFICACION_LISTA,
  DESCRIPCION_CALIFICACION_LISTA_SUCCESS,
  DESCRIPCION_CALIFICACION_LISTA_FAILED,
  DESCRIPCION_CALIFICACION_ADD,
  DESCRIPCION_CALIFICACION_ADD_SUCCESS,
  DESCRIPCION_CALIFICACION_ADD_FAILED,
  DESCRIPCION_CALIFICACION_DELETE,
  DESCRIPCION_CALIFICACION_DELETE_SUCCESS,
  DESCRIPCION_CALIFICACION_DELETE_FAILED,
} from "./actionTypes";

const initialState = {
  descripcionCalificacion: [],
  descripcionCalificacionAdded: {},
  loading: false,
  error: "",
};

const descripcionCalificacion = (state = initialState, action) => {
  switch (action.type) {

    case DESCRIPCION_CALIFICACION_LIST:
      state = {
        ...state,
        loading: true,
      };
      return state;

      case DESCRIPCION_CALIFICACION_LIST_CHANGE:
        state = {
          ...state,
          loading: true,
        };
        return state;
    case DESCRIPCION_CALIFICACION_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        descripcionCalificacion: action.payload,
        descripcionCalificacionAdded: {},
      };
      return state;
    case DESCRIPCION_CALIFICACION_LIST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      return state;

    case DESCRIPCION_CALIFICACION_LISTA:
      state = {
        ...state,
        loading: true,
      };
      return state;

    case DESCRIPCION_CALIFICACION_LISTA_SUCCESS:
      state = {
        ...state,
        loading: false,
        descripcionCalificacion: action.payload,
        descripcionCalificacionAdded: {},
      };
      return state;
    case DESCRIPCION_CALIFICACION_LISTA_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      return state;

    case DESCRIPCION_CALIFICACION_ADD:
      state = {
        ...state,
        loading: true,
      };
      return state;
    case DESCRIPCION_CALIFICACION_ADD_SUCCESS:
      state = {
        ...state,
        loading: false,
        descripcionCalificacionAdded: action.payload,
        error: "",
      };
      return state;
    case DESCRIPCION_CALIFICACION_ADD_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      return state;


    case DESCRIPCION_CALIFICACION_DELETE:
      state = {
        ...state,
        loading: true,
      };
      return state;
    case DESCRIPCION_CALIFICACION_DELETE_SUCCESS:
      state = {
        ...state,
        loading: false,
        descripcionCalificacionDeleted: action.payload,
        error: "",
      };
      return state;
    case DESCRIPCION_CALIFICACION_DELETE_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      return state;

    
    default:
      state = { ...state };
      break;
  }
    
  return state;
};

export default descripcionCalificacion;
