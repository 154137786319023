import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  Container,
  Row,
  FormFeedback,
  Col,
} from "reactstrap";
import MultipleChoiceOptions from "./components/MultipleChoiceOptions";
// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withRouter, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { surveyCreate } from "../../store/actions";
import { postSurveyQuestion } from "../../helpers/backend";

const SurveyCreate = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [questionShow, setQuestionShow] = useState(false)
  const [alertShow, setAlertShow] = useState(false)
  const [options, setOptions] = useState([]);
  const [savedQuestions, setSavedQuestions] = useState([]);
  const [showSavedQuestions, setShowSavedQuestions] = useState(false);
  const [surveyId, setSurveyId] = useState(null);
  const [surveyData, setSurveyData] = useState({
    name: "",
    survey_medium: "telefonia",
    description: "",
    welcome_message:"",
    campaign:"",
  });

  const [questionData, setQuestionData] = useState({
    text: "",
    number: 0,
  });

  const handleSurveyInputChange = (e) => {
    const { name, value } = e.target;
    setSurveyData({
      ...surveyData,
      [name]: value,
    });
  };

  const handleQuestionInputChange = (e) => {
    const { name, value } = e.target;
    setQuestionData({
      ...questionData,
      [name]: value,
    });
  };
  
  const handleSaveQuestion = () => {
    const newNumber = questionData.number + 1;
    const questionTypeId = parseInt(questionData.questionType, 10);
  
    let newQuestion = {
      ...questionData,
      number: newNumber,
      question_type_id: questionTypeId,
    };
  
    // Agregar options solo si la pregunta no es abierta
    if (questionTypeId !== 3) {
      newQuestion = {
        ...newQuestion,
        options: questionData && options ? options : [],
      };
    }
  
    setSavedQuestions([...savedQuestions, newQuestion]);
    setQuestionData({
      text: "",
      question_type_id: questionTypeId,
      number: newNumber,
    });
    setOptions([""]);
    setShowSavedQuestions(true);
  };
  
 
  const handleRemoveQuestion = (index) => {
    const newSavedQuestions = [...savedQuestions];
    newSavedQuestions.splice(index, 1);
    setSavedQuestions(newSavedQuestions);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(surveyCreate(surveyData));
    setQuestionShow(true);
  };

  const surveyFull = {
    survey_id: surveyId,
    questions: savedQuestions,
  };

  const saveFull = async () => {
    const response = await postSurveyQuestion(surveyFull);
    console.log(response);
    setAlertShow(true)
  }
  
  useEffect(() => {
    if (props.surveyCreated.id) {
      setSurveyId(props.surveyCreated.id);
    }
  }, [props.surveyCreated.id]);
  const redirect = () => {
    history.push("/survey");
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Encuestas </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="TrueContact" breadcrumbItem="Encuestas" />
          {alertShow ? (
                <SweetAlert
                  title="Hecho!"
                  success
                  timeout={2000}
                  showConfirm={false}
                  onConfirm={() => {
                    redirect();
                    setAlertShow(false)
                  }}
                >
                  Se ha creado la encuesta con éxito
                </SweetAlert>
              ) : null}
          <Card className="col-lg-7 mx-auto  mt-3">
            <CardBody>
              <CardTitle>Crear Encuesta</CardTitle>
              <Row>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="name">Nombre de la Encuesta</Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Escribe el nombre de la encuesta"
                      value={surveyData.name}
                      onChange={handleSurveyInputChange}
                      required
                      disabled = {!!surveyId}
                      invalid={
                        props.error
                          ? props.error.name
                            ? true
                            : false
                          : false
                      }
                    />
                     <FormFeedback>{props.error.name}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col lg={5}>
                  <FormGroup>
                    <Label for="survey_medium">
                      Canal de Comunicación
                    </Label>
                    <Input
                      type="select"
                      name="survey_medium"
                      id="survey_medium"
                      value={surveyData.survey_medium}
                      onChange={handleSurveyInputChange}
                      disabled ={!!surveyId}
                    >
                      <option value="telefonia">Telefonía</option>
                      <option value="whatsapp">WhatsApp</option>
                      <option value="general">General</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label for="campaign">Campaña</Label>
                    <Input
                      type="text"
                      name="campaign"
                      id="campaign"
                      placeholder="Escribe una campaña"
                      value={surveyData.campaign}
                      onChange={handleSurveyInputChange}
                      required
                      disabled ={!!surveyId}
                      invalid={
                        props.error
                          ? props.error.campaign
                            ? true
                            : false
                          : false
                      }
                    />
                     <FormFeedback>{props.error.campaign}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3">
              <Col lg={11}>
                  <FormGroup>
                    <Label for="welcome_message">
                      Mensaje de Bienvenida
                    </Label>
                    <Input
                      type="textarea"
                      name="welcome_message"
                      id="welcome_message"
                      placeholder="Escribe una descripción"
                      value={surveyData.welcome_message}
                      onChange={handleSurveyInputChange}
                      disabled ={!!surveyId}
                      invalid={
                        props.error
                          ? props.error.welcome_message
                            ? true
                            : false
                          : false
                      }
                    />
                     <FormFeedback>{props.error.welcome_message}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg={11}>
                  <FormGroup>
                    <Label for="description">Descripción</Label>
                    <Input
                      type="textarea"
                      name="description"
                      id="description"
                      placeholder="Escribe una descripción"
                      value={surveyData.description}
                      onChange={handleSurveyInputChange}
                      disabled ={!!surveyId}
                    />
                  </FormGroup>
                </Col>
                <Col lg={1} className="text-end mt-5">
                  <Button
                    style={{ borderRadius: "20px" }}
                    color="secondary"
                    onClick={handleSubmit}
                  >
                    <i className="fa fa-check text-dark" />
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
          
          {/* Tarjeta que muestra la pregunta y respuesta creada*/}
          {showSavedQuestions && (
            savedQuestions.map((savedQuestion, index) => (
                <Card className="col-lg-7 mx-auto mt-3" key={index}>
                    <Row className="p-2">
                        <Col lg={11}>
                        <p><b>{index + 1}.-</b> {savedQuestion.text}</p>
                        {savedQuestion.question_type_id === 1 && (
                        savedQuestion.options.map((option, optionIndex) => (
                            <p key={optionIndex}>
                            <Input type="radio" className="me-1" name={`question_${index}`} value={option} />
                            {option.text}
                            </p>
                        ))
                        )}
                        {savedQuestion.question_type_id === 2 && (
                        savedQuestion.options.map((option, optionIndex) => (
                            <p key={optionIndex}>
                            <Input type="checkbox" className="me-1" name={`question_${index}`} value={option} />
                            {option.text}
                            </p>
                        ))
                        )}
                        {savedQuestion.question_type_id === 3 && (             
                            <Input type="textarea" className="me-1"/>
                        )}
                        </Col>
                        <Col lg={1} className="d-flex align-items-center justify-content-center text-center">                       
                        <Button
                          style={{ borderRadius: "20px"}}
                          color="danger"
                          onClick={() => handleRemoveQuestion(index)}
                        > <span className="fas fa-minus "></span></Button>   
                        </Col>

                    </Row>
                </Card>
            ))
            )}

          {/*Card para Preguntas */}
          {questionShow ? (
            <Card className="col-lg-7 mx-auto mt-3">
              <CardBody>
                <CardTitle>Agregar Pregunta</CardTitle>
                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <Label for="text">Pregunta</Label>
                      <Input
                        type="text"
                        name="text"
                        id="text"
                        placeholder="Escribe la pregunta"
                        value={questionData.text}
                        onChange={handleQuestionInputChange}
                        autoFocus
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label for="questionType">Tipo de Pregunta</Label>
                      <Input
                        type="select"
                        name="questionType"
                        id="questionType"
                        value={questionData.questionType}
                        onChange={handleQuestionInputChange}
                      >
                        <option defaultValue = 'default'>------------</option>
                        <option value= "1" >Selección Simple</option>
                        <option value="2">Selección Múltiple</option>
                        <option value="3">Pregunta Abierta</option>                        
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                {questionData.questionType === "1" || 
                  questionData.questionType === "2" ? (
                <div className="mt-3">

                    <MultipleChoiceOptions
                        options={options}
                        setOptions={setOptions}
                    />
                <Row>
                    <Col className="text-end">
                    <Button
                        style={{ borderRadius: "20px" }}
                        color="success"
                        onClick={handleSaveQuestion}
                        disabled={!questionData.text}
                    > <span className="fas fa-check"></span>                 
                    </Button>  
                    </Col>
                </Row>
                </div>                  
                ): null}

                {questionData.questionType === "3" ? (
                <Row>
                    <Col className="text-end mt-3">
                    <Button
                        style={{ borderRadius: "20px" }}
                        color="success"
                        onClick={handleSaveQuestion}
                        disabled={!questionData.text}
                    > <span className="fas fa-check"></span>                 
                    </Button>  
                    </Col>
                </Row>
                ):null}
              </CardBody>
              <Row>
            <Col className="text-end m-3">
            <Button
                style={{ borderRadius: "20px" }}
                color="warning"
                onClick={saveFull}
            > Crear encuesta                
            </Button>  
            </Col>
          </Row>
            </Card>
          ) : null}

        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  const { error, loading, surveyCreated } = state.Survey;
  return { error, loading, surveyCreated };
};

export default withRouter(connect(mapStateToProps)(SurveyCreate));