import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Col,  Row } from 'reactstrap';
import { deleteCalendarEvent, getCalendarEventContact,  getContactsRead,  getEventGestion,  getTasksFullList, } from '../../helpers/backend';
import { EventForm } from '../Modales/EventForm';
import SweetAlert from "react-bootstrap-sweetalert";
import EventDetails from './Components/EventDetails';
const localizer = momentLocalizer(moment);

function CalendarComponent(props) {
  const contact = props.contact;
  const user = JSON.parse(localStorage.getItem("user"))
  const groups =String(user.groups);

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventList, setEventList] = useState([]);

  const [tasksList, setTasksList] = useState([]);
  const [availabilityList, setAvailabilityList] = useState([]);
  const [eventId, setEventId] = useState(null)
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const [deleteSuccess, setDeleteSuccess] = useState(false)
  const [modalEventOpen, setModalEventOpen] = useState(false)
  const [eventGestion, setEventGestion] = useState([]);


  useEffect(() => {
    fetchEventData();
    getData();
    getTasksData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (selectedEvent) {
      getGestionData(selectedEvent.id);
    }
  }, [selectedEvent]);

  async function fetchEventData() {
    try {
        const response = await getCalendarEventContact(contact);
        setEventList(response.data);
              
    } catch (error) {
      console.error('Error al obtener datos del calendario:', error);
    }
  }

  const formatEventData = (eventData) => {
    return {
      id: eventData.id,
      title: eventData.title,
      start: new Date(eventData.start),
      end: new Date(eventData.end),
      colorEvento: eventData.color ? eventData.color : 'yellow',
      color: eventData.color === 'yellow' ? 'black' : 'white' ,
    };
  };
  const formatTasksData = (taskData) => {
    return {
      id: taskData.id,
      title: taskData.title,
      start: new Date(taskData.start),
      due_date: new Date(taskData.due_date),
      colorEvento: 'blue',

    };
  };
  const getData = async () => {
    try {
      const response = await getContactsRead(contact);
      const data = response.data.availability;
      setAvailabilityList(data);
    } catch (error) {
      console.error('Error al obtener datos de disponibilidad:', error);
    }
  };
  const getTasksData = async () => {
    try {
      const response = await getTasksFullList();
      const data = response.data;
      setTasksList(data);
    } catch (error) {
      console.error('Error al obtener datos de Tareas:', error);
    }
  };
  const getGestionData = async (eventId) => {
    try {
      const response = await getEventGestion(eventId);
      const data = response.data;
      setEventGestion(data);
    } catch (error) {
      console.error('Error al obtener datos de gestión:', error);
    }
  };
  const availabilityWithDayOfWeek = Array.isArray(availabilityList)
    ? availabilityList.filter((item) => item.dayOfWeek)
    : [];

  const availabilityWithDate = Array.isArray(availabilityList)
    ? availabilityList.filter((item) => item.date)
    : [];

  const availabilityEvents = [];
  const currentDate = moment();
  const weeksToAdd = 10;
  for (let i = 0; i < weeksToAdd; i++) {
    availabilityWithDayOfWeek.forEach((available) => {
      const startDate = currentDate
        .clone()
        .day(available.dayOfWeek)
        .hour(Number(available.startTime.split(':')[0]))
        .minute(Number(available.startTime.split(':')[1]));

      const endDate = currentDate
        .clone()
        .day(available.dayOfWeek)
        .hour(Number(available.endTime.split(':')[0]))
        .minute(Number(available.endTime.split(':')[1]));

      availabilityEvents.push({
        title: available.availabilityStatus === 'disponible' ? '' : '',
        start: startDate.toDate(),
        end: endDate.toDate(),
        colorEvento: '#99cc99',
        color: 'white',
      });
    });

    currentDate.add(1, 'week');
  }

const specificEvents = Array.isArray(availabilityWithDate)
  ? availabilityWithDate.map((available) => {
      const startDateTime = moment(available.date).set({
        hour: Number(available.startTime.split(':')[0]),
        minute: Number(available.startTime.split(':')[1]),
      });

      const endDateTime = moment(available.date).set({
        hour: Number(available.endTime.split(':')[0]),
        minute: Number(available.endTime.split(':')[1]),
      });

      return {
        title: available.availabilityStatus === 'disponible' ? '' : '',
        start: startDateTime.toDate(),
        end: endDateTime.toDate(),
        colorEvento: '#99cc99',
        color: 'white',
        specificEvent: true, // Marca los eventos específicos
      };
    })
  : [];

// Filtra los eventos de disponibilidad recurrente que coinciden con los días de los eventos específicos
const availabilityEventsFiltered = availabilityEvents.filter((availabilityEvent) => {
  return !specificEvents.some((specificEvent) => {
    return (
      moment(availabilityEvent.start).isSame(specificEvent.start, 'day') &&
      moment(availabilityEvent.end).isSame(specificEvent.end, 'day')
    );
  });
});

const events = Array.isArray(eventList)
? eventList.map(formatEventData)
: [];

const tasks = Array.isArray(tasksList)
? tasksList.map(formatTasksData)
: [];


const allEvents = [ ...events, ...specificEvents, ...availabilityEventsFiltered, ...tasks ];


  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('es-ES', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const formatTime = (time) => {
    return new Date(time).toLocaleTimeString('es-ES', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  const handleEventSelect = (event) => {
    setSelectedEvent(event);
  };

  const handleEventUpdate = (id) => {
    setEventId(id)
    setModalEventOpen(true)
  }
  return (
    <React.Fragment>
      <Row>
        <Col lg={7}>
          <div style={{ height: '600px' }}>
            <Calendar
              localizer={localizer}
              events={allEvents}
              startAccessor="start"
              endAccessor="end"
              style={{ backgroundColor: 'white' }}
              onSelectEvent={handleEventSelect}
              eventPropGetter={(event) => {
                const backgroundColor = event.colorEvento
                  ? event.colorEvento
                  : '';
                const color = event.color ? event.color : 'black';
                return { style: { backgroundColor, color } };
              }}
            />
          </div>
        </Col>
        {selectedEvent && (
        <Col lg={5}>
        <EventDetails
          selectedEvent={selectedEvent}
          groups={groups}
          handleEventUpdate={handleEventUpdate}
          getGestionData={getGestionData}
          eventGestion={eventGestion}
          setEventId={setEventId}
          setDeleteConfirm={setDeleteConfirm}
          user={user}
          formatDate={formatDate}
          formatTime={formatTime}
        />
        </Col>
        )}
      </Row>
      {modalEventOpen && 
        <EventForm 
          contact ={contact}
          isOpen = {modalEventOpen}
          onClose = {()=> setModalEventOpen(false)}
          id = {eventId} />
      }
      {deleteConfirm ? (
        <SweetAlert
          title="¿Estás seguro de que quieres eliminar este Evento ?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            deleteCalendarEvent(eventId);
            setDeleteSuccess(true)            
            }}
          onCancel={() => {              
            setDeleteConfirm(false)
          }}
        >
        </SweetAlert>
      ) : null}
      {deleteSuccess ? (
        <SweetAlert
            title="Hecho!"
            success
            timeout={2000}
            showConfirm={false}
            onConfirm={() => {
              setDeleteConfirm(false)
              setDeleteSuccess(false)
              fetchEventData();
            }}
        >
        </SweetAlert>
      ) : null}
    </React.Fragment>
  );
}

export default CalendarComponent;
