import React from 'react'
import ProductsLists from './ProductsLists'
import product1 from "../../../assets/images/producto1_1.webp";
import product5 from "../../../assets/images/producto5.png";
import product3 from "../../../assets/images/producto3.webp";

const ProductosDestacados = () => {
  const products = [
    {
      id: 1,
      name: "Michelin",
      subname: "Primacy 4+",
      category: "High Preformance",
      price: 511.900,
      img: product1
    },
    {
      id: 2,
      name: "GoodYear",
      subname: "Eagle sport 204",
      category: "High Preformance",
      price: 440.900,
      img: product5
    },
    {
      id: 3,
      name: "FullRun",
      subname: "Frun one xp1",
      category: "High Preformance",
      price: 440.9000,
      img: product3
    },
  ]
  return (
    <section id='llantas' className='productos_destacados'>
      <h1 className='title_products'>Productos Destacados</h1>
      <ProductsLists products={products}/>
    </section>
  )
}

export default ProductosDestacados