import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Card, Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom";
import img1 from "../../assets/images/Profile.png"
import img3 from "../../assets/images/gestion.avif"
import calendar from "../../assets/images/calendar.jpg"
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getContactsRead } from "../../helpers/backend";
import { GestionContactoModal } from "../Modales/GestionContactoModal";

const ContactsIndex = () => {
  const history = useHistory()
  const id = useParams().id

  const [contact, setContact]= useState({})
const getContactData = async () => {
  const response = await getContactsRead(id);
  const data = response.data;
  setContact(data);

};
const [isModalGestionOpen, setIsModalGestionOpen] = useState(false);
useEffect(() => {
  getContactData();  
// eslint-disable-next-line react-hooks/exhaustive-deps
},[])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TrueContact | Contactos </title>
        </MetaTags>
      {/* Render Breadcrumbs */}
      <Breadcrumbs title="TrueContact" breadcrumbItem="Contacto" route = {contact.get_full_name} />
        <Container>


        {isModalGestionOpen && (
        <GestionContactoModal
          isOpen={isModalGestionOpen}
          onClose={() => setIsModalGestionOpen(false)}  
          contact = {id}       
          
        />
      )}
          <Row>
            <Col lg={6}>
              <Card style={{ cursor: 'pointer',  display: 'flex' }}
                onClick={()=> history.push(`/contact/${id}/contact_profile`)} >
                <Row style={{ marginLeft: 'auto', alignItems: 'center' }} className="m-2">
                  <Col lg={7}>
                  <h3 className="text-uppercase fw-semibold text-dark"> Perfil del Contacto </h3>
                  </Col>
                  <Col lg={5}>
                    <img src={img1} alt="" height="185px" style={{ maxWidth: '100%', maxHeight: '100%' }}  />
                  </Col>                
                </Row>
              </Card>
            </Col>
            <Col lg={6}>
            <Card style={{ cursor: 'pointer',  display: 'flex' }}
                onClick={() => setIsModalGestionOpen(true)} >
                <Row style={{ marginLeft: 'auto', alignItems: 'center' }} className="m-2">
                  <Col lg={7}>
                  <h3 className="text-uppercase fw-semibold text-dark">Gestiones del Contacto </h3>
                  </Col>
                  <Col lg={5}>
                    <img src={img3} alt="" height="185px" width="250px" style={{ maxWidth: '100%', maxHeight: '100%' }}  />
                  </Col>                
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Card style={{ cursor: 'pointer',  display: 'flex' }}
                onClick={()=> history.push(`/contact/calendar/${id}`)} >
                <Row style={{ marginLeft: 'auto', alignItems: 'center' }} className="m-2">
                  <Col lg={7}>
                  <h3 className="text-uppercase fw-semibold text-dark"> Agenda </h3>
                  </Col>
                  <Col lg={5}>
                    <img src={calendar} alt="" height="185px"  style={{ maxWidth: '100%', maxHeight: '100%' }}  />
                  </Col>                
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ContactsIndex