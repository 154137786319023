import {
  CONTACTS_DATA_LIST,
  CONTACTS_DATA_FILTER,
  CONTACTS_DATA_CREATE,
  CONTACTS_DATA_CREATE_SUCCESS,
  CONTACTS_DATA_LIST_SUCCESS,
  CONTACTS_DATA_REQUEST_FAILED,
  CONTACTS_DATA_UPDATE,
  CONTACTS_DATA_UPDATE_SUCCESS,
  CONTACTS_DATA_DELETE,
  CONTACTS_DATA_DELETE_SUCCESS,
} from "./actionTypes";

const initialState = {
  contactsData: [],
  contactsDataCreated: {},
  contactsDataUpdated: {},
  contactsDataDeleted:false,
  loading: false,
  error: "",
};

const contactsData = (state = initialState, action) => {
  switch (action.type) {
    case CONTACTS_DATA_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CONTACTS_DATA_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CONTACTS_DATA_FILTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CONTACTS_DATA_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CONTACTS_DATA_DELETE:
        state = {
          ...state,
          loading: true,
          contactsDataDeleted: false,
        };
        break;
    case CONTACTS_DATA_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        contactsDataCreated: action.payload,
        error: "",
      };
      break;
    case CONTACTS_DATA_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        contactsData: action.payload,
        contactsDataCreated: {},
        contactsDataUpdated: {},
        contactsDataDeleted: false,
        error: "",
      };
      break;
    case CONTACTS_DATA_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        contactsDataUpdated: action.payload,
        error: "",
      };
      break;
    case CONTACTS_DATA_DELETE_SUCCESS:
      state = {
        ...state,
        loading: false,
        contactsDataDeleted: true,
        error: "",
      };
      break;
    case CONTACTS_DATA_REQUEST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default contactsData;
