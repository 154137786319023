import React, { useState } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { useDispatch } from "react-redux"

// Redux
import { Link, useHistory } from "react-router-dom"

// users
import userDefault from "../../../assets/images/users/default-user-image1.png"

// actions
import { logoutUser } from "../../../store/auth/actions";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const user = JSON.parse(localStorage.getItem("user"))
  const history = useHistory()
  const dispatch = useDispatch()
  
  const onClick = () => {
    dispatch(logoutUser(history))
  }

  return (
    <React.Fragment>

     
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"            
            src={user.image ? user.image : userDefault}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1 text-dark">{user.get_full_name}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>

        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/userProfile">
            <i className="bx bx-user font-size-16 align-middle me-1"/>
            Perfil
          </DropdownItem>
          <DropdownItem tag="a" href="/changePassword">
            <i className="bx bx-wrench font-size-16 align-middle me-1"/>
            Cambiar Contraseña
          </DropdownItem>
          <div className="dropdown-divider"/>
          <Link to="#" className="dropdown-item" onClick={onClick}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"/>
            <span>Logout</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default ProfileMenu

