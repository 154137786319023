import {
  ACCOUNT_LIST,
  ACCOUNT_FILTER,
  ACCOUNT_CREATE,
  ACCOUNT_UPDATE,
  ACCOUNT_UPDATE_SUCCESS,
  ACCOUNT_CREATE_SUCCESS,
  ACCOUNT_LIST_SUCCESS,
  ACCOUNT_REQUEST_FAILED,
} from "../account/actionTypes";

export const accountList = () => {
  return {
    type: ACCOUNT_LIST,
    payload: {},
  };
};

export const accountCreate = (account) => {
  return {
    type: ACCOUNT_CREATE,
    payload: account,
  };
};

export const accountFilter = (changeUrl) => {
  return {
    type: ACCOUNT_FILTER,
    payload: { changeUrl },
  };
};

export const accountUpdate = (id, data) => {
  return {
    type: ACCOUNT_UPDATE,
    payload: { id, data },
  };
};

export const accountListSuccess = (account) => {
  return {
    type: ACCOUNT_LIST_SUCCESS,
    payload: account,
  };
};

export const accountCreateSuccess = (accountCreated) => {
  return {
    type: ACCOUNT_CREATE_SUCCESS,
    payload: accountCreated,
  };
};

export const accountUpdateSuccess = (account) => {
  return {
    type: ACCOUNT_UPDATE_SUCCESS,
    payload: account,
  };
};

export const accountRequestFailed = (error) => {
  return {
    type: ACCOUNT_REQUEST_FAILED,
    payload: error,
  };
};
