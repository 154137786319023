// CONTACTS_DOCUMENT requests
export const CONTACTS_DOCUMENT_LIST = "CONTACTS_DOCUMENT_LIST";
export const CONTACTS_DOCUMENT_CREATE = "CONTACTS_DOCUMENT_CREATE";
export const CONTACTS_DOCUMENT_FILTER = "CONTACTS_DOCUMENT_FILTER";
export const CONTACTS_DOCUMENT_UPDATE = "CONTACTS_DOCUMENT_UPDATE";
export const CONTACTS_DOCUMENT_DELETE = "CONTACTS_DOCUMENT_DELETE";
export const CONTACTS_DOCUMENT_READ = "CONTACTS_DOCUMENT_READ";

// CONTACTS_DOCUMENT success
export const CONTACTS_DOCUMENT_LIST_SUCCESS = "CONTACTS_DOCUMENT_LIST_SUCCESS";
export const CONTACTS_DOCUMENT_CREATE_SUCCESS = "CONTACTS_DOCUMENT_CREATE_SUCCESS";
export const CONTACTS_DOCUMENT_UPDATE_SUCCESS = "CONTACTS_DOCUMENT_UPDATE_SUCCESS";
export const CONTACTS_DOCUMENT_DELETE_SUCCESS = "CONTACTS_DOCUMENT_DELETE_SUCCESS";
export const CONTACTS_DOCUMENT_READ_SUCCESS = "CONTACTS_DOCUMENT_READ_SUCCESS";

// CONTACTS_DOCUMENT failed
export const CONTACTS_DOCUMENT_REQUEST_FAILED = "CONTACTS_DOCUMENT_REQUEST_FAILED";
