import React from 'react';
import { useCart } from '../../ProductosLlantas/components/CartContext';
import visa from "../../../assets/images/visa.png";
import wompi from "../../../assets/images/logo-wompi.svg";
import { TbTrash } from 'react-icons/tb';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';


const CardCarrito = () => {
  const { cart, removeFromCart, incrementQuantity, decrementQuantity } = useCart();
  const totalPagar = cart.reduce((total, item) => total + item.quantity * Number(item.precio), 0);
  const cantidadProductos = cart.reduce((cantidad, item) => cantidad + item.quantity, 0);
  console.log(cart)
  const formatPrecio = (precio) => {
    return precio.toLocaleString('es-ES')
  }

  const history = useHistory();

  const redirect = (path) => {
    history.push(`${path}`)
  };


  return (
    <div className="carrito">
      <div className="container_carrito">
        <div className="container_productos">
          <h2 className="title_carrito">Mi carrito</h2>
          {cart.length === 0 ? (
            <p className="carrito_vacio">El carrito está vacío.</p>
          ) : (
            cart.map((item) => (
              <div className="content_producto_carrito" key={item.id}>
                <div className="container_img_carrito">
                  <Link
                    to={`/llanta-${item.name}-${item.id}`}
                    className="img_carrito"
                  >
                    <img src={item.image} alt="" />
                  </Link>
                </div>
                <div className="container_info_carrito">
                  <div>
                    <Link to={`/llanta-${item.name}-${item.id}`}>
                      <h3 className="name_llanta_card">{item.name}</h3>
                    </Link>
                    <h4 className="medida_producto_carrito">{item.medida}</h4>
                    <div className=" card-carrito">
                      {item.caracteristicas.map((obj) => (
                        <p
                          key={obj.valor}
                          className="caracteristicas_llanta_carrito"
                        >
                          <span className="span_llanta">{obj.clave}</span>:{" "}
                          {obj.valor}
                        </p>
                      ))}
                    </div>
                  </div>
                  <div className="content_actions_carrito">
                    <div className="content_buttons_market">
                      <div className="content_buttons_cantidad">
                        <button
                          className="buttons_cantidad_carrito left"
                          onClick={() => decrementQuantity(item.id)}
                        >
                          -
                        </button>
                        <input
                          className="input_cantidad_carrito"
                          type="text"
                          value={item.quantity}
                          readOnly
                        />
                        <button
                          className="buttons_cantidad_carrito right"
                          onClick={() => incrementQuantity(item.id)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <TbTrash
                      className="button_trash_cart"
                      size={25}
                      title="Borrar de la lista"
                      onClick={() => removeFromCart(item.id)}
                    />
                  </div>
                </div>
                <div>
                  <p className="precio_producto_carrito">
                    ${formatPrecio(item.precio)}
                  </p>
                </div>
              </div>
            ))
          )}
          <div>
            <p
              style={{ textAlign: "end", paddingTop: "10px" }}
              className="subtotal"
            >
              Subtotal ({cantidadProductos} productos):{" "}
              <span className="total_pagar">${formatPrecio(totalPagar)}</span>
            </p>
          </div>
        </div>
        <div className="container_actions">
          <div className="container_pagar">
            <h3 className="title_pagar">Total a pagar </h3>
            <h4 className="subtotal">
              Subtotal ({cantidadProductos} productos):
            </h4>
            <div className="conten_pagar">
              <div className="conten_img_pagar">
                <img className="visa" src={visa} alt="" />
                <img className="wompi" src={wompi} alt="" />
              </div>
              <div>
                <p className="total_pagar">${formatPrecio(totalPagar)}</p>
              </div>
            </div>
            <button
              onClick={() => redirect("/to_buy/154")}
              className="button_pagar"
            >
              Proceder a pagar
            </button>
          </div>
          <div className="container_recomendaciones">
            <h3 className="title_pagar">Recomendaciones</h3>
            <p>Estamos trabajando en ello.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardCarrito;
