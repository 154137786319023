import React from 'react'
import LoadingImg from '../../assets/images/llantas/loading3.png'
import './styles/LoadingCarga.css'

const LoadingCargaFullScreen = () => {
  return (
    <div className="loading_spinner_screen">
      <div className="spinner_screen">
        <img className="img_loading_list_screen" src={LoadingImg} alt="" />
      </div>
      <p>Cargando productos...</p>
    </div>
  );
}

export default LoadingCargaFullScreen