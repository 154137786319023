import { call, put, takeEvery } from "redux-saga/effects";

import {
  LEADS_LIST,
  LEADS_FILTER,
  LEADS_CREATE,
  LEADS_UPDATE,
} from "./actionTypes";

import {
 leadsListSuccess,
 leadsCreateSuccess,
 leadsUpdateSuccess,
 leadsRequestFailed,
} from "./actions";

import {
  getLeadsList,
  getLeadsFilter,
  postLeadsCreate,
  patchLeadsUpdate,
} from "../../helpers/backend";

function*leadsListSaga() {
  console.log("Trayendo lista de leads...");
  try {
    const response = yield call(getLeadsList);
    if (response.status === 200) {
      yield put(leadsListSuccess(response.data));
    } else {
      yield put(leadsRequestFailed(response.data));
    }
  } catch (error) {
    yield put(leadsRequestFailed(error.response.data));
  }
}

function*leadsCreateSaga({ payload:leads }) {
  console.log("Creando nuevo leads...");
  try {
    const response = yield call(postLeadsCreate,leads);
    if (response.status === 201) {
      yield put(leadsCreateSuccess(response.data));
    } else {
      yield put(leadsRequestFailed(response.data));
    }
  } catch (error) {
    yield put(leadsRequestFailed(error.response.data));
  }
}

function*leadsFilterSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getLeadsFilter, { changeUrl });
    if (response.status === 200) {
      yield put(leadsListSuccess(response.data));
    } else {
      yield put(leadsRequestFailed(response.data));
    }
  } catch (error) {
    yield put(leadsRequestFailed(error.response.data));
  }
}

function*leadsUpdateSaga({ payload: { id, data } }) {
  console.log("Actualizando datos del leads...");
  try {
    const response = yield call(patchLeadsUpdate, { id }, data);
    if (response.status === 200) {
      yield put(leadsUpdateSuccess(response.data));
    } else {
      yield put(leadsRequestFailed(response.data));
    }
  } catch (error) {
    yield put(leadsRequestFailed(error.response.data));
  }
}

export default function*leadsSaga() {
  yield takeEvery(LEADS_LIST,leadsListSaga);
  yield takeEvery(LEADS_FILTER,leadsFilterSaga);
  yield takeEvery(LEADS_CREATE,leadsCreateSaga);
  yield takeEvery(LEADS_UPDATE,leadsUpdateSaga);
}
