import {
  GESTIONES_CONTACTO_LIST,
  GESTIONES_CONTACTO_FILTER,
  GESTIONES_CONTACTO_CREATE,
  GESTIONES_CONTACTO_UPDATE,
  GESTIONES_CONTACTO_UPDATE_SUCCESS,
  GESTIONES_CONTACTO_CREATE_SUCCESS,
  GESTIONES_CONTACTO_LIST_SUCCESS,
  GESTIONES_CONTACTO_REQUEST_FAILED,
} from "./actionTypes";

export const gestionesContactoList = () => {
  return {
    type: GESTIONES_CONTACTO_LIST,
    payload: {},
  };
};

export const gestionesContactoCreate = (gestionesContacto) => {
  return {
    type: GESTIONES_CONTACTO_CREATE,
    payload: gestionesContacto,
  };
};

export const gestionesContactoFilter = (changeUrl) => {
  return {
    type: GESTIONES_CONTACTO_FILTER,
    payload: { changeUrl },
  };
};

export const gestionesContactoUpdate = (id, data) => {
  return {
    type: GESTIONES_CONTACTO_UPDATE,
    payload: { id, data },
  };
};

export const gestionesContactoListSuccess = (gestionesContacto) => {
  return {
    type: GESTIONES_CONTACTO_LIST_SUCCESS,
    payload: gestionesContacto,
  };
};

export const gestionesContactoCreateSuccess = (gestionesContactoCreated) => {
  return {
    type: GESTIONES_CONTACTO_CREATE_SUCCESS,
    payload: gestionesContactoCreated,
  };
};

export const gestionesContactoUpdateSuccess = (gestionesContacto) => {
  return {
    type: GESTIONES_CONTACTO_UPDATE_SUCCESS,
    payload: gestionesContacto,
  };
};

export const gestionesContactoRequestFailed = (error) => {
  return {
    type: GESTIONES_CONTACTO_REQUEST_FAILED,
    payload: error,
  };
};
