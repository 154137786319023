/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { BiSend } from 'react-icons/bi';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ChatMessage.css';
import chatLogo from '../../assets/images/chat_logo.png'
import avatar from "../../assets/images/avatardefault2.avif"
import { Row } from 'reactstrap';

function ChatMessage({ contactId, contactName }) {
  const messageContainerRef = useRef(null);
  const URL = process.env.REACT_APP_API_URL.split('/');
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user.id;
  const [conversation, setConversation] = useState([]);
  const [messages, setMessages] = useState("");
  const [alert, setAlert] = useState(false);
  const [conected, setConected] = useState(false);
  const chatSocket = new WebSocket(`wss://${URL[2]}/ws/chat/${userId}/${contactId}/`);

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(e);
    }
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    if(messages){
    chatSocket.send(JSON.stringify({
      type: 'message',
      message: messages,
      id: userId
    }));
   }
   else{
    setAlert(true)
   }
    setMessages("");
    
  };

  useEffect(() => {
  if (chatSocket) {
    chatSocket.onopen = () => {
      setConected(true)
      console.log('websocket conectado');
    };

    chatSocket.onclose = () => {
      console.log('websocket desconectado');
      setConected(false)
    };
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [URL[2], userId, contactId]);

  useEffect(() => {
    chatSocket.onmessage = (message) => {
        const dataFromServer = JSON.parse(message.data);  
        if (dataFromServer) {
          setConversation(dataFromServer)
      };
  // eslint-disable-next-line react-hooks/exhaustive-deps
    }},[URL[2], userId, contactId])

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  }, [conversation]);

  return (
<>
    {!conected ? (
      <div className='d-flex flex-column align-items-center justify-content-center' style={{ height: '80vh' }}>
        <img alt="" src={chatLogo} style={{ height: '250px' }}></img>
        <p className="text-dark">¡Chatea con tus contactos!</p>
      </div>

      ) : (
        <Row>
          <div className="wschat-container">
            <div className="wschat-header">
            <div className="d-flex align-items-center">
              <div className="avatar">
                <img  src={avatar} alt="Avatar" />
              </div>
              <div>
                <h3 className="wscontact-name">{contactName}</h3>
                <p className="wscontact-status"></p>
              </div>
            </div>
              <span className="text-white" style={{ cursor: 'pointer' }}>...</span>
            </div>
            <div className="wsmessage-container" ref={messageContainerRef}>
              {Array.isArray(conversation) ? conversation.map((message, messageIndex) => (
                <div key={messageIndex} className={message.id === userId ? 'wssent-message' : 'wsreceived-message'}>
                  <div className="wsmessage-content">
                    <div className="wsmessage-text">{message.message}</div>
                    <div className="wsmessage-time"></div>
                  </div>
                </div>
              )) : []}
            </div>
            <div className="wsinput-container">
              <input
                type="text"
                className="input-field"
                placeholder={alert ? "No puedes enviar textos vacíos" : "Escribe un mensaje..."}
                value={messages}
                onChange={(e) => { setMessages(e.target.value); setAlert(false) }}
                onKeyDown={handleInputKeyDown}
              />
              <button className="send-button" onClick={handleSendMessage}>
                <BiSend />
              </button>
            </div>
          </div>
        </Row>
      )
      
    }
</>

  );
}

export default ChatMessage;
