import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Label,

  Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumb from "../../../components/Common/Breadcrumb"
import Loader from "../../../components/Common/Loader";

import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";

import {  Link, useHistory, useParams} from "react-router-dom";

import { getPermission, getUserRead, patchPermissionToUser } from "../../../helpers/backend";



const UserPermissions = (props) => {
  const history = useHistory ();
  const user = useParams().id

  const [alert, setAlert] = useState(false)


  const [permissionList, setPermissionList] = useState({});
  const getPermissionData = async () => {
    const response = await getPermission();
    const data = response.data;
    setPermissionList(data);
  };


  const getUserDetailData = async () => {
    const response = await getUserRead(user);
    const data = response.data; console.log(response);
    setFormData({
      user_permission: data.user_permission,
      get_full_name: data.get_full_name,
      grupos: data.grupos,
 
    });
  };
  const [formData, setFormData] = useState({
    user_permission: [],
    get_full_name: '',
    grupos: '',

});

  useEffect(() => {
    getPermissionData();
    getUserDetailData();
 // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [])



 const onSubmit = (e) => {
  e.preventDefault();
  const selectedPermissionsValues = Array.isArray(formData.user_permission) ? formData.user_permission.map((option) => option[1]):[];
  // Crea un nuevo objeto formDataToSend solo con los valores necesarios
  const formDataToSend = {
    ...formData,
    user_permission: selectedPermissionsValues,
  };console.log(formDataToSend);
    patchPermissionToUser( user, formDataToSend );
  setAlert(true)
 };

console.log(formData);
   return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Usuarios| Asignar Permisos  </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={"Roles"} breadcrumbItem={"Asignar Permisos "} />
          {props.loading ? (
            <Loader />
          ) : (
            <div className="d-flex justify-content-center">
            <Card
                 style={{
                width: "50rem",
              }}>
                {alert ? (
                <SweetAlert
                  title="¡Hecho!"
                  success
                  showConfirm={true}
                  onConfirm={() => {
                    history.push('/users');
                  }}
                >
                  Se han
                  agregado los permisos correctamente.
                </SweetAlert>

              ) : null}
              <CardBody>
                <h4 className="card-title">Asignar Permisos al Usuario</h4>
                <Form className="container form-control" onSubmit={onSubmit}>                    
                    <div className="mt-3">
                      <h6><b> Usuario: </b>{formData.get_full_name} </h6>
                      <h6><b> Rol: </b>{Array.isArray(formData.grupos) ? formData.grupos[0][0]:null} </h6>
                    <Row className="mt-3 justify-content-center">
                        <Col lg= {8}>
                            <Label>
                               <b> Permisos</b> 
                            </Label>
                            <Select
                              id="permission"
                              name="permission"                                      
                              options={Array.isArray(permissionList)? permissionList.map(item=> ({label: item.name, value: item.id})):[]}
                              value={formData.user_permission ? formData.user_permission.map(([label, value]) => ({ label, value })): null}
                              onChange={(selectedOptions) => {
                                // Obtener los datos seleccionados de los contactos
                                const selectedContactData = selectedOptions.map((option) => [ option.label, option.value]);
                                // Actualizar formData.contacto con los datos seleccionados
                                setFormData({ ...formData, user_permission: selectedContactData });
                              }}  
                              placeholder ={'Seleccione'}
                              isMulti={true}
                              menuPosition="fixed"
                            />
                        </Col>
                    </Row>
                    </div> 

                  <Row className="col-lg-12 justify-content-center mt-5">
                    <Col md={4} >
                      <Link className="btn btn-secondary text-dark" to="/users">
                        <strong> Volver</strong>
                       
                      </Link>

                      <Button 
                      type = 'submit' 
                      className="btn btn-primary mx-1"
                      color="primary">
                        <strong> Guardar</strong>                       
                      </Button>
                    </Col>

                  </Row>
  
                </Form>               
                
              </CardBody>
            </Card>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};


export default UserPermissions
