import { call, put, takeEvery } from "redux-saga/effects";

import {
  CONTACTS_DOCUMENT_LIST,
  CONTACTS_DOCUMENT_FILTER,
  CONTACTS_DOCUMENT_CREATE,
  CONTACTS_DOCUMENT_UPDATE,
  CONTACTS_DOCUMENT_DELETE
} from "./actionTypes";

import {
 contactsDocumentListSuccess,
 contactsDocumentCreateSuccess,
 contactsDocumentUpdateSuccess,
 contactsDocumentDeleteSuccess,
 contactsDocumentRequestFailed,
} from "./actions";

import {
  getContactsDocumentList,
  getContactsDocumentFilter,
  postContactsDocumentCreate,
  patchContactsDocumentUpdate,
  deleteContactsDocument,
} from "../../helpers/backend";

function*contactsDocumentListSaga() {
  console.log("Trayendo lista de contactsDocument...");
  try {
    const response = yield call(getContactsDocumentList);
    if (response.status === 200) {
      yield put(contactsDocumentListSuccess(response.data));
    } else {
      yield put(contactsDocumentRequestFailed(response.data));
    }
  } catch (error) {
    yield put(contactsDocumentRequestFailed(error.response.data));
  }
}

function*contactsDocumentCreateSaga({ payload:contactsDocument }) {
  console.log("Creando nuevo contactsDocument...");
  try {
    const response = yield call(postContactsDocumentCreate,contactsDocument);
    if (response.status === 201) {
      yield put(contactsDocumentCreateSuccess(response.data));
    } else {
      yield put(contactsDocumentRequestFailed(response.data));
    }
  } catch (error) {
    yield put(contactsDocumentRequestFailed(error.response.data));
  }
}

function*contactsDocumentFilterSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getContactsDocumentFilter, { changeUrl });
    if (response.status === 200) {
      yield put(contactsDocumentListSuccess(response.data));
    } else {
      yield put(contactsDocumentRequestFailed(response.data));
    }
  } catch (error) {
    yield put(contactsDocumentRequestFailed(error.response.data));
  }
}

function*contactsDocumentUpdateSaga({ payload: { id, data } }) {
  console.log("Actualizando datos del CONTACTS_DOCUMENT...");
  try {
    const response = yield call(patchContactsDocumentUpdate, { id }, data);
    if (response.status === 200) {
      yield put(contactsDocumentUpdateSuccess(response.data));
    } else {
      yield put(contactsDocumentRequestFailed(response.data));
    }
  } catch (error) {
    yield put(contactsDocumentRequestFailed(error.response.data));
  }
}

function*contactsDocumentDeleteSaga({ payload: { id } }) {
  console.log("Actualizando datos del Contacto...");
  try {
    const response = yield call(deleteContactsDocument, id );
    if (response.status === 204) {
      yield put(contactsDocumentDeleteSuccess(response.data));
    } else {
      yield put(contactsDocumentRequestFailed(response.data));
    }
  } catch (error) {
    yield put(contactsDocumentRequestFailed(error.response.data));
  }
}

export default function*contactsDocumentSaga() {
  yield takeEvery(CONTACTS_DOCUMENT_LIST,contactsDocumentListSaga);
  yield takeEvery(CONTACTS_DOCUMENT_FILTER,contactsDocumentFilterSaga);
  yield takeEvery(CONTACTS_DOCUMENT_CREATE,contactsDocumentCreateSaga);
  yield takeEvery(CONTACTS_DOCUMENT_UPDATE,contactsDocumentUpdateSaga);
  yield takeEvery(CONTACTS_DOCUMENT_DELETE,contactsDocumentDeleteSaga);
}
