import {
  LEADS_LIST,
  LEADS_FILTER,
  LEADS_CREATE,
  LEADS_UPDATE,
  LEADS_UPDATE_SUCCESS,
  LEADS_CREATE_SUCCESS,
  LEADS_LIST_SUCCESS,
  LEADS_REQUEST_FAILED,
} from "./actionTypes";

export const leadsList = () => {
  return {
    type: LEADS_LIST,
    payload: {},
  };
};

export const leadsCreate = (leads) => {
  return {
    type: LEADS_CREATE,
    payload: leads,
  };
};

export const leadsFilter = (changeUrl) => {
  return {
    type: LEADS_FILTER,
    payload: { changeUrl },
  };
};

export const leadsUpdate = (id, data) => {
  return {
    type: LEADS_UPDATE,
    payload: { id, data },
  };
};

export const leadsListSuccess = (leads) => {
  return {
    type: LEADS_LIST_SUCCESS,
    payload: leads,
  };
};

export const leadsCreateSuccess = (leadsCreated) => {
  return {
    type: LEADS_CREATE_SUCCESS,
    payload: leadsCreated,
  };
};

export const leadsUpdateSuccess = (leads) => {
  return {
    type: LEADS_UPDATE_SUCCESS,
    payload: leads,
  };
};

export const leadsRequestFailed = (error) => {
  return {
    type: LEADS_REQUEST_FAILED,
    payload: error,
  };
};
