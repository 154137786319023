import {
  USER_LIST,
  USER_FILTER,
  USER_CREATE,
  USER_CREATE_SUCCESS,
  USER_LIST_SUCCESS,
  USER_REQUEST_FAILED,
  USER_UPDATE,
  USER_UPDATE_SUCCESS,
  USER_DELETE,
  USER_DELETE_SUCCESS,
  USER_PERMISSION,
  USER_PERMISSION_SUCCESS,
  USER_CHANGE_PASSWORD,
  USER_CHANGE_PASSWORD_SUCCESS,
} from "../../admin/user/actionTypes";

const initialState = {
  user: [],
  userCreated: {},
  userUpdated: {},
  userDeleted:false,
  loading: false,
  error: "",
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case USER_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case USER_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case USER_FILTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case USER_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
      case USER_CHANGE_PASSWORD:
        state = {
          ...state,
          loading: true,
        };
        break;
    case USER_DELETE:
        state = {
          ...state,
          loading: true,
          userDeleted: false,
        };
        break;
    case USER_PERMISSION:
      state = {
        ...state,
        loading: true,
      };
      break
    case USER_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        userCreated: action.payload,
        error: "",
      };
      break;
    case USER_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        userCreated: {},
        userUpdated: {},
        userDeleted: false,
        error: "",
      };
      break;
    case USER_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        userUpdated: action.payload,
        error: "",
      };
      break;
      case USER_CHANGE_PASSWORD_SUCCESS:
        state = {
          ...state,
          loading: false,
          userUpdated: action.payload,
          error: "",
        };
        break;
    case USER_DELETE_SUCCESS:
      state = {
        ...state,
        loading: false,
        userDeleted: true,
        error: "",
      };
      break;

      case USER_PERMISSION_SUCCESS:
      state = {
        ...state,
        loading: false,
        permissionAssigned: action.payload,
        error: "",
      };
      break;
    case USER_REQUEST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default user;
