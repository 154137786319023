import React, { useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import Chat from './Chat'
import { MetaTags } from 'react-meta-tags'
import { SideComponent } from './SideComponent'
import ManagementCreate from './components/ManagementCreate'
import ManagementHistory from './components/ManagementHistory'
import ChatContact from './components/ChatContact'

export const Index = (props) => {
  const [managementOpen, setManagementOpen] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [chatType, setChatType] = useState(null);
  const [managementUpdate, setManagementUpdate] = useState(null);
  const [contactsRefresh, setContactsRefresh] = useState(false);
  const [isLead, setIsLead] = useState(false);
  const [campaignRefresh, setCampaignRefresh] = useState(false);
  const [idConversation, setIdConversation] = useState(null)
  const [leadId, setLeadId] = useState(null);

  const updateManagementOpen = (isOpen) => {
    setManagementOpen(isOpen);
    setHistoryOpen(false);
    setContactOpen(false);
  };
  const updateHistoryOpen = (isOpen) => {
    setHistoryOpen(isOpen);
    setContactOpen(false);
    setManagementOpen(false);
  };
  const updateContactOpen = (isOpen) => {
    setContactOpen(isOpen);
    setHistoryOpen(false);
    setManagementOpen(false);
  };
  const handlePhoneNumberSelect = (phoneNumber) => {
    setSelectedPhoneNumber(phoneNumber);
  };
  const handleContactId = (id) => {
    setSelectedContact(id);
  };
  const handleCampaignId = (id) => {
    setSelectedCampaign(id);
  };
  const handleChatType = (type) => {
    setChatType(type);
  };
  const handleManagementCreateSubmitStatus = (isSuccess) => {
    setManagementUpdate(isSuccess)
  }
  const handleIsLeadUpdate = (isLead) => {
    setIsLead(isLead)
  };
  const handleCampaignRefresh = (campaign) => {
    setCampaignRefresh(campaign)
  }
  const handleIdConversation = (id) => {
    setIdConversation(id)
  }
  const handleLeadIdUpdate = (leadId) => {
    setLeadId(leadId)
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TrueContact | Whatsapp </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col lg={4} style={{ display: managementOpen || historyOpen || contactOpen ? 'none'  : 'block' }}>
              <SideComponent
                onPhoneNumberSelect={handlePhoneNumberSelect}
                onContactId={handleContactId}
                onCampaignId={handleCampaignId}
                onChatType={handleChatType}
                managementUpdate={managementUpdate}
                contactsRefresh={contactsRefresh}
                campaignRefresh={campaignRefresh}
                idConversation = { idConversation}
                setContactsRefresh ={()=> setContactsRefresh(false)}
              />
            </Col>
            <Col lg={managementOpen || historyOpen || contactOpen ? 7 : 8} 
              style={{ position: managementOpen || historyOpen || contactOpen ? 'absolute' : 'relative', 
                left: managementOpen || historyOpen || contactOpen  ? '7%' : 0 }}>
              <Chat
                updateManagementOpen={updateManagementOpen}
                updateHistoryOpen={updateHistoryOpen}
                updateContactOpen={updateContactOpen}
                selectedContact={selectedContact}
                selectedPhoneNumber={selectedPhoneNumber}
                onCampaignId={handleCampaignId}
                chatType = {chatType}
                contactsRefresh={contactsRefresh}
                onIsLeadUpdate={handleIsLeadUpdate}
                campaignRefresh={handleCampaignRefresh}
                idConversation={handleIdConversation}
                onLeadIdUpdate={handleLeadIdUpdate}
                setContactsRefresh ={()=> setContactsRefresh(false)}
                onContactId={handleContactId} //para cuando abren el chat desde contactos 
              />
            </Col>
            {managementOpen && (
              <Col lg={4} style={{ position: 'absolute', right:'2%' }}>
                <ManagementCreate 
                updateManagementOpen={updateManagementOpen}
                selectedContact={selectedContact}
                selectedCampaign={selectedCampaign}
                isLead={isLead}
                leadId={leadId}
                onSubmitStatus={handleManagementCreateSubmitStatus} />
              </Col>
            )}
             {historyOpen && (
              <Col lg={4} style={{ position: 'absolute', right:'2%' }}>
                <ManagementHistory updateHistoryOpen={updateHistoryOpen} />
              </Col>
            )}
              {contactOpen && (
              <Col lg={4} style={{ position: 'absolute', right:'2%' }}>
                <ChatContact 
                  updateContactOpen={updateContactOpen} 
                  selectedContact={selectedContact}
                  contactsRefresh={()=> setContactsRefresh(true)} />
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
