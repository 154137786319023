import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Form,
  Table,
  Input,
  Button,
  InputGroup,
  Modal,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import { connect, useDispatch } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader"
import Pagination from "../../components/Common/Pagination"
import Select from "react-select";
import { campaignList, campaignFilter, campaignDelete } from "../../store/campaign/actions"
import { getContactsOfCampaign, getContactsWitUser, getEncargadosOfCampaign, getSupervisor, postContactsOfCampaign, postEncargadoOfCampaign, postRemoveContactsOfCampaign, postRemoveEncargadosOfCampaign } from "../../helpers/backend";


const Campaign = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user"))
  const groups = String(user.groups);
  const [search, setSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalEncargadoOpen, setModalEncargadoOpen] = useState(false);
  const [idCampaign, setIdCampaign] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  //Delete
  const [confirmText, setConfirmText] = useState(false);
  const [confirmDel, setConfirmDel] = useState("");
  const [alert, setAlert] = useState(false);
  const [contactsList, setContactsList] = useState({});
  const getContactsData = async () => {
    const response = await getContactsOfCampaign(idCampaign);
    const data = response.data;
    setContactsList(data);
  };
  const [encargadosList, setEncargadosList] = useState({});
  const getEncargadosData = async () => {
    const response = await getEncargadosOfCampaign(idCampaign);
    const data = response.data;
    setEncargadosList(data);
  };
  const [contacts, setContacts] = useState({});
  const getContactData = async () => {
    const response = await getContactsWitUser();
    const data = response.data;
    setContacts(data)
  }
  const [supervisor, setSupervisor] = useState({});
  const getSupervisorData = async () => {
    const response = await getSupervisor();
    const data = response.data;
    setSupervisor(data)
  }

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(campaignFilter(`?search=${search}`));
  };

  const resetSearch = () => {
    dispatch(campaignFilter(`?search=`));
    setSearch("");
  };

  const toggleDropdown = (idx) => {
    setDropdownOpen(!dropdownOpen);

  }
  useEffect(() => {
    dispatch(campaignList())
    getContactData()
    getSupervisorData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    if (idCampaign) {
      getContactsData()
      getEncargadosData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCampaign])

  const params = {
    count: props.campaign.count,
    next: props.campaign.next,
    previous: props.campaign.previous,
    results: props.campaign.results,
  };

  const assignContacts = async () => {
    const response = await postContactsOfCampaign(idCampaign, {
      contact: selectedContact
    })
    console.log(response);
    if (response.status === 200) {
      getContactsData()
      dispatch(campaignList())
    }
  }
  const assignEncargado = async () => {
    const response = await postEncargadoOfCampaign(idCampaign, {
      contact: selectedContact
    })
    console.log(response);
    if (response.status === 200) {
      getEncargadosData()
      dispatch(campaignList())
    }
  }
  const removeContacts = async (id) => {
    const response = await postRemoveContactsOfCampaign(idCampaign, {
      contact: [id]
    })
    console.log(response);
    if (response.status === 200) {
      getContactsData()
      dispatch(campaignList())
    }
  }
  const removeEncargado = async (id) => {
    const response = await postRemoveEncargadosOfCampaign(idCampaign, {
      contact: [id]
    })
    console.log(response);
    if (response.status === 200) {
      getEncargadosData()
      dispatch(campaignList())
    }
  }
  const handleConfirm = (id) => {
    setConfirmText(true)
    setConfirmDel(id)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>  TrueContact | Campañas </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="TrueContact" breadcrumbItem=" Campañas" />
          {props.loading ? (
            <Loader />
          ) : (
            <div>
              <Modal
                isOpen={modalOpen}
                toggle={() => setModalOpen(false)}
                centered={true}
                size="lg"
              >
                <div className="modal-header">
                  <h3 className="mt-2">Usuarios</h3>
                  <button
                    type="button"
                    onClick={() => setModalOpen(false)}
                    className="btn-close btn-sm"
                    data-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <h6 className="p-2 text-dark">Agrega un usuario a la Campaña</h6>
                <div className='px-3'>
                  <Row className="d-flex justify-content-center">
                    <Row className="mb-2">
                      <Col lg={10} className="mt-1">
                        <Select
                          options={Array.isArray(contacts)
                            ? contacts.map((item) => ({
                              label: item.get_full_name,
                              value: item.id,
                            }))
                            : []}
                          onChange={(selectedOptions) => {
                            const selectedContactData = selectedOptions.map(
                              (option) => option.value
                            );
                            setSelectedContact(selectedContactData);
                          }}
                          placeholder={"Seleccione"}
                          isMulti={true}
                          menuPosition="fixed"
                        />
                      </Col>
                      <Col lg={2}>
                        <Link
                          to="#"
                          onClick={() => { assignContacts() }}>
                          <span className="font-size-16"><i className=" bx bx-lg bx-plus-circle "></i></span>
                        </Link>
                      </Col>
                    </Row>
                    <Table
                      className="table table-sm  align-middle table-centered table-nowrap mb-3 mt-1"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <thead className="table-dark">
                        <tr>
                          <th>#</th>
                          <th>Nombre</th>
                          <th>Telefono</th>
                          <th>Eliminar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(contactsList) ? contactsList.map((item, idx) => (
                          <tr key={idx}>
                            <th >{idx + 1}</th>
                            <td >{item.get_full_name}</td>
                            <td></td>
                            <td>
                              <span style={{ cursor: 'pointer' }} onClick={() => removeContacts(item.id)}>
                                <i className='fas fa-times'></i>
                              </span>
                            </td>
                          </tr>
                        )) : []}
                      </tbody>
                    </Table>
                  </Row>
                </div>
                <Row className="mt-3 p-2">
                  <Col className="d-flex justify-content-end">
                    <Button className="text-dark btn btn-secondary"
                      onClick={() => setModalOpen(false)}
                    >
                      Cerrar
                    </Button>
                  </Col>
                </Row>
              </Modal>
              <Modal
                isOpen={modalEncargadoOpen}
                toggle={() => setModalEncargadoOpen(false)}
                centered={true}
                size="lg"
              >
                <div className="modal-header">
                  <h3 className="mt-2">Encargados</h3>
                  <button
                    type="button"
                    onClick={() => setModalEncargadoOpen(false)}
                    className="btn-close btn-sm"
                    data-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <h6 className="p-2 text-dark">Agrega un Encargado agregado la Campaña</h6>
                <div className='px-3'>
                  <Row className="d-flex justify-content-center">
                    <Row className="mb-2">
                      <Col lg={10} className="mt-1">
                        <Select
                          id="supervisor"
                          name="supervisor"
                          options={Array.isArray(supervisor)
                            ? supervisor.map((item) => ({
                              label: item.get_full_name,
                              value: item.id,
                            }))
                            : []}
                          onChange={(selectedOptions) => {
                            const selectedContactData = selectedOptions.map(
                              (option) => option.value
                            );
                            setSelectedContact(selectedContactData);
                          }}
                          placeholder={"Seleccione"}
                          isMulti={true}
                          menuPosition="fixed"
                        />
                      </Col>
                      <Col lg={2}>
                        <Link
                          to="#"
                          onClick={() => { assignEncargado() }}>
                          <span className="font-size-16"><i className=" bx bx-lg bx-plus-circle "></i></span>
                        </Link>
                      </Col>
                    </Row>
                    <Table
                      className="table table-sm  align-middle table-centered table-nowrap mb-3 mt-1"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <thead className="table-dark">
                        <tr>
                          <th>#</th>
                          <th>Nombre</th>
                          <th>Eliminar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(encargadosList) ? encargadosList.map((item, idx) => (
                          <tr key={idx}>
                            <th >{idx + 1}</th>
                            <td >{item.get_full_name}</td>
                            <td>
                              <span style={{ cursor: 'pointer' }} onClick={() => removeEncargado(item.id)}>
                                <i className='fas fa-times'></i>
                              </span>
                            </td>
                          </tr>
                        )) : []}
                      </tbody>
                    </Table>
                  </Row>
                </div>
                <Row className="mt-3 p-2">
                  <Col className="d-flex justify-content-end">
                    <Button className="text-dark btn btn-secondary"
                      onClick={() => setModalEncargadoOpen(false)}
                    >
                      Cerrar
                    </Button>
                  </Col>
                </Row>
              </Modal>
              {confirmText ? (
                <SweetAlert
                  title="¿Estás seguro de que quieres eliminar esta Campaña ?"
                  warning
                  showCancel
                  confirmBtnBsStyle="success"
                  confirmBtnText="Si"
                  cancelBtnBsStyle="danger"
                  cancelBtnText="No"
                  onConfirm={() => {
                    dispatch(campaignDelete(confirmDel))
                    setConfirmText(false)
                    setAlert(true)
                  }}
                  onCancel={() => {
                    setConfirmText(false)
                  }}
                >
                </SweetAlert>
              ) : null}
              {alert ? (
                <SweetAlert
                  title="Hecho!"
                  success
                  timeout={2000}
                  showConfirm={false}
                  onConfirm={() => {
                    setConfirmText(false);
                    dispatch(campaignList())
                    setAlert(false)
                  }}
                >
                  Se ha eliminado la Campaña
                </SweetAlert>
              ) : null}
              <Card>
                <CardBody>
                  <Row>
                    <Col md={7}>
                      <Link
                        className="btn btn-primary waves-effect waves-light mb-1"
                        to="/campaign/create"
                      >
                        Agregar
                      </Link>
                      <Dropdown isOpen={dropdownOpen} toggle={() => toggleDropdown()} className="d-inline mx-2">
                        <DropdownToggle variant="secondary" id="dropdown-basic" color="warning">
                          Tipificaciones
                        </DropdownToggle>

                        <DropdownMenu>
                          <DropdownItem onClick={() => { history.push("/tipoGestionContacto") }}>Tipo</DropdownItem>
                          <DropdownItem onClick={() => history.push("/calificacionContacto")}>Resultado</DropdownItem>
                          <DropdownItem onClick={() => history.push("/descripcionCalificacion")}>Calificación</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                    <Col md={5}>
                      <Form className="search-box" onSubmit={onSubmit}>
                        <InputGroup>
                          <Input
                            type="text"
                            className="form-control"
                            name="search"
                            placeholder="Código, nombre, descripción..."
                            value={search}
                            onChange={onChange}
                          />
                          <i className="bx bx-search-alt search-icon" />
                          <Button color="primary" onClick={resetSearch}>
                            <span className="bx bx-x fa-lg align-middle"></span>
                          </Button>
                        </InputGroup>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                {params.results ? (
                  <CardBody className="ps-5 pe-5">
                    <h2 className="mb-3 font-size-20">Lista de Campañas</h2>
                    <div className="table-responsive">
                      <Table
                        className="table  align-middle table-centered table-nowrap mb-3"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <thead className="table-dark">
                          <tr>
                            <th>#</th>
                            <th>Nombre</th>
                            <th>Descripción</th>
                            <th>Encargados</th>
                            <th></th>
                            <th>Acción</th>
                          </tr>
                        </thead>
                        <tbody>
                          {params.results.map((campaign, idx) => (
                            <tr key={idx}>
                              <th >{idx + 1}</th>
                              <td >{campaign.name}</td>
                              <td>{campaign.description}</td>
                              <td>{campaign.encargado && campaign.encargado.map(item => item[0] + ' ')}</td>
                              <td>
                                <Button className="btn-sm" color="success" onClick={() => {
                                  setIdCampaign(campaign.id);
                                  setModalOpen(true)
                                }} >
                                  Usuarios</Button>
                               {groups.includes("SuperAdmin") || groups.includes("Administrador") ?
                                <>
                                <Button className="btn-sm ms-1" color="warning" onClick={() => {
                                  setIdCampaign(campaign.id);
                                  setModalEncargadoOpen(true)
                                }} >
                                  Encargados</Button> 
                                </>  
                              :null}   
                              </td>
                              <td>
                                <Link to={`/campaign/update/${campaign.id}`}>
                                  <span className="bx bx-edit fa-lg align-middle"></span>
                                </Link>{" "}
                                <Link
                                  to="#"
                                  onClick={() => handleConfirm(campaign.id)}
                                >
                                  <span className="fa fa-trash fa-md align-middle"></span>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    <Pagination
                      count={params.count}
                      next={params.next}
                      previous={params.previous}
                      limit={30}
                      baseUrl="campaign/campaign/"
                      filterFunction={campaignFilter}
                    />
                  </CardBody>
                ) : (
                  <CardBody>
                    <h4 className="card-title">No se encontraron resultados</h4>
                  </CardBody>
                )}
              </Card>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}


const mapStateToProps = (state) => {
  const { error, loading, campaign, campaignDeleted } = state.Campaign;
  return { error, loading, campaign, campaignDeleted };
};

export default withRouter(connect(mapStateToProps)(Campaign));
