import React, { useEffect, useState } from 'react';
import "./ContactsList.css"
import { Card, Col, Row } from 'reactstrap';
import avatar from "../../assets/images/avatardefault.png"
import { getUserFullList } from '../../helpers/backend';


const GestionCard = ({ users }) => {
    const cardClassName = users.isActive ? 'ws-gestion-card active ' : 'ws-gestion-card';
  
    return (
      <Card className={cardClassName}>
        <Row>
            <Col lg={2} className='ws-avatar-container'>
            <img className="ws-avatar" src={avatar} alt="Avatar" />
            </Col>
            <Col lg={10}>
            <div className='ws-management-contain'>
              <h6><b>{users.get_full_name}</b> </h6>
            </div>
            </Col>

        </Row>

      </Card>
    );
  };

  const ContactsList = ({ onContactClick }) => {
  // Ejemplo de datos para las tarjetas de gestiones
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');  

  const filteredUsers = users.filter(user =>
    user.get_full_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const getUsersData = async () => {
    const response = await getUserFullList();
    const data = response.data;
    setUsers(data)
  };
  useEffect(() => {
    getUsersData();
  }, [])

  return (
<Card>
    <h6 className="ws-management-header"><b>Contactos</b></h6>
    <div className='d-flex justify-content-center'>
    <input
      type="text"
      className="search-input"
      placeholder="Buscar contacto..."
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
    </div>
    <div className='management-body-container' >
      {filteredUsers.map((data, index) => (
        <div key={index} onClick={() => onContactClick(data.id, data.get_full_name)}>
          <GestionCard users={data} />
        </div>
      ))}
    </div>

  </Card>
  );
};

export default ContactsList;