import React, { useState } from 'react'
import FiltroLlantas from './components/FiltroLlantas'
import Llantas from './components/Llantas'
import './styles/PageLlantas.css'
import ContainerTienda from '../../components/TiendaLayout/ContainerTienda'
import { MetaTags } from 'react-meta-tags'
import { TbLayoutList } from "react-icons/tb";
import { TbLayoutGrid } from "react-icons/tb";

const PageLlantas = () => {
  const [listType, setListType] = useState(() => {
    const savedListType = localStorage.getItem('listType');
    return savedListType ? JSON.parse(savedListType) : false;
  });

  const handleListType = () => {
    const newListType = !listType;
    setListType(newListType);
    localStorage.setItem('listType', JSON.stringify(newListType));
  };

  return (
    <ContainerTienda>
      <MetaTags>
        <title>Resultado de tu busqueda - Colombia Ruedas</title>
      </MetaTags>
      <div className="page_llantas">
        <div className="container_page_llantas">
          <div
            className="container_filtro"
            data-aos="fade-right"
            data-aos-duration="500"
          >
            <FiltroLlantas />
          </div>

          <div className="container_llantas" data-aos-duration="500">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3 className="title_page_llantas">
                Resultados de la medida que escogiste.
              </h3>
              <div className="contain_list_type" onClick={handleListType}>
                {listType ? (
                  <div className="content_list_type">
                    <TbLayoutGrid size={26} strokeWidth={1.5} />
                  </div>
                ) : (
                  <div className="content_list_type">
                    <TbLayoutList size={26} strokeWidth={1.5} />
                  </div>
                )}
              </div>
            </div>
            <div >
              <Llantas listType={listType} />
            </div>
          </div>
        </div>
      </div>
    </ContainerTienda>
  );
}

export default PageLlantas