import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";

import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";

// Redux
import { connect, useDispatch } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";

import { contactsCreate } from "../../../store/actions";


//Import Breadcrumb
import Breadcrumb from "../../../components/Common/Breadcrumb";
import Loader from "../../../components/Common/Loader";
import { getCampaignFullList } from "../../../helpers/backend";

const UserAdd = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const optionGroupTipoIdentificacion = [
    {

      options: [
        { label: "Cédula Ciudadanía", value: "CEDULA" },
        { label: "Pasaporte", value: "PASAPORTE" },
        { label: "Cedula de Extranjería", value: "CEDULA_EXTRANJERIA" },
        { label: "Otro", value: "OTRO" },
      ],
    },

  ];

  const  [selectedTipoIdentificacion, setSelectedTipoIdentificacion] = useState(null); 
  function handleSelectTipoIdentificacion(selectedTipoContacto) {
    setSelectedTipoIdentificacion(selectedTipoContacto.value);
  } 
  const [campaignData, setCampaignData] = useState({});
const getCampaignData = async () => {
  const response = await getCampaignFullList();
  const data = response.data;
  setCampaignData(data);
};

useEffect(() => {
  getCampaignData()
},[dispatch]);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    confirm_password: "",
    first_name: "",
    last_name: "",
    documentNumber: "",
    campaign:[]


  });

  const { username, password, confirm_password, first_name, last_name, documentNumber, campaign } =
    formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    props.contactsCreate({
      username,
      password,
      confirm_password,
      first_name,
      last_name,
      documentNumber,
      contactType: 'PERSONA NATURAL',
      documentType : selectedTipoIdentificacion,
      campaign,

    });
  };

  const redirect = () => {
    history.push("/users");
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TrueContact| Usuarios </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={"TrueContact"} breadcrumbItem={"Usuarios"} />
          {props.loading ? (
            <Loader />
          ) : (
            <Card className= "col-lg-8 mx-auto  mt-3">
              {props.contactsCreated.id ? (
                <SweetAlert
                  title="Hecho!"
                  success
                  timeout={2000}
                  showConfirm={false}
                  onConfirm={() => {
                    redirect();
                  }}
                >
                  Se ha agregado el usuario exitosamente
                </SweetAlert>
              ) : null}
              <CardBody>
                <h4 className="card-title">Agregar usuario</h4>
                <Form className="form-control needs-validation" onSubmit={onSubmit}>
                  <Row>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="username"
                        className="form-label"
                      >
                        Correo Electrónico
                      </Label>
                      <Input
                        id="username"
                        name="username"
                        type="mail"
                        onChange={onChange}
                        value={username}
                        valid={
                          props.error.username
                            ? false
                            : props.contactsCreate.id && true
                        }
                        invalid={
                          props.error
                            ? props.error.username
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.username}</FormFeedback>
                    </Col>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="password"
                        className="form-label"
                      >
                        Contraseña
                      </Label>
                      <Input
                        id="password"
                        name="password"
                        type="password"
                        onChange={onChange}
                        value={password}
                        valid={props.contactsCreate.id ? true : false}
                        invalid={
                          props.error
                            ? props.error.password
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.password}</FormFeedback>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="confirm_password"
                        className="form-label"
                      >
                        Confirmación de Contraseña
                      </Label>
                      <Input
                        id="confirm_password"
                        name="confirm_password"
                        type="password"
                        onChange={onChange}
                        value={confirm_password}
                        valid={props.contactsCreate.id ? true : false}
                        invalid={
                          props.error
                            ? props.error.confirm_password
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>
                        {props.error.confirm_password}
                      </FormFeedback>
                    </Col>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="first_name"
                        className="form-label"
                      >
                        Nombres
                      </Label>
                      <Input
                        id="first_name"
                        name="first_name"
                        type="text"
                        onChange={onChange}
                        value={first_name}
                        valid={props.contactsCreate.id ? true : false}
                        invalid={
                          props.error
                            ? props.error.first_name
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.first_name}</FormFeedback>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="last_name"
                        className="form-label"
                      >
                        Apellidos
                      </Label>
                      <Input
                        id="last_name"
                        name="last_name"
                        type="text"
                        onChange={onChange}
                        value={last_name}
                        valid={props.contactsCreate.id ? true : false}
                        invalid={
                          props.error
                            ? props.error.last_name
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.last_name}</FormFeedback>
                    </Col>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="contactType"
                        className="form-label"
                      >
                      Tipo de identificación
                      </Label>
                    <Select
                            id="documentType"
                            name="documentType"
                            onChange={handleSelectTipoIdentificacion}
                            options={optionGroupTipoIdentificacion}
                            placeholder ={'Seleccione'}
                          />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6} className="mb-3">
                      <Label
                        for="documento"
                        className="form-label"
                      >
                        Documento
                      </Label>
                      <Input
                        id="documentNumber"
                        name="documentNumber"
                        type="number"
                        onChange={onChange}
                        value={documentNumber}
                        valid={props.error.documentNumber
                          ? true
                          : props.contactsCreated && false
                        }
                        invalid={
                          props.error
                            ? props.error.documentNumber
                              ? true
                              : false
                            : false
                        }
                      />
                      <FormFeedback>{props.error.documentNumber}</FormFeedback>
                    </Col>
                    <Col lg ={6} className="mb-3">
                      <Label
                        for="campaign"
                        className="form-label"
                      >
                        Campaña
                      </Label>
                      <Select
                        id="cliente"
                        name="cliente"
                        options={Array.isArray(campaignData)
                          ? campaignData.map((item) => ({
                              label: item.name,
                              value: item.id,
                            }))
                          : []}
                        onChange={(selectedOptions) => {
                          const selectedContactData = selectedOptions.map(
                            (option) => option.value
                          );
                          setFormData({
                            ...formData,
                            campaign: selectedContactData,
                          });
                        }}
                        placeholder={"Seleccione"}
                        isMulti={true}
                        menuPosition="fixed"
                      />
                    </Col>

                  </Row>

                  <Row className="col-lg-12 justify-content-center mt-3">
                    <Col md={5} >
                      <Link className="btn btn-secondary text-dark ms-5" to="/users">
                        Volver
                      </Link>
                      <Button color="primary" type="submit" className="ms-1">
                        Enviar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, contactsCreated } = state.Contacts;
  const { rol } = state.Rol;
  return { error, loading, contactsCreated, rol };
};

UserAdd.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  userCreated: PropTypes.object,
};
export default withRouter(connect(mapStateToProps, { contactsCreate })(UserAdd));
