import React from 'react'

const NavBarAddProductos = ({ handleSubmit }) => {
  return (
    <section className='content_title_productos_form'>
      <div>
        <h1 className="title_productos">Creando Producto</h1>
      </div>
      <div style={{paddingRight: '1rem'}}>
        <button onClick={handleSubmit} className='button_product_agg'>Crear</button>
      </div>
      
    </section>
  )
}

export default NavBarAddProductos