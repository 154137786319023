import {
  CASOS_LIST,
  CASOS_FILTER,
  CASOS_CREATE,
  CASOS_UPDATE,
  CASOS_UPDATE_SUCCESS,
  CASOS_CREATE_SUCCESS,
  CASOS_LIST_SUCCESS,
  CASOS_REQUEST_FAILED,
} from "./actionTypes";

export const casosList = () => {
  return {
    type: CASOS_LIST,
    payload: {},
  };
};

export const casosCreate = (casos) => {
  return {
    type: CASOS_CREATE,
    payload: casos,
  };
};

export const casosFilter = (changeUrl) => {
  return {
    type: CASOS_FILTER,
    payload: { changeUrl },
  };
};

export const casosUpdate = (id, data) => {
  return {
    type: CASOS_UPDATE,
    payload: { id, data },
  };
};

export const casosListSuccess = (casos) => {
  return {
    type: CASOS_LIST_SUCCESS,
    payload: casos,
  };
};

export const casosCreateSuccess = (casosCreated) => {
  return {
    type: CASOS_CREATE_SUCCESS,
    payload: casosCreated,
  };
};

export const casosUpdateSuccess = (casos) => {
  return {
    type: CASOS_UPDATE_SUCCESS,
    payload: casos,
  };
};

export const casosRequestFailed = (error) => {
  return {
    type: CASOS_REQUEST_FAILED,
    payload: error,
  };
};
