import {
  GESTIONES_CONTACTO_LIST,
  GESTIONES_CONTACTO_FILTER,
  GESTIONES_CONTACTO_CREATE,
  GESTIONES_CONTACTO_CREATE_SUCCESS,
  GESTIONES_CONTACTO_LIST_SUCCESS,
  GESTIONES_CONTACTO_REQUEST_FAILED,
  GESTIONES_CONTACTO_UPDATE,
  GESTIONES_CONTACTO_UPDATE_SUCCESS,
} from "../../store/gestionesContacto/actionTypes";

const initialState = {
  gestionesContacto: [],
  gestionesContactoCreated: {},
  gestionesContactoUpdated: {},
  loading: false,
  error: "",
};

const gestionesContacto = (state = initialState, action) => {
  switch (action.type) {
    case GESTIONES_CONTACTO_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GESTIONES_CONTACTO_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GESTIONES_CONTACTO_FILTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GESTIONES_CONTACTO_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GESTIONES_CONTACTO_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        gestionesContactoCreated: action.payload,
        error: "",
      };
      break;
    case GESTIONES_CONTACTO_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        gestionesContacto: action.payload,
        gestionesContactoCreated: {},
        gestionesContactoUpdated: {},
        error: "",
      };
      break;
    case GESTIONES_CONTACTO_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        gestionesContactoUpdated: action.payload,
        error: "",
      };
      break;
    case GESTIONES_CONTACTO_REQUEST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default gestionesContacto;
