import React, { useState } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import HeaderEstadisticas from './components/NavbarEstadisticas';
import Resaltados from './components/Resaltados';
import CorreosAbiertos from './components/CorreosAbiertos';
import ClicksCorreos from './components/ClicksCorreos';
import './styles/Estadisticas.css'

const Estadisticas = () => {
  const [activeSection, setActiveSection] = useState("resaltados");

  const location = useLocation();
  const { llanta} = location.state || {};

  if (!llanta) {
    return <p style={{ paddingTop: "6rem" }}>Llantaaaaaaaaaaaaaa</p>;
  }

  return (
    <main
      style={{ paddingTop: "5rem" }}
      className="container_productos_true_contact_add "
    >
      <section className='content_estadisticas' style={{padding:"0 1rem"}}>
        <div>
          <HeaderEstadisticas onButtonClick={setActiveSection} />
        </div>
        {activeSection === "resaltados" && (
          <div>
            <Resaltados llanta={llanta}/>
          </div>
        )}
        {activeSection === "correosAbiertos" && (
          <div>
            <CorreosAbiertos />
          </div>
        )}
        {activeSection === "clicksCorreos" && (
          <div>
            <ClicksCorreos />
          </div>
        )}
      </section>
    </main>
  );
}

export default Estadisticas