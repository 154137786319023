import {
  AUDITS_LIST,
  AUDITS_FILTER,
  AUDITS_CREATE,
  AUDITS_CREATE_SUCCESS,
  AUDITS_LIST_SUCCESS,
  AUDITS_REQUEST_FAILED,
  AUDITS_UPDATE,
  AUDITS_UPDATE_SUCCESS,
} from "../../store/audits/actionTypes";

const initialState = {
  audits: [],
  auditsCreated: {},
  auditsUpdated: {},
  loading: false,
  error: "",
};

const audits = (state = initialState, action) => {
  switch (action.type) {
    case AUDITS_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case AUDITS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case AUDITS_FILTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case AUDITS_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case AUDITS_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        auditsCreated: action.payload,
        error: "",
      };
      break;
    case AUDITS_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        audits: action.payload,
        auditsCreated: {},
        auditsUpdated: {},
        error: "",
      };
      break;
    case AUDITS_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        auditsUpdated: action.payload,
        error: "",
      };
      break;
    case AUDITS_REQUEST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default audits;
