import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import { Modal } from 'reactstrap';

const MapModal = ({ isOpen, onClose, coords }) => {
  const [latitude, setLatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)
  
  const mapStyles = {
    height: "600px",
    width: "100%"
  };

  const defaultCenter = {
    lat: latitude,
    lng: longitude
  };
useEffect(() => {
  const coordenadaString = coords;
  const coordenadaArray = coordenadaString.split(" ");
    setLatitude(parseFloat(coordenadaArray[0]));
    setLongitude(parseFloat(coordenadaArray[1]));
}, [coords])


  return (
    <Modal
      isOpen={ isOpen}
      toggle={ onClose }
      size='xl'
    >
      <LoadScript
        googleMapsApiKey="AIzaSyCu18FYcih70HvdtUGV5bLmBPGAmWPv6nQ"
      >
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={13}
          center={defaultCenter}
        >
          <Marker position={defaultCenter} />
        </GoogleMap>
      </LoadScript>
    </Modal>
  );
};

export default MapModal;
