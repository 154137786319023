import { call, put, takeEvery } from "redux-saga/effects";

import {
  CASOS_LIST,
  CASOS_FILTER,
  CASOS_CREATE,
  CASOS_UPDATE,
} from "./actionTypes";

import {
 casosListSuccess,
 casosCreateSuccess,
 casosUpdateSuccess,
 casosRequestFailed,
} from "./actions";

import {
  getCasosList,
  getCasosFilter,
  postCasosCreate,
  patchCasosUpdate,
} from "../../helpers/backend";

function*casosListSaga() {
  console.log("Trayendo lista de casos...");
  try {
    const response = yield call(getCasosList);
    if (response.status === 200) {
      yield put(casosListSuccess(response.data));
    } else {
      yield put(casosRequestFailed(response.data));
    }
  } catch (error) {
    yield put(casosRequestFailed(error.response.data));
  }
}

function*casosCreateSaga({ payload:casos }) {
  console.log("Creando nuevo casos...");
  try {
    const response = yield call(postCasosCreate,casos);
    if (response.status === 201) {
      yield put(casosCreateSuccess(response.data));
    } else {
      yield put(casosRequestFailed(response.data));
    }
  } catch (error) {
    yield put(casosRequestFailed(error.response.data));
  }
}

function*casosFilterSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getCasosFilter, { changeUrl });
    if (response.status === 200) {
      yield put(casosListSuccess(response.data));
    } else {
      yield put(casosRequestFailed(response.data));
    }
  } catch (error) {
    yield put(casosRequestFailed(error.response.data));
  }
}

function*casosUpdateSaga({ payload: { id, data } }) {
  console.log("Actualizando datos del casos...");
  try {
    const response = yield call(patchCasosUpdate, { id }, data);
    if (response.status === 200) {
      yield put(casosUpdateSuccess(response.data));
    } else {
      yield put(casosRequestFailed(response.data));
    }
  } catch (error) {
    yield put(casosRequestFailed(error.response.data));
  }
}

export default function*casosSaga() {
  yield takeEvery(CASOS_LIST,casosListSaga);
  yield takeEvery(CASOS_FILTER,casosFilterSaga);
  yield takeEvery(CASOS_CREATE,casosCreateSaga);
  yield takeEvery(CASOS_UPDATE,casosUpdateSaga);
}
