import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { DESCRIPCION_CALIFICACION_LIST,DESCRIPCION_CALIFICACION_LISTA,DESCRIPCION_CALIFICACION_ADD, DESCRIPCION_CALIFICACION_LIST_CHANGE } from "./actionTypes";
import {
  descripcionCalificacionListSuccess,
  descripcionCalificacionListFailed,
  descripcionCalificacionListaSuccess,
  descripcionCalificacionListaFailed,
  descripcionCalificacionAddSuccess,
  descripcionCalificacionAddFailed,


} from "./actions";

import {
  getDescripcionCalificacionList,
  getDescripcionCalificacionFullList,
  getChangeDescripcionCalificacionList,
  postDescripcionCalificacionAdd,

} from "../../helpers/backend";

function* descripcionCalificacionListChangeSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getChangeDescripcionCalificacionList, { changeUrl });

    if (response.status === 200) {
      yield put(descripcionCalificacionListSuccess(response.data));
    } else {
      yield put(descripcionCalificacionListFailed(response.data));
    }
  } catch (error) {
    yield put(descripcionCalificacionListFailed(error.response.data));
  }
}
function* descripcionCalificacionListSaga() {
  try {
    const response = yield call(getDescripcionCalificacionList);

    if (response.status === 200) {
      yield put(descripcionCalificacionListSuccess(response.data));
    } else {
      yield put(descripcionCalificacionListFailed(response.data));
    }
  } catch (error) {
    yield put(descripcionCalificacionListFailed(error.response.data));
  }
}

function* descripcionCalificacionListaSaga() {
  try {
    const response = yield call(getDescripcionCalificacionFullList);

    if (response.status === 200) {
      yield put(descripcionCalificacionListaSuccess(response.data));
    } else {
      yield put(descripcionCalificacionListaFailed(response.data));
    }
  } catch (error) {
    yield put(descripcionCalificacionListaFailed(error.response.data));
  }
}

function* descripcionCalificacionAddSaga({ payload: { descripcionCalificacion } }) {
  try {
    const response = yield call(postDescripcionCalificacionAdd, {
      name: descripcionCalificacion.name,
      calificacion: descripcionCalificacion.calificacion,

    });

    if (response.status === 201) {
      yield put(descripcionCalificacionAddSuccess(response.data));
    } else {
      yield put(descripcionCalificacionAddFailed(response.data));
    }
  } catch (error) {
    yield put(descripcionCalificacionAddFailed(error.response.data));
  }
}



function* descripcionCalificacionSaga() {
  yield takeEvery(DESCRIPCION_CALIFICACION_LIST, descripcionCalificacionListSaga);
  yield takeEvery(DESCRIPCION_CALIFICACION_LISTA, descripcionCalificacionListaSaga);
  yield takeEvery(DESCRIPCION_CALIFICACION_ADD, descripcionCalificacionAddSaga);
  yield takeEvery(DESCRIPCION_CALIFICACION_LIST_CHANGE, descripcionCalificacionListChangeSaga);

}
  
export default descripcionCalificacionSaga;
