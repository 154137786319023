import React, { useState } from 'react';
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import PhoneList from './ContactData/PhoneList';
import EmailList from './ContactData/EmailList';
import AddressList from './ContactData/AddressList';
import SocialList from './ContactData/SocialList';


const DatoTab = (props) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);

  }

  return (
    <React.Fragment>

      <Link
        className="btn btn-info btn-sm mb-2 "
        to="/contacts">
        Volver
      </Link>{"  "}
      <Card className="mb-0">
        <Nav tabs justified className="nav-tabs-custom" role="tablist">
          <NavItem>
            <NavLink
              to="#"
              className={classnames({ active: activeTab === '1' })}
              onClick={() => { toggle('1'); }}
              style={{ cursor: 'pointer' }}
            >
              <i className="bx  bx-mobile bx-sm"></i>
              <span className="d-none d-sm-block">Teléfonos </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to="#"
              className={classnames({ active: activeTab === '2' })}
              onClick={() => { toggle('2'); }}
              style={{ cursor: 'pointer' }}>
              <i className=" fas fa-envelope fa-md "></i>
              <span className="d-none d-sm-block">E- mails</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to="#"
              className={classnames({ active: activeTab === '3' })}
              onClick={() => { toggle('3'); }}
            >
              <i className="bx bx-detail bx-sm"></i>
              <span className="d-none d-sm-block">Direcciones</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to="#"
              className={classnames({ active: activeTab === '4' })}
              onClick={() => { toggle('4'); }}
            >
              <i className="bx bx-globe bx-sm"></i>
              <span className="d-none d-sm-block">Redes Sociales</span>
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent className="p-4" activeTab={activeTab}>

          <TabPane id="1" role="tabpanel" tabId="1">
            <PhoneList />
          </TabPane>

          <TabPane id="2" role="tabpanel" tabId="2">
            <EmailList />

          </TabPane>

          <TabPane id="3" role="tabpanel" tabId="3">
            <AddressList />

          </TabPane>
          <TabPane id="4" role="tabpanel" tabId="4">
            <SocialList />

          </TabPane>
        </TabContent>
      </Card>
    </React.Fragment>
  );
}


export default DatoTab;