import React from "react";
import { Input, Button, Row, Col } from "reactstrap";

const MultipleChoiceOptions = (props) => {
  const {options, setOptions} = props;

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = { number: index + 1, text: value };
    setOptions(newOptions);
  };  

  const handleAddOption = () => {
    setOptions([...options, ""]);
  };

  const handleRemoveOption = (index) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

// Dentro de la función renderOptions en el componente MultipleChoiceOptions

const renderOptions = () => {
  return options.map((option, index) => (
    <Row key={index} className="mb-2">
      <Col lg={8}>
        <Input
          type="text"
          placeholder={`Respuesta ${index + 1}`}
          value={option.text} // Mostrar el texto de la opción
          onChange={(e) => handleOptionChange(index, e.target.value)}
        />
      </Col>
      <Col lg={4}>
        <Button
          style={{ borderRadius: '20px' }}
          color="danger"
          onClick={() => handleRemoveOption(index)}
        >
          <span className="fas fa-minus "></span>
        </Button>
      </Col>
    </Row>
  ));
};


  // Render the options and the "Add" button
  return (
    <Row>
        <Col lg={9}>
        {renderOptions()}
        </Col>  
        <Col lg={3} className="text-end">        
        <Button style={{borderRadius:'20px'}} color="primary" onClick={handleAddOption}>
            <span className="fas fa-plus "></span>
        </Button>
        </Col>        
    </Row>

  );
};

export default MultipleChoiceOptions;
