import {
  CALIFICACION_CONTACTO_LIST,
  CALIFICACION_CONTACTO_LIST_CHANGE,
  CALIFICACION_CONTACTO_LIST_SUCCESS,
  CALIFICACION_CONTACTO_LIST_FAILED,
  CALIFICACION_CONTACTO_LISTA,
  CALIFICACION_CONTACTO_LISTA_SUCCESS,
  CALIFICACION_CONTACTO_LISTA_FAILED,
  CALIFICACION_CONTACTO_ADD,
  CALIFICACION_CONTACTO_ADD_SUCCESS,
  CALIFICACION_CONTACTO_ADD_FAILED,
  CALIFICACION_CONTACTO_DELETE,
  CALIFICACION_CONTACTO_DELETE_SUCCESS,
  CALIFICACION_CONTACTO_DELETE_FAILED,

} from "./actionTypes";


export const calificacionContactoList = () => {
  return {
    type: CALIFICACION_CONTACTO_LIST,
    payload: {},
  };
};
export const calificacionContactoListChange = (changeUrl) => {
  return {
    type: CALIFICACION_CONTACTO_LIST_CHANGE,
    payload: { changeUrl },
  };
};
export const calificacionContactoListSuccess = (calificacionContacto) => {
  return {
    type: CALIFICACION_CONTACTO_LIST_SUCCESS,
    payload: calificacionContacto,
  };
};

export const calificacionContactoListFailed = (error) => {
  return {
    type: CALIFICACION_CONTACTO_LIST_FAILED,
    payload: error,
  };
};

export const calificacionContactoLista = () => {
  return {
    type: CALIFICACION_CONTACTO_LISTA,
    payload: {},
  };
};

export const calificacionContactoListaSuccess = (calificacionContacto) => {
  return {
    type: CALIFICACION_CONTACTO_LISTA_SUCCESS,
    payload: calificacionContacto,
  };
};

export const calificacionContactoListaFailed = (error) => {
  return {
    type: CALIFICACION_CONTACTO_LISTA_FAILED,
    payload: error,
  };
};

export const calificacionContactoAdd = (calificacionContacto) => {
  return {
    type: CALIFICACION_CONTACTO_ADD,
    payload: { calificacionContacto },
  };
};

export const calificacionContactoAddSuccess = (calificacionContactoAdded) => {
  return {
    type: CALIFICACION_CONTACTO_ADD_SUCCESS,
    payload: calificacionContactoAdded,
  };
};

export const calificacionContactoAddFailed = (error) => {
  return {
    type: CALIFICACION_CONTACTO_ADD_FAILED,
    payload: error,
  };
};

export const calificacionContactoDelete = (id) => {
  return {
    type: CALIFICACION_CONTACTO_DELETE,
    payload: {id},
  };
};

export const calificacionContactoDeleteSuccess = (data) => {
  return {
    type: CALIFICACION_CONTACTO_DELETE_SUCCESS,
    payload: data,
  };
};

export const calificacionContactoDeleteFailed = (error) => {
  return {
    type: CALIFICACION_CONTACTO_DELETE_FAILED,
    payload: error,
  };
};