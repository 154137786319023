import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {

  Card,
  CardBody,
  Table,
  Button,
  Modal, 

} from "reactstrap";


import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert"

// Redux
import { connect} from "react-redux";
import { withRouter, useParams, Link} from "react-router-dom";


//Import Breadcrumb
import Loader from "../../../components/Common/Loader";
import Pagination from "../../../components/Common/Pagination";

//Action
import { contactsDocumentCreate } from "../../../store/contactsDocument/actions";
import { getContactsDocumentFilter, getContactsDocument } from "../../../helpers/backend";

const DocumentoContactoList = (props) => {
//  const dispatch= useDispatch();
  const param = useParams();
//const history = useHistory();
  const str = param.id; 
  const contactoId = Number(str);
  const URL = process.env.REACT_APP_API_URL;
  const url = URL.split('/api')
  const user = JSON.parse(localStorage.getItem("user"))
  const groups =String(user.groups);


  const optionGroupTipoIdentificacion = [
    {

      options: [
        { label: "Cédula Ciudadanía", value: "CEDULA_CIUDADANIA" },
        { label: "Pasaporte", value: "PASAPORTE" },
        { label: "RUT", value: "RUT" },
        { label: "NIT", value: "NIT" },
        { label: "Otro", value: "OTRO" },
      ],
    },

  ];
  
  const [tipoDocumento, setTipoDocumento] = useState(false)
  const [tipoDocumento_text, setTipoDocumento_text] = useState("")
  const [Documento, setDocumento] = useState(false)
  const [Documento_file, setDocumento_file] = useState("")
  //const [MostrarDocumento, setMostrarDocumento] = useState(false)
  const  [error, setError] = useState(false)
  const [showAlert,setShowAlert] = useState(true)


  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function handleTipoDocumentoChange(tipoDocumento_text) {
    setTipoDocumento_text(tipoDocumento_text.value)
  }
  
  const handleDocumentoAdd = () =>{ 
    setTipoDocumento(!tipoDocumento)
    removeBodyCss();
  }

  const handleMostrarDocumento =(path) => {
    window.open(`${url[0]}${path}`);

  }

  const handleDocumentoChange = (archivos) => {
    Array.from(archivos).forEach(archivo =>{
      var reader=new FileReader();
      reader.readAsDataURL(archivo);
      reader.onload=function(){
        var imgDocumento=[];
        var base64 = reader.result;
        imgDocumento=base64.split(',')
        setDocumento_file(imgDocumento[1])
      }
    })
  }

useEffect(() => {
  getContactsDocumentData();

// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);


const  [contactsDocument, setContactsDocument] = useState ({});
const getContactsDocumentData = async () => {
  const response = await getContactsDocument(contactoId);
  const data = response.data;
  setContactsDocument(data)
};

const params = {
  count: contactsDocument.count,
  next: contactsDocument.next,
  previous: contactsDocument.previous,
  results: contactsDocument.results,
};

  const onSubmit = () => {
    if (tipoDocumento_text !== "" && Documento_file !== "" ){
      props.contactsDocumentCreate({
        contacto : contactoId,
        documentType : tipoDocumento_text,
        document : Documento_file ,
      });
      setDocumento(false)
      setShowAlert(true)
    }
    else{
      setError(true)
    }

   };


  return (
    <React.Fragment>
        {props.loading ? (
          <Loader />
        ) : (
          <div>
            <Link
              className ="btn btn-info btn-sm mb-2 "
              to="/contacts">
                Volver
             </Link>{"  "}
             
            {groups.includes("SuperAdmin") 
              ||  groups.includes("Supervisor") 
              || groups.includes("Operador") 
              || groups.includes("Crear Documento de Cliente")
              || groups.includes("Cliente")  ? (
            <>  

              <Button className="btn btn-primary waves-effect waves-light btn-sm mb-2"
                color="success" 
                type="submit"
                onClick={() => handleDocumentoAdd()}
                  >
                  Cargar nuevo documento
              </Button> 
              </>
            ):null}
            
            <Card >       
                <CardBody>
                  <h4 className="card-title">Documentos del Contacto</h4>

        <Modal
            isOpen={tipoDocumento}
            toggle={() => {
                setTipoDocumento_text("")
                setTipoDocumento(false);
            }}
            centered={true}

        >
          <div className="modal-header">
              <h3 className="modal-title mt-2">
                  Tipo de Documento
                <span className="text-danger">*</span>
              </h3>
              <button
                  type="button"
                  onClick={() => {
                      setTipoDocumento_text("")
                      setTipoDocumento(false);
                  }}
                  className="btn"
                  data-dismiss="modal"
                  aria-label="Close"
              >
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div className="modal-body">
            <Select
                id= 'tipo_Contacto'
                name='tipo_Conatcto'
                options={optionGroupTipoIdentificacion}
                placeholder="Seleccione una opcion"    
                onChange={handleTipoDocumentoChange}           
            />
            <div className="modal-footer">
              <button
                  type="button"
                  className="btn btn-secondary text-dark"
                  onClick={() => {
                    setTipoDocumento_text(""); 
                    setTipoDocumento(false)
                  }}
              >
                  Cerrar
              </button>
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={()=> {
                    if (tipoDocumento_text !== ""){
                      setDocumento(true); setTipoDocumento(false)
                    }
                    else{
                      setError(true);
                    }
                    }}
              >
                  Siguiente
              </button>
          </div>
                               
          </div>  
        </Modal>

        <Modal
          isOpen={Documento}
          centered={true}
          toggle={()=> {setTipoDocumento_text(""); setDocumento(false)}}
          

        >
          <div className="modal-header">
              <h3 className="modal-title mt-2">
                  Documento
                <span className="text-danger">*</span>
              </h3>
              <button
                  type="button"
                  onClick={() => {
                    setTipoDocumento_text("")
                    setDocumento(false);
                  }}
                  className="btn"
                  data-dismiss="modal"
                  aria-label="Close"
              >
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div className="modal-body">
            <input
                type="file"
                className="form-control"
                onChange={(e) => handleDocumentoChange(e.target.files)}               
            />
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary text-dark"
              onClick={() => {
                setTipoDocumento_text("");
                setDocumento(false)
                setTipoDocumento(true)
              }}
              >
                  Atras
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={()=> {onSubmit()}}          

              >
                  Guardar
            </button>
          </div>
        </div>
        </Modal>

            {props.contactsDocumentCreated.contacto && showAlert ? (
                <SweetAlert
                    title="Hecho!"
                    success
                    timeout={2000}
                    showConfirm={false}                    
                    onConfirm={() => {
                      getContactsDocumentData();
                      setShowAlert(false)
                    }}
                >
                Se ha Agregado el documento con Éxito
                </SweetAlert>
            ) : null} 
            {error  ? (
              <SweetAlert
                title="Error"
                warning
                timeout={2000}
                showConfirm={false}
                onConfirm={() => {
                  setError(false);
                }}
              >
                Debe Agregar los Campos Requeridos
              </SweetAlert>
             ) : null}
             
                  <div className="table-responsive">
                    <Table
                       className="table  text-center"
                      hover
                    >
                      <thead className="table-dark">
                        <tr>
                          <th>#</th>
                          <th>Contacto</th>
                          <th>Tipo de Documento</th>
                          <th>Documento</th>
                          <th>Usuario</th>
                          {groups.includes("SuperAdmin") ||  groups.includes("Supervisor") || groups.includes("Operador") || groups.includes("Crear Documento de Cliente") ? (
                          <th>Accion</th>
                          ):null}
                        </tr>
                      </thead>
                      <tbody>
                          {Array.isArray(params.results) ? params.results.map((documento, idx) => (
                              <tr key={idx}>
                                <th scope="row">{idx +1}</th>
                                <td>{documento.contact}</td>
                                <td>{documento.documentType}</td>
                                <td>
                                  <Link  
                                  to='#'
                                  onClick={() => handleMostrarDocumento(documento.document)}
                                  >                             
                                     <span className=" fas fa-eye fa-lg "></span>
                                  </Link> 
                  
                                 </td>
                                <td>{documento.user}</td>
                               
                                <td>
                                {groups.includes("SuperAdmin") ||  groups.includes("Supervisor") || groups.includes("Operador") || groups.includes("Crear Documento de Cliente") ? (
                                  <Link to = '#'                               
                                  >
                                    <span className="bx bx-edit fa-lg align-middle"></span>
                                  </Link> ):null} {' '}
                                  {groups.includes("SuperAdmin") ||  groups.includes("Supervisor")  ? ( 

                                  <Link  to=""                              
                                >
                                  <span className="bx bx-trash fa-lg align-middle"></span>
                                </Link>
                                ):null}
                                </td>
                              </tr>
                            )):[]}
                        </tbody>
                      
                    </Table>
                  </div>
                  <Pagination
                      count={params.count}
                      next={params.next}
                      previous={params.previous}
                      limit={30}
                      baseUrl="contact/"
                      filterFunction={getContactsDocumentFilter}
                    />
                </CardBody>

            </Card>
        
          </div>
        )}
  </React.Fragment>
);
};
const mapStateToProps = (state) => {
  const { error, loading, contactsDocument, contactsDocumentCreated } = state.ContactsDocument;
  return { error, loading, contactsDocument, contactsDocumentCreated };
};
contactsDocumentCreate.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  contactsDocumentCreated: PropTypes.object,
};
export default withRouter(connect(mapStateToProps,{ contactsDocumentCreate })(DocumentoContactoList));