import { TbSearch } from "react-icons/tb";
import { TbLayoutList } from "react-icons/tb";
import { TbLayoutGrid } from "react-icons/tb";



const NavbarProductosMarket = ({ listType, onListTypeChange, onSearchChange }) => {

 

  return (
    <section className="content_navbar_market">
      <div className="content_searh_market">
        <div>
          <input
            className="input_search_market"
            type="text"
            placeholder="Buscar"
            onChange={onSearchChange}
          />
        </div>
        <div className="content_button_search_market">
          <TbSearch size={20} color="#fff" strokeWidth={2.5} />
        </div>
      </div>

      <div className='contain_list_type' onClick={onListTypeChange}> 
        {listType ? (
          <div className='content_list_type'>
            <TbLayoutGrid size={26} strokeWidth={1.5}/>
          </div>
        ) : (
          <div className='content_list_type'>
            <TbLayoutList size={26} strokeWidth={1.5}/>
          </div>
        )}
      </div>
    </section>
  );
}

export default NavbarProductosMarket