// SURVEY requests
export const SURVEY_LIST = "SURVEY_LIST";
export const SURVEY_CREATE = "SURVEY_CREATE";
export const SURVEY_FILTER = "SURVEY_FILTER";
export const SURVEY_UPDATE = "SURVEY_UPDATE";
export const SURVEY_DELETE = "SURVEY_DELETE";
export const SURVEY_READ = "SURVEY_READ";

// SURVEY success
export const SURVEY_LIST_SUCCESS = "SURVEY_LIST_SUCCESS";
export const SURVEY_CREATE_SUCCESS = "SURVEY_CREATE_SUCCESS";
export const SURVEY_UPDATE_SUCCESS = "SURVEY_UPDATE_SUCCESS";
export const SURVEY_DELETE_SUCCESS = "SURVEY_DELETE_SUCCESS";
export const SURVEY_READ_SUCCESS = "SURVEY_READ_SUCCESS";

// SURVEY failed
export const SURVEY_REQUEST_FAILED = "SURVEY_REQUEST_FAILED";
