import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Table,
  Input,
  Button,
  Form,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
// Import Breadcrumb
import Loader from "../../components/Common/Loader";
import Pagination from "../../components/Common/Pagination_ansync";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getBroadcastCampaign, getBroadcastCampaignFilter } from "../../helpers/backend";
import BroadcastCampaignAdd from "./BroadcastCampaignAdd";
import BroadcastMessagesModal from "../Leads/components/BroadcastMessages";
import ChartsModal from "./ChartsModal";

const BroadcastCampaign = () => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [modalAdd, setModalAdd] = useState(false);
  const [modalSend, setModalSend] = useState(false);
  const [modalDetails, setModalDetails] = useState(false);
  const [modalData, setModalData] = useState({});
  const [broadcastCampaignId, setBroadcastCampaignId] = useState(null);
  const [modalCharts, setModalCharts] = useState(false);
  const [managementData, setManagementData] = useState({});
  const [params, setParams] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });


  const openChartModal = (data) => {
    setManagementData({
      exitosos: data.exitosos,
      fallidos: data.fallidos,
      sin_telefono: data.sin_telefono,
      total_contactos: data.total_contactos,
    });
    setModalCharts(true);
  };
  const getData = async () => {
    const response = await getBroadcastCampaign();
    setLoading(true);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    getFilterData(`?search=${search}`);
  };

  const resetSearch = () => {
    getFilterData(`?search=`);
    setSearch("");
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterData = async (url) => {
    setLoading(true);
    const response = await getBroadcastCampaignFilter(url);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };


  const openModal = (data) => {
    setModalData(data);
    setModalDetails(true);
  };
  console.log(params);
  return (
    <React.Fragment>
      <div>
        {modalAdd ? <BroadcastCampaignAdd isOpen={modalAdd} onClose={() => setModalAdd(false)} refresh={() => getData()} /> : null}
        {modalSend ? <BroadcastMessagesModal isOpen={modalSend} onClose={() => setModalSend(false)} broadcastCampaign={broadcastCampaignId} /> : null}
        {modalCharts ? <ChartsModal  isOpen={modalCharts} toggle={() => setModalCharts(false)} managementData={managementData} /> : null }
        <Container fluid>
          {loading ? (
            <Loader />
          ) : (
            <div>
              <Row className="mb-2 mt-3">
                <Col md={7}>
                  <Link
                    className="btn btn-success waves-effect waves-light btn-sm mb-0 mt-2"
                    to="#"
                    onClick={() => setModalAdd(true)}
                  >
                    Agregar Campañas de Difusión{" "}
                  </Link>
                </Col>
                <Col md={5}>
                  <Form className="search-box" onSubmit={onSubmit}>
                    <InputGroup>
                      <Input
                        type="text"
                        className="form-control"
                        name="search"
                        placeholder="Nombre..."
                        value={search}
                        onChange={onChange}
                      />
                      <i className="bx bx-search-alt search-icon" />
                      <Button color="primary" onClick={resetSearch}>
                        <span className="bx bx-x fa-lg align-middle"></span>
                      </Button>
                    </InputGroup>
                  </Form>
                </Col>
              </Row>

              <Card>
                {params.results && params.results.length > 0 ? (
                  <CardBody className="ps-5 pe-5">
                    <h2 className="mb-3 font-size-20"> Campañas de Difusión</h2>
                    <div className="table-responsive">
                      <Table
                        className="table align-middle table-centered mb-3"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <thead className="table-dark">
                          <tr>
                            <th>#</th>
                            <th>Nombre</th>
                            <th>Canal</th>
                            <th>Campaña</th>
                            <th>Estado</th>
                            <th>Descripción</th>
                            <th>Mensajes</th>
                            <th>Exitosos</th>
                            <th>Fallidos</th>
                            <th>Creado</th>
                            <th>Estadísticas</th>
                            <th>Enviar</th>
                          </tr>
                        </thead>
                        <tbody>
                          {params.results.map((item, index) => (
                            <tr key={index}>
                              <td><b>{index + 1}</b></td>
                              <td>{item.name}</td>
                              <td>{item.managements && item.managements.length > 0 ? item.managements[0].channel : null}</td>
                              <td>{item.campaign}</td>
                              <td>{item.managements && item.managements.length > 0 ? item.managements[0].status : null}</td>
                              <td>{item.description}</td>
                              <td>{item.managements && item.managements.length > 0 ? item.managements[0].template : null}</td>
                              <td>
                                <Link to="#" onClick={() => openModal(item.managements && item.managements.length > 0 ? item.managements[0].metadata.exitosos_detalles.detalles : null)}>
                                  {item.managements && item.managements.length > 0 ? item.managements[0].metadata.exitosos : null}
                                </Link>
                              </td>
                              <td>
                                <Link to="#" onClick={() => openModal(item.managements && item.managements.length > 0 ? item.managements[0].metadata.fallidos_detalles.detalles : null)}>
                                  {item.managements && item.managements.length > 0 ? item.managements[0].metadata.fallidos : null}
                                </Link>
                              </td>
                              <td>{item.creado}</td>
                              <td>
                              {item.managements && item.managements.length > 0 ? (
                                <Link to="#" onClick={() => openChartModal(item.managements && item.managements.length > 0 ? item.managements[0].metadata : null)}>
                                  <span
                                    className="fas fa-chart-bar fa-md"
                                    style={{ cursor: "pointer" }}
                                  ></span>
                                </Link>
                              ):null}
                              </td>
                              <td>
                                {item.managements && item.managements.length === 0 ? (
                                  <Link to="#" onClick={() => { setModalSend(true); setBroadcastCampaignId(item.id) }}>
                                    <span
                                      className="fas fa-paper-plane fa-md"
                                      style={{ cursor: "pointer" }}
                                    ></span>
                                  </Link>
                                ) : null}
                              </td>
                            </tr>
                          ))}
                        </tbody>


                      </Table>

                    </div>
                    <Pagination
                      count={params.count}
                      next={params.next}
                      previous={params.previous}
                      limit={30}
                      baseUrl={`whatsapp/template/`}
                      filterFunction={getFilterData}
                    />
                  </CardBody>
                ) : (
                  <CardBody>
                    <h4 className="card-title">No se encontraron resultados</h4>
                  </CardBody>
                )}
              </Card>
              <Modal isOpen={modalDetails} toggle={() => setModalDetails(false)}>
                <h4 className="text-primary pt-3 px-3">Detalles de Gestión</h4>
                <ModalBody style={{overflowY: 'scroll' , maxHeight: "350px"}}>
                  {modalData && modalData && modalData.length > 0 ? (
                    <Table className="table table-sm align-middle table-centered mb-3">
                      <thead>
                        <tr>
                          <th>Contacto</th>
                          <th>Teléfono Enviado</th>
                        </tr>
                      </thead>
                      <tbody>
                        {modalData.map((contacto, index) => (
                          <tr key={index}>
                            <td>{contacto.nombre}</td>
                            <td>{contacto.telefono}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <p>No hay detalles disponibles</p>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" className="text-dark" onClick={() => setModalDetails(false)}>Cerrar</Button>
                </ModalFooter>
              </Modal>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BroadcastCampaign;
