import { call, put, takeEvery } from "redux-saga/effects";

import {
  SURVEY_LIST,
  SURVEY_FILTER,
  SURVEY_CREATE,
  SURVEY_UPDATE,
} from "./actionTypes";

import {
 surveyListSuccess,
 surveyCreateSuccess,
 surveyUpdateSuccess,
 surveyRequestFailed,
} from "./actions";

import {
  getSurveyList,
  getSurveyFilter,
  postSurveyCreate,
  patchSurveyUpdate,
} from "../../helpers/backend";

function*surveyListSaga() {
  console.log("Trayendo lista de survey...");
  try {
    const response = yield call(getSurveyList);
    if (response.status === 200) {
      yield put(surveyListSuccess(response.data));
    } else {
      yield put(surveyRequestFailed(response.data));
    }
  } catch (error) {
    yield put(surveyRequestFailed(error.response.data));
  }
}

function*surveyCreateSaga({ payload:survey }) {
  console.log("Creando nuevo survey...");
  try {
    const response = yield call(postSurveyCreate,survey);
    if (response.status === 201) {
      yield put(surveyCreateSuccess(response.data));
    } else {
      yield put(surveyRequestFailed(response.data));
    }
  } catch (error) {
    yield put(surveyRequestFailed(error.response.data));
  }
}

function*surveyFilterSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getSurveyFilter, { changeUrl });
    if (response.status === 200) {
      yield put(surveyListSuccess(response.data));
    } else {
      yield put(surveyRequestFailed(response.data));
    }
  } catch (error) {
    yield put(surveyRequestFailed(error.response.data));
  }
}

function*surveyUpdateSaga({ payload: { id, data } }) {
  console.log("Actualizando datos del survey...");
  try {
    const response = yield call(patchSurveyUpdate, { id }, data);
    if (response.status === 200) {
      yield put(surveyUpdateSuccess(response.data));
    } else {
      yield put(surveyRequestFailed(response.data));
    }
  } catch (error) {
    yield put(surveyRequestFailed(error.response.data));
  }
}

export default function*surveySaga() {
  yield takeEvery(SURVEY_LIST,surveyListSaga);
  yield takeEvery(SURVEY_FILTER,surveyFilterSaga);
  yield takeEvery(SURVEY_CREATE,surveyCreateSaga);
  yield takeEvery(SURVEY_UPDATE,surveyUpdateSaga);
}
